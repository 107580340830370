import { type ZitadelTokenResponse } from '@flyward/platform/models'
import { getPersistedSupportFilteredTenantId, persistSupportFilteredTenantId } from '@flyward/platform/services/AUTH/supportFilteredTenantIdStore'
import { persistZitadelUserAccessToken, removePersistedZitadelUserAccessToken } from '@flyward/platform/services/AUTH/userTokenStore'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface TZitadelUser {
  token: ZitadelTokenResponse
}

interface AuthState {
  zitadelUser: TZitadelUser | null
  supportFilteredTenantId: string | null
}

const authSlice = createSlice({
  name: 'auth',
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  initialState: {
    zitadelUser: null,
    supportFilteredTenantId: getPersistedSupportFilteredTenantId(),
  } as AuthState,
  reducers: {
    clearZitadelUserInformation: (state) => {
      state.zitadelUser = null
      state.supportFilteredTenantId = null
      removePersistedZitadelUserAccessToken()
    },

    setZitadelUserInformation: (state, { payload: { user } }: PayloadAction<{ user: TZitadelUser }>) => {
      state.zitadelUser = user

      persistZitadelUserAccessToken(user.token)
    },

    setSupportFilteredTenantId: (state, { payload: { tenantId } }: PayloadAction<{ tenantId: string }>) => {
      state.supportFilteredTenantId = tenantId

      persistSupportFilteredTenantId(tenantId)
    },
  },
})

export const { clearZitadelUserInformation, setZitadelUserInformation, setSupportFilteredTenantId } = authSlice.actions
export const { reducer: authReducer } = authSlice
