import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAllEngineChecksWithDetailsByAssetIdQueryResponse, type IGetEnginesByAssetIdQueryResponse } from './types'
import { MaintenanceProgramType } from '@flyward/knowledgeBase/models/enums'

const enginesApiMocks = [
  http.get(APIRoutes.AssetsModule.Components.EnginesController.GetAll('*'), () => {
    return HttpResponse.json<IGetEnginesByAssetIdQueryResponse>(
      [
        {
          assignedKBProgramId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          engineThrustRating: 27000,
          engineLifeLimitedParts: [
            {
              assetId: 'b1b1b1b1-b1b1-b1b1-b1b1-b1b1b1b1b1b1',
              componentId: 'eb0a5bcf-5c0b-4826-ade6-7ffa4e9299d2',
              componentType: 2,
              manufacturingDetail: {
                manufacturer: 'IAE',
                dom: '2011-02-15',
              },
              componentModel: 'V2527-A5',
              componentModule: 't',
              technicalLimitUnit: 3,
              technicalLimitAmount: 20000,
              componentSerialNumber: 'MAT00994',
              partNumber: '3A2522',
              assetComponentUtilizationSnapshot: {
                dateOfLastSnapshot: new Date('2023-02-23'),
                totalHoursSinceNew: 0,
                cyclesSinceNew: 3858,
                remainingCycles: 16142,
                componentUtilizationAtEvents: [
                  {
                    componentUtilizationSnapshotId: '6c471ccb-8a57-4383-8132-0f4bcec74efa',
                    eventType: 2,
                    totalHoursSinceNewAtLastQualifyingEvent: 0,
                    cyclesSinceNewAtLastQualifyingEvent: 5462,
                    dateAtLastQualifyingEvent: '2018-10-04',
                  },
                ],
              },
            },
          ],
          engineContract: {
            contractDeliverySnapshot: {
              checkType: 4,
            },
            escalations: {
              eprEscalation: {
                maintenanceReserveAgreedEscalationPercentage: 3,
              },
              llPsEscalation: {
                maintenanceReserveAgreedEscalationPercentage: 7,
              },
            },
            llPsContractualBuildStandard: 8000,
            isFirstRun: false,
            engineUnitMaintenanceReserveContract: {
              isFirstRun: false,
              isEolActive: false,
              isMaintenanceReserveFundFlushAtEvent: true,
              enginePerformanceRestorationMaintenanceRate: {
                checkType: 1,
                utilizationUnit: 2,
                maintenanceReserveCollectionType: 1,
                ratesYear: 2023,
                rateAmount: 198,
                currentFund: 796141.69,
              },
              engineLLPsTotalMaintenanceReserveRate: {
                checkType: 2,
                utilizationUnit: 3,
                maintenanceReserveCollectionType: 1,
                ratesYear: 2023,
                rateAmount: 19.7170762621657,
                currentFund: 53816.7845793638,
              },
              isMaintenanceReserveActive: true,
            },

            minimumReDeliveryConditions: {
              llpMinimumReDeliveryConditions: [
                {
                  minimumReDeliveryConditionType: 1,
                  minimumReDeliveryConditionUnit: 3,
                  value: 3500,
                },
              ],
              eprMinimumReDeliveryConditions: [
                {
                  minimumReDeliveryConditionType: 1,
                  minimumReDeliveryConditionUnit: 2,
                  value: 5000,
                },
                {
                  minimumReDeliveryConditionType: 1,
                  minimumReDeliveryConditionUnit: 3,
                  value: 3500,
                },
              ],
            },
            contractualFlightHoursToFlightCyclesPerformanceRestorationReserves: [
              {
                flightHoursToFlightCyclesRatio: 1.7,
                reserveAmount: 188,
              },
              {
                flightHoursToFlightCyclesRatio: 1.3,
                reserveAmount: 220,
              },
            ],
          },
          componentId: '409b0912-fda6-47df-bd1a-bbb6aee84686',
          componentSerialNumber: 'V15770-one_llp-E_new',
          componentType: 2,
          componentModel: 'V2527-A5',

          manufacturingDetail: {
            manufacturer: 'CFM International',
            dom: '2023-03-01',
          },
          assetId: 'bcb4ba13-90b5-4b38-a2c1-8adbdd8171f2',
          assetComponentUtilizationSnapshot: {
            componentUtilizationAtEvents: [],
            cyclesSinceNew: 0,
            dateOfLastSnapshot: new Date('2023-02-23'),
            totalHoursSinceNew: 20,
          },
          partNumber: '5R0159',
        },
      ],
      { status: 200 },
    )
  }),
  http.get(APIRoutes.AssetsModule.Components.EnginesController.GetExpandedEngines('*'), () => {
    return HttpResponse.json<IGetAllEngineChecksWithDetailsByAssetIdQueryResponse>(
      [
        {
          id: 'comp-001',
          type: MaintenanceProgramType.Engine,
          serialNumber: 'SN-ABC123456',
          model: 'CFM56-7B24',
          totalHoursSinceNew: 12000,
          cyclesSinceNew: 5000,
          hoursSinceLastPr: 1000,
          cyclesSinceLastPr: 400,
          llpCyclesRemaining: 2000,
          llpDescriptor: 'Fan Disk',
          prFund: 75000,
          llpsFund: 125000,
        },
      ],
      { status: 200 },
    )
  }),
]

export { enginesApiMocks }
