import { AircraftTechSpec } from '@flyward/assets'
import { OutletTabs, useHeaderContent, useNavigationState, useSpinnerState } from '@flyward/platform'
import {
  useGetAircraftDetailsQuery,
  useGetAllAirframesByAssetIdQuery,
  useGetAllAuxiliaryPowerUnitsByAssetIdQuery,
  useGetAllEnginesByAssetIdQuery,
  useGetAllLandingGearsByAssetIdQuery,
} from '@flyward/platform/store'
import { isEmpty } from 'lodash'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../layout'
import { AircraftMaintenanceProgramsSection } from './AircraftMaintenanceProgramsSection'

export const AircraftPageBody = () => {
  const currentTab = useRef<string>('Tech Spec')

  const onTabChanged = (activeTab: string) => {
    currentTab.current = activeTab
  }

  const { id } = useParams()
  const { setTitle, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()
  const { showSpinner, hideSpinner } = useSpinnerState()

  const assetId: string = id ?? ''

  useEffect(() => {
    toggleVisibility()
    setTitle('View Aircraft')
    setHasBackButton(true)

    return () => {
      setHasBackButton(false)
      setTitle('')
      toggleVisibility()
    }
  }, [setHasBackButton, setTitle, toggleVisibility, assetId])

  const { data: aircraftDetails, isLoading: isLoadingAircrafts } = useGetAircraftDetailsQuery({ assetId })
  const { data: engines = [], isLoading: isLoadingEngines } = useGetAllEnginesByAssetIdQuery({ assetId, includeLLPs: true })

  const { data: airframes = [], isLoading: isLoadingAirframes } = useGetAllAirframesByAssetIdQuery({
    assetId,
  })
  const { data: auxiliaryPowerUnits = [], isLoading: isLoadingAuxiliaryPowerUnits } = useGetAllAuxiliaryPowerUnitsByAssetIdQuery(
    { assetId },
    { skip: isEmpty(assetId) },
  )
  const { data: landingGears = [], isLoading: isLoadingLandingGears } = useGetAllLandingGearsByAssetIdQuery({
    assetId,
  })

  const areComponentsLoading = isLoadingAircrafts || isLoadingEngines || isLoadingAirframes || isLoadingAuxiliaryPowerUnits || isLoadingLandingGears

  const airframeKBProgramId = airframes?.[0]?.assignedKBProgramId ?? ''
  const engineAssignedKBProgramId = engines?.[0]?.assignedKBProgramId ?? ''
  const auxiliaryPowerUnitKBProgramId = auxiliaryPowerUnits?.[0]?.assignedKBProgramId ?? ''
  const landingGearKBProgramId = landingGears?.[0]?.assignedKBProgramId ?? ''

  const tabs = {
    'Tech Spec': {
      content: (
        <AircraftTechSpec
          key={assetId}
          assetId={assetId}
          aircraft={aircraftDetails}
          airframes={airframes}
          auxiliaryPowerUnits={auxiliaryPowerUnits}
          engines={engines}
          landingGears={landingGears}
        />
      ),
    },
    'Maintenance Program': {
      content: (
        <AircraftMaintenanceProgramsSection
          key={`${assetId}-${airframeKBProgramId}-${engineAssignedKBProgramId}-${auxiliaryPowerUnitKBProgramId}-${landingGearKBProgramId}`}
          assetId={assetId}
          airframeKBProgramId={airframeKBProgramId}
          engineKBProgramId={engineAssignedKBProgramId}
          auxiliaryPowerUnitKBProgramId={auxiliaryPowerUnitKBProgramId}
          landingGearKBProgramId={landingGearKBProgramId}
          airframes={airframes}
          auxiliaryPowerUnits={auxiliaryPowerUnits}
          engines={engines}
          landingGears={landingGears}
        />
      ),
    },
  }

  areComponentsLoading ? showSpinner() : hideSpinner()

  return (
    <PageContainer>
      <OutletTabs tabs={tabs} activeTab={currentTab.current} onTabChanged={onTabChanged} />
    </PageContainer>
  )
}
