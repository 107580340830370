import { useNavigationState, useHeaderContent, cn, Logo, LogoVariant, Button, IconVariant, ButtonVariant, Size, Input } from '@flyward/platform'
import { Link, useNavigate } from 'react-router-dom'
import styles from './NavHeader.module.css'

export const NavHeader = () => {
  const { isCollapsed, isHidden } = useNavigationState()
  const { title, buttons, customElements, inputs, hasBackButton } = useHeaderContent()
  const navigate = useNavigate()

  return (
    <div className="flex h-20">
      <div
        className={cn('z-50 flex flex-shrink-0 items-center gap-x-1 bg-black-0 p-6', styles.NavHeader__SideNav, isCollapsed ? 'w-21' : 'w-61', {
          hidden: isHidden,
        })}
      >
        <Link className="flex w-full items-center" to="/home/user-info">
          <Logo isCollapsed={isCollapsed} variant={LogoVariant.black} />
        </Link>
      </div>
      <div className="flex w-full gap-x-4 pb-4 pl-6 pr-6 pt-6">
        <h6 className="mr-auto flex items-center !text-h6 font-semibold text-text-1" data-testid="nav-header-title">
          {hasBackButton ? (
            <Button
              leftIcon={IconVariant.ArrowBack}
              variant={ButtonVariant.Ghost}
              size={Size.Large}
              key="header-back-button"
              className=" ml-0 pl-0 text-text-1"
              onClick={() => {
                navigate(-1)
              }}
            />
          ) : null}
          {title}
        </h6>
        <div className="flex gap-x-6">
          {inputs.map((inputsProps) => (
            <Input key={inputsProps.label} {...inputsProps} />
          ))}
        </div>
        {buttons.length > 0 && (
          <div className="flex gap-x-6 pr-2">
            {buttons.map((buttonProps) => (
              <Button key={buttonProps.label} {...buttonProps} />
            ))}
          </div>
        )}
        {customElements.length > 0 && (
          <div className="flex gap-x-6 pr-3" data-testid="nav-header-custom-elements">
            {customElements}
          </div>
        )}
      </div>
    </div>
  )
}
