import { ComponentMinimumReDeliveryConditionDtoSchema, type ComponentMinimumReDeliveryConditionDto } from '@flyward/platform'
import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Engine.EngineUnitMinimumReDeliveryConditionDto
 */

export interface EngineUnitMinimumReDeliveryConditionDto {
  llpMinimumReDeliveryConditions: ComponentMinimumReDeliveryConditionDto[]
  eprMinimumReDeliveryConditions: ComponentMinimumReDeliveryConditionDto[]
}

export const EngineMinimumReDeliveryConditionSchema = z.object({
  llpMinimumReDeliveryConditions: z.array(ComponentMinimumReDeliveryConditionDtoSchema).optional().nullable(),
  eprMinimumReDeliveryConditions: z.array(ComponentMinimumReDeliveryConditionDtoSchema).optional().nullable(),
})
