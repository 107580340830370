import * as React from 'react'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { cn } from './utils'
import { Icon, IconVariant } from '../Icon'

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>>(
  ({ className, ...props }, ref) => (
    <SwitchPrimitives.Root
      className={cn(
        'transition-colors relative inline-flex h-7 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent p-0.5 outline-none ring-2 ring-primary duration-200 ease-in-out',
        props.checked ? 'bg-primary' : 'bg-light-2',
        props.disabled && 'cursor-not-allowed bg-header-table ring-black-50',
        className,
      )}
      {...props}
      ref={ref}
    >
      <Icon
        variant={props.checked ? IconVariant.ToggleOn : IconVariant.ToggleOff}
        className={cn(
          'transition pointer-events-none absolute -mt-0.5 box-content inline-block transform rounded-full duration-200 ease-in-out',
          props.checked ? 'translate-x-4' : 'translate-x-0',
          props.checked ? 'bg-black-light-2 text-black-0' : 'bg-black-0 text-black-50',
        )}
      />
    </SwitchPrimitives.Root>
  ),
)
Switch.displayName = 'Switch'

export { Switch }
