/* eslint-disable max-len */
import { RedeliveryConditionsEditable } from '@flyward/assets/components/AircraftComponents/common'
import { type ApuDto } from '@flyward/assets/models'
import {
  CheckTypeDisplay,
  EditableBooleanCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  InputType,
  MaintenanceReserveCollectionTypeDisplay,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { type Control } from 'react-hook-form'

interface IAPUContractualEditableTabProps {
  component: ApuDto
  formControl: Control<ApuDto, unknown>
}

export const APUContractualEditableTab = ({ component, formControl }: IAPUContractualEditableTabProps) => {
  const maintenanceReserve = component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract
  const rate = maintenanceReserve.auxiliaryPowerUnitPRMaintenanceReserveRate
  const minimumRedeliveryConditions = component.auxiliaryPowerUnitContract.minimumReDeliveryConditions
  const contractDeliverySnapshot = component.auxiliaryPowerUnitContract.contractDeliverySnapshot

  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-x-6 gap-y-2">
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="MR Active"
          formControl={formControl}
          fieldName={`auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Decimal}
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate?.checkType)} MR rate`}
          formControl={formControl}
          fieldName={`auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.auxiliaryPowerUnitPRMaintenanceReserveRate.rateAmount`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate?.checkType)} rate type`}
          info={UtilizationUnitsDisplay(rate?.utilizationUnit)}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} rate year`}
          formControl={formControl}
          fieldName={`auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.auxiliaryPowerUnitPRMaintenanceReserveRate.ratesYear`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate?.checkType)} MR collection type`}
          info={MaintenanceReserveCollectionTypeDisplay(rate?.maintenanceReserveCollectionType)}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`Current ${CheckTypeDisplay(rate?.checkType)} MR fund`}
          formControl={formControl}
          fieldName={`auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.auxiliaryPowerUnitPRMaintenanceReserveRate.currentFund`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
      </div>
      <div className="flex w-full gap-x-6 gap-y-2">
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          formControl={formControl}
          fieldName={`auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isEolActive`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Percentage}
          className="basis-1/6"
          label="MR Escalation"
          formControl={formControl}
          fieldName={`auxiliaryPowerUnitContract.escalations.maintenanceReserveAgreedEscalationPercentage`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`Hours since ${CheckTypeDisplay(contractDeliverySnapshot.checkType)} at contract delivery`}
          formControl={formControl}
          fieldName={`auxiliaryPowerUnitContract.contractDeliverySnapshot.hoursSinceEventAtContractDelivery`}
        />
        <div className="mr-12 basis-3/6" />
      </div>
      <div className="flex gap-x-6">
        <RedeliveryConditionsEditable
          label="Redelivery Conditions"
          fieldPath="auxiliaryPowerUnitContract.minimumReDeliveryConditions"
          formControl={formControl}
          conditions={minimumRedeliveryConditions}
        />
        <div className="mr-12 basis-2/6" />
      </div>
    </div>
  )
}
