import * as React from 'react'
import {
  Dialog as CnDialog,
  DialogPortal as CnDialogPortal,
  DialogClose as CnDialogClose,
  DialogContent as CnDialogContent,
  DialogHeader as CnDialogHeader,
  DialogFooter as CnDialogFooter,
  DialogTitle as CnDialogTitle,
  DialogDescription as CnDialogDescription,
} from '../_shadcn'
import { Button, type IButtonProps } from '../Button'
import { useState } from 'react'
import { cn } from '../utils'
import { isNil } from 'lodash'

interface IDialogButtonProps extends IButtonProps {
  isClose?: boolean
}

interface IDialogProps {
  children?: React.ReactNode
  contentClassName?: string
  description?: string
  footerActions?: IDialogButtonProps[]
  headerActions?: React.ReactNode
  title?: string
  triggerButton: ({ onClick }: { onClick: () => void }) => JSX.Element
}

const Dialog = ({
  children = <></>,
  contentClassName = '',
  description = '',
  footerActions = [],
  headerActions = <></>,
  title = '',
  triggerButton = (_) => <></>,
}: IDialogProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  const footerButtons = React.useMemo(() => {
    const buttons = footerActions?.map((buttonProps) => {
      const { isClose, onClick, ...rest } = buttonProps
      const handleOnClick =
        isClose ?? false
          ? (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (onClick != null) {
                onClick(ev)
              }
              setIsOpen(false)
            }
          : onClick

      return <Button key={buttonProps.label} onClick={handleOnClick} {...rest} />
    })

    return <div className="flex gap-x-6">{buttons}</div>
  }, [footerActions])

  const TriggerButton = triggerButton

  return (
    <>
      <TriggerButton onClick={handleOpen} />
      <CnDialog open={isOpen} onOpenChange={handleClose}>
        <CnDialogPortal>
          <CnDialogContent className={cn('gap-6', contentClassName)}>
            <CnDialogHeader className="flex flex-row items-start">
              <>
                <CnDialogTitle>{title}</CnDialogTitle>
                {!isNil(description) && <CnDialogDescription>{description}</CnDialogDescription>}
              </>
              {headerActions}
            </CnDialogHeader>
            {children}
            <CnDialogFooter>
              {footerButtons}
              <CnDialogClose onClick={handleClose} />
            </CnDialogFooter>
          </CnDialogContent>
        </CnDialogPortal>
      </CnDialog>
    </>
  )
}

export { Dialog }
