import React, { useMemo } from 'react'
import { withSuspenseAndErrorBoundary } from './withSuspenseAndErrorBoundary'
import { LoadingFallback } from './LoadingFallback'

interface ILoadingComponentWithSuspenseAndErrorBoundary {
  children: React.ReactNode
  hasSuspense?: boolean
  isLoadingControlled?: boolean
  controlledError?: Error
}

const ErrorBoundary = ({
  children,
  hasSuspense = true,
  isLoadingControlled = false,
  controlledError = undefined,
}: ILoadingComponentWithSuspenseAndErrorBoundary): React.ReactElement | null => {
  const WrappedChildren = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const WrappedComponent = withSuspenseAndErrorBoundary(() => child, { hasSuspense, controlledError })
          return <WrappedComponent />
        }
        return child
      }),
    [children, controlledError, hasSuspense],
  )

  if (isLoadingControlled) {
    return <LoadingFallback />
  }

  return <>{WrappedChildren}</>
}
export { ErrorBoundary }
