import React from 'react'
import { TableCell, TableRow } from '../_shadcn'
import { Button, ButtonVariant } from '../Button'
import { Size, type TableColumn, type TableRowData } from '../common'
import { IconVariant } from '../Icon'
import { DynamicTable } from './DynamicTable'

interface ITableProps<T> {
  columns: Array<TableColumn<T>>
  data: TableRowData<T | undefined> | undefined
  tableClassName?: string
  canAddNewRow?: boolean
  canUploadFromFile?: boolean
  summaryRow?: React.ReactElement
}

const Table = <T extends object>({
  columns,
  data,
  canAddNewRow,
  tableClassName = '',
  canUploadFromFile,
  summaryRow,
}: ITableProps<T>): React.ReactElement<ITableProps<T>> => {
  return (
    <div>
      {canUploadFromFile === true && (
        <div className="flex justify-end">
          <Button leftIcon={IconVariant.UploadFile} variant={ButtonVariant.Ghost} size={Size.Small} label="Upload from file" className="p-0" />
        </div>
      )}
      <DynamicTable columns={columns} data={data} summaryRow={summaryRow} tableClassName={tableClassName} />
      {canAddNewRow === true && (
        <div className="flex justify-end">
          <Button leftIcon={IconVariant.Add} variant={ButtonVariant.Ghost} size={Size.Small} label="Add a new row" className="p-0" />
        </div>
      )}
    </div>
  )
}

export { Table, TableCell, TableRow }
