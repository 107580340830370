import { type Row } from '@tanstack/react-table'
import { Button, ButtonVariant } from '../../Button'
import { IconVariant } from '../../Icon'

export interface CellWithExpandableProps<T> {
  row: Row<T>
}

export const CellWithExpander = <T,>({ row }: CellWithExpandableProps<T>) => (
  <Button
    variant={ButtonVariant.Ghost}
    onClick={row.getToggleExpandedHandler()}
    className={`${row.getIsExpanded() ? 'text-text-4' : 'text-text-1'}`}
    leftIcon={row.getIsExpanded() ? IconVariant.ExpandLess : IconVariant.ExpandMore}
  />
)
