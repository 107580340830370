/* eslint-disable max-len */
import React, { useState } from 'react'
import { isNil } from 'lodash'
import { Input, InputVariant } from '../_shadcn'

interface IUploadInput {
  setFile: (file: File | null) => void
  acceptedFileTypes?: string
}

export const UploadInput = ({ setFile, acceptedFileTypes }: IUploadInput) => {
  const [dragging, setDragging] = useState(false)
  const [fileName, setFileName] = useState<string | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNil(event.target.files) && event.target.files.length > 0) {
      const file = event.target.files[0]
      setFile(file)
      setFileName(file.name)
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragging(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragging(false)
    const files = event.dataTransfer.files
    if (files.length > 0) {
      const file = files[0]
      setFile(file)
      setFileName(file.name)
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={`rounded-md border-2 border-dashed p-4 ${dragging ? 'border-primary bg-gray-200' : 'border-gray-300'}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <p className="mb-4 text-center">Drag and drop a file here, or click to select one.</p>
      <Input type="file" accept={acceptedFileTypes} onChange={handleFileChange} className="hidden" variant={InputVariant.File} multiple={false} />
      <label htmlFor="file-input" className="block w-full cursor-pointer rounded-md bg-primary px-4 py-2 text-center text-white">
        Choose File
      </label>
      <input id="file-input" type="file" accept={acceptedFileTypes} onChange={handleFileChange} className="hidden" />
      {!isNil(fileName) && (
        <p className="mt-4 text-center text-gray-700">
          Selected File: <span className="font-medium">{fileName}</span>
        </p>
      )}
    </div>
  )
}

export default UploadInput
