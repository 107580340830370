import { CheckTypeDisplay, EditableDateCellWithLabelWithLabel, EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { Fragment } from 'react'
import { cloneDeep } from 'lodash'
import { type AirframeDto } from '@flyward/assets/models'
import { type Control, type FormState, type UseFormSetValue, type UseFormWatch } from 'react-hook-form'

interface IAirframeTechnicalEditableTabProps {
  airframe: AirframeDto
  formControl: Control<AirframeDto, unknown>
  formState: FormState<AirframeDto>
  watchFields: UseFormWatch<AirframeDto>
  setFormValue: UseFormSetValue<AirframeDto>
}

export const AirframeTechnicalEditableTab = ({ airframe, formControl, setFormValue }: IAirframeTechnicalEditableTabProps) => {
  const utilizationAtEvents = airframe.assetComponentUtilizationSnapshot.componentUtilizationAtEvents

  const UtilizationAtEventComponent = cloneDeep(utilizationAtEvents)
    .sort((a, b) => Number(a.eventType) - Number(b.eventType))
    .map((event, index) => {
      return (
        <Fragment key={event.eventType}>
          <EditableDateCellWithLabelWithLabel
            className="basis-1/6"
            label={`Date @ last ${CheckTypeDisplay(event.eventType)}`}
            formControl={formControl}
            fieldName={`assetComponentUtilizationSnapshot.componentUtilizationAtEvents.${index}.dateAtLastQualifyingEvent`}
            setFormValue={setFormValue}
          />
        </Fragment>
      )
    })
  return (
    <div className="flex w-full gap-x-6 gap-y-2">
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.NaturalNumber}
        className="basis-1/6"
        label="TSN"
        formControl={formControl}
        fieldName={`assetComponentUtilizationSnapshot.totalHoursSinceNew`}
      />
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.NaturalNumber}
        className="basis-1/6"
        label="CSN"
        formControl={formControl}
        fieldName={`assetComponentUtilizationSnapshot.cyclesSinceNew`}
      />
      {UtilizationAtEventComponent}
      <div className="mr-6 basis-2/6" />
    </div>
  )
}
