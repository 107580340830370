import { type EngineLifeLimitedPartDto, type AircraftEngineDto } from '@flyward/assets/models'
import { EPRLessorContributionsReadonlyTab } from './EPRLessorContributionsReadonlyTab'
import { LLPsLessorContributionsReadonlyTab } from './LLPsLessorContributionsReadonlyTab'

interface IEngineLessorContributionsReadonlyTabProps {
  component: AircraftEngineDto
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
}
export const EngineLessorContributionsReadonlyTab = ({ component, engineLifeLimitedParts }: IEngineLessorContributionsReadonlyTabProps) => {
  return (
    <div>
      <div className="mb-4">
        <EPRLessorContributionsReadonlyTab component={component} />
      </div>
      <div>
        <LLPsLessorContributionsReadonlyTab component={component} engineLifeLimitedParts={engineLifeLimitedParts} />
      </div>
    </div>
  )
}
