import { http, HttpResponse } from 'msw'
import { type IGetReportsSearchQueryResponse } from './types'
import { APIRoutes } from '@flyward/platform/constants'

const reportsSearchApiMocks = [
  http.get(APIRoutes.ForecastModule.ReportsController.SearchReports(), () => {
    return HttpResponse.json<IGetReportsSearchQueryResponse>({
      items: [
        {
          id: '3ed86242-5ce5-47c9-8cf5-f3ab16a4115b',
          name: 'Report2',
          createdAt: '2024-07-15T07:44:40.634491+00:00',
          createdByUser: { id: '81076ea1-d89f-430a-8770-09c10b4bbb83', name: 'Dev Admin' },
        },
        {
          id: '6a40f368-ace1-43eb-aee2-dbcecf7c0e03',
          name: 'Report1',
          createdAt: '2024-07-15T07:44:13.2704802+00:00',
          createdByUser: { id: '81076ea1-d89f-430a-8770-09c10b4bbb83', name: 'Dev Admin' },
        },
      ],
      totalCount: 2,
      currentPage: 0,
      pageSize: 20,
    })
  }),
]

export { reportsSearchApiMocks }
