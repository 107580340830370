import { type EngineLifeLimitedPartDto } from '@flyward/assets/models'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { llpTechnicalReadOnlyColumns } from './constants'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'

interface ILLPTechnicalReadOnlyTabProps {
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
}

const LLPTechnicalReadOnlyTab = ({ engineLifeLimitedParts }: ILLPTechnicalReadOnlyTabProps) => {
  const llpTechnicalTable = useReactTable<EngineLifeLimitedPartDto>({
    data: engineLifeLimitedParts,
    columns: llpTechnicalReadOnlyColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable {...llpTechnicalTable} />
}

export { LLPTechnicalReadOnlyTab }
export type { ILLPTechnicalReadOnlyTabProps }
