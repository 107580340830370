export enum MinimumReDeliveryConditionType {
  MinRemaining = 1,
  MaxConsumed = 2,
}

export const MinimumReDeliveryConditionTypeDisplay = (value: MinimumReDeliveryConditionType | undefined | null): string => {
  if (value === null || value === undefined) {
    return '-'
  }
  switch (value) {
    case MinimumReDeliveryConditionType.MinRemaining:
      return 'Min Remaining'
    case MinimumReDeliveryConditionType.MaxConsumed:
      return 'Max Consumed'
  }
}
