import { validateNullableDate, validateNullableNaturalNumber } from '@flyward/platform/helpers/inputValidators'
import { ComponentUtilizationAtEventDtoSchema, type ComponentUtilizationAtEventDto } from './ComponentUtilizationAtEventDto'
import { z } from 'zod'

export interface ComponentUtilizationSnapshot {
  dateOfLastSnapshot: Date
  totalHoursSinceNew: number
  cyclesSinceNew: number
  remainingHours?: number
  remainingCycles?: number
  hoursSinceEvent?: number
  cyclesSinceEvent?: number
  componentUtilizationAtEvents: ComponentUtilizationAtEventDto[]
}

export const ComponentUtilizationSnapshotSchema = z.object({
  dateOfLastSnapshot: validateNullableDate,
  totalHoursSinceNew: validateNullableNaturalNumber,
  cyclesSinceNew: validateNullableNaturalNumber,
  remainingHours: validateNullableNaturalNumber,
  remainingCycles: validateNullableNaturalNumber,
  hoursSinceEvent: validateNullableNaturalNumber,
  cyclesSinceEvent: validateNullableNaturalNumber,
  componentUtilizationAtEvents: z.array(ComponentUtilizationAtEventDtoSchema).optional().nullable(),
})
