import { useUserAuthenticated } from '../UserAuthorization'
import React from 'react'

interface IElementForSupportUserProps {
  children: React.ReactNode
}

export const ElementForSupportUser = ({ children }: IElementForSupportUserProps) => {
  const { loggedUser } = useUserAuthenticated()

  return loggedUser?.isSupportUser === true ? children : <></>
}
