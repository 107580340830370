import * as React from 'react'
import { type IIconProps } from '../IIconProps'
import { IconVariant } from '../IconVariant'

export const NewListAlt = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg
      className={className}
      data-variant={IconVariant[variant]}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6.25" y="11.4583" width="6.25" height="6.25" rx="2.08333" />
      <rect x="16.666" y="11.4583" width="27.0833" height="6.25" rx="2.08333" />
      <rect x="16.666" y="21.875" width="27.0833" height="6.25" rx="2.08333" />
      <rect x="16.666" y="32.2917" width="27.0833" height="6.25" rx="2.08333" />
      <rect x="6.25" y="21.875" width="6.25" height="6.25" rx="2.08333" />
      <rect x="6.25" y="32.2917" width="6.25" height="6.25" rx="2.08333" />
    </svg>
  )
}
