import { type AircraftDetailsDto } from '../../../../models/DTOs/aircraftDetailsDto'
import {
  ComponentContainer,
  ErrorBoundary,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  formatDate,
  getAssetAge,
} from '@flyward/platform'

interface IAircraftReadonlyDetailsProps {
  aircraft: AircraftDetailsDto
}

export const AircraftReadonlyDetails = ({ aircraft }: IAircraftReadonlyDetailsProps) => {
  return (
    <ComponentContainer className="p-6">
      <ErrorBoundary>
        <div className="flex w-full flex-col">
          <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">Aircraft</p>
          <div className="flex flex-col gap-x-6 gap-y-2">
            <div className="flex gap-x-6">
              <TextCellWithLabel className="basis-1/6" label="Manufacturer" info={aircraft.manufacturer} />
              <TextCellWithLabel className="basis-1/6" label="Aircraft Type" info={aircraft.aircraftType} />
              <TextCellWithLabel className="basis-1/6" label="MSN" info={aircraft.serialNumber} />
              <TextCellWithLabel className="basis-1/6" label="Registration" info={aircraft.registrationNo} />
              <div className="mr-6 basis-2/6"></div>
            </div>
            <div className="flex gap-x-6">
              <TextCellWithLabel className="basis-1/6" label="Engine Model" info={aircraft.engineModel} />
              <NumberCellWithLabel
                className="basis-1/6"
                label="Engine Thrust rating"
                info={aircraft.engineThrustRating}
                displayType={NumberDisplayType.Integer}
              />
              <TextCellWithLabel className="basis-1/6" label="DOM" info={formatDate(aircraft.dom)} />
              <TextCellWithLabel className="basis-1/6" label="Aircraft Age" info={getAssetAge(aircraft.dom)} />
              <TextCellWithLabel className="basis-1/6" label="Lease Start Date" info={formatDate(aircraft.leaseStartDate)} />
              <TextCellWithLabel className="basis-1/6" label="Lease End Date" info={formatDate(aircraft.leaseEndDate)} />
            </div>
            <div className="flex gap-x-6">
              <TextCellWithLabel className="basis-1/6" label="Tech Spec. Date" info={formatDate(aircraft.dateOfLastTechSpec)} />
              <TextCellWithLabel className="basis-1/6" label="Operator/Lessee" info={aircraft.lessee} />
              <TextCellWithLabel className="basis-1/6" label="Lessee Habitual Base" info={aircraft.lesseeHabitualBase} />
              <TextCellWithLabel className="basis-1/6" label="Region" info={aircraft.region} />
              <TextCellWithLabel className="basis-1/6" label="Portfolio" info={aircraft.portfolio} />
              <div className="basis-1/6"></div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </ComponentContainer>
  )
}
