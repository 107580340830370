import React from 'react'
import { Select } from '../Select'
import { type PageSizes } from '../../models/enums'

interface IPageSizeSelectorProps {
  pageSize: PageSizes
  setPageSize: (size: PageSizes) => void
  setCurrentPage: (page: number) => void
}

const PageSizeSelector: React.FC<IPageSizeSelectorProps> = ({ pageSize, setPageSize, setCurrentPage }) => {
  return (
    <div className="flex items-center pl-2" data-test-id="pagination-pagesize-selector">
      <label htmlFor="pageSize" className="mr-2 text-nowrap text-xs">
        Rows per page
      </label>
      <Select
        options={[
          { value: '-1', label: 'all' },
          { value: '10', label: '10' },
          { value: '20', label: '20' },
          { value: '50', label: '50' },
          { value: '100', label: '100' },
        ]}
        controlledValue={pageSize.toString()}
        setControlledValue={(value: string) => {
          setCurrentPage(0)
          setPageSize(Number(value) as PageSizes)
        }}
        selectClassName="w-17"
      />
    </div>
  )
}

export { PageSizeSelector }
