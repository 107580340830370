import { AssetImportType, getAssetImportTypeDisplayName, Label, RadioGroup, RadioGroupItem } from '@flyward/platform'

interface IAssetImportTypeSelectorProps {
  assetImportType: AssetImportType
  setAssetImportType: (assetType: AssetImportType) => void
}

export const AssetImportTypeSelector = ({ assetImportType, setAssetImportType }: IAssetImportTypeSelectorProps) => {
  return (
    <div className="flex">
      <RadioGroup
        value={assetImportType?.toString()}
        className="flex"
        onValueChange={(value) => {
          setAssetImportType(value as unknown as AssetImportType)
        }}
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={AssetImportType.ExcelTemplate.toString()} id={getAssetImportTypeDisplayName(AssetImportType.ExcelTemplate)} />
          <Label htmlFor={getAssetImportTypeDisplayName(AssetImportType.ExcelTemplate)}>
            {getAssetImportTypeDisplayName(AssetImportType.ExcelTemplate)}
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={AssetImportType.PdfFile.toString()} id={getAssetImportTypeDisplayName(AssetImportType.PdfFile)} />
          <Label htmlFor={getAssetImportTypeDisplayName(AssetImportType.PdfFile)}>{getAssetImportTypeDisplayName(AssetImportType.PdfFile)}</Label>
        </div>
      </RadioGroup>
    </div>
  )
}
