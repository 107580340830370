import { createZitadelAuth, type ZitadelAuth, type ZitadelConfig } from '@zitadel/react'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ZitadelInstance {
  private static instance: ZitadelAuth

  private constructor() {}

  public static getInstance(): ZitadelAuth {
    const currentUrlDomain = window.location.origin
    const config: ZitadelConfig = {
      authority: 'https://login.flyward.eu',
      client_id: '281130475236480555',
      redirect_uri: `${currentUrlDomain}/auth-done`,
      post_logout_redirect_uri: `${currentUrlDomain}/home/login`,
    }

    if (typeof ZitadelInstance.instance === 'undefined') {
      ZitadelInstance.instance = createZitadelAuth(config)
    }

    return ZitadelInstance.instance
  }
}
