import { type AssetWithReportItemWithIndex } from '@flyward/forecasts'
import { ReportItemStatus } from '@flyward/forecasts/models/enums'
import {
  formatAxiosErrorMessage,
  IconVariant,
  type IdWithIndex,
  isArrayEmptyOrNull,
  ToastVariant,
  useSpinnerState,
  useToast,
} from '@flyward/platform'
import type { IReportAsset } from '@flyward/platform/store'
import {
  initFlyResultsStatus,
  setAssetFlyResults,
  setSelectedAssetId,
  useFlyForwardMutation,
  useInitMultiAssetReportMutation,
} from '@flyward/platform/store'
import { useAppDispatch } from '@flyward/platform/store/configureHooks'
import { cloneDeep, isNil } from 'lodash'
import { useCallback } from 'react'

interface IUseComputeSchedule {
  computeSchedule: (unsortedReportAssets: IReportAsset[]) => Promise<string | undefined>
}

const useComputeSchedule = (): IUseComputeSchedule => {
  const ValidationErrorIdentifier = `[ValidationError]`
  const batchSize = 3
  const dispatch = useAppDispatch()

  const [initMultiAssetReport] = useInitMultiAssetReportMutation()
  const [flyForward] = useFlyForwardMutation()

  const { showSpinner, hideSpinner } = useSpinnerState()
  const { toast } = useToast()

  const computeSchedule = useCallback(
    async (unsortedReportAssets: IReportAsset[]) => {
      const sortedReportAssets = cloneDeep(unsortedReportAssets)?.sort((a, b) => a.assetSerialNumber.localeCompare(b.assetSerialNumber)) ?? []
      showSpinner()
      const startTime = Date.now()
      try {
        if (sortedReportAssets.length === 0) {
          return
        }

        for (const asset of sortedReportAssets) {
          dispatch(
            initFlyResultsStatus({
              assetId: asset.assetId,
              reportItemStatus: ReportItemStatus.QueuedForProcessing,
            }),
          )
        }

        const reportAssetsIds = sortedReportAssets.map((asset) => asset.assetId.id)
        const { data: initialReport } = await initMultiAssetReport({ assetIds: reportAssetsIds })

        if (isArrayEmptyOrNull(initialReport?.assetWithReportItemList)) {
          hideSpinner()
          return
        }

        const maxIndexOnReportItemArrayForAssetId = (array: AssetWithReportItemWithIndex[]) => {
          array.forEach((a, index) => {
            if (index === 0) {
              a.index = 0
            } else if (a.assetId === array[index - 1].assetId) {
              a.index = array[index - 1].index + 1
            } else {
              a.index = 0
            }
          })

          return array
        }

        const assetWithReportItemListWithIndexZero: AssetWithReportItemWithIndex[] = initialReport!.assetWithReportItemList.map(
          (assetWithReportItem) => {
            return {
              ...assetWithReportItem,
              index: 0,
            }
          },
        )

        const assetWithReportItemListWithIndex: AssetWithReportItemWithIndex[] =
          maxIndexOnReportItemArrayForAssetId(assetWithReportItemListWithIndexZero)

        const assetsFlyForwardPromises: Array<
          () => Promise<{
            id: string
            index: number
          }>
        > = assetWithReportItemListWithIndex.map((reportItem) => {
          return async () => {
            try {
              const asset = sortedReportAssets.find((asset) => asset.assetId.id === reportItem.assetId && asset.assetId.index === reportItem.index)

              if (asset == null) {
                throw new Error(`Asset with id ${reportItem.assetId} not found`)
              }

              dispatch(
                initFlyResultsStatus({
                  assetId: asset.assetId,
                  reportItemStatus: ReportItemStatus.Processing,
                }),
              )

              const createdReportItem = await flyForward({
                reportItemId: reportItem.reportItemId,
                assetType: asset.flyForwardParameters.assetType,
                flyForwardOverrideParams: {
                  assetId: asset.assetId.id,
                  assetType: asset.flyForwardParameters.assetType,
                  averageMonthlyAPUHours: asset.flyForwardParameters.averageMonthlyAPUHours,
                  averageMonthlyFlightCycles: asset.flyForwardParameters.averageMonthlyFlightCycles,
                  averageMonthlyFlightHours: asset.flyForwardParameters.averageMonthlyFlightHours,
                  endDate: asset.flyForwardParameters.endDate,
                  engineLifeLimitedPartsBuildGoal: asset.flyForwardParameters.engineLifeLimitedPartsBuildGoal,
                  flyToType: asset.flyForwardParameters.flyToType,
                  masterComponentsRates: asset.flyForwardParameters.masterComponentsRates,
                  assetKbChecks: asset.flyForwardParameters.assetKbChecks,
                  aircraftDetails: asset.flyForwardParameters.aircraftDetails,
                  standaloneEngineDetails: asset.flyForwardParameters.standaloneEngineDetails,
                },
              })

              if (isNil(createdReportItem.data)) {
                initFlyResultsStatus({
                  assetId: asset.assetId,
                  reportItemStatus: ReportItemStatus.Failed,
                })
                if (!isNil(createdReportItem.error)) {
                  toast({
                    variant: ToastVariant.Destructive,
                    description: formatAxiosErrorMessage(createdReportItem.error.message),
                    icon: IconVariant.Error,
                  })
                  throw new Error(ValidationErrorIdentifier)
                }
                throw new Error(`Error processing asset ${reportItem.assetId}`)
              }

              dispatch(
                setAssetFlyResults({
                  assetId: asset.assetId,
                  assetComponentsMonthlyStatistics: createdReportItem.data.assetComponentsMonthlyStatistics,
                  eventSchedule: createdReportItem.data.outputSchedules,
                  reportItemId: createdReportItem.data.id,
                  reportItemStatus: createdReportItem.data.status,
                }),
              )

              return asset.assetId
            } catch (error) {
              console.error(`Error processing asset ${reportItem.assetId}:`, error)
              if (!(error instanceof Error) || error.message !== ValidationErrorIdentifier) {
                toast({
                  variant: ToastVariant.Destructive,
                  description: `Error processing asset ${reportItem.assetId}`,
                  icon: IconVariant.Error,
                })
              }
              throw error
            }
          }
        })

        const successfullyProcessedAssetIds: IdWithIndex[] = []
        const failedProcessedAssetIds: string[] = []

        // Resolve the first promise separately
        const firstResolvedAssetId = await assetsFlyForwardPromises[0]()
        if (!isNil(firstResolvedAssetId)) {
          dispatch(setSelectedAssetId({ assetId: firstResolvedAssetId }))
          hideSpinner()
          successfullyProcessedAssetIds.push(firstResolvedAssetId)
        } else {
          toast({
            variant: ToastVariant.Destructive,
            description: 'There was an error processing the first asset',
            icon: IconVariant.Error,
          })
          hideSpinner()
          return
        }

        // Process the rest in batches
        for (let i = 1; i < assetsFlyForwardPromises.length; i += batchSize) {
          const batch = assetsFlyForwardPromises.slice(i, i + batchSize).map(async (fn) => await fn())
          const batchIdsToProcess = sortedReportAssets.slice(i, i + batchSize).map((asset) => asset.assetId)
          const batchResults = (await Promise.all(batch)).filter((assetId) => !isNil(assetId)) ?? []
          const successfullyProcessedAssetIdsFromBatch = batchResults.filter((assetId) => !isNil(assetId))
          const failedAssetIdsFromBatch = batchIdsToProcess.filter((assetId) => !successfullyProcessedAssetIdsFromBatch.includes(assetId))
          successfullyProcessedAssetIds.push(...successfullyProcessedAssetIdsFromBatch)
          failedAssetIdsFromBatch.push(...failedAssetIdsFromBatch)
        }
        const duration = Math.floor((Date.now() - startTime) / 1000)

        if (successfullyProcessedAssetIds.length === initialReport!.assetWithReportItemList.length) {
          toast({
            variant: ToastVariant.Success,
            description: `Fly forward for ${initialReport!.assetWithReportItemList.length}
             ${initialReport!.assetWithReportItemList.length === 1 ? 'asset' : 'assets'}
              done in ${Math.max(duration, 1)} ${duration === 1 ? 'second' : 'seconds'}`,
            icon: IconVariant.Success,
          })
          return initialReport!.reportId
        } else {
          toast({
            variant: ToastVariant.Success,
            description: `Fly forward was partially successful for ${successfullyProcessedAssetIds.length} assets,
            ${failedProcessedAssetIds.length} assets failed,
             in ${duration} seconds`,
            icon: IconVariant.Success,
          })
        }
      } catch (error) {
        console.error('Error during computeSchedule:', error)
        if (!(error instanceof Error) || error.message !== ValidationErrorIdentifier) {
          toast({
            variant: ToastVariant.Destructive,
            description: 'There was an error generating the report',
            icon: IconVariant.Error,
          })
        }
      } finally {
        hideSpinner()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSpinner, initMultiAssetReport, hideSpinner, dispatch, flyForward],
  )

  return { computeSchedule }
}

export { useComputeSchedule, type IUseComputeSchedule }
