import { type CheckDto } from '../../../../models'
import { type ChecksDisplay, ChecksReadOnlyTable } from '../../ChecksReadOnlyTable'

interface ILandingGearChecksReadOnlyTableProps {
  checks?: CheckDto[]
}

export const LandingGearChecksReadOnlyTable = ({ checks = [] }: ILandingGearChecksReadOnlyTableProps) => {
  const mappedData = checks.map((t: CheckDto): ChecksDisplay => {
    return {
      checkId: t.id,
      checkType: t.checkType,
      utilizationUnit: t.utilizationUnit,
      utilization: t.matureLimit,
      baseCost: t.baseCost,
    }
  })
  return <ChecksReadOnlyTable checks={mappedData} tableClassName="w-100" />
}
