import axios from 'axios'
import { isEmpty, isNil } from 'lodash'
import { getPersistedSupportFilteredTenantId, getZitadelPersistedUserAccessToken } from '../AUTH'
import { AzureAppInsightsInstance } from '../Logging/AzureAppInsightsInstance'

export const axiosInstance = axios.create({
  timeout: 300000, // 5 minutes
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const userTokens = getZitadelPersistedUserAccessToken()
    const persistSupportFilteredTenantId = getPersistedSupportFilteredTenantId()
    if (!isNil(config.headers)) {
      config.headers.Authorization = `Bearer ${userTokens?.accessToken}`
    }
    if (!isNil(config.headers) && !isNil(persistSupportFilteredTenantId) && !isEmpty(persistSupportFilteredTenantId)) {
      config.headers['X-TenantId'] = persistSupportFilteredTenantId
    }
    return config
  },
  async (error) => await Promise.reject(error),
)

// Response interceptor to handle unauthorized errors and retry.
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const appInsights = AzureAppInsightsInstance.getInstance()

    if (error.code === 'ECONNABORTED' && Boolean(error.message.includes('timeout'))) {
      console.error('request timed out:', error.config.url, 'at', new Date().toISOString())
    }
    if (error.response?.status === 401) {
      window.location.pathname = '/auth-failed'
    }
    if (error.response?.status === 403) {
      window.location.pathname = '/unauthorized'
    }

    appInsights?.trackException({
      error,
      severityLevel: 3,
      properties: {
        url: window.location.href,
        apiUrl: error.config?.url,
        method: error.config?.method?.toUpperCase(),
        statusCode: error.response?.status,
        statusText: error.response?.statusText,
        requestData: JSON.stringify(error.config.data),
        responseData: JSON.stringify(error.response.data),
        headers: JSON.stringify(error.config.headers),
        source: 'AxiosInterceptor',
      },
    })

    return await Promise.reject(error)
  },
)

export default axiosInstance
