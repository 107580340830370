import { ComponentWithLoadingSpinner } from '../components/ComponentWithLoadingSpinner'

const LoadingFallback = () => {
  return (
    <ComponentWithLoadingSpinner isLoading={true} defaultSize={16}>
      <div className="h-10 w-full">Loading...</div>
    </ComponentWithLoadingSpinner>
  )
}

export { LoadingFallback }
