/* eslint-disable max-len */
import React from 'react'
import style from './FullPageSpinner.module.css'
import { cn } from '../utils'
import { Button, ButtonVariant } from '../Button'
import { IconVariant } from '../Icon'
import { Size } from '../common/Size'

export interface ISpinnerProps {
  isShowing: boolean
  secondsSinceLoading: number
  hideSpinner: () => void
}

export const FullPageLoadingSpinner: React.FC<ISpinnerProps> = ({ isShowing = false, secondsSinceLoading, hideSpinner }) => {
  return (
    <div
      className={cn(
        'inset-0 z-[100] flex max-h-screen w-screen flex-col-reverse items-center justify-center bg-black-100-opacity-20 p-4 sm:inset-y-0 sm:left-0 sm:flex-col',
        isShowing ? 'fixed' : 'hidden',
      )}
    >
      <div className="relative flex items-center justify-center">
        <div className={style.spinner}></div>
        <div className="absolute text-center text-xl text-primary">Flyward</div>
        {/* TODO: only show this on dev, we need a mechanism to determine if we are in dev or prod */}
        {/* <div className="absolute top-[120px] text-center text-xl text-primary">{spinnerTime}</div> */}
        {secondsSinceLoading > 60 && (
          <div className="absolute top-[140px] text-center text-xl text-primary">
            <Button
              leftIcon={IconVariant.Clear}
              variant={ButtonVariant.Ghost}
              size={Size.Small}
              key="close-spinner-button"
              className="text-center text-xl text-primary"
              onClick={() => {
                hideSpinner()
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
