import { APIRoutes } from '@flyward/platform'
import { flywardApi } from '@flyward/platform/store'
import { type IChangeStateMaintenanceProgramMutationRequest, type IDeleteMaintenanceProgramMutationRequest } from './types'

const maintenanceProgramsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteMaintenanceProgram: builder.mutation<void, IDeleteMaintenanceProgramMutationRequest>({
      query: ({ maintenanceId }) => {
        return {
          url: `${APIRoutes.KnowledgeBaseModule.MaintenancePlansController.DeleteMaintenancePrograms(maintenanceId)}`,
          method: 'Delete',
        }
      },
      invalidatesTags: [
        'airframeMaintenancePrograms',
        'landingGearMaintenancePrograms',
        'engineMaintenancePrograms',
        'auxiliaryPowerUnitMaintenancePrograms',
      ],
    }),
    changeStateMaintenanceProgram: builder.mutation<void, IChangeStateMaintenanceProgramMutationRequest>({
      query: ({ maintenanceId }) => {
        return {
          url: `${APIRoutes.KnowledgeBaseModule.MaintenancePlansController.ChangeStateMaintenancePrograms(maintenanceId)}`,
          method: 'Post',
        }
      },
      invalidatesTags: [
        'airframeMaintenancePrograms',
        'landingGearMaintenancePrograms',
        'engineMaintenancePrograms',
        'auxiliaryPowerUnitMaintenancePrograms',
      ],
    }),
  }),
})

export const { useDeleteMaintenanceProgramMutation, useChangeStateMaintenanceProgramMutation } = maintenanceProgramsApi

type Endpoints = typeof maintenanceProgramsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAssetsApiActions = InitiateActions[keyof InitiateActions]
