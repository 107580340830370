import { type Table, type Header, type HeaderGroup, flexRender } from '@tanstack/react-table'
import { type ExpandedRowFields } from '../types'

interface IAircraftExpandedDataGridHeaderProps {
  table: Table<ExpandedRowFields>
}

const AircraftExpandedDataGridHeader = ({ table }: IAircraftExpandedDataGridHeaderProps) => {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup: HeaderGroup<ExpandedRowFields>) => (
        <tr key={headerGroup.id} className="px-4 py-2">
          {headerGroup.headers.map((header: Header<ExpandedRowFields, unknown>) => {
            return (
              <th key={header.id} colSpan={header.colSpan} className="border-x border-black-10">
                {!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}

export { AircraftExpandedDataGridHeader }
