import { useAssetComponentsForms } from '@flyward/assets/context'
import { type LandingGearDto } from '@flyward/assets/models'
import { type UseFormSetValue, type UseFormRegister, type Control, type FormState } from 'react-hook-form'
import { LandingGearLessorContributionsEditableTab } from './LandingGearLessorContributionsEditableTab'
import { LandingGearLessorContributionsReadonlyTab } from './LandingGearLessorContributionsReadonlyTab'

interface ILandingGearLessorContributionsTabProps {
  component: LandingGearDto
  registerFormField: UseFormRegister<LandingGearDto>
  setFormValue: UseFormSetValue<LandingGearDto>
  formControl: Control<LandingGearDto, unknown>
  formState: FormState<LandingGearDto>
}

export const LandingGearLessorContributionsTab = ({
  component,
  registerFormField,
  setFormValue,
  formControl,
  formState,
}: ILandingGearLessorContributionsTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return isInEditMode ? (
    <LandingGearLessorContributionsEditableTab
      component={component}
      register={registerFormField}
      setFormValue={setFormValue}
      formControl={formControl}
      formState={formState}
    />
  ) : (
    <LandingGearLessorContributionsReadonlyTab component={component} />
  )
}
