import { type CheckDto } from '@flyward/knowledgeBase/models'
import { ChecksReadOnlyTable } from '../../ChecksReadOnlyTable'

interface IAuxiliaryPowerUnitChecksReadOnlyTableProps {
  checks?: CheckDto[]
}

export const AuxiliaryPowerUnitChecksReadOnlyTable = ({
  checks = [],
}: IAuxiliaryPowerUnitChecksReadOnlyTableProps): React.ReactElement<IAuxiliaryPowerUnitChecksReadOnlyTableProps> => {
  const mappedData = checks.map((t) => {
    return {
      checkId: t.id,
      checkType: t.checkType,
      utilizationUnit: t.utilizationUnit,
      utilization: t.matureLimit,
      baseCost: t.baseCost,
    }
  })
  return <ChecksReadOnlyTable checks={mappedData} tableClassName="w-100" />
}
