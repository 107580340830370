import {
  type EnvironmentalImpactType,
  type BaseEntityIdentifiable,
  transformCommaSeparatedStringToArray,
  validateRequiredNaturalNumber,
  validateRequiredString,
  validateNullableNaturalNumber,
  validateNullableDecimalNumber,
  validateRequiredIntegerNumber,
} from '@flyward/platform'
import { InflationSchema, type Inflation } from '../entities/Inflation'
import { CheckDtoSchema, type CheckDto } from './CheckDto'
import { z } from 'zod'

export interface BasicAssumption {
  maintenanceProgramId: string
  checkDowntimeMonths: number
  monthsDelayInPaymentOnClaims: number
}

export const BasicAssumptionSchema = z.object({
  maintenanceProgramId: z.any().optional().nullable(),
  checkDowntimeMonths: validateRequiredNaturalNumber('Downtime', 1),
  monthsDelayInPaymentOnClaims: validateNullableNaturalNumber,
})

export interface EnvironmentalImpact extends BaseEntityIdentifiable {
  maintenanceProgramId: string
  impactType: EnvironmentalImpactType
  percentage: number
}

export const EnvironmentalImpactSchema = z.object({
  id: z.any().optional().nullable(),
  maintenanceProgramId: z.any().optional().nullable(),
  impactType: validateNullableNaturalNumber,
  percentage: validateRequiredIntegerNumber('Percentage'),
})

export interface DerateRatio extends BaseEntityIdentifiable {
  maintenanceProgramId: string
  intervalStart: number
  intervalEnd: number
  percentage: number
}

export const DerateRatioSchema = z.object({
  id: z.any().optional().nullable(),
  maintenanceProgramId: z.any().optional().nullable(),
  intervalStart: validateRequiredNaturalNumber('Interval start'),
  intervalEnd: validateRequiredNaturalNumber('Interval end'),
  percentage: validateRequiredNaturalNumber('Percentage'),
})

export interface StubLife extends BaseEntityIdentifiable {
  maintenanceProgramId: string
  intervalStart: number
}

export const StubLifeSchema = z.object({
  id: z.any().optional().nullable(),
  maintenanceProgramId: z.any().optional().nullable(),
  intervalStart: validateNullableNaturalNumber,
})

export interface FlightHoursFlightCyclesRatio extends BaseEntityIdentifiable {
  maintenanceProgramId: string
  ratio: number
  firstRunLimit: number
  matureLimit: number
}

export const FlightHoursFlightCyclesRatioSchema = z.object({
  id: z.any().optional().nullable(),
  maintenanceProgramId: z.any().optional().nullable(),
  ratio: validateNullableDecimalNumber,
  firstRunLimit: validateNullableNaturalNumber,
  matureLimit: validateNullableNaturalNumber,
})

/**
 * Cloned from API: KnowledgeBase.Core.Domain.DTOs
 */
export interface llpCheckDto extends CheckDto {
  componentModel: string
  componentModule: string
  costPerCycle: number
}

export const llpCheckDtoSchema = CheckDtoSchema.extend({
  componentModel: validateRequiredString('Description'),
  componentModule: z.any().optional().nullable(),
  costPerCycle: validateRequiredNaturalNumber('Cost'),
})

/**
 * Cloned from API: KnowledgeBase.Core.Domain.DTOs
 */
export interface EprCheckDto extends CheckDto {
  firstRunLimit: number
}

export const EprCheckDtoSchema = CheckDtoSchema.extend({
  firstRunLimit: validateRequiredNaturalNumber('Limit'),
})

/**
 * Cloned from API: KnowledgeBase.Core.Domain.DTOs
 */
export interface ProgramDto {
  id: string
  maintenanceProgramName: string
  kbReferenceYear: number
  isActive: boolean
  dateOfCreation: string
  compatibleComponentModels: string[]
  manufacturer: string
  inflation: Inflation
  basicAssumptions: BasicAssumption
}

export const ProgramDtoSchema = z.object({
  id: z.any().optional().nullable(),
  maintenanceProgramName: z.any().optional().nullable(),
  kbReferenceYear: validateRequiredNaturalNumber('Base year'),
  isActive: z.any().optional().nullable(),
  dateOfCreation: z.any().optional().nullable(),
  compatibleComponentModels: validateRequiredString('Model').transform(transformCommaSeparatedStringToArray),
  manufacturer: validateRequiredString('Manufacturer'),
  inflation: InflationSchema.optional().nullable(),
  basicAssumptions: BasicAssumptionSchema.optional().nullable(),
})

/**
 * Cloned from API: KnowledgeBase.Core.Domain.DTOs
 */
export interface EngineProgramDto extends ProgramDto {
  llpChecks: llpCheckDto[]
  totalLLPsCost: number
  totalLLPsCostPerCycle: number
  eprCheck: EprCheckDto
  environmentalImpacts: EnvironmentalImpact[]
  derateRatios: DerateRatio[]
  stubLife: StubLife
  flightHoursFlightCyclesRatios: FlightHoursFlightCyclesRatio[]
}

export const EngineProgramDtoSchema = ProgramDtoSchema.extend({
  llpChecks: z.array(llpCheckDtoSchema),
  totalLLPsCost: z.any().optional().nullable(),
  totalLLPsCostPerCycle: z.any().optional().nullable(),
  eprCheck: EprCheckDtoSchema,
  environmentalImpacts: z.array(EnvironmentalImpactSchema).optional().nullable(),
  derateRatios: z.array(DerateRatioSchema).optional().nullable(),
  stubLife: StubLifeSchema.optional().nullable(),
  flightHoursFlightCyclesRatios: z.array(FlightHoursFlightCyclesRatioSchema).optional().nullable(),
})

/**
 * Cloned from API: KnowledgeBase.Core.Domain.DTOs
 */
export interface AirframeProgramDto extends ProgramDto {
  checks: CheckDto[]
  aircraft: string
  series: string
}

export const AirframeProgramDtoSchema = ProgramDtoSchema.extend({
  checks: z.array(CheckDtoSchema),
  aircraft: validateRequiredString('Aircraft'),
  series: z.any().optional().nullable(),
})

/**
 * Cloned from API: KnowledgeBase.Core.Domain.DTOs
 */
export interface LandingGearProgramDto extends ProgramDto {
  check: CheckDto
}

export const LandingGearProgramDtoSchema = ProgramDtoSchema.extend({
  check: CheckDtoSchema,
})

/**
 * Cloned from API: KnowledgeBase.Core.Domain.DTOs
 */
export interface ApuProgramDto extends ProgramDto {
  check: CheckDto
}

export const ApuProgramDtoSchema = ProgramDtoSchema.extend({
  check: CheckDtoSchema,
})
