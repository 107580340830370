import { ComponentMinimumReDeliveryConditionDtoSchema } from '@flyward/platform'
import { EscalationDtoSchema, type EscalationDto } from '../../contract/EscalationDto'
import {
  AuxiliaryPowerUnitMaintenanceReserveContractSchema,
  type AuxiliaryPowerUnitMaintenanceReserveContract,
} from './auxiliaryPowerUnitMaintenanceReserves'
import { type AuxiliaryPowerUnitMinimumReDeliveryCondition } from './auxiliaryPowerUnitMinimumReDeliveryConditions'
import { z } from 'zod'
import { ContractDeliverySnapshotDtoSchema, type ContractDeliverySnapshotDto } from '../../contract/ContractDeliverySnapshotDto'

export interface AuxiliaryPowerUnitContract {
  auxiliaryPowerUnitId: string
  assetContractId: string
  auxiliaryPowerUnitMaintenanceReserveContract: AuxiliaryPowerUnitMaintenanceReserveContract
  minimumReDeliveryConditions: AuxiliaryPowerUnitMinimumReDeliveryCondition[]
  escalations: EscalationDto
  contractDeliverySnapshot: ContractDeliverySnapshotDto
}

export const AuxiliaryPowerUnitContractSchema = z.object({
  escalations: EscalationDtoSchema.optional().nullable(),
  auxiliaryPowerUnitMaintenanceReserveContract: AuxiliaryPowerUnitMaintenanceReserveContractSchema.optional().nullable(),
  minimumReDeliveryConditions: z.array(ComponentMinimumReDeliveryConditionDtoSchema).optional().nullable(),
  contractDeliverySnapshot: ContractDeliverySnapshotDtoSchema,
})
