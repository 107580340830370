import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import queryString from 'query-string'
import { type IGetTenantsResponse } from './types'

const usersApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getTenants: builder.query<IGetTenantsResponse, void>({
      query: () => ({
        url: APIRoutes.AppIdentityModule.TenantController.GetAllTenants(),
        method: 'GET',
      }),
      providesTags: (_, __, _request) => ['apiData', { type: 'tenants' }],
      keepUnusedDataFor: 0,
    }),
    addTenant: builder.mutation<string, string>({
      query: (user) => ({
        url: APIRoutes.AppIdentityModule.TenantController.CreateTenant(),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(user),
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'apiData' }],
    }),
    changeState: builder.mutation<string, string>({
      query: (userId) => {
        const params = queryString.stringify({ userId }, { arrayFormat: 'none' })
        return {
          url: `${APIRoutes.AppIdentityModule.TenantController.ChangeState()}?${params}`,
          method: 'POST',
        }
      },
      invalidatesTags: (_, __, _request) => [{ type: 'apiData' }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetTenantsQuery, useAddTenantMutation, useChangeStateMutation, util: usersApiSliceUtil } = usersApi

type Endpoints = typeof usersApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TTenantsApiActions = InitiateActions[keyof InitiateActions]
