import { LandingGearMaintenanceProgramEditable, LandingGearMaintenanceProgramReadOnly, type LandingGearProgramDto } from '@flyward/knowledgeBase'
import { type Control } from 'react-hook-form'

interface ILandingGearMaintenanceProgramPageHeaderProps {
  isInEditMode: boolean
  landingGearMaintenanceProgram: LandingGearProgramDto | undefined
  formControl: Control<LandingGearProgramDto, unknown>
}

export const LandingGearMaintenanceProgramPageBody = ({
  isInEditMode,
  landingGearMaintenanceProgram,
  formControl,
}: ILandingGearMaintenanceProgramPageHeaderProps) => {
  return (
    <>
      {isInEditMode ? (
        <LandingGearMaintenanceProgramEditable landingGearMaintenanceProgram={landingGearMaintenanceProgram} formControl={formControl} />
      ) : (
        <LandingGearMaintenanceProgramReadOnly landingGearMaintenanceProgram={landingGearMaintenanceProgram} />
      )}
    </>
  )
}
