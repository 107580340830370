import { useAssetComponentsForms } from '@flyward/assets/context'
import { type AirframeDto } from '@flyward/assets/models'
import { type UseFormSetValue, type UseFormRegister, type Control, type FormState } from 'react-hook-form'
import { AirframeLessorContributionsEditableTab } from './AirframeLessorContributionsEditableTab'
import { AirframeLessorContributionsReadonlyTab } from './AirframeLessorContributionsReadonlyTab'

interface IAirframeLessorContributionsTabProps {
  airframe: AirframeDto
  registerFormField: UseFormRegister<AirframeDto>
  setFormValue: UseFormSetValue<AirframeDto>
  formControl: Control<AirframeDto, unknown>
  formState: FormState<AirframeDto>
}

export const AirframeLessorContributionsTab = ({
  airframe,
  registerFormField,
  setFormValue,
  formControl,
  formState,
}: IAirframeLessorContributionsTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return isInEditMode ? (
    <AirframeLessorContributionsEditableTab
      airframe={airframe}
      register={registerFormField}
      setFormValue={setFormValue}
      formControl={formControl}
      formState={formState}
    />
  ) : (
    <AirframeLessorContributionsReadonlyTab airframe={airframe} />
  )
}
