import { cn } from '../_shadcn/utils'
import { Size } from '../common'
import { type IIconProps } from './IIconProps'
import { icons } from './variants'

const sizeMap = {
  [Size.None]: '',
  [Size.Small]: 'w-[1.125rem] h-[1.125rem]',
  [Size.Medium]: 'w-6 h-6',
  [Size.Large]: 'w-[1.875rem] h-[1.875rem]',
  [Size.ExtraLarge]: 'w-[3rem] h-[3rem]',
  [Size.ExtraSmall]: 'w-[0.6rem] h-[0.6rem]',
  [Size.ResponsiveTablet]: 'lg:w-[2.5rem] lg:h-[2.5rem] xl:w-[3.125rem] xl:h-[3.125rem]',
}

const Icon = ({ variant, size = Size.Medium, className = '' }: IIconProps) => {
  const Icon = icons[variant]
  return <Icon className={cn('fill-current', sizeMap[size], className)} variant={variant} />
}

export { Icon }
