import { ErrorBoundary, TextCellWithLabel } from '@flyward/platform'
import { isArray, isNil } from 'lodash'
import { MaintenanceProgramDetailsArea } from '../MaintenanceProgramDetailsArea'
import { InflationReadOnly } from '../InflationReadOnly'
import { BasicAssumptionsReadOnly } from '../BasicAssumptionsReadOnly'
import { type ApuProgramDto } from '@flyward/knowledgeBase/models'
import { AuxiliaryPowerUnitChecksReadOnlyTable } from './AuxiliaryPowerUnitChecksTable'

interface IAuxiliaryPowerUnitMaintenanceProgramReadOnlyProps {
  auxiliaryPowerUnitMaintenanceProgram: ApuProgramDto | undefined
}

export const AuxiliaryPowerUnitMaintenanceProgramReadOnly = ({
  auxiliaryPowerUnitMaintenanceProgram,
}: IAuxiliaryPowerUnitMaintenanceProgramReadOnlyProps) => {
  return (
    <ErrorBoundary>
      <MaintenanceProgramDetailsArea title="AuxiliaryPowerUnit">
        <div className="flex gap-6">
          <TextCellWithLabel className="basis-1/6" label="Manufacturer" info={auxiliaryPowerUnitMaintenanceProgram?.manufacturer} />
          <TextCellWithLabel
            className="basis-1/6"
            label="AuxiliaryPowerUnit Model"
            info={
              !isNil(auxiliaryPowerUnitMaintenanceProgram?.compatibleComponentModels) &&
              isArray(auxiliaryPowerUnitMaintenanceProgram?.compatibleComponentModels)
                ? auxiliaryPowerUnitMaintenanceProgram?.compatibleComponentModels?.join(', ')
                : auxiliaryPowerUnitMaintenanceProgram?.compatibleComponentModels
            }
          />
          <TextCellWithLabel className="basis-1/6" label="Base year" info={auxiliaryPowerUnitMaintenanceProgram?.kbReferenceYear?.toString()} />
        </div>
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Checks">
        <AuxiliaryPowerUnitChecksReadOnlyTable
          checks={!isNil(auxiliaryPowerUnitMaintenanceProgram?.check) ? [auxiliaryPowerUnitMaintenanceProgram!.check] : []}
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Inflation">
        <InflationReadOnly inflation={auxiliaryPowerUnitMaintenanceProgram?.inflation} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Basic Assumptions">
        <BasicAssumptionsReadOnly basicAssumption={auxiliaryPowerUnitMaintenanceProgram?.basicAssumptions} />
      </MaintenanceProgramDetailsArea>
    </ErrorBoundary>
  )
}
