import { type ApuDto } from '@flyward/assets/models'
import { CheckTypeDisplay, DateCellWithLabel, NumberCellWithLabel, NumberDisplayType } from '@flyward/platform'
import { Fragment } from 'react'

interface IAPUTechnicalReadOnlyTabProps {
  component: ApuDto
}

export const APUTechnicalReadOnlyTab = ({ component }: IAPUTechnicalReadOnlyTabProps) => {
  const utilizationAtEvents = component.assetComponentUtilizationSnapshot.componentUtilizationAtEvents

  const UtilizationAtEventComponent = utilizationAtEvents.map((event) => {
    return (
      <Fragment key={event.eventType}>
        <NumberCellWithLabel
          className="basis-1/6"
          label={`TSN @ last ${CheckTypeDisplay(event.eventType)}`}
          info={event.totalHoursSinceNewAtLastQualifyingEvent}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`CSN @ last ${CheckTypeDisplay(event.eventType)}`}
          info={event.cyclesSinceNewAtLastQualifyingEvent}
          displayType={NumberDisplayType.Integer}
        />
        <DateCellWithLabel className="basis-1/6" label={`Date @ last ${CheckTypeDisplay(event.eventType)}`} info={event.dateAtLastQualifyingEvent} />
      </Fragment>
    )
  })
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-x-6 gap-y-2">
        <NumberCellWithLabel
          className="basis-1/6"
          label="TSN"
          info={component.assetComponentUtilizationSnapshot.totalHoursSinceNew}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="CSN"
          info={component.assetComponentUtilizationSnapshot.cyclesSinceNew}
          displayType={NumberDisplayType.Integer}
        />
        {UtilizationAtEventComponent}
        <div className="basis-1/6" />
      </div>
    </div>
  )
}
