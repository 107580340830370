import { ActionDialog, Button, ButtonVariant, IconVariant, Input, InputType, ToastVariant, useHeaderContent, useToast } from '@flyward/platform'
import { useSaveReportWithNameMutation } from '@flyward/platform/store'
import { useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectSuccessfulReportId, selectUnsortedReportAssets } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { ReportItemExcelExtract } from '@flyward/forecasts'

const FlyForwardSave = () => {
  const reportId = useAppSelector(selectSuccessfulReportId)
  const unsortedReportAssets = useAppSelector(selectUnsortedReportAssets)

  const { setCustomElements } = useHeaderContent()
  const { toast } = useToast()

  const [reportName, setReportName] = useState('')
  const [isReportNameValid, setIsReportNameValid] = useState(false)

  const [saveReportWithName] = useSaveReportWithNameMutation()
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)

  const handleSave = async () => {
    if (reportId === undefined) {
      return
    }
    const saveResponse = await saveReportWithName({ id: reportId, name: reportName.trim() })
    if (!isNil(saveResponse.error)) {
      toast({
        variant: ToastVariant.Destructive,
        description: (
          <p>
            An error occurred while saving the report <strong>{reportName.trim()}</strong>
          </p>
        ),
        icon: IconVariant.Error,
      })
    } else {
      toast({
        variant: ToastVariant.Success,
        description: (
          <p>
            Your forecast report <strong>{reportName.trim()}</strong> has been successfully saved!
          </p>
        ),
        icon: IconVariant.Success,
      })
    }
    setIsSaveModalOpen(false)
  }

  useEffect(() => {
    const reportItemIds = () => {
      return unsortedReportAssets
        .map((asset) => ({
          reportItemId: asset.reportItemId,
          assetSerialNumber: asset.assetSerialNumber,
        }))
        .filter((item): item is { reportItemId: string; assetSerialNumber: string } => item.reportItemId !== undefined)
    }
    setCustomElements(
      !isEmpty(reportId) && !isNil(reportId)
        ? [
            <Button
              key={`save-btn-${reportId}`}
              variant={ButtonVariant.Primary}
              leftIcon={IconVariant.Save}
              className="mr-1 h-full px-3"
              onClick={() => {
                setIsSaveModalOpen(true)
              }}
            />,
            <ReportItemExcelExtract key={`extract-${reportId}`} reportId={reportId} reportItems={reportItemIds()} />,
          ]
        : [],
    )
  }, [isReportNameValid, isSaveModalOpen, reportId, reportName, saveReportWithName, setCustomElements, unsortedReportAssets])

  return (
    <ActionDialog
      isValid={reportName.trim().length >= 3 && isReportNameValid}
      confirmBtnLabel="Save"
      dialogHeader="Save report"
      key={`save-${reportId}`}
      onConfirm={handleSave}
      onCancel={() => {
        setReportName('')
        setIsSaveModalOpen(false)
      }}
      onSuccess={() => {
        setReportName('')
        setIsSaveModalOpen(false)
      }}
      isOpen={isSaveModalOpen}
      setIsOpen={setIsSaveModalOpen}
      dialogContent={
        <Input
          label="Report name"
          labelClassName="text-semibold text-text-1"
          setValueAfterValidation={(value: string) => {
            setReportName(value.trimStart())
          }}
          type={InputType.Alphanumeric}
          inputClassName="w-full"
          setIsValid={setIsReportNameValid}
        />
      }
    />
  )
}

export { FlyForwardSave }
