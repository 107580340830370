import {
  ActionDialog,
  Combobox,
  type GetAllUsersDto,
  IconVariant,
  Input,
  InputType,
  type ISelectOption,
  Role,
  RoleDisplay,
  ToastVariant,
  useHeaderContent,
  useToast,
} from '@flyward/platform'
import { type IAddEditUserQueryRequest, useAddUserMutation, useEditUserMutation } from '@flyward/platform/store'
import { isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

interface IAddEditUserProps {
  user: GetAllUsersDto | null
  isEditMode: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const AddEditUser = ({ isOpen, isEditMode, user, setIsOpen }: IAddEditUserProps) => {
  const { setCustomElements } = useHeaderContent()
  const { toast } = useToast()
  const [userId, setUserId] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [role, setRole] = useState<Role>(Role.None)
  const [isValidFirstName, setIsValidFirstName] = useState<boolean>(false)
  const [isValidLastName, setIsValidLastName] = useState<boolean>(false)
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false)
  const [addUser] = useAddUserMutation()
  const [editUser] = useEditUserMutation()

  const handleSave = useCallback(async () => {
    const user: IAddEditUserQueryRequest = {
      userId,
      firstName,
      lastName,
      email,
      role: Number(Role[role]),
    }
    if (isEditMode) {
      const { error: editError } = await editUser(user)
      if (isNil(editError)) {
        toast({
          variant: ToastVariant.Success,
          description: 'Your user has been successfully saved!',
          icon: IconVariant.Success,
        })
      } else {
        toast({
          variant: ToastVariant.Destructive,
          description: 'Something went wrong while trying to save the user!',
          icon: IconVariant.Error,
        })
      }
    } else {
      const { error: addError } = await addUser(user)
      if (isNil(addError)) {
        toast({
          variant: ToastVariant.Success,
          description: 'Your user has been successfully saved!',
          icon: IconVariant.Success,
        })
      } else {
        toast({
          variant: ToastVariant.Destructive,
          description: 'Something went wrong while trying to save the user!',
          icon: IconVariant.Error,
        })
      }
    }
  }, [userId, firstName, lastName, email, role, isEditMode, editUser, toast, addUser])

  useEffect(() => {
    if (!isNil(user)) {
      setUserId(user.userId)
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmail(user.email)
      if (!isNil(user.role)) {
        setRole(Role[user.role.toString() as keyof typeof Role])
      }
    } else {
      setFirstName('')
      setLastName('')
      setEmail('')
      setRole(Role.None)
    }
  }, [user])

  useEffect(() => {
    const roleStates: ISelectOption[] = [
      { label: RoleDisplay(Role.NormalUser), value: Role.NormalUser.toString() },
      { label: RoleDisplay(Role.SuperUser), value: Role.SuperUser.toString() },
      { label: RoleDisplay(Role.Admin), value: Role.Admin.toString() },
    ]

    const updateRole = (value: string) => {
      const role: Role = Role[value as keyof typeof Role]
      setRole(role)
    }

    const resetFields = () => {
      setFirstName('')
      setLastName('')
      setEmail('')
      setRole(Role.None)
    }

    const handleSuccess = () => {
      resetFields()
    }

    setCustomElements([
      <ActionDialog
        isValid={isValidFirstName && isValidLastName && isValidEmail && role !== Role.None}
        confirmBtnLabel="Save"
        dialogHeader={isEditMode ? `Edit user ${user?.firstName}` : 'Add user'}
        key={`save-${!isNil(user) ? user.userId : 'add'}`}
        onConfirm={handleSave}
        onCancel={resetFields}
        onSuccess={handleSuccess}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dialogContent={
          <>
            <Input
              label="First Name"
              labelClassName="text-semibold text-text-1"
              setValueAfterValidation={(value: string) => {
                setFirstName(value)
              }}
              type={InputType.Alphanumeric}
              inputClassName="w-full"
              controlledValue={firstName}
              setIsValid={setIsValidFirstName}
            />
            <Input
              label="Last Name"
              labelClassName="text-semibold text-text-1"
              setValueAfterValidation={(value: string) => {
                setLastName(value)
              }}
              type={InputType.Alphanumeric}
              inputClassName="w-full"
              controlledValue={lastName}
              setIsValid={setIsValidLastName}
            />
            <Input
              label="Email"
              labelClassName="text-semibold text-text-1"
              setValueAfterValidation={(value: string) => {
                setEmail(value)
              }}
              type={InputType.Email}
              inputClassName="w-full"
              controlledValue={email}
              setIsValid={setIsValidEmail}
            />
            <Combobox
              items={roleStates}
              id="userState"
              className="w-full"
              label="Role"
              itemLabel="Role"
              selectedValues={[Number(Role[role]).toString()]}
              setSelectedValues={(value: string[]) => {
                updateRole(value[0])
              }}
            />
          </>
        }
      />,
    ])
  }, [
    userId,
    setCustomElements,
    toast,
    firstName,
    lastName,
    email,
    role,
    addUser,
    handleSave,
    isEditMode,
    isOpen,
    setIsOpen,
    user,
    isValidFirstName,
    isValidLastName,
    isValidEmail,
  ])

  return null
}
