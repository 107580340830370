import { type ComponentMaintenanceReserveRate, ComponentMaintenanceReserveRateSchema } from '../../contract'
import {
  ComponentMaintenanceReserveContractSchema,
  type ComponentMaintenanceReserveContractDto,
} from '../../contract/ComponentMaintenanceReserveContractDto'

export interface LandingGearMaintenanceReserveContract extends ComponentMaintenanceReserveContractDto {
  landingGearMaintenanceReserveRate: ComponentMaintenanceReserveRate
}

export const LandingGearMaintenanceReserveContractSchema = ComponentMaintenanceReserveContractSchema.extend({
  landingGearMaintenanceReserveRate: ComponentMaintenanceReserveRateSchema.optional().nullable(),
})
