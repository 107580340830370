import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IPutEnginesMaintenanceProgramMutationRequest,
  type IGetEnginesByAssetIdQueryRequest,
  type IGetEnginesByAssetIdQueryResponse,
  type IGetAllEngineChecksWithDetailsByAssetIdQueryResponse,
  type IGetAllEngineChecksWithDetailsByAssetIdQueryRequest,
  type IPutEngineMutationRequest,
} from './types'
import { ComponentType, ComponentTypeURI } from '@flyward/platform'
import { type AxiosError } from 'axios'

const enginesApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEnginesByAssetId: builder.query<IGetEnginesByAssetIdQueryResponse, IGetEnginesByAssetIdQueryRequest>({
      query: ({ assetId, includeLLPs }) => ({
        url: APIRoutes.AssetsModule.Components.EnginesController.GetAll(assetId),
        params: {
          includeLLPs,
        },
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'engines', id: `${request.assetId}-${request.includeLLPs}` }],
    }),
    getAllEngineChecksWithDetailsByAssetId: builder.query<
      IGetAllEngineChecksWithDetailsByAssetIdQueryResponse,
      IGetAllEngineChecksWithDetailsByAssetIdQueryRequest
    >({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.EnginesController.GetExpandedEngines(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [
        'apiData',
        { type: 'componentChecks', id: `${request.assetId}-${ComponentTypeURI(ComponentType.EngineUnit)}` },
      ],
    }),
    updateEnginesMaintenanceProgram: builder.mutation<void, IPutEnginesMaintenanceProgramMutationRequest>({
      queryFn: async (
        { assetId, kbProgramId, engines },
        _queryApi,
        _extraOptions,
        baseQuery,
      ): Promise<{ data: void } | { error: AxiosError<unknown, unknown> }> => {
        if (engines == null || engines.length === 0) {
          return { data: undefined }
        }

        // Iterate over the engines and update each one
        for (const engine of engines) {
          const engineUpdateResponse = await baseQuery({
            url: APIRoutes.AssetsModule.Components.EnginesController.Update(assetId),
            method: 'PUT',
            data: { ...engine, assignedKBProgramId: kbProgramId },
          })

          // If an error occurs while updating engines, return early
          if (engineUpdateResponse.error != null) {
            return { error: engineUpdateResponse.error }
          }
        }

        return { data: undefined }
      },
      invalidatesTags: (_, __, request) => [
        { type: 'engines', id: `${request.assetId}-true` },
        { type: 'engines', id: `${request.assetId}-false` },
      ],
    }),
    updateEngine: builder.mutation<string, IPutEngineMutationRequest>({
      query: ({ assetId, engine }) => ({
        url: APIRoutes.AssetsModule.Components.EnginesController.Update(assetId),
        method: 'PUT',
        data: engine,
      }),
      invalidatesTags: (_, __, request) => [
        { type: 'engines', id: `${request.assetId}-true` },
        { type: 'engines', id: `${request.assetId}-false` },
        { type: 'assets' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllEnginesByAssetIdQuery,
  useGetAllEngineChecksWithDetailsByAssetIdQuery,
  useUpdateEnginesMaintenanceProgramMutation,
  useUpdateEngineMutation,
  util: enginesApiSliceUtil,
} = enginesApi

type Endpoints = typeof enginesApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TEnginesApiActions = InitiateActions[keyof InitiateActions]
