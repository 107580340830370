/* eslint-disable max-len */
import * as React from 'react'
import { type IIconProps } from '../IIconProps'
import { IconVariant } from '../IconVariant'

export const Settings = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg
      className={className}
      data-variant={IconVariant[variant]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.1169 12.7833C18.1512 12.5333 18.1683 12.275 18.1683 12C18.1683 11.7333 18.1512 11.4667 18.1084 11.2167L19.8475 9.9C20.0017 9.78333 20.0445 9.55833 19.9503 9.39167L18.3054 6.625C18.2026 6.44167 17.9884 6.38333 17.8 6.44167L15.7524 7.24167C15.3241 6.925 14.87 6.65833 14.3645 6.45833L14.0561 4.34167C14.0218 4.14167 13.8505 4 13.6449 4H10.3551C10.1495 4 9.98672 4.14167 9.95245 4.34167L9.64404 6.45833C9.13857 6.65833 8.67595 6.93333 8.25616 7.24167L6.20861 6.44167C6.02013 6.375 5.80595 6.44167 5.70315 6.625L4.06682 9.39167C3.96402 9.56667 3.99829 9.78333 4.16963 9.9L5.90876 11.2167C5.86592 11.4667 5.83166 11.7417 5.83166 12C5.83166 12.2583 5.84879 12.5333 5.89163 12.7833L4.1525 14.1C3.99829 14.2167 3.95545 14.4417 4.04969 14.6083L5.69458 17.375C5.79739 17.5583 6.01157 17.6167 6.20004 17.5583L8.24759 16.7583C8.67595 17.075 9.13001 17.3417 9.63547 17.5417L9.94389 19.6583C9.98672 19.8583 10.1495 20 10.3551 20H13.6449C13.8505 20 14.0218 19.8583 14.0475 19.6583L14.356 17.5417C14.8614 17.3417 15.3241 17.075 15.7438 16.7583L17.7914 17.5583C17.9799 17.625 18.194 17.5583 18.2969 17.375L19.9417 14.6083C20.0445 14.425 20.0017 14.2167 19.8389 14.1L18.1169 12.7833ZM12 15C10.3037 15 8.91583 13.65 8.91583 12C8.91583 10.35 10.3037 9 12 9C13.6963 9 15.0842 10.35 15.0842 12C15.0842 13.65 13.6963 15 12 15Z" />
    </svg>
  )
}
