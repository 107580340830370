/* eslint-disable @typescript-eslint/no-throw-literal */
import { Button, ButtonVariant } from '@flyward/platform'
import React, { useState } from 'react'

// Function to create a delay that throws a promise for Suspense to handle
const createSuspenseDelay = async (ms: number) => {
  await new Promise<void>((resolve) => {
    setTimeout(resolve, ms)
  })
}

const ErrorThrowingComponent: React.FC = () => {
  const result: { test: { test: string } } | null = null
  return <div>{result!.test.test}</div>
}

const AsyncErrorComponent: React.FC = () => {
  const [triggerSuspense, setTriggerSuspense] = useState<boolean>(false)
  const [showError, setShowError] = useState(false)

  if (triggerSuspense) {
    // Throw a promise to trigger the Suspense fallback
    throw createSuspenseDelay(2000).then(() => {
      console.log('Error generated')
      setTriggerSuspense(false) // Reset the state after the delay
      setShowError(true)
    })
  }

  const generateError = () => {
    setTriggerSuspense(true)
  }

  return (
    <div>
      <Button variant={ButtonVariant.ModalConfirm} onClick={generateError}>
        Generate Error
      </Button>
      {/* Conditionally render the error-throwing component after suspense delay */}
      {showError && <ErrorThrowingComponent />}
    </div>
  )
}

const GenerateError: React.FC = () => {
  return <AsyncErrorComponent />
}

export { GenerateError }
