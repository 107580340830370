import {
  type EngineUnitContractDto,
  type AircraftEngineDto,
  type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto,
} from '@flyward/assets/models'
import { useAssetComponentsForms } from '@flyward/assets/context/AssetComponentsFormsContext'
import { ContractualReserveEditableTab } from './ContractualReserveEditableTab'
import { ContractualReserveReadOnlyTab } from './ContractualReserveReadOnlyTab'
import { type Control, type FormState, type UseFormRegister, type UseFormSetValue } from 'react-hook-form'

interface IContractualReserveTabProps {
  component: AircraftEngineDto
  engineContract: EngineUnitContractDto
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

const ContractualReserveTab = ({
  component,
  engineContract,
  performanceRestorationReserves,
  registerFormField,
  setFormValue,
  formControl,
  formState,
}: IContractualReserveTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()
  const escalations = engineContract.escalations
  const maintenanceReserve = engineContract.engineUnitMaintenanceReserveContract
  return (
    <>
      {isInEditMode ? (
        <ContractualReserveEditableTab
          component={component}
          performanceRestorationReserves={performanceRestorationReserves}
          registerFormField={registerFormField}
          setFormValue={setFormValue}
          formControl={formControl}
          formState={formState}
        />
      ) : (
        <ContractualReserveReadOnlyTab
          performanceRestorationReserves={performanceRestorationReserves}
          currentPrMrFund={maintenanceReserve.enginePerformanceRestorationMaintenanceRate?.currentFund}
          isMrFlagFirstRun={maintenanceReserve.isFirstRun}
          prMrAgreedEscalation={escalations.eprEscalation?.maintenanceReserveAgreedEscalationPercentage}
          prMrRate={engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate?.rateAmount}
          prMrRateType={engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate?.utilizationUnit}
          isMrFlag={maintenanceReserve.isMaintenanceReserveActive}
          isEolActive={maintenanceReserve.isEolActive ?? false}
        />
      )}
    </>
  )
}

export { ContractualReserveTab }
export type { IContractualReserveTabProps }
