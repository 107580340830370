import { ComponentMaintenanceReserveRateSchema, type ComponentMaintenanceReserveRate } from '../../contract'
import {
  ComponentMaintenanceReserveContractSchema,
  type ComponentMaintenanceReserveContractDto,
} from '../../contract/ComponentMaintenanceReserveContractDto'
import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Engine.EngineUnitMaintenanceReserveContractDto
 */

export interface EngineUnitMaintenanceReserveContractDto extends ComponentMaintenanceReserveContractDto {
  isFirstRun: boolean
  enginePerformanceRestorationMaintenanceRate?: ComponentMaintenanceReserveRate
  engineLLPsTotalMaintenanceReserveRate?: ComponentMaintenanceReserveRate
}

export const EngineUnitMaintenanceReserveContractSchema = ComponentMaintenanceReserveContractSchema.extend({
  isFirstRun: z.any().nullable(),
  enginePerformanceRestorationMaintenanceRate: ComponentMaintenanceReserveRateSchema.nullable(),
  engineLLPsTotalMaintenanceReserveRate: ComponentMaintenanceReserveRateSchema.nullable(),
})
