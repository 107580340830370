import { EditableInputCellWithLabelWithLabel, InputType, type TableColumn } from '@flyward/platform'
import { type FlightHoursFlightCyclesRatio } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type Path, type Control, type FieldValues } from 'react-hook-form'

interface ICyclesHoursRatioTableEditableProps<TFormData extends FieldValues> {
  flightHoursFlightCyclesRatios: FlightHoursFlightCyclesRatio[] | undefined
  formControl: Control<TFormData, unknown>
  tableClassName?: string
}

const tableColumns = <TFormData extends FieldValues>(formControl: Control<TFormData, unknown>): Array<TableColumn<FlightHoursFlightCyclesRatio>> => [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'ratio',
    title: 'Ratio',
    customCell: ({ rowIndex: index }) => {
      const filedName = `flightHoursFlightCyclesRatios.${index}.ratio` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Decimal} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
  {
    accessor: 'firstRunLimit',
    title: 'First run limit',
    customCell: ({ rowIndex: index }) => {
      const filedName = `flightHoursFlightCyclesRatios.${index}.firstRunLimit` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
  {
    accessor: 'matureLimit',
    title: 'Mature limit',
    customCell: ({ rowIndex: index }) => {
      const filedName = `flightHoursFlightCyclesRatios.${index}.matureLimit` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
]

export const FlightHoursFlightCyclesRatioEditableTable = <TFormData extends FieldValues>({
  flightHoursFlightCyclesRatios,
  formControl,
  tableClassName = '',
}: ICyclesHoursRatioTableEditableProps<TFormData>): React.ReactElement<ICyclesHoursRatioTableEditableProps<TFormData>> => {
  return <MaintenanceProgramDetailsTable columns={tableColumns(formControl)} data={flightHoursFlightCyclesRatios} tableClassName={tableClassName} />
}
