import { NumberDisplay, NumberDisplayType, type TableColumn } from '@flyward/platform'
import { type FlightHoursFlightCyclesRatio } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'

interface ICyclesHoursRatioTableReadOnlyProps {
  flightHoursFlightCyclesRatios: FlightHoursFlightCyclesRatio[] | undefined
  tableClassName?: string
}

const tableColumns: Array<TableColumn<FlightHoursFlightCyclesRatio>> = [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'ratio',
    title: 'Ratio',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Decimal} />,
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
  {
    accessor: 'firstRunLimit',
    title: 'First run limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
  {
    accessor: 'matureLimit',
    title: 'Mature limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
]

export const FlightHoursFlightCyclesRatioReadOnlyTable = ({
  flightHoursFlightCyclesRatios,
  tableClassName = '',
}: ICyclesHoursRatioTableReadOnlyProps): React.ReactElement<ICyclesHoursRatioTableReadOnlyProps> => {
  return <MaintenanceProgramDetailsTable columns={tableColumns} data={flightHoursFlightCyclesRatios} tableClassName={tableClassName} />
}
