import { type Control } from 'react-hook-form'
import { type EngineProgramDto } from '@flyward/knowledgeBase/models'
import { EngineMaintenanceProgramEditable, EngineMaintenanceProgramReadOnly } from '@flyward/knowledgeBase/components'

interface IEngineMaintenanceProgramPageBodyProps {
  isInEditMode: boolean
  engineMaintenanceProgram: EngineProgramDto | undefined
  formControl: Control<EngineProgramDto, unknown>
}

export const EngineMaintenanceProgramPageBody = ({ isInEditMode, engineMaintenanceProgram, formControl }: IEngineMaintenanceProgramPageBodyProps) => {
  return isInEditMode ? (
    <EngineMaintenanceProgramEditable engineProgram={engineMaintenanceProgram} formControl={formControl} />
  ) : (
    <EngineMaintenanceProgramReadOnly engineProgram={engineMaintenanceProgram} />
  )
}
