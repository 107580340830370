import { useGetAllAssetsBySearchQuery } from '@flyward/assets/store'
import { PageContainer } from '@flyward/main-app/layout'
import { clearAllReportAssets, initFilteredAssetsData } from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import { FlyForwardPageHeader } from './FlyForwardPageHeader/FlyForwardPageHeader'
import { FlyForwardPageBody } from './FlyForwardPageBody/FlyForwardPageBody'
import { useHeaderContent } from '@flyward/platform'
import { selectForecastingAssetsSearchInput } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'

const FlyForwardPage = () => {
  const dispatch = useAppDispatch()

  const forecastingAssetsSearchInput = useAppSelector(selectForecastingAssetsSearchInput)

  const { data: filteredAssetsData, isLoading } = useGetAllAssetsBySearchQuery(forecastingAssetsSearchInput, {})

  const { setCustomElements } = useHeaderContent()

  useEffect(() => {
    return () => {
      dispatch(clearAllReportAssets())
      setCustomElements([])
    }
  }, [])

  if (isLoading || isNil(filteredAssetsData) || filteredAssetsData.items.length === 0) {
    return <div data-testid="fly-forward-loading"></div>
  }

  dispatch(initFilteredAssetsData({ newAssets: filteredAssetsData }))

  return (
    <PageContainer>
      <FlyForwardPageHeader />
      <FlyForwardPageBody />
    </PageContainer>
  )
}

export { FlyForwardPage }
