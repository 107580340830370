/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Engine.ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto
 */
import { validateNullablePositiveDecimalNumber } from '@flyward/platform/helpers/inputValidators'
import { z } from 'zod'

export interface ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto {
  flightHoursToFlightCyclesRatio: number
  reserveAmount: number
}

export const ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDtoSchema = z.object({
  flightHoursToFlightCyclesRatio: validateNullablePositiveDecimalNumber,
  reserveAmount: validateNullablePositiveDecimalNumber,
})
