export enum ComponentType {
  // ComponentMainUnit level
  Airframe = 1,
  EngineUnit = 2,
  AuxiliaryPowerUnit = 3,
  LandingGear = 4,
  ThrustReversers = 5,

  // ComponentSubUnit level
  EngineCore = 101,

  // ComponentModule level
  // nothing here yet

  // Subcomponent level
  EngineLlp = 301,
}

export const ComponentTypeDisplay = (value: ComponentType) => {
  switch (value) {
    case ComponentType.Airframe:
      return 'Airframe'
    case ComponentType.EngineUnit:
      return 'Engine Unit'
    case ComponentType.AuxiliaryPowerUnit:
      return 'APU'
    case ComponentType.EngineCore:
      return 'EPR'
    case ComponentType.EngineLlp:
      return 'LLP'
    case ComponentType.LandingGear:
      return 'Landing Gear'
    case ComponentType.ThrustReversers:
      return 'Thrust Reversers'
  }
}

export const ComponentTypeURI = (value: ComponentType) => {
  switch (value) {
    case ComponentType.Airframe:
      return 'airframes'
    case ComponentType.EngineUnit:
      return 'engines'
    case ComponentType.AuxiliaryPowerUnit:
      return 'auxiliary-power-units'
    case ComponentType.EngineCore:
      return 'engine-performance-restorations'
    case ComponentType.EngineLlp:
      return 'engine-life-limited-parts'
    case ComponentType.LandingGear:
      return 'landing-gears'
    case ComponentType.ThrustReversers:
      return 'thrust-reversers'
  }
}
