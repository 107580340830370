import { SearchInput } from '@flyward/platform/components'
import { debounce } from 'lodash'

import { useEffect, useMemo, useState } from 'react'

interface IAssetFilterDialogProps {
  initialSearchTerm: string | undefined | null
  updateSearchTerm: (searchTerm: string) => void
  clearSearchTerm: () => void
}

const FleetPageSearchTerm = ({ initialSearchTerm, updateSearchTerm, clearSearchTerm }: IAssetFilterDialogProps) => {
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm ?? '')

  const debouncedSearch = useMemo(() => debounce(updateSearchTerm, 300), [updateSearchTerm])

  useEffect(() => {
    if (searchTerm.length >= 3 && searchTerm !== initialSearchTerm) {
      debouncedSearch(searchTerm)
    }
    if (searchTerm.length === 0) {
      clearSearchTerm()
    }
  }, [clearSearchTerm, debouncedSearch, initialSearchTerm, searchTerm])

  return (
    <SearchInput
      controlledValue={searchTerm}
      inputClassName="w-49"
      setRawValueOnChange={(value: string) => {
        setSearchTerm(value)
      }}
      resetValue={() => {
        clearSearchTerm()
        setSearchTerm('')
      }}
    />
  )
}

export default FleetPageSearchTerm
