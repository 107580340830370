export enum UserState {
  None,
  PendingInvitation,
  Active,
  Inactive,
}

export const UserStateDisplay = (value: UserState) => {
  switch (value) {
    case UserState.None:
      return 'None'
    case UserState.PendingInvitation:
      return 'Pending'
    case UserState.Active:
      return 'Active'
    case UserState.Inactive:
      return 'Inactive'
  }
}
