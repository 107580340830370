import { useAssetComponentsForms } from '@flyward/assets/context'
import { type LandingGearDto } from '@flyward/assets/models'
import { LGContractualReadOnlyTab } from './LGContractualReadOnlyTab'
import { LGContractualEditableTab } from './LGContractualEditableTab'
import { type Control } from 'react-hook-form'

interface ILGContractualTabProps {
  component: LandingGearDto
  formControl: Control<LandingGearDto, unknown>
}

export const LGContractualTab = ({ component, formControl }: ILGContractualTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()
  return (
    <>
      {isInEditMode ? (
        <LGContractualEditableTab component={component} formControl={formControl} />
      ) : (
        <LGContractualReadOnlyTab component={component} />
      )}
    </>
  )
}
