import {
  type CheckType,
  type UtilizationUnits,
  type MaintenanceReserveCollectionType,
  validateNullableNaturalNumber,
  validateNullablePositiveDecimalNumber,
} from '@flyward/platform'
import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Abstract.MaintenanceReserve.ComponentMaintenanceReserveRateDto
 */
export interface ComponentMaintenanceReserveRate {
  checkType: CheckType
  maintenanceReserveCollectionType: MaintenanceReserveCollectionType | null
  utilizationUnit: UtilizationUnits | null
  ratesYear: number | null
  rateAmount: number | null
  currentFund: number | null
}

export const ComponentMaintenanceReserveRateSchema = z.object({
  checkType: z.any().nullable(),
  maintenanceReserveCollectionType: z.any().nullable(),
  utilizationUnit: z.any().nullable(),
  ratesYear: validateNullableNaturalNumber,
  rateAmount: validateNullablePositiveDecimalNumber,
  currentFund: validateNullablePositiveDecimalNumber,
})
