import { type AirframeCheckType } from '@flyward/platform/models/enums'
import { LessorContributionDtoSchema, type LessorContributionDto } from '../../DTOs/lessorContributionDto'
import { z } from 'zod'

export interface AirframeLessorContributionDto {
  checkType: AirframeCheckType
  lessorContribution?: LessorContributionDto
}

export const AirframeLessorContributionSchema = z.object({
  checkType: z.any(),
  lessorContribution: LessorContributionDtoSchema,
})
