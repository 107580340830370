export class UnauthorizedRetryCounter {
  private static instance: UnauthorizedRetryCounter
  private _count: number = 0

  public static readonly maxRetries: number = 10

  private constructor() {}

  public static getInstance(): UnauthorizedRetryCounter {
    if (typeof UnauthorizedRetryCounter.instance === 'undefined') {
      UnauthorizedRetryCounter.instance = new UnauthorizedRetryCounter()
    }
    return UnauthorizedRetryCounter.instance
  }

  get count(): number {
    return this._count
  }

  increment() {
    this._count++
  }

  reset() {
    this._count = 0
  }
}
