import { useAssetComponentsForms } from '@flyward/assets/context'
import { type EngineLifeLimitedPartDto, type AircraftEngineDto } from '@flyward/assets/models'
import { type UseFormSetValue, type UseFormRegister, type Control, type FormState } from 'react-hook-form'
import { EngineLessorContributionsEditableTab } from './Editable/EngineLessorContributionsEditableTab'
import { EngineLessorContributionsReadonlyTab } from './Readonly/EngineLessorContributionsReadonlyTab'

interface IEngineLessorContributionsTabProps {
  component: AircraftEngineDto
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

export const EngineLessorContributionsTab = ({
  component,
  engineLifeLimitedParts,
  registerFormField,
  setFormValue,
  formControl,
  formState,
}: IEngineLessorContributionsTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return isInEditMode ? (
    <EngineLessorContributionsEditableTab
      component={component}
      engineLifeLimitedParts={engineLifeLimitedParts}
      register={registerFormField}
      setFormValue={setFormValue}
      formControl={formControl}
      formState={formState}
    />
  ) : (
    <EngineLessorContributionsReadonlyTab component={component} engineLifeLimitedParts={engineLifeLimitedParts} />
  )
}
