import { type AssetSearchDTO } from '@flyward/assets/models'
import { useFlyForwardDefaultParametersPrefetch } from '@flyward/assets/store'
import { Icon, IconVariant, MultiSelect, Size, type MultiSelectValue } from '@flyward/platform/components'
import { useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectFilteredAssetsData } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'

interface IAssetsSearchProps {
  handleAssetSelectionAndGetData: (newSelectedAssetId: string) => Promise<void>
}

const AssetsSearch = ({ handleAssetSelectionAndGetData }: IAssetsSearchProps) => {
  const filteredAssetsData = useAppSelector(selectFilteredAssetsData)

  const availableAssetsData = filteredAssetsData?.items ?? []

  const prefetchGetAllAssetDefaultFlyForwardParameters = useFlyForwardDefaultParametersPrefetch('getFlyForwardDefaultParameters')

  const availableFilteredAssetsOptions = availableAssetsData.map((asset: AssetSearchDTO) => {
    const icon = asset.assetType === 1 ? IconVariant.PlaneZoomOut : IconVariant.Engine

    return {
      value: asset.assetId,
      label: (
        <span className="relative flex h-full flex-row items-center gap-1">
          <span className="w-min-8 my-auto self-start">
            <Icon variant={icon} size={Size.Medium} />
          </span>
          <span className="w-56 truncate">{asset.serialNumber}</span>
        </span>
      ),
    } satisfies MultiSelectValue
  })

  return (
    <MultiSelect
      label="MSN/ESN"
      placeholder="You can select another asset..."
      onValueSelected={(selectedValue: MultiSelectValue) => {
        prefetchGetAllAssetDefaultFlyForwardParameters({ assetId: selectedValue.value })
        handleAssetSelectionAndGetData(selectedValue.value)
      }}
      values={availableFilteredAssetsOptions}
    />
  )
}

export { AssetsSearch }
