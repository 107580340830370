import { type ApuDto } from '@flyward/assets/models'
import { CheckTypeDisplay, EditableDateCellWithLabelWithLabel, EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { Fragment } from 'react'
import { type UseFormSetValue, type Control } from 'react-hook-form'

interface IAPUTechnicalEditableTabProps {
  component: ApuDto
  setFormValue: UseFormSetValue<ApuDto>
  formControl: Control<ApuDto, unknown>
}

export const APUTechnicalEditableTab = ({ component, setFormValue, formControl }: IAPUTechnicalEditableTabProps) => {
  const utilizationAtEvents = component.assetComponentUtilizationSnapshot.componentUtilizationAtEvents

  const UtilizationAtEventComponent = utilizationAtEvents.map((event, index) => {
    return (
      <Fragment key={event.eventType}>
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`TSN @ last ${CheckTypeDisplay(event.eventType)}`}
          formControl={formControl}
          fieldName={`assetComponentUtilizationSnapshot.componentUtilizationAtEvents.${index}.totalHoursSinceNewAtLastQualifyingEvent`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`CSN @ last ${CheckTypeDisplay(event.eventType)}`}
          formControl={formControl}
          fieldName={`assetComponentUtilizationSnapshot.componentUtilizationAtEvents.${index}.cyclesSinceNewAtLastQualifyingEvent`}
        />
        <EditableDateCellWithLabelWithLabel
          className="basis-1/6"
          label={`Last ${CheckTypeDisplay(event.eventType)} date`}
          formControl={formControl}
          fieldName={`assetComponentUtilizationSnapshot.componentUtilizationAtEvents.${index}.dateAtLastQualifyingEvent`}
          setFormValue={setFormValue}
        />
      </Fragment>
    )
  })
  return (
    <div className="flex w-full gap-x-6 gap-y-2">
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.NaturalNumber}
        className="basis-1/6"
        label="TSN"
        formControl={formControl}
        fieldName={`assetComponentUtilizationSnapshot.totalHoursSinceNew`}
      />
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.NaturalNumber}
        className="basis-1/6"
        label="CSN"
        formControl={formControl}
        fieldName={`assetComponentUtilizationSnapshot.cyclesSinceNew`}
      />
      {UtilizationAtEventComponent}
      <div className="basis-1/6" />
    </div>
  )
}
