import { ApplicationInsights, type IConfiguration } from '@microsoft/applicationinsights-web'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AzureAppInsightsInstance {
  private static instance: ApplicationInsights

  private constructor() {}

  public static getInstance(): ApplicationInsights {
    if (typeof AzureAppInsightsInstance.instance === 'undefined' && window.location.hostname !== 'localhost') {
      const config: IConfiguration = {
        instrumentationKey: 'd7e80e3b-4834-4129-a376-260e8093145c',
      }

      AzureAppInsightsInstance.instance = new ApplicationInsights({ config })
      AzureAppInsightsInstance.instance.loadAppInsights()
    }

    return AzureAppInsightsInstance.instance
  }
}
