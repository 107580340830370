import { NumberCellWithLabel, NumberDisplayType } from '@flyward/platform'
import { type StubLife } from '../../../../models'

interface IStubLifeReadOnlyProps {
  stubLife: StubLife | undefined
}

export const StubLifeReadOnly = ({ stubLife }: IStubLifeReadOnlyProps): React.ReactElement<IStubLifeReadOnlyProps> => {
  return (
    <div className="flex gap-6">
      <NumberCellWithLabel displayType={NumberDisplayType.Integer} className="basis-1/6" info={stubLife?.intervalStart} label={''} />
    </div>
  )
}
