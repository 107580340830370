import { ComponentMaintenanceReserveRateSchema, type ComponentMaintenanceReserveRate } from '../../contract'
import {
  ComponentMaintenanceReserveContractSchema,
  type ComponentMaintenanceReserveContractDto,
} from '../../contract/ComponentMaintenanceReserveContractDto'
import { z } from 'zod'

export interface AirframeMaintenanceReserveContract extends ComponentMaintenanceReserveContractDto {
  airframeMaintenanceRates: ComponentMaintenanceReserveRate[]
}

export const AirframeMaintenanceReserveContractSchema = ComponentMaintenanceReserveContractSchema.extend({
  airframeMaintenanceRates: z.array(ComponentMaintenanceReserveRateSchema),
})
