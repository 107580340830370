import { AuthorizedElement } from '@flyward/appIdentity'
import { Button, ButtonVariant, IconVariant, Size } from '@flyward/platform'

interface IAuxiliaryPowerUnitMaintenanceProgramPageHeaderProps {
  isInEditMode: boolean
  setIsInEditMode: (isInEditMode: boolean) => void
  isDirty: boolean
  isValid: boolean
  submitForm: () => void
}

export const AuxiliaryPowerUnitMaintenanceProgramPageHeader = ({
  isInEditMode,
  setIsInEditMode,
  isDirty,
  isValid,
  submitForm,
}: IAuxiliaryPowerUnitMaintenanceProgramPageHeaderProps) => {
  return (
    <AuthorizedElement>
      <div data-permission-element-id="edit-asset">
        {!isInEditMode && (
          <div className="absolute right-0 top-0 mr-4 mt-4">
            <Button
              variant={ButtonVariant.Primary}
              size={Size.Medium}
              leftIcon={IconVariant.Edit}
              label="Edit"
              key={'edit-kb-auxiliary-power-unit-maintenance-program'}
              labelClassName={`hidden group-hover:block`}
              onClick={() => {
                setIsInEditMode(true)
              }}
            />
          </div>
        )}
        {isDirty && (
          <div className="absolute right-0 top-0 mr-4 mt-4">
            <Button
              variant={ButtonVariant.Primary}
              disabled={!isValid}
              size={Size.Medium}
              leftIcon={IconVariant.Save}
              key={'update-kb-auxiliary-power-unit-maintenance-program'}
              label="Save"
              labelClassName={`hidden group-hover:block`}
              onClick={() => {
                setIsInEditMode(false)
                submitForm()
              }}
            />
          </div>
        )}
      </div>
    </AuthorizedElement>
  )
}
