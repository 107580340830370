import { ComponentSchema, type ComponentDto } from '../../ComponentDto'
import { LessorContributionDtoSchema, type LessorContributionDto } from '../../DTOs/lessorContributionDto'
import { LandingGearContractSchema, type LandingGearContract } from './landingGearContract'

/**
 * Cloned from from API:Assets.Core.Domain.DTOs.Components.LandingGear.LandingGearDto
 */
export interface LandingGearDto extends ComponentDto {
  landingGearContract: LandingGearContract
  lessorContribution: LessorContributionDto
}

export const LandingGearDtoSchema = ComponentSchema.extend({
  landingGearContract: LandingGearContractSchema,
  lessorContribution: LessorContributionDtoSchema.optional().nullable(),
})
