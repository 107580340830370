import {
  AssetType,
  type IYearMonth,
  NumberDisplay,
  NumberDisplayType,
  YearMonth,
  type MonthlyStatistics,
  cn,
  type ComponentMonthlyStatistics,
  type GroupedComponentMonthlyStatistics,
  UtilizationUnits,
  Tooltip,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { CheckTypeDisplay } from './CheckTypeDisplay'
import { type IMaintenanceScheduleDisplay } from '@flyward/forecasts/models/entities/MaintenanceSchedule'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'

const getMonthYear = (rawValue: unknown) => {
  const value: IYearMonth = rawValue as IYearMonth
  const yearMonth: YearMonth = new YearMonth(value.year, value.month)
  return yearMonth.toShortString()
}

interface ISummaryTableProps {
  assetType: AssetType
  assetMonthlyStatistics: MonthlyStatistics[]
  groupedComponentsMonthlyStatistics: GroupedComponentMonthlyStatistics[]
  componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[]
  allEvents: IMaintenanceScheduleDisplay[]
}

export const SummaryTable = ({
  assetType,
  assetMonthlyStatistics,
  groupedComponentsMonthlyStatistics,
  componentTotalStatisticsAtEvent,
  allEvents,
}: ISummaryTableProps) => {
  const columnClassName = 'pr-3 text-end h-5 text-sm pl-4'

  const totalUtilizationLabelForAssetType = assetType === AssetType.Aircraft ? 'Airframe' : 'Engine PR'
  const totalUtilizationUnitForAssetType = UtilizationUnits.FlightHours

  const componentsMonthlyStatistics: ComponentMonthlyStatistics[] = []
  groupedComponentsMonthlyStatistics.forEach((component) => {
    if (component.subComponents !== undefined && component.subComponents.length > 0) {
      componentsMonthlyStatistics.push(...component.subComponents)
    } else {
      componentsMonthlyStatistics.push(component)
    }
  })

  return (
    <table className="w-full">
      <thead></thead>
      <tbody>
        {assetMonthlyStatistics.map((t, index) => (
          <tr key={t.eventMonth} className={cn('grid grid-cols-9 py-2', index % 2 === 0 ? 'bg-row-even' : 'bg-row-odd')}>
            <td className={cn(columnClassName, 'flex basis-1/9 justify-between pl-5')}>
              <CheckTypeDisplay
                checkTypes={t.checkTypes}
                eventYearMonth={t.yearMonth}
                componentsMonthlyStatistics={componentsMonthlyStatistics}
                allEvents={allEvents}
                componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
              />
              <span
                className={cn(
                  'font-semibold text-primary-dark-1',
                  (assetMonthlyStatistics[index]?.isEvent ?? false) && 'highlight-event -mr-3 px-2 pb-5',
                )}
              >
                {getMonthYear(t.yearMonth)}
              </span>
            </td>
            <td className={cn(columnClassName, 'basis-1/9 overflow-hidden text-ellipsis whitespace-nowrap')}>
              <NumberDisplay value={t.maintenanceReserveCashFlowIn} displayType={NumberDisplayType.CurrencyRounded} />
            </td>
            <td className={cn(columnClassName, 'basis-1/9 overflow-hidden text-ellipsis whitespace-nowrap')}>
              <NumberDisplay value={t.maintenanceReserveCashFlowOut} displayType={NumberDisplayType.CurrencyRounded} />
            </td>
            <td className={cn(columnClassName, 'basis-1/9 overflow-hidden text-ellipsis whitespace-nowrap')}>
              <NumberDisplay value={t.maintenanceReserveBalance} displayType={NumberDisplayType.CurrencyRounded} />
            </td>
            <td className={cn(columnClassName, 'basis-1/9 overflow-hidden text-ellipsis whitespace-nowrap')}>
              <NumberDisplay value={t.dollarMaintenanceBurn} displayType={NumberDisplayType.CurrencyRounded} />
            </td>
            <td className={cn(columnClassName, 'basis-1/9 overflow-hidden text-ellipsis whitespace-nowrap')}>
              <Tooltip
                content={
                  <NumberDisplay value={t.forecastedMileage?.find((m) => m.utilizationUnit === UtilizationUnits.FlightHours)?.utilizationAmount} />
                }
                tooltipContent={`${totalUtilizationLabelForAssetType} ${UtilizationUnitsDisplay(totalUtilizationUnitForAssetType)}`}
              />
            </td>
            <td className={cn(columnClassName, 'basis-1/9 overflow-hidden text-ellipsis whitespace-nowrap')}>
              <NumberDisplay value={t.endOfLease} displayType={NumberDisplayType.CurrencyRounded} />
            </td>
            <td className={cn(columnClassName, 'basis-1/9 overflow-hidden text-ellipsis whitespace-nowrap')}>
              <NumberDisplay value={t.fullLifeShortfall} displayType={NumberDisplayType.CurrencyRounded} />
            </td>
            <td className={cn(columnClassName, 'basis-1/9 overflow-hidden text-ellipsis whitespace-nowrap')}>
              <NumberDisplay value={t.lessorContribution} displayType={NumberDisplayType.CurrencyRounded} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
