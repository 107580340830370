import { format, parseISO } from 'date-fns'

export interface IYearMonth {
  year: number
  month: number
}

export const yearMonthFormatLong = 'MMMM yyyy'
export const yearMonthFormatShort = 'MMM-yy'

export class YearMonth {
  private readonly date: Date

  constructor(year: number, month: number) {
    // JavaScript Date months are 0-indexed, so subtract 1 from the month
    this.date = new Date(year, month - 1, 1)
  }

  static fromObject({ year, month }: { year: number; month: number }): YearMonth {
    return new YearMonth(year, month)
  }

  static fromYearMonthOrIYearMonth(object: YearMonth | IYearMonth): YearMonth {
    if (object instanceof YearMonth) {
      return object
    }
    return new YearMonth(object.year, object.month)
  }

  static fromDate(date: Date): YearMonth {
    return new YearMonth(date.getFullYear(), date.getMonth() + 1)
  }

  static fromISOString(isoDate: string): IYearMonth {
    const date = parseISO(isoDate)
    return new YearMonth(date.getFullYear(), date.getMonth() + 1).toIYearMonth()
  }

  getYear(): number {
    return this.date.getFullYear()
  }

  getMonth(): number {
    // JavaScript Date months are 0-indexed, add 1 to normalize
    return this.date.getMonth() + 1
  }

  toDate(): Date {
    return new Date(this.date)
  }

  toString(): string {
    // Ensuring month is two digits
    return format(this.date, yearMonthFormatLong)
  }

  toShortString(): string {
    // Ensuring month is two digits
    return format(this.date, yearMonthFormatShort)
  }

  toIYearMonth(): IYearMonth {
    return { year: this.getYear(), month: this.getMonth() }
  }

  equals(other: YearMonth): boolean {
    return this.date.getTime() === other.date.getTime()
  }

  compareTo(other: YearMonth): number {
    return this.date.getTime() - other.date.getTime()
  }

  addMonths(months: number): YearMonth {
    const newDate = new Date(this.date)
    newDate.setMonth(newDate.getMonth() + months)
    return YearMonth.fromDate(newDate)
  }
}
