import { useAssetComponentsForms } from '@flyward/assets/context'
import { PRTechnicalEditableTab } from './PRTechnicalEditableTab'
import { PRTechnicalReadOnlyTab } from './PRTechnicalReadOnlyTab'
import { type AircraftEngineDto } from '@flyward/assets/models'
import { type UseFormSetValue, type Control } from 'react-hook-form'

interface IPRTechnicalTabProps {
  setFormValue: UseFormSetValue<AircraftEngineDto>
  component: AircraftEngineDto
  formControl: Control<AircraftEngineDto, unknown>
  csnAtLastPR?: number
  dateAtLastPR?: string
  tsnAtLastPR?: number
}

export const PRTechnicalTab = ({
  setFormValue,
  component,
  formControl,
  csnAtLastPR = undefined,
  dateAtLastPR = undefined,
  tsnAtLastPR = undefined,
}: IPRTechnicalTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return (
    <>
      {isInEditMode ? (
        <PRTechnicalEditableTab component={component} formControl={formControl} setFormValue={setFormValue} />
      ) : (
        <PRTechnicalReadOnlyTab component={component} csnAtLastPR={csnAtLastPR} dateAtLastPR={dateAtLastPR} tsnAtLastPR={tsnAtLastPR} />
      )}
    </>
  )
}
