import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAuxiliaryPowerUnitMaintenanceProgramDetailsQueryResponse } from './types'

const auxiliaryPowerUnitMaintenanceProgramMocks = [
  http.get(APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AuxiliaryPowerUnits.GetSingleAuxiliaryPowerUnitProgram('*'), () => {
    return HttpResponse.json<IGetAuxiliaryPowerUnitMaintenanceProgramDetailsQueryResponse>(
      {
        check: {
          matureLimit: 6000,
          id: 'd591edde-cd67-4b2b-b614-6ef436ca1032',
          checkType: 5,
          utilizationUnit: 4,
          baseCost: 299943,
        },
        inflation: {
          maintenanceProgramId: 'd591edde-cd67-4b2b-b614-6ef436ca1032',
          referenceYear: 2017,
          percentage: 3.5,
          id: '9deab9c2-68e7-43f2-95b9-f76c1e54e6e4',
        },
        maintenanceProgramName: 'Hamilton - APS 3200 - 2021',
        isActive: true,
        dateOfCreation: '2024-07-10T06:50:07.3496167Z',
        id: 'd591edde-cd67-4b2b-b614-6ef436ca1032',
        compatibleComponentModels: ['APS 3200'],
        kbReferenceYear: 2021,
        manufacturer: 'Hamilton',
        basicAssumptions: {
          maintenanceProgramId: 'd591edde-cd67-4b2b-b614-6ef436ca1032',
          checkDowntimeMonths: 1,
          monthsDelayInPaymentOnClaims: 1,
        },
      },
      {
        status: 200,
      },
    )
  }),
]

export { auxiliaryPowerUnitMaintenanceProgramMocks }
