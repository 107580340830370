import { useAssetComponentsForms } from '@flyward/assets/context'
import { type LandingGearDto } from '@flyward/assets/models'
import { type UseFormRegister, type Control, type FormState, type UseFormSetValue } from 'react-hook-form'
import { LGTechnicalEditableTab } from './LGTechnicalEditableTab'
import { LGTechnicalReadOnlyTab } from './LGTechnicalReadOnlyTab'

interface ILGTechnicalTabProps {
  component: LandingGearDto
  registerFormField: UseFormRegister<LandingGearDto>
  setFormValue: UseFormSetValue<LandingGearDto>
  formControl: Control<LandingGearDto, unknown>
  formState: FormState<LandingGearDto>
}

export const LGTechnicalTab = ({ component, registerFormField, setFormValue, formControl, formState }: ILGTechnicalTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return (
    <>
      {isInEditMode ? (
        <LGTechnicalEditableTab
          component={component}
          register={registerFormField}
          setFormValue={setFormValue}
          formControl={formControl}
          formState={formState}
        />
      ) : (
        <LGTechnicalReadOnlyTab component={component} />
      )}
    </>
  )
}
