import { LessorContributionDtoSchema, type LessorContributionDto } from '../../DTOs/lessorContributionDto'
import { LlpLessorContributionDtoSchema, type LlpLessorContributionDto } from './LlpLessorContributionDto'
import { z } from 'zod'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Engine.EngineLessorContributionsDto
 */
export interface EngineLessorContributionsDto {
  eprLessorContribution?: LessorContributionDto
  llpLessorContributions?: LlpLessorContributionDto[]
}

export const EngineLessorContributionsDtoSchema = z.object({
  eprLessorContribution: LessorContributionDtoSchema,
  llpLessorContributions: z.array(LlpLessorContributionDtoSchema),
})
