import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IPutStandaloneEnginesQueryRequest,
  type IGetStandaloneEnginesByAssetIdQueryRequest,
  type IGetStandaloneEnginesByAssetIdQueryResponse,
} from './types'

const standaloneEnginesApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStandaloneEnginesByAssetId: builder.query<IGetStandaloneEnginesByAssetIdQueryResponse, IGetStandaloneEnginesByAssetIdQueryRequest>({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.StandAloneEngineController.GetStandAloneEngineDetails(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'standaloneEngines', id: request.assetId }],
    }),
    updateStandAloneEngine: builder.mutation<string, IPutStandaloneEnginesQueryRequest>({
      query: ({ assetId, standaloneEngine }) => ({
        url: APIRoutes.AssetsModule.StandAloneEngineController.Update(assetId),
        method: 'PUT',
        data: standaloneEngine,
      }),
      invalidatesTags: (_, __, request) => ['apiData', { type: 'standaloneEngines', id: request.assetId }, { type: 'assets' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllStandaloneEnginesByAssetIdQuery,
  useUpdateStandAloneEngineMutation,
  util: standaloneEnginesApiSliceUtil,
} = standaloneEnginesApi

type Endpoints = typeof standaloneEnginesApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TStandaloneEnginesApiActions = InitiateActions[keyof InitiateActions]
