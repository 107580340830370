/* eslint-disable max-len */
import { IconVariant } from '../IconVariant'
import { type IIconProps } from '../IIconProps'

export const FullScreen = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg className={className} data-variant={IconVariant[variant]} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_21_10265)">
        <path d="M7 14H5V19H10V17H7V14ZM5 10H7V7H10V5H5V10ZM17 17H14V19H19V14H17V17ZM14 5V7H17V10H19V5H14Z" />
      </g>
      <defs>
        <clipPath id="clip0_21_10265">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  )
}
