import { useGetTenantsQuery } from '@flyward/appIdentity/store/api/tenant/endpoints'
import { Combobox } from '@flyward/platform/components'
import { useNavigationState } from '@flyward/platform/contexts'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import { resetFleetSlice } from '@flyward/platform/store/slices/features'
import { selectSupportFilteredTenantId } from '@flyward/platform/store/slices/features/auth/authSelectors'
import { setSupportFilteredTenantId } from '@flyward/platform/store/slices/features/auth/authSlice'
import { resetFlyForwardSlice } from '@flyward/platform/store/slices/features/flyForward/flyForwardSlice'
import { isNil } from 'lodash'

const TenantSelector = () => {
  const { isCollapsed } = useNavigationState()

  const dispatch = useAppDispatch()

  const { data: tenants } = useGetTenantsQuery()

  const supportFilteredTenantId = useAppSelector(selectSupportFilteredTenantId)

  const tenantsList =
    tenants?.map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
      isDisabled: !tenant.isActive,
    })) ?? []

  return (
    <div>
      {!isCollapsed && (
        <Combobox
          items={tenantsList}
          selectedValues={!isNil(supportFilteredTenantId) ? [supportFilteredTenantId] : []}
          id="tenantSelector"
          itemLabel="tenant"
          className="w-52"
          label="Tenant"
          setSelectedValues={(value: string[]) => {
            dispatch(flywardApi.util.invalidateTags(['apiData']))
            dispatch(resetFlyForwardSlice())
            dispatch(resetFleetSlice())
            dispatch(
              setSupportFilteredTenantId({
                tenantId: value[0],
              }),
            )
          }}
        />
      )}
    </div>
  )
}

export { TenantSelector }
