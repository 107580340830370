import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IGetAuxiliaryPowerUnitMaintenanceProgramsQueryResponse,
  type IGetAuxiliaryPowerUnitMaintenanceProgramDetailsQueryRequest,
  type IGetAuxiliaryPowerUnitMaintenanceProgramDetailsQueryResponse,
  type IImportAuxiliaryPowerUnitKnowledgeBase,
  type IPutApuMaintenanceProgramDetailsMutationRequest,
} from './types'

const auxiliaryPowerUnitMaintenanceProgram = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAuxiliaryPowerUnitMaintenancePrograms: builder.query<IGetAuxiliaryPowerUnitMaintenanceProgramsQueryResponse, boolean>({
      query: (includeInactive) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AuxiliaryPowerUnits.GetAllAuxiliaryPowerUnitMaintenancePrograms(),
        method: 'GET',
        params: { includeInactive },
      }),
      providesTags: ['apiData', 'auxiliaryPowerUnitMaintenancePrograms'],
    }),
    getAuxiliaryPowerUnitMaintenanceProgramDetails: builder.query<
      IGetAuxiliaryPowerUnitMaintenanceProgramDetailsQueryResponse,
      IGetAuxiliaryPowerUnitMaintenanceProgramDetailsQueryRequest
    >({
      query: ({ programId }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AuxiliaryPowerUnits.GetSingleAuxiliaryPowerUnitProgram(programId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'auxiliaryPowerUnitMaintenancePrograms', id: request.programId }],
    }),
    importAuxiliaryPowerUnitKnowledgeBase: builder.mutation<void, IImportAuxiliaryPowerUnitKnowledgeBase>({
      query: ({ componentType, file }) => {
        const formData = new FormData()
        formData.append('inputFile', file)
        return {
          url: `${APIRoutes.KnowledgeBaseModule.MaintenancePlansController.ImportMaintenancePrograms()}?type=${componentType}`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': ' multipart/form-data',
          },
        }
      },
      invalidatesTags: [{ type: 'auxiliaryPowerUnitMaintenancePrograms' }],
    }),
    updateAuxiliaryPowerUnitMaintenanceProgramDetails: builder.mutation<string, IPutApuMaintenanceProgramDetailsMutationRequest>({
      query: ({ programId, apu }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AuxiliaryPowerUnits.UpdateAuxiliaryPowerUnitProgram(programId),
        method: 'PUT',
        data: apu,
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'auxiliaryPowerUnitMaintenancePrograms' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAuxiliaryPowerUnitMaintenanceProgramsQuery,
  useGetAuxiliaryPowerUnitMaintenanceProgramDetailsQuery,
  useImportAuxiliaryPowerUnitKnowledgeBaseMutation,
  useUpdateAuxiliaryPowerUnitMaintenanceProgramDetailsMutation,
  util: auxiliaryPowerUnitMaintenanceProgramsApiSliceUtil,
} = auxiliaryPowerUnitMaintenanceProgram

type Endpoints = typeof auxiliaryPowerUnitMaintenanceProgram.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAuxiliaryPowerUnitMaintenanceProgramsApiActions = InitiateActions[keyof InitiateActions]
