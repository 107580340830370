import { Table, type TableRowData, type TableColumn } from '@flyward/platform'
import React from 'react'

interface IMaintenanceProgramDetailsTableProps<T> {
  columns: Array<TableColumn<T>>
  data: TableRowData<T | undefined> | undefined
  tableClassName?: string
  canAddNewRow?: boolean
  canUploadFromFile?: boolean
  summaryRow?: React.ReactElement
}

export const MaintenanceProgramDetailsTable = <T extends object>({
  columns,
  data,
  canAddNewRow,
  tableClassName = '',
  canUploadFromFile,
  summaryRow,
}: IMaintenanceProgramDetailsTableProps<T>): React.ReactElement<IMaintenanceProgramDetailsTableProps<T>> => (
  <div className="flex flex-grow">
    <Table
      columns={columns}
      data={data}
      canAddNewRow={canAddNewRow}
      canUploadFromFile={canUploadFromFile}
      summaryRow={summaryRow}
      tableClassName={tableClassName}
    />
  </div>
)
