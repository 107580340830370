import { useAssetComponentsForms } from '@flyward/assets/context'
import { type AircraftEngineDto } from '@flyward/assets/models'
import { EngineDetailsGeneralEditable } from './EngineDetailsGeneralEditable'
import { EngineDetailsGeneralReadOnly } from './EngineDetailsGeneralReadOnly'
import { type UseFormSetValue, type Control } from 'react-hook-form'

export interface IEngineDetailsGeneralProps {
  engine: AircraftEngineDto
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
}

export const EngineDetailsGeneral = ({ engine, setFormValue, formControl }: IEngineDetailsGeneralProps) => {
  const { isInEditMode } = useAssetComponentsForms()
  return (
    <>
      {isInEditMode ? (
        <EngineDetailsGeneralEditable setFormValue={setFormValue} formControl={formControl} />
      ) : (
        <EngineDetailsGeneralReadOnly engine={engine} />
      )}
    </>
  )
}
