import { store } from '@flyward/platform/store/configureStore'
import { Provider } from 'react-redux'

interface TStoreProviderProps {
  children?: JSX.Element
}

const StoreProvider = ({ children }: TStoreProviderProps) => {
  return <Provider store={store}>{children}</Provider>
}

export { StoreProvider }
