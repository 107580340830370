/* eslint-disable max-len */
import * as React from 'react'

interface IProps {
  height: number
  width: number
}

export const FleetEmptyState = ({ height, width }: IProps): React.ReactElement<IProps> => {
  return (
    <svg width={width} height={height} viewBox="0 0 499 290" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M115.819 245.307H52.7461V252.982H115.819V245.307Z" fill="#EBEBEB" />
      <path d="M230.159 245.307H167.086V252.982H230.159V245.307Z" fill="#EBEBEB" />
      <path d="M344.494 245.307H281.421V252.982H344.494V245.307Z" fill="#EBEBEB" />
      <path
        d="M276.768 20.4581C277.204 17.2963 272.742 14.696 269.38 14.43C267.785 14.2971 266.123 14.5409 264.608 14.0163C261.328 12.8713 260.021 8.63836 256.815 7.28648C254.421 6.27441 251.643 7.17567 249.346 8.34287C247.048 9.51007 244.736 10.9802 242.143 11.1427C239.89 11.2904 237.445 10.4557 235.495 11.5564C234.017 12.3764 233.278 14.0681 231.897 15.0654C230.516 16.0626 228.846 16.2547 227.199 16.3434C225.551 16.432 223.852 16.4468 222.36 17.1338C220.868 17.8208 219.619 19.4017 219.93 21.0196L276.768 20.4581Z"
        fill="#FAFAFA"
      />
      <path
        d="M246.169 77.8727C246.804 73.2482 240.259 69.4512 235.383 69.0522C233.041 68.8676 230.618 69.2222 228.402 68.4465C223.608 66.7769 221.687 60.579 217.011 58.6065C213.509 57.1291 209.483 58.444 206.092 60.1505C202.701 61.857 199.348 64.0067 195.558 64.2505C192.256 64.4647 188.68 63.2384 185.792 64.8488C183.642 66.053 182.519 68.5425 180.539 70.02C178.56 71.4974 176.07 71.7486 173.669 71.8816C171.268 72.0146 168.771 72.0367 166.585 73.034C164.398 74.0313 162.581 76.3583 163.039 78.7223L246.169 77.8727Z"
        fill="#EBEBEB"
      />
      <path
        d="M118.722 38.1505C119.276 34.1318 113.602 30.8223 109.34 30.4825C107.308 30.3126 105.196 30.6302 103.268 29.958C99.0937 28.4805 97.4315 23.1099 93.3611 21.3961C90.3175 20.1107 86.8085 21.2557 83.861 22.7405C80.9134 24.2254 77.9954 26.0944 74.6933 26.3012C71.827 26.4859 68.7169 25.4222 66.2052 26.8257C64.3362 27.8674 63.3537 30.0245 61.6325 31.2582C59.9112 32.4918 57.7467 32.7356 55.6561 32.876C53.5655 33.0163 51.401 33.009 49.4951 33.8807C47.5891 34.7524 46.0156 36.7691 46.4072 38.8302L118.722 38.1505Z"
        fill="#F5F5F5"
      />
      <path
        d="M475.103 40.6036C475.538 37.4418 471.076 34.8415 467.715 34.5755C466.112 34.4426 464.457 34.6864 462.943 34.1619C459.663 33.0168 458.348 28.7765 455.149 27.432C452.756 26.4199 449.978 27.3212 447.681 28.4884C445.383 29.6556 443.071 31.1257 440.478 31.2882C438.262 31.4359 435.78 30.6012 433.829 31.7019C432.352 32.5219 431.613 34.2136 430.232 35.2109C428.85 36.2081 427.181 36.3928 425.533 36.4889C423.886 36.5849 422.187 36.5923 420.687 37.2793C419.188 37.9663 417.954 39.5472 418.264 41.1651L475.103 40.6036Z"
        fill="#F5F5F5"
      />
      <path
        d="M98.761 191.638C99.2534 191.663 99.7359 191.786 100.18 192C100.624 192.215 101.021 192.516 101.347 192.886C101.999 193.629 102.467 194.514 102.713 195.472C103.162 197.402 103.098 199.417 102.528 201.315C100.519 200.65 99.2411 198.56 98.6575 197.459C97.7341 195.716 97.1284 192.014 98.761 191.638Z"
        fill="#F5F5F5"
      />
      <path
        d="M105.447 203.457C105.137 203.002 104.953 202.474 104.914 201.925C104.875 201.376 104.983 200.826 105.225 200.332C105.473 199.84 105.817 199.403 106.237 199.046C106.657 198.69 107.145 198.421 107.671 198.256C107.904 198.153 108.158 198.103 108.414 198.109C108.669 198.115 108.92 198.178 109.148 198.293C109.343 198.429 109.498 198.616 109.594 198.834C109.69 199.051 109.725 199.291 109.695 199.527C109.636 200.001 109.45 200.451 109.155 200.827C108.225 202.164 107.072 203.287 105.462 203.457"
        fill="#F5F5F5"
      />
      <path
        d="M105.41 208.2C105.276 207.802 105.182 207.391 105.129 206.974C105.055 206.52 105.035 206.059 105.07 205.6C105.096 205.04 105.157 204.482 105.254 203.93C105.44 202.92 105.871 201.971 106.51 201.167C106.708 200.928 106.931 200.71 107.175 200.517C107.352 200.384 107.456 200.325 107.463 200.34C107.471 200.355 107.094 200.65 106.629 201.256C106.044 202.065 105.648 202.993 105.469 203.975C105.371 204.517 105.302 205.065 105.262 205.615C105.217 206.059 105.217 206.507 105.262 206.952C105.344 207.363 105.393 207.781 105.41 208.2Z"
        fill="#E0E0E0"
      />
      <path
        d="M99.4773 194.393C99.5941 194.568 99.6954 194.754 99.7802 194.947C99.9649 195.316 100.223 195.848 100.519 196.513C101.147 197.828 101.996 199.667 102.875 201.684C103.755 203.701 104.508 205.614 105.025 206.981C105.276 207.668 105.483 208.222 105.609 208.613C105.689 208.809 105.75 209.012 105.793 209.219C105.69 209.035 105.604 208.842 105.535 208.643C105.365 208.229 105.143 207.69 104.885 207.04C104.338 205.68 103.563 203.811 102.669 201.765L100.371 196.535C100.09 195.892 99.8541 195.36 99.6768 194.947C99.5935 194.768 99.5268 194.583 99.4773 194.393Z"
        fill="#E0E0E0"
      />
      <path
        d="M103.519 205.045C102.767 204.158 101.815 203.464 100.741 203.018C99.6668 202.572 98.5023 202.388 97.3433 202.482C96.7597 202.541 96.0727 202.748 95.9028 203.316C95.7329 203.885 96.1761 204.439 96.6415 204.794C97.6274 205.536 98.8111 205.969 100.043 206.039C101.275 206.109 102.5 205.812 103.563 205.185"
        fill="#F5F5F5"
      />
      <path
        d="M98.533 203.738C98.6428 203.718 98.7555 203.718 98.8654 203.738C99.0796 203.738 99.3899 203.738 99.774 203.782C100.781 203.849 101.768 204.1 102.685 204.521C103.182 204.779 103.635 205.113 104.029 205.511C104.345 205.841 104.614 206.214 104.827 206.619C104.971 206.888 105.089 207.17 105.182 207.461C105.227 207.564 105.255 207.674 105.263 207.786C105.219 207.786 105.078 207.343 104.694 206.693C104.467 206.323 104.196 205.98 103.889 205.673C103.505 205.294 103.063 204.978 102.581 204.735C101.689 204.318 100.737 204.044 99.7593 203.923C99.347 203.887 98.9373 203.826 98.533 203.738Z"
        fill="#E0E0E0"
      />
      <path
        d="M388.197 1.53882C388.197 1.53882 387.805 1.43539 387.104 1.29503C386.13 1.10192 385.131 1.07444 384.149 1.21377C382.682 1.42212 381.3 2.02755 380.152 2.96457C378.756 4.11714 377.768 5.68987 377.338 7.44869V7.5004L377.16 8.16526L376.909 7.53734C376.222 5.86413 375.045 4.4376 373.533 3.44475C372.297 2.63656 370.876 2.15376 369.404 2.04116C368.034 1.97099 366.663 2.14612 365.355 2.55827C365.674 2.35585 366.023 2.20618 366.39 2.11503C367.37 1.80344 368.399 1.67326 369.426 1.73089C370.964 1.79786 372.457 2.26926 373.755 3.09755C375.356 4.11901 376.602 5.61036 377.323 7.36743H376.894V7.31572C377.346 5.47254 378.396 3.83085 379.879 2.64692C380.891 1.84466 382.077 1.28942 383.341 1.02526C384.605 0.761099 385.914 0.795293 387.163 1.12513C387.528 1.20596 387.877 1.34569 388.197 1.53882Z"
        fill="#E0E0E0"
      />
      <path
        d="M317.272 5.21018C316.967 5.61262 316.636 5.99508 316.282 6.35522C315.385 7.38455 314.574 8.48658 313.859 9.64998C311.47 13.4961 310.218 17.9395 310.247 22.467V22.6295L309.796 22.6665C309.108 18.2259 307.04 14.1142 303.886 10.9132C302.565 9.61475 301.123 8.44475 299.58 7.419C299.706 7.45414 299.825 7.50899 299.934 7.58152C300.156 7.7071 300.496 7.86962 300.902 8.13557C302.059 8.85978 303.138 9.70147 304.123 10.6473C305.579 12.0784 306.822 13.7103 307.816 15.4934C309.037 17.6869 309.855 20.0812 310.232 22.5631H309.789V22.467C309.755 17.8606 311.073 13.3454 313.579 9.48007C314.33 8.31024 315.193 7.21664 316.157 6.21486C316.489 5.85288 316.785 5.6091 316.969 5.4318C317.154 5.25451 317.265 5.19541 317.272 5.21018Z"
        fill="#E0E0E0"
      />
      <path
        d="M333.045 71.3716C333.045 71.4159 332.269 71.1057 330.829 70.788C328.805 70.3483 326.719 70.2683 324.668 70.5516C321.616 70.9514 318.736 72.1919 316.35 74.1345C313.431 76.5093 311.373 79.7769 310.491 83.4352C310.489 83.4696 310.489 83.5041 310.491 83.5386L310.321 84.2034L310.063 83.5755C308.641 80.1047 306.195 77.1492 303.052 75.1022C300.486 73.4347 297.536 72.4506 294.483 72.2433C292.427 72.0974 290.361 72.287 288.366 72.8048C286.948 73.1815 286.202 73.5435 286.187 73.4622C286.356 73.3622 286.534 73.2781 286.719 73.2111C287.238 72.9858 287.771 72.7934 288.315 72.6349C290.322 72.0454 292.417 71.808 294.505 71.9331C297.624 72.0923 300.647 73.0652 303.274 74.755C306.507 76.8316 309.024 79.8514 310.484 83.4056H310.056V83.2948C310.961 79.5414 313.083 76.1932 316.091 73.7725C318.535 71.8011 321.489 70.5638 324.609 70.2044C326.695 69.9427 328.811 70.0705 330.851 70.5812C331.401 70.7167 331.942 70.8895 332.469 71.0983C332.668 71.1724 332.861 71.2639 333.045 71.3716Z"
        fill="#263238"
      />
      <path
        d="M394.656 37.5815C394.656 37.6184 394.22 37.4559 393.429 37.2934C392.32 37.0718 391.181 37.0393 390.061 37.1974C388.403 37.4267 386.841 38.1092 385.547 39.1698C383.96 40.4658 382.842 42.2457 382.363 44.2375V44.2892L382.193 44.9541L381.935 44.3261C381.161 42.4364 379.832 40.8263 378.123 39.709C376.728 38.7906 375.12 38.2463 373.454 38.1282C372.331 38.0425 371.202 38.1348 370.107 38.4015C369.324 38.5936 368.911 38.7635 368.896 38.7265C368.881 38.6896 368.992 38.66 369.184 38.5714C369.465 38.4379 369.757 38.3268 370.056 38.239C371.161 37.894 372.319 37.7489 373.476 37.8105C375.206 37.8951 376.884 38.4298 378.344 39.3618C380.144 40.5079 381.545 42.1826 382.356 44.1562H381.927V44.0971C382.432 42.0111 383.614 40.1512 385.288 38.8078C386.425 37.9048 387.757 37.2791 389.178 36.9803C390.598 36.6815 392.069 36.7179 393.474 37.0865C393.776 37.1663 394.072 37.2675 394.36 37.3894C394.537 37.515 394.656 37.5667 394.656 37.5815Z"
        fill="#263238"
      />
      <path
        d="M354.366 23.7451C353.995 24.2133 353.601 24.662 353.184 25.0896C352.126 26.3076 351.172 27.6124 350.332 28.9901C347.494 33.5451 346.006 38.8117 346.04 44.1785C346.037 44.2425 346.037 44.3066 346.04 44.3706H345.597C345.159 41.4886 344.219 38.7058 342.819 36.1485C341.683 34.0555 340.261 32.1311 338.594 30.4307C337.461 29.2847 336.224 28.2461 334.9 27.328C333.962 26.6779 333.423 26.3528 333.423 26.3159C333.571 26.3648 333.712 26.4318 333.844 26.5154C334.102 26.6705 334.501 26.8626 334.981 27.1802C336.347 28.0545 337.617 29.0697 338.771 30.209C342.636 34.0052 345.171 38.9495 345.996 44.3041H345.553V44.112C345.515 38.6643 347.068 33.324 350.022 28.7463C350.909 27.3714 351.923 26.0828 353.051 24.8975C353.442 24.4691 353.79 24.1588 354.011 23.9667C354.123 23.8831 354.242 23.809 354.366 23.7451Z"
        fill="#263238"
      />
      <path
        d="M436.836 136.38L442.074 127.139C438.836 121.867 437.687 115.575 438.853 109.5C440.02 103.424 443.417 98.0049 448.376 94.3071C453.336 90.6092 459.499 88.9005 465.655 89.5169C471.811 90.1333 477.513 93.0302 481.641 97.638C485.769 102.246 488.024 108.231 487.962 114.417C487.901 120.603 485.527 126.543 481.308 131.068C477.09 135.592 471.331 138.375 465.164 138.869C458.997 139.363 452.869 137.532 447.984 133.736L436.836 136.38Z"
        fill="#FAFAFA"
      />
      <path
        d="M436.836 136.381L447.991 133.655L447.931 133.81C447.914 133.792 447.905 133.768 447.905 133.744C447.905 133.719 447.914 133.695 447.931 133.677C447.948 133.663 447.969 133.655 447.991 133.655C448.012 133.655 448.033 133.663 448.05 133.677C451.489 136.322 455.567 138.012 459.869 138.575C461.155 138.743 462.452 138.802 463.748 138.752C464.413 138.708 465.092 138.693 465.772 138.634L467.84 138.316C473.982 137.088 479.429 133.573 483.08 128.484C485.104 125.667 486.504 122.451 487.188 119.05C487.886 115.457 487.785 111.755 486.892 108.206C485.969 104.496 484.177 101.06 481.662 98.1809C479.096 95.22 475.859 92.9151 472.221 91.4584C468.751 90.0867 465.017 89.5112 461.295 89.7741C457.77 90.0114 454.341 91.0202 451.248 92.7291C445.695 95.8161 441.534 100.913 439.621 106.972C438.858 109.551 438.492 112.231 438.535 114.921C438.641 119.226 439.879 123.428 442.125 127.102C442.132 127.117 442.136 127.134 442.136 127.15C442.136 127.167 442.132 127.183 442.125 127.198L436.806 136.388C437.116 135.819 438.697 132.99 441.977 127.102V127.198C439.681 123.508 438.406 119.273 438.284 114.928C438.225 112.207 438.586 109.494 439.355 106.883C441.265 100.733 445.468 95.5539 451.093 92.4188C454.227 90.6811 457.705 89.6546 461.28 89.4121C465.055 89.142 468.841 89.7227 472.361 91.1112C476.039 92.5868 479.309 94.9224 481.898 97.9224C484.453 100.845 486.274 104.335 487.21 108.102C488.115 111.708 488.214 115.469 487.498 119.117C486.807 122.58 485.381 125.855 483.317 128.72C479.608 133.872 474.077 137.42 467.848 138.641L465.75 138.959C465.063 139.018 464.376 139.033 463.703 139.07C462.393 139.12 461.08 139.053 459.781 138.87C455.452 138.279 451.357 136.548 447.917 133.854L448.05 133.721C448.065 133.74 448.074 133.764 448.074 133.788C448.074 133.812 448.065 133.836 448.05 133.854L436.836 136.381Z"
        fill="#263238"
      />
      <path
        d="M453.775 115.15L456.279 112.646L461.11 116.938L470.595 107.814L472.923 110.319L460.756 121.777L453.775 115.15Z"
        fill="#00838F"
      />
      <path
        d="M30.1092 127.723C30.1609 128.461 31.3503 146.191 55.8319 160.759C75.3936 172.379 101.175 182.463 116.578 184.147H300.094C300.094 184.147 330.974 182.869 339.986 181.694C351.067 180.217 360.227 173.295 361.727 168.043C363.227 162.79 360.501 156.962 357.819 153.689C355.802 151.229 341.072 136.698 339.824 135.693C338.575 134.689 325.573 123.948 304.327 123.948L37.3931 123.896C37.3931 123.896 29.7694 123.128 30.1092 127.723Z"
        fill="#EBEBEB"
      />
      <path d="M33.9219 72.5908H25.4561L36.3081 123.844H74.1978L33.9219 72.5908Z" fill="#00838F" />
      <path
        d="M55.1457 123.844C55.0335 123.704 54.9392 123.55 54.865 123.386L54.1262 122.041C53.4909 120.823 52.597 119.131 51.5111 117.055L43.0157 100.537L38.3616 91.421C37.7356 90.0764 36.9941 88.7886 36.1454 87.5722C34.2949 85.0006 31.8522 82.9127 29.024 81.485C28.4257 81.1895 27.9603 80.9753 27.6352 80.8423C27.4697 80.7779 27.3092 80.7014 27.155 80.6133C27.3303 80.6454 27.5013 80.6974 27.6648 80.7684C28.1476 80.9376 28.621 81.1324 29.0831 81.352C30.799 82.1488 32.3833 83.2025 33.7815 84.4769C34.7506 85.3571 35.6291 86.3322 36.404 87.3875C37.2681 88.6099 38.0245 89.9051 38.6645 91.2584C40.142 94.0582 41.6859 97.1314 43.3407 100.36L51.7549 116.929C52.8039 119.027 53.6534 120.741 54.2666 121.975C54.5473 122.546 54.7689 123.004 54.9314 123.349C55.0183 123.507 55.09 123.673 55.1457 123.844Z"
        fill="#263238"
      />
      <path
        d="M61.0691 107.133C61.0691 107.237 54.6864 107.326 46.8115 107.326C38.9366 107.326 32.554 107.237 32.554 107.133C32.554 107.03 38.9366 106.941 46.8115 106.941C54.6864 106.941 61.0691 107.023 61.0691 107.133Z"
        fill="#263238"
      />
      <path
        d="M48.769 104.208C48.769 104.171 49.0201 104.008 49.3821 104.26C49.5079 104.337 49.6076 104.451 49.6682 104.585C49.7288 104.72 49.7476 104.87 49.7221 105.016C49.6966 105.161 49.6281 105.296 49.5254 105.402C49.4226 105.508 49.2904 105.581 49.1457 105.611C48.9915 105.668 48.8232 105.674 48.6652 105.629C48.5073 105.584 48.3677 105.49 48.2666 105.36C48.193 105.254 48.1464 105.131 48.1309 105.002C48.1155 104.873 48.1316 104.743 48.178 104.622C48.3331 104.215 48.6582 104.186 48.6508 104.23C48.6434 104.274 48.4809 104.393 48.4365 104.688C48.4249 104.764 48.4286 104.842 48.4477 104.917C48.4667 104.992 48.5006 105.062 48.5474 105.124C48.608 105.189 48.687 105.235 48.774 105.255C48.861 105.274 48.9519 105.267 49.0349 105.235C49.1186 105.211 49.1941 105.165 49.2528 105.101C49.3116 105.037 49.3512 104.958 49.3674 104.873C49.37 104.795 49.357 104.718 49.329 104.646C49.3011 104.573 49.2589 104.507 49.2048 104.452C49.0054 104.245 48.7838 104.267 48.769 104.208Z"
        fill="#263238"
      />
      <path
        d="M54.2283 104.327C54.2283 104.29 54.4795 104.128 54.8415 104.379C54.9473 104.448 55.0335 104.544 55.0918 104.657C55.15 104.769 55.1783 104.895 55.1739 105.021C55.1732 105.136 55.1474 105.249 55.0985 105.353C55.0496 105.457 54.9786 105.549 54.8906 105.623C54.8025 105.696 54.6995 105.75 54.5886 105.78C54.4778 105.81 54.3618 105.815 54.2487 105.795C54.1355 105.776 54.0281 105.732 53.9337 105.666C53.8393 105.601 53.7602 105.516 53.7021 105.417C53.6439 105.318 53.608 105.208 53.5968 105.093C53.5856 104.979 53.5994 104.864 53.6373 104.755C53.7851 104.349 54.1101 104.32 54.1101 104.364C54.1101 104.408 53.9402 104.526 53.8959 104.822C53.8727 104.979 53.9126 105.138 54.0067 105.265C54.0681 105.327 54.1462 105.37 54.2315 105.388C54.3167 105.406 54.4054 105.4 54.4869 105.369C54.5719 105.347 54.6489 105.301 54.7091 105.237C54.7692 105.173 54.81 105.093 54.8267 105.007C54.8293 104.929 54.8163 104.852 54.7884 104.78C54.7604 104.707 54.7182 104.641 54.6642 104.586C54.4647 104.364 54.2357 104.386 54.2283 104.327Z"
        fill="#263238"
      />
      <path
        d="M37.2603 103.921C37.2603 103.921 37.5115 103.721 37.8735 103.965C37.9762 104.039 38.0599 104.137 38.1176 104.25C38.1754 104.363 38.2057 104.488 38.2059 104.615C38.1995 104.785 38.1405 104.948 38.0373 105.083C37.934 105.217 37.7914 105.317 37.6293 105.367C37.4672 105.417 37.2935 105.416 37.1321 105.363C36.9708 105.311 36.8297 105.209 36.7285 105.073C36.6558 104.966 36.6093 104.843 36.5926 104.715C36.5759 104.587 36.5896 104.456 36.6324 104.334C36.7876 103.921 37.1126 103.891 37.1126 103.943C37.1126 103.994 36.9353 104.105 36.8984 104.401C36.8853 104.477 36.8885 104.555 36.9076 104.63C36.9266 104.706 36.9613 104.776 37.0092 104.837C37.0708 104.898 37.1485 104.941 37.2334 104.961C37.3183 104.98 37.407 104.976 37.4894 104.947C37.5739 104.926 37.6503 104.88 37.7093 104.816C37.7683 104.751 37.8074 104.671 37.8218 104.585C37.8269 104.507 37.8157 104.429 37.789 104.355C37.7623 104.281 37.7206 104.214 37.6667 104.157C37.4967 103.958 37.2677 103.98 37.2603 103.921Z"
        fill="#263238"
      />
      <path d="M276.765 190.825H272.85V204.994H276.765V190.825Z" fill="#263238" />
      <path
        d="M272.848 207.419C275.303 204.964 275.303 200.983 272.848 198.528C270.393 196.073 266.413 196.073 263.958 198.528C261.503 200.983 261.503 204.964 263.958 207.419C266.413 209.874 270.393 209.874 272.848 207.419Z"
        fill="#455A64"
      />
      <path
        d="M271.757 202.977C271.757 203.64 271.56 204.289 271.192 204.84C270.823 205.392 270.299 205.822 269.687 206.075C269.074 206.329 268.399 206.396 267.749 206.266C267.098 206.137 266.501 205.817 266.032 205.348C265.563 204.879 265.243 204.282 265.114 203.631C264.984 202.981 265.051 202.306 265.305 201.693C265.558 201.081 265.988 200.557 266.54 200.188C267.091 199.82 267.74 199.623 268.403 199.623C269.293 199.623 270.146 199.976 270.775 200.605C271.404 201.234 271.757 202.087 271.757 202.977Z"
        fill="#263238"
      />
      <path
        d="M283.48 208.773C286.691 207.451 288.222 203.776 286.9 200.566C285.578 197.355 281.904 195.824 278.693 197.146C275.483 198.468 273.952 202.143 275.274 205.353C276.596 208.564 280.27 210.095 283.48 208.773Z"
        fill="#455A64"
      />
      <path
        d="M284.433 202.977C284.433 203.64 284.236 204.289 283.867 204.84C283.499 205.392 282.975 205.822 282.362 206.075C281.749 206.329 281.075 206.396 280.425 206.266C279.774 206.137 279.176 205.817 278.707 205.348C278.238 204.879 277.919 204.282 277.789 203.631C277.66 202.981 277.726 202.306 277.98 201.693C278.234 201.081 278.664 200.557 279.216 200.188C279.767 199.82 280.416 199.623 281.079 199.623C281.52 199.622 281.956 199.708 282.363 199.876C282.771 200.045 283.141 200.292 283.453 200.603C283.764 200.915 284.011 201.285 284.179 201.692C284.348 202.1 284.434 202.536 284.433 202.977Z"
        fill="#263238"
      />
      <path
        d="M277.621 181.694H271.992C271.025 181.694 270.241 182.478 270.241 183.445V189.074C270.241 190.041 271.025 190.825 271.992 190.825H277.621C278.588 190.825 279.372 190.041 279.372 189.074V183.445C279.372 182.478 278.588 181.694 277.621 181.694Z"
        fill="#00838F"
      />
      <g opacity="0.3">
        <path
          d="M277.621 181.694H271.992C271.025 181.694 270.241 182.478 270.241 183.445V189.074C270.241 190.041 271.025 190.825 271.992 190.825H277.621C278.588 190.825 279.372 190.041 279.372 189.074V183.445C279.372 182.478 278.588 181.694 277.621 181.694Z"
          fill="black"
        />
      </g>
      <path d="M141.902 190.825H137.987V204.994H141.902V190.825Z" fill="#263238" />
      <path
        d="M139.655 204.412C140.455 201.034 138.365 197.646 134.987 196.846C131.608 196.045 128.221 198.135 127.42 201.514C126.62 204.892 128.709 208.28 132.088 209.08C135.466 209.881 138.854 207.791 139.655 204.412Z"
        fill="#455A64"
      />
      <path
        d="M136.885 202.977C136.885 203.641 136.688 204.289 136.319 204.841C135.95 205.393 135.426 205.823 134.813 206.076C134.2 206.33 133.525 206.396 132.874 206.266C132.223 206.136 131.626 205.816 131.157 205.346C130.688 204.876 130.369 204.278 130.241 203.627C130.112 202.976 130.18 202.301 130.435 201.688C130.69 201.076 131.121 200.552 131.673 200.185C132.226 199.817 132.875 199.622 133.538 199.623C133.979 199.623 134.414 199.71 134.821 199.879C135.227 200.047 135.597 200.294 135.907 200.606C136.218 200.917 136.465 201.287 136.632 201.694C136.8 202.101 136.886 202.537 136.885 202.977Z"
        fill="#263238"
      />
      <path
        d="M148.617 208.779C151.828 207.458 153.359 203.783 152.037 200.573C150.715 197.362 147.04 195.831 143.83 197.153C140.619 198.475 139.089 202.15 140.41 205.36C141.732 208.57 145.407 210.101 148.617 208.779Z"
        fill="#455A64"
      />
      <path
        d="M149.569 202.977C149.569 203.64 149.373 204.289 149.004 204.84C148.636 205.392 148.112 205.822 147.499 206.075C146.886 206.329 146.212 206.396 145.561 206.266C144.911 206.137 144.313 205.817 143.844 205.348C143.375 204.879 143.055 204.282 142.926 203.631C142.797 202.981 142.863 202.306 143.117 201.693C143.371 201.081 143.801 200.557 144.352 200.188C144.904 199.82 145.552 199.623 146.215 199.623C146.656 199.622 147.093 199.708 147.5 199.876C147.907 200.045 148.278 200.292 148.589 200.603C148.901 200.915 149.148 201.285 149.316 201.692C149.484 202.1 149.57 202.536 149.569 202.977Z"
        fill="#263238"
      />
      <path
        d="M142.758 181.694H137.128C136.162 181.694 135.378 182.478 135.378 183.445V189.074C135.378 190.041 136.162 190.825 137.128 190.825H142.758C143.725 190.825 144.508 190.041 144.508 189.074V183.445C144.508 182.478 143.725 181.694 142.758 181.694Z"
        fill="#00838F"
      />
      <g opacity="0.3">
        <path
          d="M142.758 181.694H137.128C136.162 181.694 135.378 182.478 135.378 183.445V189.074C135.378 190.041 136.162 190.825 137.128 190.825H142.758C143.725 190.825 144.508 190.041 144.508 189.074V183.445C144.508 182.478 143.725 181.694 142.758 181.694Z"
          fill="black"
        />
      </g>
      <path
        d="M72.6011 169.646C88.7572 177.41 105.512 183.342 116.999 184.598H300.538C300.538 184.598 331.417 183.32 340.429 182.145C351.51 180.704 360.102 173.746 361.601 168.493L72.6011 169.646Z"
        fill="#00838F"
      />
      <path
        d="M321.097 142.882C321.097 142.882 322.257 147.839 326.888 147.839H352.05L347.255 143.007L321.097 142.882Z"
        fill="white"
      />
      <path
        d="M321.097 142.881C321.259 142.87 321.422 142.87 321.584 142.881H323.062H328.521H347.374L347.425 142.933L352.227 147.764L352.552 148.096H351.88L328.41 148.037C327.672 148.037 326.933 148.037 326.261 147.978C325.929 147.939 325.6 147.875 325.278 147.786C323.979 147.368 322.872 146.501 322.153 145.341C321.796 144.796 321.51 144.208 321.304 143.59C321.23 143.383 321.185 143.221 321.156 143.11C321.147 143.054 321.147 142.996 321.156 142.94C321.156 142.94 321.23 143.154 321.4 143.568C321.635 144.16 321.935 144.725 322.294 145.252C322.847 146.094 323.611 146.777 324.51 147.232C324.779 147.365 325.058 147.476 325.345 147.565C325.658 147.641 325.977 147.698 326.297 147.734C326.948 147.794 327.672 147.734 328.418 147.734L351.887 147.675H352.094L351.961 148.008L347.167 143.169L347.3 143.228L328.684 143.043L323.173 142.962H321.695L321.097 142.881Z"
        fill="#263238"
      />
      <path
        d="M78.599 146.464H78.5251C77.5337 146.464 76.73 147.268 76.73 148.259V150.63C76.73 151.622 77.5337 152.425 78.5251 152.425H78.599C79.5904 152.425 80.3941 151.622 80.3941 150.63V148.259C80.3941 147.268 79.5904 146.464 78.599 146.464Z"
        fill="white"
      />
      <path
        d="M78.6066 152.433C78.6066 152.396 78.8356 152.433 79.1976 152.248C79.4483 152.142 79.6706 151.978 79.8464 151.77C80.0222 151.562 80.1466 151.316 80.2097 151.051C80.248 150.666 80.248 150.277 80.2097 149.891C80.2097 149.456 80.2097 148.998 80.2097 148.517C80.2331 148.286 80.2099 148.053 80.1413 147.831C80.0728 147.608 79.9604 147.402 79.8108 147.225C79.6483 147.043 79.4486 146.899 79.2253 146.802C79.0021 146.705 78.7605 146.658 78.5172 146.663C78.2738 146.668 78.0344 146.725 77.8153 146.831C77.5961 146.937 77.4024 147.089 77.2473 147.276C77.1044 147.46 77.0013 147.671 76.9445 147.897C76.8999 148.125 76.8826 148.359 76.8928 148.591C76.8928 149.071 76.8928 149.529 76.8928 149.965C76.8607 150.35 76.8756 150.737 76.9371 151.118C77.0142 151.379 77.1501 151.62 77.3345 151.821C77.5189 152.022 77.7468 152.178 78.0009 152.278C78.3702 152.41 78.6066 152.396 78.6066 152.433C78.3949 152.498 78.1683 152.498 77.9565 152.433C77.6581 152.351 77.3849 152.196 77.1616 151.982C76.9384 151.768 76.7723 151.501 76.6785 151.206C76.5872 150.804 76.5524 150.392 76.5751 149.98C76.5751 149.547 76.5751 149.089 76.5751 148.606C76.5608 148.344 76.5782 148.081 76.6268 147.823C76.7256 147.387 76.9694 146.998 77.3183 146.719C77.6673 146.439 78.1007 146.287 78.5475 146.286C78.8509 146.282 79.1513 146.346 79.4264 146.474C79.7015 146.602 79.944 146.79 80.1358 147.025C80.4878 147.456 80.6551 148.008 80.6012 148.562C80.6012 149.054 80.6012 149.512 80.6012 149.936C80.6191 150.346 80.5918 150.757 80.5199 151.162C80.4385 151.46 80.2835 151.732 80.0692 151.954C79.8549 152.176 79.5882 152.341 79.2936 152.433C79.0722 152.516 78.828 152.516 78.6066 152.433Z"
        fill="#263238"
      />
      <path
        d="M84.2438 146.464H84.1699C83.1785 146.464 82.3748 147.268 82.3748 148.259V150.63C82.3748 151.622 83.1785 152.425 84.1699 152.425H84.2438C85.2352 152.425 86.0389 151.622 86.0389 150.63V148.259C86.0389 147.268 85.2352 146.464 84.2438 146.464Z"
        fill="white"
      />
      <path
        d="M84.2432 152.433C84.2432 152.396 84.4722 152.433 84.8342 152.248C85.0849 152.142 85.3071 151.978 85.4829 151.77C85.6587 151.563 85.7832 151.316 85.8462 151.051C85.8846 150.666 85.8846 150.277 85.8462 149.892C85.8462 149.456 85.8462 148.998 85.8462 148.517C85.8696 148.286 85.8464 148.053 85.7779 147.831C85.7094 147.609 85.5969 147.403 85.4473 147.225C85.2823 147.049 85.0818 146.911 84.8593 146.82C84.6367 146.728 84.3971 146.685 84.1566 146.694C83.9161 146.702 83.6801 146.762 83.4644 146.869C83.2488 146.975 83.0584 147.127 82.9061 147.313C82.7659 147.499 82.663 147.709 82.6032 147.934C82.5615 148.163 82.5466 148.396 82.5588 148.628C82.5588 149.121 82.5588 149.579 82.5588 150.002C82.5251 150.387 82.5425 150.775 82.6106 151.155C82.6848 151.417 82.8189 151.658 83.0022 151.859C83.1855 152.06 83.4131 152.216 83.667 152.315C84.0363 152.448 84.2727 152.433 84.2727 152.47C84.061 152.536 83.8343 152.536 83.6226 152.47C83.3248 152.387 83.0522 152.232 82.8291 152.018C82.6061 151.804 82.4396 151.538 82.3446 151.243C82.2533 150.841 82.2185 150.429 82.2412 150.017C82.2412 149.581 82.2412 149.123 82.2412 148.643C82.2242 148.381 82.239 148.118 82.2855 147.86C82.3845 147.423 82.6295 147.033 82.9799 146.753C83.3304 146.474 83.7655 146.322 84.2136 146.323C84.5158 146.319 84.815 146.384 85.0888 146.511C85.3626 146.639 85.6038 146.828 85.7945 147.062C86.1465 147.493 86.3138 148.045 86.2599 148.599C86.2599 149.091 86.2599 149.549 86.2599 149.973C86.2778 150.383 86.2505 150.795 86.1786 151.199C86.0972 151.497 85.9422 151.769 85.7279 151.991C85.5136 152.213 85.2469 152.378 84.9524 152.47C84.7197 152.547 84.4665 152.534 84.2432 152.433Z"
        fill="#263238"
      />
      <path
        d="M89.8797 146.464H89.8059C88.8144 146.464 88.0107 147.268 88.0107 148.259V150.63C88.0107 151.622 88.8144 152.425 89.8059 152.425H89.8797C90.8712 152.425 91.6749 151.622 91.6749 150.63V148.259C91.6749 147.268 90.8712 146.464 89.8797 146.464Z"
        fill="white"
      />
      <path
        d="M89.8798 152.433C89.8798 152.396 90.1162 152.433 90.4782 152.248C90.7272 152.141 90.9477 151.977 91.1221 151.769C91.2965 151.561 91.4201 151.316 91.4829 151.052C91.5213 150.666 91.5213 150.278 91.4829 149.892C91.4829 149.456 91.4829 148.998 91.4829 148.518C91.5061 148.287 91.4834 148.054 91.4162 147.832C91.349 147.61 91.2386 147.404 91.0914 147.225C90.9282 147.044 90.7278 146.9 90.504 146.803C90.2802 146.706 90.0381 146.658 89.7941 146.663C89.5502 146.668 89.3102 146.725 89.0905 146.831C88.8707 146.937 88.6763 147.089 88.5206 147.277C88.3799 147.461 88.2793 147.672 88.2251 147.897C88.1771 148.125 88.1597 148.359 88.1734 148.592C88.1734 149.084 88.1734 149.542 88.1734 149.966C88.1434 150.351 88.1608 150.738 88.2251 151.118C88.2993 151.38 88.4334 151.621 88.6167 151.822C88.8 152.024 89.0276 152.18 89.2815 152.278C89.6508 152.411 89.8872 152.396 89.8872 152.433C89.6755 152.499 89.4488 152.499 89.2371 152.433C88.9393 152.35 88.6667 152.195 88.4436 151.981C88.2206 151.767 88.0541 151.501 87.9591 151.207C87.8678 150.805 87.833 150.392 87.8557 149.981C87.8557 149.545 87.8557 149.087 87.8557 148.606C87.8387 148.345 87.8536 148.082 87.9 147.823C87.9912 147.463 88.1796 147.134 88.4447 146.873C88.7098 146.613 89.0413 146.43 89.4033 146.344C89.7652 146.259 90.1437 146.275 90.4974 146.389C90.851 146.504 91.1664 146.714 91.409 146.996C91.761 147.427 91.9283 147.979 91.8744 148.533C91.8744 149.025 91.8744 149.483 91.8744 149.907C91.8923 150.317 91.865 150.728 91.7932 151.133C91.7118 151.429 91.5577 151.701 91.3449 151.923C91.132 152.145 90.8671 152.31 90.5742 152.404C90.3538 152.496 90.1074 152.507 89.8798 152.433Z"
        fill="#263238"
      />
      <path
        d="M95.5157 146.464H95.4419C94.4504 146.464 93.6467 147.268 93.6467 148.259V150.63C93.6467 151.622 94.4504 152.425 95.4419 152.425H95.5157C96.5072 152.425 97.3109 151.622 97.3109 150.63V148.259C97.3109 147.268 96.5072 146.464 95.5157 146.464Z"
        fill="white"
      />
      <path
        d="M95.5158 152.433C95.5158 152.396 95.7522 152.433 96.1142 152.249C96.3632 152.141 96.5837 151.977 96.7581 151.769C96.9325 151.561 97.0561 151.316 97.1189 151.052C97.161 150.666 97.161 150.278 97.1189 149.892C97.1189 149.456 97.1189 148.998 97.1189 148.518C97.1421 148.287 97.1194 148.054 97.0522 147.833C96.985 147.611 96.8746 147.404 96.7273 147.225C96.5674 147.039 96.3672 146.892 96.1419 146.795C95.9167 146.697 95.6723 146.653 95.4272 146.664C95.1837 146.662 94.9431 146.717 94.7241 146.823C94.5051 146.93 94.3134 147.085 94.1639 147.277C94.0181 147.459 93.9146 147.671 93.8611 147.897C93.8131 148.126 93.7957 148.359 93.8093 148.592C93.8093 149.084 93.8093 149.542 93.8093 149.966C93.7794 150.351 93.7968 150.738 93.8611 151.118C93.9353 151.38 94.0694 151.621 94.2527 151.823C94.436 152.024 94.6636 152.18 94.9174 152.278C95.2868 152.411 95.5232 152.396 95.5232 152.433C95.3115 152.499 95.0848 152.499 94.8731 152.433C94.5758 152.351 94.3037 152.196 94.0817 151.982C93.8598 151.768 93.695 151.501 93.6025 151.207C93.5087 150.805 93.4714 150.393 93.4917 149.981C93.4917 149.545 93.4917 149.087 93.4917 148.607C93.4747 148.345 93.4895 148.082 93.536 147.824C93.6067 147.556 93.7298 147.305 93.898 147.085C94.0864 146.85 94.324 146.66 94.5942 146.527C94.8644 146.394 95.1604 146.322 95.4613 146.317C95.7623 146.311 96.0608 146.372 96.3358 146.494C96.6107 146.617 96.8553 146.798 97.0524 147.026C97.3989 147.459 97.5632 148.01 97.5104 148.562C97.5104 149.043 97.5104 149.501 97.5104 149.936C97.5258 150.347 97.4961 150.758 97.4217 151.163C97.3404 151.459 97.1863 151.731 96.9735 151.953C96.7606 152.175 96.4957 152.34 96.2028 152.433C95.9811 152.515 95.7376 152.515 95.5158 152.433Z"
        fill="#263238"
      />
      <path
        d="M130.103 141.086H129.897C127.257 141.086 125.117 143.226 125.117 145.866V152.196C125.117 154.836 127.257 156.976 129.897 156.976H130.103C132.743 156.976 134.883 154.836 134.883 152.196V145.866C134.883 143.226 132.743 141.086 130.103 141.086Z"
        fill="white"
      />
      <path
        d="M130.096 156.976C130.653 156.917 131.201 156.793 131.729 156.607C132.333 156.367 132.884 156.007 133.346 155.55C133.961 154.943 134.394 154.175 134.595 153.334C134.706 152.85 134.753 152.353 134.735 151.856C134.735 151.325 134.735 150.771 134.735 150.194C134.735 149.049 134.735 147.823 134.735 146.501C134.767 145.857 134.704 145.213 134.55 144.587C134.377 143.947 134.061 143.354 133.627 142.851C133.068 142.184 132.335 141.685 131.509 141.41C130.683 141.136 129.797 141.095 128.95 141.295C128.103 141.494 127.328 141.924 126.711 142.538C126.094 143.152 125.66 143.926 125.457 144.772C125.335 145.407 125.29 146.054 125.324 146.7C125.324 147.986 125.324 149.212 125.324 150.394C125.246 151.439 125.306 152.489 125.501 153.519C125.716 154.242 126.101 154.904 126.622 155.45C127.144 155.996 127.787 156.41 128.5 156.658C129.037 156.816 129.59 156.917 130.148 156.961C130.004 156.988 129.856 156.988 129.712 156.961C129.286 156.956 128.864 156.891 128.456 156.769C127.806 156.579 127.206 156.249 126.698 155.801C126.01 155.213 125.499 154.446 125.22 153.585C125.07 153.082 124.996 152.559 124.999 152.034C124.999 151.502 124.999 150.948 124.999 150.379C124.999 149.227 124.999 148 124.999 146.685C124.957 146.013 124.999 145.338 125.124 144.676C125.29 143.978 125.602 143.323 126.04 142.755C126.514 142.17 127.109 141.695 127.785 141.364C128.462 141.032 129.202 140.853 129.955 140.837C130.708 140.822 131.455 140.971 132.144 141.274C132.833 141.577 133.448 142.027 133.945 142.593C134.409 143.137 134.747 143.778 134.935 144.469C135.096 145.128 135.159 145.808 135.119 146.486C135.119 147.771 135.119 148.998 135.119 150.18C135.119 150.756 135.119 151.31 135.119 151.842C135.135 152.364 135.078 152.886 134.949 153.393C134.75 154.159 134.364 154.864 133.827 155.445C133.289 156.026 132.617 156.466 131.869 156.725C131.469 156.867 131.051 156.949 130.628 156.969C130.452 157.001 130.272 157.003 130.096 156.976Z"
        fill="#263238"
      />
      <path
        d="M146.253 146.464H146.179C145.187 146.464 144.384 147.268 144.384 148.259V150.63C144.384 151.622 145.187 152.425 146.179 152.425H146.253C147.244 152.425 148.048 151.622 148.048 150.63V148.259C148.048 147.268 147.244 146.464 146.253 146.464Z"
        fill="white"
      />
      <path
        d="M146.254 152.433C146.254 152.396 146.49 152.433 146.852 152.248C147.103 152.142 147.325 151.978 147.501 151.77C147.677 151.562 147.801 151.316 147.864 151.051C147.902 150.666 147.902 150.277 147.864 149.891C147.864 149.453 147.864 148.995 147.864 148.517C147.887 148.287 147.865 148.054 147.797 147.832C147.73 147.61 147.62 147.404 147.472 147.225C147.31 147.043 147.11 146.899 146.887 146.802C146.664 146.705 146.422 146.658 146.179 146.663C145.936 146.668 145.696 146.725 145.477 146.831C145.258 146.937 145.064 147.089 144.909 147.276C144.763 147.458 144.66 147.67 144.606 147.897C144.562 148.125 144.544 148.359 144.555 148.591C144.555 149.071 144.555 149.529 144.555 149.965C144.519 150.35 144.534 150.737 144.599 151.118C144.673 151.381 144.808 151.622 144.993 151.824C145.178 152.025 145.407 152.181 145.663 152.278C146.032 152.41 146.268 152.396 146.261 152.433C146.052 152.499 145.827 152.499 145.618 152.433C145.32 152.351 145.047 152.196 144.823 151.982C144.6 151.768 144.434 151.501 144.34 151.206C144.246 150.805 144.211 150.392 144.237 149.98C144.237 149.547 144.237 149.089 144.237 148.606C144.223 148.344 144.24 148.081 144.289 147.823C144.387 147.387 144.631 146.998 144.98 146.719C145.329 146.439 145.762 146.287 146.209 146.286C146.513 146.282 146.813 146.346 147.088 146.474C147.363 146.602 147.606 146.79 147.798 147.025C147.972 147.238 148.103 147.483 148.181 147.747C148.26 148.011 148.285 148.288 148.256 148.562C148.256 149.042 148.256 149.5 148.256 149.936C148.275 150.347 148.245 150.758 148.167 151.162C148.088 151.46 147.935 151.733 147.722 151.955C147.508 152.177 147.242 152.341 146.948 152.433C146.724 152.516 146.478 152.516 146.254 152.433Z"
        fill="#263238"
      />
      <path
        d="M151.889 146.464H151.815C150.823 146.464 150.02 147.268 150.02 148.259V150.63C150.02 151.622 150.823 152.425 151.815 152.425H151.889C152.88 152.425 153.684 151.622 153.684 150.63V148.259C153.684 147.268 152.88 146.464 151.889 146.464Z"
        fill="white"
      />
      <path
        d="M151.896 152.433C151.896 152.396 152.125 152.433 152.487 152.248C152.738 152.142 152.96 151.978 153.136 151.77C153.312 151.562 153.436 151.316 153.499 151.051C153.538 150.666 153.538 150.277 153.499 149.891C153.499 149.456 153.499 148.998 153.499 148.517C153.521 148.286 153.497 148.053 153.429 147.831C153.36 147.609 153.249 147.403 153.101 147.225C152.938 147.043 152.738 146.899 152.515 146.802C152.292 146.705 152.05 146.658 151.807 146.663C151.564 146.668 151.324 146.725 151.105 146.831C150.886 146.937 150.692 147.089 150.537 147.276C150.391 147.458 150.288 147.67 150.234 147.897C150.19 148.125 150.172 148.359 150.183 148.591C150.183 149.071 150.183 149.529 150.183 149.965C150.15 150.35 150.165 150.737 150.227 151.118C150.304 151.379 150.44 151.62 150.624 151.821C150.809 152.022 151.037 152.178 151.291 152.278C151.66 152.41 151.896 152.396 151.896 152.433C151.685 152.498 151.458 152.498 151.246 152.433C150.948 152.351 150.675 152.196 150.451 151.982C150.228 151.768 150.062 151.501 149.968 151.206C149.877 150.804 149.842 150.392 149.865 149.98C149.865 149.547 149.865 149.089 149.865 148.606C149.851 148.344 149.868 148.081 149.917 147.823C150.015 147.387 150.259 146.998 150.608 146.719C150.957 146.439 151.39 146.287 151.837 146.286C152.141 146.282 152.441 146.346 152.716 146.474C152.991 146.602 153.234 146.79 153.426 147.025C153.778 147.456 153.945 148.008 153.891 148.562C153.891 149.054 153.891 149.512 153.891 149.936C153.909 150.346 153.882 150.757 153.81 151.162C153.728 151.46 153.573 151.732 153.359 151.954C153.145 152.176 152.878 152.341 152.583 152.433C152.362 152.516 152.118 152.516 151.896 152.433Z"
        fill="#263238"
      />
      <path
        d="M157.533 146.464H157.459C156.468 146.464 155.664 147.268 155.664 148.259V150.63C155.664 151.622 156.468 152.425 157.459 152.425H157.533C158.525 152.425 159.328 151.622 159.328 150.63V148.259C159.328 147.268 158.525 146.464 157.533 146.464Z"
        fill="white"
      />
      <path
        d="M157.519 152.433C157.519 152.396 157.748 152.433 158.109 152.248C158.36 152.142 158.582 151.978 158.758 151.77C158.934 151.562 159.059 151.316 159.122 151.051C159.16 150.666 159.16 150.277 159.122 149.891C159.122 149.455 159.122 148.997 159.122 148.517C159.145 148.286 159.122 148.053 159.053 147.83C158.985 147.608 158.872 147.402 158.723 147.225C158.56 147.043 158.36 146.899 158.137 146.802C157.914 146.705 157.672 146.658 157.429 146.663C157.186 146.668 156.946 146.725 156.727 146.831C156.508 146.937 156.314 147.089 156.159 147.276C156.016 147.46 155.913 147.671 155.856 147.897C155.815 148.126 155.8 148.359 155.812 148.591C155.812 149.084 155.812 149.542 155.812 149.965C155.778 150.35 155.796 150.737 155.864 151.118C155.938 151.38 156.072 151.621 156.255 151.822C156.439 152.023 156.666 152.179 156.92 152.277C157.29 152.41 157.526 152.396 157.526 152.433C157.314 152.499 157.087 152.499 156.876 152.433C156.578 152.35 156.305 152.194 156.082 151.98C155.859 151.766 155.693 151.5 155.598 151.206C155.506 150.804 155.472 150.392 155.494 149.98C155.494 149.544 155.494 149.086 155.494 148.606C155.477 148.344 155.492 148.081 155.539 147.823C155.638 147.386 155.883 146.996 156.233 146.716C156.584 146.437 157.019 146.285 157.467 146.286C157.769 146.282 158.068 146.346 158.342 146.474C158.616 146.602 158.857 146.791 159.048 147.025C159.4 147.456 159.567 148.008 159.513 148.562C159.513 149.054 159.513 149.512 159.513 149.936C159.531 150.346 159.504 150.757 159.432 151.162C159.35 151.46 159.195 151.732 158.981 151.954C158.767 152.176 158.5 152.341 158.206 152.433C157.984 152.516 157.74 152.516 157.519 152.433Z"
        fill="#263238"
      />
      <path
        d="M163.17 146.464H163.096C162.104 146.464 161.301 147.268 161.301 148.259V150.63C161.301 151.622 162.104 152.425 163.096 152.425H163.17C164.161 152.425 164.965 151.622 164.965 150.63V148.259C164.965 147.268 164.161 146.464 163.17 146.464Z"
        fill="white"
      />
      <path
        d="M163.17 152.433C163.17 152.396 163.399 152.433 163.768 152.248C164.017 152.141 164.238 151.977 164.412 151.769C164.586 151.561 164.71 151.316 164.773 151.052C164.811 150.666 164.811 150.278 164.773 149.892C164.773 149.456 164.773 148.998 164.773 148.518C164.796 148.287 164.773 148.053 164.704 147.831C164.636 147.609 164.523 147.403 164.374 147.225C164.216 147.039 164.017 146.891 163.793 146.794C163.569 146.696 163.325 146.652 163.081 146.664C162.837 146.663 162.595 146.718 162.375 146.824C162.155 146.931 161.962 147.085 161.81 147.277C161.67 147.461 161.569 147.672 161.515 147.897C161.467 148.125 161.45 148.359 161.463 148.592C161.463 149.084 161.463 149.542 161.463 149.966C161.433 150.351 161.451 150.738 161.515 151.118C161.589 151.38 161.723 151.621 161.907 151.822C162.09 152.024 162.317 152.18 162.571 152.278C162.941 152.411 163.177 152.396 163.177 152.433C162.965 152.499 162.739 152.499 162.527 152.433C162.229 152.35 161.956 152.195 161.733 151.981C161.51 151.767 161.344 151.501 161.249 151.207C161.158 150.805 161.123 150.392 161.145 149.981C161.145 149.545 161.145 149.087 161.145 148.606C161.128 148.345 161.143 148.082 161.19 147.823C161.281 147.463 161.469 147.134 161.734 146.873C162 146.613 162.331 146.43 162.693 146.344C163.055 146.259 163.433 146.275 163.787 146.389C164.141 146.504 164.456 146.714 164.699 146.996C165.051 147.427 165.218 147.979 165.164 148.533C165.164 149.025 165.164 149.483 165.164 149.907C165.182 150.317 165.155 150.728 165.083 151.133C165.002 151.429 164.848 151.701 164.635 151.923C164.422 152.145 164.157 152.31 163.864 152.404C163.644 152.496 163.397 152.507 163.17 152.433Z"
        fill="#263238"
      />
      <path
        d="M168.806 146.464H168.732C167.74 146.464 166.937 147.268 166.937 148.259V150.63C166.937 151.622 167.74 152.425 168.732 152.425H168.806C169.797 152.425 170.601 151.622 170.601 150.63V148.259C170.601 147.268 169.797 146.464 168.806 146.464Z"
        fill="white"
      />
      <path
        d="M168.806 152.433C168.806 152.396 169.042 152.433 169.404 152.249C169.653 152.141 169.873 151.977 170.048 151.769C170.222 151.561 170.346 151.316 170.409 151.052C170.451 150.666 170.451 150.278 170.409 149.892C170.409 149.456 170.409 148.998 170.409 148.518C170.432 148.287 170.409 148.054 170.342 147.833C170.275 147.611 170.164 147.404 170.017 147.225C169.857 147.039 169.657 146.892 169.432 146.795C169.206 146.697 168.962 146.653 168.717 146.664C168.473 146.662 168.233 146.717 168.014 146.823C167.795 146.93 167.603 147.085 167.454 147.277C167.308 147.459 167.204 147.671 167.151 147.897C167.103 148.126 167.086 148.359 167.099 148.592C167.099 149.084 167.099 149.542 167.099 149.966C167.069 150.351 167.087 150.738 167.151 151.118C167.225 151.38 167.359 151.621 167.543 151.823C167.726 152.024 167.953 152.18 168.207 152.278C168.577 152.411 168.813 152.396 168.813 152.433C168.601 152.499 168.375 152.499 168.163 152.433C167.866 152.351 167.593 152.196 167.372 151.982C167.15 151.768 166.985 151.501 166.892 151.207C166.798 150.805 166.761 150.393 166.781 149.981C166.781 149.545 166.781 149.087 166.781 148.607C166.764 148.345 166.779 148.082 166.826 147.824C166.897 147.556 167.02 147.305 167.188 147.085C167.376 146.85 167.614 146.66 167.884 146.527C168.154 146.394 168.45 146.322 168.751 146.317C169.052 146.311 169.351 146.372 169.626 146.494C169.9 146.617 170.145 146.798 170.342 147.026C170.689 147.459 170.853 148.01 170.8 148.562C170.8 149.043 170.8 149.501 170.8 149.936C170.816 150.347 170.786 150.758 170.712 151.163C170.63 151.459 170.476 151.731 170.263 151.953C170.05 152.175 169.786 152.34 169.493 152.433C169.271 152.515 169.027 152.515 168.806 152.433Z"
        fill="#263238"
      />
      <path
        d="M174.444 146.464H174.37C173.378 146.464 172.575 147.268 172.575 148.259V150.63C172.575 151.622 173.378 152.425 174.37 152.425H174.444C175.435 152.425 176.239 151.622 176.239 150.63V148.259C176.239 147.268 175.435 146.464 174.444 146.464Z"
        fill="white"
      />
      <path
        d="M174.442 152.433C174.442 152.396 174.679 152.433 175.041 152.249C175.29 152.141 175.51 151.977 175.685 151.769C175.859 151.561 175.983 151.316 176.045 151.052C176.096 150.667 176.109 150.279 176.082 149.892C176.082 149.454 176.082 148.996 176.082 148.518C176.106 148.287 176.083 148.054 176.016 147.833C175.948 147.611 175.838 147.404 175.691 147.225C175.531 147.039 175.331 146.892 175.105 146.795C174.88 146.697 174.636 146.653 174.391 146.664C174.147 146.663 173.907 146.718 173.688 146.825C173.469 146.931 173.278 147.086 173.127 147.277C172.982 147.459 172.878 147.671 172.825 147.897C172.78 148.126 172.763 148.359 172.773 148.592C172.773 149.072 172.773 149.53 172.773 149.966C172.737 150.35 172.752 150.738 172.817 151.118C172.891 151.38 173.025 151.621 173.209 151.823C173.392 152.024 173.62 152.18 173.874 152.278C174.25 152.411 174.479 152.396 174.479 152.433C174.268 152.5 174.041 152.5 173.829 152.433C173.532 152.351 173.26 152.196 173.038 151.982C172.816 151.768 172.651 151.501 172.559 151.207C172.465 150.805 172.427 150.393 172.448 149.981C172.448 149.545 172.448 149.087 172.448 148.607C172.431 148.345 172.446 148.082 172.492 147.824C172.563 147.556 172.686 147.305 172.854 147.085C173.042 146.85 173.28 146.66 173.55 146.527C173.82 146.394 174.116 146.322 174.417 146.317C174.718 146.311 175.017 146.372 175.292 146.494C175.567 146.617 175.811 146.798 176.008 147.026C176.355 147.459 176.519 148.01 176.466 148.562C176.466 149.043 176.466 149.501 176.466 149.936C176.482 150.347 176.452 150.758 176.378 151.163C176.298 151.46 176.144 151.732 175.931 151.954C175.718 152.176 175.452 152.341 175.159 152.433C174.928 152.522 174.673 152.522 174.442 152.433Z"
        fill="#263238"
      />
      <path
        d="M180.08 146.464H180.006C179.014 146.464 178.211 147.268 178.211 148.259V150.63C178.211 151.622 179.014 152.425 180.006 152.425H180.08C181.071 152.425 181.875 151.622 181.875 150.63V148.259C181.875 147.268 181.071 146.464 180.08 146.464Z"
        fill="white"
      />
      <path
        d="M180.081 152.433C180.081 152.396 180.317 152.433 180.679 152.248C180.93 152.142 181.152 151.978 181.328 151.77C181.503 151.562 181.628 151.316 181.691 151.051C181.729 150.666 181.729 150.277 181.691 149.891C181.691 149.453 181.691 148.995 181.691 148.517C181.714 148.287 181.691 148.054 181.624 147.832C181.557 147.61 181.447 147.404 181.299 147.225C181.139 147.039 180.939 146.891 180.714 146.794C180.489 146.697 180.244 146.652 179.999 146.663C179.756 146.663 179.516 146.718 179.297 146.824C179.078 146.93 178.886 147.085 178.736 147.276C178.59 147.458 178.487 147.67 178.433 147.897C178.389 148.125 178.371 148.359 178.381 148.591C178.381 149.071 178.381 149.529 178.381 149.965C178.346 150.35 178.361 150.737 178.426 151.118C178.5 151.381 178.635 151.622 178.82 151.824C179.005 152.025 179.234 152.181 179.49 152.278C179.859 152.41 180.095 152.396 180.088 152.433C179.876 152.5 179.649 152.5 179.438 152.433C179.14 152.351 178.868 152.195 178.646 151.981C178.424 151.767 178.26 151.501 178.167 151.206C178.073 150.805 178.038 150.392 178.064 149.98C178.064 149.547 178.064 149.089 178.064 148.606C178.049 148.344 178.067 148.081 178.115 147.823C178.214 147.387 178.458 146.998 178.807 146.719C179.156 146.439 179.589 146.287 180.036 146.286C180.34 146.282 180.64 146.346 180.915 146.474C181.19 146.602 181.433 146.79 181.624 147.025C181.799 147.238 181.93 147.483 182.008 147.747C182.087 148.011 182.112 148.288 182.082 148.562C182.082 149.042 182.082 149.5 182.082 149.936C182.102 150.347 182.072 150.758 181.994 151.162C181.914 151.459 181.76 151.731 181.547 151.953C181.334 152.175 181.068 152.34 180.775 152.433C180.551 152.516 180.305 152.516 180.081 152.433Z"
        fill="#263238"
      />
      <path
        d="M185.716 146.464H185.642C184.65 146.464 183.847 147.268 183.847 148.259V150.63C183.847 151.622 184.65 152.425 185.642 152.425H185.716C186.707 152.425 187.511 151.622 187.511 150.63V148.259C187.511 147.268 186.707 146.464 185.716 146.464Z"
        fill="white"
      />
      <path
        d="M185.716 152.433C185.716 152.396 185.953 152.433 186.315 152.248C186.566 152.142 186.788 151.978 186.964 151.77C187.139 151.562 187.264 151.316 187.327 151.051C187.365 150.666 187.365 150.277 187.327 149.891C187.327 149.453 187.327 148.995 187.327 148.517C187.35 148.287 187.327 148.054 187.26 147.832C187.193 147.61 187.083 147.404 186.935 147.225C186.773 147.043 186.573 146.899 186.35 146.802C186.127 146.705 185.885 146.658 185.642 146.663C185.398 146.668 185.159 146.725 184.94 146.831C184.721 146.937 184.527 147.089 184.372 147.276C184.226 147.458 184.123 147.67 184.069 147.897C184.025 148.125 184.007 148.359 184.017 148.591C184.017 149.071 184.017 149.529 184.017 149.965C183.982 150.35 183.997 150.737 184.062 151.118C184.136 151.381 184.271 151.622 184.456 151.824C184.641 152.025 184.87 152.181 185.125 152.278C185.495 152.41 185.731 152.396 185.724 152.433C185.515 152.499 185.29 152.499 185.081 152.433C184.783 152.351 184.509 152.196 184.286 151.982C184.063 151.768 183.897 151.501 183.803 151.206C183.709 150.805 183.674 150.392 183.7 149.98C183.7 149.547 183.7 149.089 183.7 148.606C183.685 148.344 183.703 148.081 183.751 147.823C183.85 147.387 184.094 146.998 184.443 146.719C184.792 146.439 185.225 146.287 185.672 146.286C185.975 146.282 186.276 146.346 186.551 146.474C186.826 146.602 187.069 146.79 187.26 147.025C187.435 147.238 187.566 147.483 187.644 147.747C187.723 148.011 187.748 148.288 187.718 148.562C187.718 149.042 187.718 149.5 187.718 149.936C187.738 150.347 187.708 150.758 187.63 151.162C187.551 151.46 187.398 151.733 187.185 151.955C186.971 152.177 186.705 152.341 186.411 152.433C186.187 152.516 185.94 152.516 185.716 152.433Z"
        fill="#263238"
      />
      <path
        d="M191.352 146.464H191.278C190.286 146.464 189.483 147.268 189.483 148.259V150.63C189.483 151.622 190.286 152.425 191.278 152.425H191.352C192.343 152.425 193.147 151.622 193.147 150.63V148.259C193.147 147.268 192.343 146.464 191.352 146.464Z"
        fill="white"
      />
      <path
        d="M191.361 152.433C191.361 152.396 191.59 152.433 191.952 152.248C192.203 152.142 192.425 151.978 192.601 151.77C192.776 151.563 192.901 151.316 192.964 151.051C193.002 150.666 193.002 150.277 192.964 149.892C192.964 149.456 192.964 148.998 192.964 148.518C192.987 148.286 192.964 148.053 192.896 147.831C192.827 147.609 192.715 147.403 192.565 147.225C192.4 147.05 192.2 146.911 191.977 146.82C191.754 146.728 191.515 146.685 191.274 146.694C191.034 146.702 190.798 146.762 190.582 146.869C190.366 146.975 190.176 147.127 190.024 147.313C189.881 147.497 189.778 147.708 189.721 147.934C189.68 148.163 189.663 148.396 189.669 148.628C189.669 149.109 189.669 149.567 189.669 150.002C189.637 150.387 189.652 150.774 189.713 151.155C189.791 151.416 189.926 151.657 190.111 151.858C190.295 152.059 190.523 152.215 190.777 152.315C191.147 152.448 191.383 152.433 191.383 152.47C191.171 152.535 190.945 152.535 190.733 152.47C190.434 152.388 190.161 152.233 189.938 152.019C189.715 151.805 189.549 151.538 189.455 151.243C189.364 150.841 189.329 150.429 189.351 150.017C189.351 149.584 189.351 149.126 189.351 148.643C189.337 148.381 189.355 148.118 189.403 147.86C189.502 147.424 189.746 147.035 190.095 146.756C190.444 146.477 190.877 146.324 191.324 146.323C191.627 146.319 191.928 146.383 192.203 146.511C192.478 146.639 192.72 146.827 192.912 147.062C193.264 147.493 193.431 148.045 193.378 148.599C193.378 149.091 193.378 149.549 193.378 149.973C193.395 150.383 193.368 150.795 193.296 151.199C193.215 151.497 193.06 151.769 192.846 151.991C192.631 152.213 192.365 152.378 192.07 152.47C191.837 152.547 191.584 152.534 191.361 152.433Z"
        fill="#263238"
      />
      <path
        d="M196.996 146.464H196.922C195.931 146.464 195.127 147.268 195.127 148.259V150.63C195.127 151.622 195.931 152.425 196.922 152.425H196.996C197.988 152.425 198.791 151.622 198.791 150.63V148.259C198.791 147.268 197.988 146.464 196.996 146.464Z"
        fill="white"
      />
      <path
        d="M196.997 152.433C196.997 152.396 197.226 152.433 197.588 152.248C197.838 152.142 198.06 151.979 198.236 151.771C198.412 151.563 198.537 151.317 198.6 151.052C198.638 150.666 198.638 150.278 198.6 149.892C198.6 149.456 198.6 148.998 198.6 148.518C198.623 148.287 198.6 148.053 198.531 147.831C198.463 147.609 198.35 147.403 198.201 147.225C198.038 147.044 197.838 146.9 197.615 146.803C197.392 146.706 197.15 146.658 196.907 146.663C196.664 146.668 196.424 146.725 196.205 146.831C195.986 146.937 195.792 147.089 195.637 147.277C195.497 147.462 195.394 147.673 195.334 147.897C195.293 148.126 195.278 148.359 195.29 148.592C195.29 149.084 195.29 149.542 195.29 149.966C195.256 150.35 195.274 150.738 195.342 151.118C195.416 151.38 195.55 151.621 195.733 151.822C195.917 152.024 196.144 152.18 196.398 152.278C196.768 152.411 197.004 152.396 197.004 152.433C196.792 152.499 196.566 152.499 196.354 152.433C196.056 152.35 195.783 152.195 195.56 151.981C195.337 151.767 195.171 151.501 195.076 151.207C194.984 150.805 194.95 150.392 194.972 149.981C194.972 149.545 194.972 149.087 194.972 148.606C194.955 148.345 194.97 148.082 195.017 147.823C195.108 147.463 195.296 147.134 195.561 146.873C195.826 146.613 196.158 146.43 196.52 146.344C196.882 146.259 197.26 146.275 197.614 146.389C197.968 146.504 198.283 146.714 198.526 146.996C198.878 147.427 199.045 147.979 198.991 148.533C198.991 149.025 198.991 149.483 198.991 149.907C199.009 150.317 198.982 150.728 198.91 151.133C198.828 151.431 198.673 151.703 198.459 151.925C198.245 152.147 197.978 152.312 197.684 152.404C197.466 152.497 197.222 152.507 196.997 152.433Z"
        fill="#263238"
      />
      <path
        d="M210.013 141.861H209.732C207.235 141.861 205.211 143.885 205.211 146.382V152.514C205.211 155.011 207.235 157.035 209.732 157.035H210.013C212.51 157.035 214.534 155.011 214.534 152.514V146.382C214.534 143.885 212.51 141.861 210.013 141.861Z"
        fill="white"
      />
      <path
        d="M210.013 157.036C210.545 156.981 211.069 156.86 211.571 156.674C212.151 156.444 212.676 156.097 213.115 155.654C213.69 155.071 214.091 154.339 214.275 153.541C214.373 153.073 214.411 152.594 214.386 152.116C214.386 151.606 214.386 151.074 214.386 150.527C214.386 149.424 214.386 148.257 214.386 147.026C214.422 146.412 214.365 145.797 214.216 145.201C214.044 144.591 213.736 144.028 213.315 143.554C212.884 143.058 212.35 142.665 211.749 142.401C211.122 142.143 210.446 142.025 209.769 142.054C208.781 142.06 207.825 142.399 207.055 143.018C206.285 143.636 205.747 144.497 205.529 145.46C205.425 146.069 205.388 146.689 205.418 147.306C205.418 148.54 205.418 149.707 205.418 150.808C205.343 151.796 205.398 152.789 205.58 153.763C205.819 154.549 206.269 155.254 206.88 155.802C207.34 156.222 207.884 156.54 208.476 156.733C208.993 156.874 209.522 156.963 210.057 156.999C209.918 157.024 209.775 157.024 209.636 156.999C209.229 156.999 208.824 156.944 208.432 156.836C207.808 156.66 207.231 156.35 206.74 155.927C206.078 155.368 205.586 154.633 205.322 153.807C205.182 153.327 205.11 152.83 205.107 152.33C205.107 151.82 205.107 151.288 205.107 150.742C205.107 149.641 205.107 148.474 205.107 147.24C205.07 146.595 205.105 145.949 205.211 145.312C205.445 144.266 206.029 143.33 206.865 142.659C207.701 141.988 208.741 141.621 209.813 141.618C210.542 141.589 211.268 141.72 211.941 142.002C212.595 142.287 213.178 142.714 213.647 143.251C214.097 143.772 214.427 144.384 214.615 145.046C214.764 145.677 214.822 146.326 214.785 146.974C214.785 148.208 214.785 149.375 214.785 150.476C214.785 151.022 214.785 151.554 214.785 152.064C214.806 152.56 214.758 153.058 214.644 153.541C214.435 154.385 213.992 155.153 213.366 155.758C212.899 156.207 212.339 156.55 211.726 156.762C211.344 156.9 210.943 156.982 210.537 157.006C210.365 157.046 210.188 157.056 210.013 157.036Z"
        fill="#263238"
      />
      <path
        d="M225.179 146.464H225.105C224.113 146.464 223.31 147.268 223.31 148.259V150.63C223.31 151.622 224.113 152.425 225.105 152.425H225.179C226.17 152.425 226.974 151.622 226.974 150.63V148.259C226.974 147.268 226.17 146.464 225.179 146.464Z"
        fill="white"
      />
      <path
        d="M225.186 152.433C225.186 152.396 225.415 152.433 225.777 152.248C226.028 152.142 226.25 151.978 226.426 151.77C226.602 151.562 226.726 151.316 226.789 151.051C226.827 150.666 226.827 150.277 226.789 149.891C226.789 149.456 226.789 148.998 226.789 148.517C226.83 148.051 226.687 147.587 226.39 147.225C226.228 147.043 226.028 146.899 225.805 146.802C225.582 146.705 225.34 146.658 225.097 146.663C224.853 146.668 224.614 146.725 224.395 146.831C224.176 146.937 223.982 147.089 223.827 147.276C223.681 147.458 223.577 147.67 223.524 147.897C223.479 148.125 223.462 148.359 223.472 148.591C223.472 149.071 223.472 149.529 223.472 149.965C223.44 150.35 223.455 150.737 223.517 151.118C223.594 151.379 223.73 151.62 223.914 151.821C224.098 152.022 224.326 152.178 224.58 152.278C224.95 152.41 225.186 152.396 225.186 152.433C224.974 152.498 224.748 152.498 224.536 152.433C224.238 152.351 223.964 152.196 223.741 151.982C223.518 151.768 223.352 151.501 223.258 151.206C223.167 150.804 223.132 150.392 223.155 149.98C223.155 149.547 223.155 149.089 223.155 148.606C223.14 148.344 223.158 148.081 223.206 147.823C223.305 147.387 223.549 146.998 223.898 146.719C224.247 146.439 224.68 146.287 225.127 146.286C225.43 146.282 225.731 146.346 226.006 146.474C226.281 146.602 226.523 146.79 226.715 147.025C227.067 147.456 227.235 148.008 227.181 148.562C227.181 149.054 227.181 149.512 227.181 149.936C227.199 150.346 227.171 150.757 227.099 151.162C227.018 151.46 226.863 151.732 226.649 151.954C226.434 152.176 226.168 152.341 225.873 152.433C225.652 152.517 225.407 152.517 225.186 152.433Z"
        fill="#263238"
      />
      <path
        d="M230.823 146.464H230.749C229.758 146.464 228.954 147.268 228.954 148.259V150.63C228.954 151.622 229.758 152.425 230.749 152.425H230.823C231.815 152.425 232.618 151.622 232.618 150.63V148.259C232.618 147.268 231.815 146.464 230.823 146.464Z"
        fill="white"
      />
      <path
        d="M230.822 152.433C230.822 152.396 231.051 152.433 231.413 152.248C231.664 152.142 231.886 151.978 232.062 151.77C232.238 151.562 232.362 151.316 232.425 151.051C232.464 150.666 232.464 150.277 232.425 149.891C232.425 149.455 232.425 148.997 232.425 148.517C232.449 148.286 232.425 148.053 232.357 147.83C232.288 147.608 232.176 147.402 232.026 147.225C231.864 147.043 231.664 146.899 231.441 146.802C231.218 146.705 230.976 146.658 230.733 146.663C230.489 146.668 230.25 146.725 230.031 146.831C229.812 146.937 229.618 147.089 229.463 147.276C229.32 147.46 229.217 147.671 229.16 147.897C229.119 148.126 229.102 148.359 229.108 148.591C229.108 149.071 229.108 149.529 229.108 149.965C229.075 150.35 229.092 150.737 229.16 151.118C229.234 151.38 229.368 151.621 229.552 151.822C229.735 152.023 229.963 152.179 230.216 152.277C230.586 152.41 230.822 152.396 230.822 152.433C230.61 152.498 230.384 152.498 230.172 152.433C229.874 152.35 229.602 152.194 229.379 151.98C229.156 151.766 228.989 151.5 228.894 151.206C228.803 150.804 228.768 150.392 228.791 149.98C228.791 149.544 228.791 149.086 228.791 148.606C228.774 148.344 228.788 148.081 228.835 147.823C228.934 147.386 229.179 146.996 229.529 146.716C229.88 146.437 230.315 146.285 230.763 146.286C231.065 146.282 231.364 146.346 231.638 146.474C231.912 146.602 232.153 146.791 232.344 147.025C232.696 147.456 232.863 148.008 232.809 148.562C232.809 149.054 232.809 149.512 232.809 149.936C232.827 150.346 232.8 150.757 232.728 151.162C232.647 151.46 232.492 151.732 232.277 151.954C232.063 152.176 231.796 152.341 231.502 152.433C231.283 152.514 231.041 152.514 230.822 152.433Z"
        fill="#263238"
      />
      <path
        d="M236.459 146.464H236.385C235.394 146.464 234.59 147.268 234.59 148.259V150.63C234.59 151.622 235.394 152.425 236.385 152.425H236.459C237.451 152.425 238.254 151.622 238.254 150.63V148.259C238.254 147.268 237.451 146.464 236.459 146.464Z"
        fill="white"
      />
      <path
        d="M236.459 152.433C236.459 152.396 236.688 152.433 237.058 152.249C237.307 152.141 237.527 151.977 237.701 151.769C237.876 151.561 237.999 151.316 238.062 151.052C238.101 150.666 238.101 150.278 238.062 149.892C238.062 149.456 238.062 148.998 238.062 148.518C238.086 148.287 238.062 148.053 237.994 147.831C237.925 147.609 237.813 147.403 237.663 147.225C237.498 147.044 237.295 146.9 237.069 146.805C236.843 146.709 236.599 146.664 236.354 146.673C236.108 146.681 235.868 146.743 235.649 146.854C235.43 146.964 235.237 147.121 235.085 147.314C234.945 147.499 234.842 147.71 234.782 147.934C234.741 148.163 234.726 148.396 234.738 148.629C234.738 149.121 234.738 149.579 234.738 150.003C234.704 150.388 234.722 150.775 234.79 151.155C234.864 151.417 234.998 151.658 235.181 151.86C235.365 152.061 235.592 152.217 235.846 152.315C236.215 152.448 236.452 152.433 236.452 152.47C236.24 152.536 236.013 152.536 235.802 152.47C235.504 152.387 235.231 152.232 235.008 152.018C234.785 151.804 234.619 151.538 234.524 151.244C234.432 150.842 234.398 150.429 234.42 150.018C234.42 149.582 234.42 149.124 234.42 148.644C234.403 148.382 234.418 148.119 234.465 147.861C234.556 147.5 234.744 147.171 235.009 146.911C235.274 146.65 235.606 146.467 235.968 146.381C236.33 146.296 236.708 146.312 237.062 146.427C237.416 146.542 237.731 146.751 237.974 147.033C238.326 147.464 238.493 148.016 238.439 148.57C238.439 149.062 238.439 149.52 238.439 149.944C238.457 150.354 238.43 150.765 238.358 151.17C238.276 151.467 238.122 151.738 237.909 151.96C237.697 152.182 237.432 152.347 237.139 152.441C236.918 152.518 236.678 152.515 236.459 152.433Z"
        fill="#263238"
      />
      <path
        d="M242.095 146.464H242.021C241.03 146.464 240.226 147.268 240.226 148.259V150.63C240.226 151.622 241.03 152.425 242.021 152.425H242.095C243.087 152.425 243.89 151.622 243.89 150.63V148.259C243.89 147.268 243.087 146.464 242.095 146.464Z"
        fill="white"
      />
      <path
        d="M242.095 152.433C242.095 152.396 242.332 152.433 242.694 152.248C242.943 152.141 243.163 151.977 243.338 151.769C243.512 151.561 243.636 151.315 243.698 151.052C243.741 150.666 243.741 150.277 243.698 149.892C243.698 149.456 243.698 148.998 243.698 148.518C243.722 148.287 243.699 148.054 243.632 147.832C243.565 147.61 243.454 147.404 243.307 147.225C243.144 147.044 242.943 146.9 242.72 146.802C242.496 146.705 242.254 146.658 242.01 146.663C241.766 146.668 241.526 146.725 241.306 146.831C241.086 146.937 240.892 147.089 240.736 147.277C240.595 147.461 240.495 147.672 240.441 147.897C240.393 148.125 240.375 148.359 240.389 148.592C240.389 149.084 240.389 149.542 240.389 149.966C240.359 150.35 240.376 150.738 240.441 151.118C240.515 151.38 240.649 151.621 240.832 151.822C241.016 152.024 241.243 152.18 241.497 152.278C241.866 152.411 242.103 152.396 242.103 152.433C241.891 152.499 241.664 152.499 241.453 152.433C241.155 152.351 240.883 152.196 240.661 151.982C240.439 151.767 240.275 151.501 240.182 151.207C240.088 150.805 240.051 150.392 240.071 149.98C240.071 149.545 240.071 149.087 240.071 148.606C240.054 148.345 240.069 148.082 240.116 147.823C240.186 147.556 240.309 147.305 240.478 147.085C240.665 146.85 240.902 146.66 241.172 146.527C241.441 146.394 241.737 146.322 242.037 146.317C242.338 146.311 242.636 146.372 242.91 146.494C243.184 146.617 243.428 146.798 243.625 147.026C243.977 147.456 244.144 148.009 244.09 148.562C244.09 149.042 244.09 149.5 244.09 149.936C244.105 150.347 244.076 150.758 244.001 151.162C243.92 151.459 243.766 151.73 243.553 151.952C243.34 152.174 243.075 152.339 242.782 152.433C242.561 152.514 242.317 152.514 242.095 152.433Z"
        fill="#263238"
      />
      <path
        d="M247.733 146.464H247.659C246.668 146.464 245.864 147.268 245.864 148.259V150.63C245.864 151.622 246.668 152.425 247.659 152.425H247.733C248.725 152.425 249.528 151.622 249.528 150.63V148.259C249.528 147.268 248.725 146.464 247.733 146.464Z"
        fill="white"
      />
      <path
        d="M247.732 152.433C247.732 152.396 247.969 152.433 248.331 152.249C248.58 152.141 248.8 151.977 248.975 151.769C249.149 151.561 249.273 151.316 249.335 151.052C249.374 150.666 249.374 150.278 249.335 149.892C249.335 149.454 249.335 148.996 249.335 148.518C249.359 148.287 249.336 148.054 249.269 147.833C249.202 147.611 249.091 147.404 248.944 147.225C248.784 147.039 248.584 146.892 248.359 146.795C248.133 146.697 247.889 146.653 247.644 146.664C247.401 146.663 247.16 146.718 246.941 146.825C246.723 146.931 246.531 147.086 246.381 147.277C246.235 147.459 246.131 147.671 246.078 147.897C246.033 148.126 246.016 148.359 246.026 148.592C246.026 149.072 246.026 149.53 246.026 149.966C245.99 150.35 246.005 150.738 246.07 151.118C246.145 151.38 246.279 151.621 246.462 151.823C246.645 152.024 246.873 152.18 247.127 152.278C247.503 152.411 247.732 152.396 247.732 152.433C247.521 152.5 247.294 152.5 247.082 152.433C246.785 152.351 246.513 152.196 246.291 151.982C246.069 151.768 245.904 151.501 245.812 151.207C245.718 150.805 245.681 150.393 245.701 149.981C245.701 149.545 245.701 149.087 245.701 148.607C245.684 148.345 245.699 148.082 245.745 147.824C245.816 147.556 245.939 147.305 246.107 147.085C246.296 146.85 246.533 146.66 246.803 146.527C247.074 146.394 247.37 146.322 247.671 146.317C247.972 146.311 248.27 146.372 248.545 146.494C248.82 146.617 249.065 146.798 249.262 147.026C249.608 147.459 249.772 148.01 249.72 148.562C249.72 149.043 249.72 149.501 249.72 149.936C249.735 150.347 249.705 150.758 249.631 151.163C249.551 151.46 249.397 151.732 249.184 151.954C248.971 152.176 248.706 152.341 248.412 152.433C248.193 152.513 247.952 152.513 247.732 152.433Z"
        fill="#263238"
      />
      <path
        d="M253.369 146.464H253.295C252.304 146.464 251.5 147.268 251.5 148.259V150.63C251.5 151.622 252.304 152.425 253.295 152.425H253.369C254.361 152.425 255.164 151.622 255.164 150.63V148.259C255.164 147.268 254.361 146.464 253.369 146.464Z"
        fill="white"
      />
      <path
        d="M253.37 152.433C253.37 152.396 253.607 152.433 253.969 152.248C254.219 152.142 254.442 151.979 254.617 151.771C254.793 151.563 254.918 151.317 254.981 151.052C255.019 150.666 255.019 150.278 254.981 149.892C254.981 149.454 254.981 148.996 254.981 148.518C255.004 148.287 254.981 148.054 254.914 147.832C254.847 147.61 254.736 147.404 254.589 147.225C254.429 147.039 254.229 146.892 254.004 146.794C253.779 146.697 253.534 146.652 253.289 146.664C253.046 146.663 252.806 146.718 252.587 146.825C252.368 146.931 252.176 147.085 252.026 147.277C251.88 147.458 251.776 147.67 251.723 147.897C251.678 148.126 251.661 148.359 251.671 148.592C251.671 149.072 251.671 149.53 251.671 149.966C251.636 150.35 251.651 150.738 251.716 151.118C251.79 151.381 251.925 151.623 252.11 151.824C252.294 152.026 252.524 152.181 252.779 152.278C253.149 152.411 253.385 152.396 253.378 152.433C253.166 152.5 252.939 152.5 252.728 152.433C252.43 152.351 252.158 152.196 251.936 151.982C251.714 151.767 251.55 151.501 251.457 151.207C251.362 150.805 251.328 150.392 251.354 149.981C251.354 149.547 251.354 149.089 251.354 148.606C251.337 148.345 251.351 148.082 251.398 147.823C251.471 147.555 251.597 147.304 251.767 147.085C251.954 146.852 252.192 146.664 252.462 146.536C252.732 146.408 253.027 146.343 253.326 146.346C253.629 146.341 253.93 146.405 254.205 146.533C254.48 146.661 254.722 146.85 254.914 147.085C255.264 147.516 255.429 148.069 255.372 148.621C255.372 149.101 255.372 149.559 255.372 149.995C255.392 150.406 255.362 150.818 255.284 151.222C255.204 151.519 255.05 151.791 254.837 152.013C254.624 152.235 254.358 152.4 254.065 152.492C253.834 152.557 253.587 152.536 253.37 152.433Z"
        fill="#263238"
      />
      <path
        d="M259.005 146.464H258.932C257.94 146.464 257.136 147.268 257.136 148.259V150.63C257.136 151.622 257.94 152.425 258.932 152.425H259.005C259.997 152.425 260.801 151.622 260.801 150.63V148.259C260.801 147.268 259.997 146.464 259.005 146.464Z"
        fill="white"
      />
      <path
        d="M259.006 152.433C259.006 152.396 259.243 152.433 259.605 152.248C259.855 152.142 260.078 151.978 260.253 151.77C260.429 151.562 260.554 151.316 260.617 151.051C260.655 150.666 260.655 150.277 260.617 149.891C260.617 149.453 260.617 148.995 260.617 148.517C260.64 148.287 260.617 148.054 260.55 147.832C260.483 147.61 260.372 147.404 260.225 147.225C260.063 147.043 259.863 146.899 259.64 146.802C259.417 146.705 259.175 146.658 258.932 146.663C258.688 146.668 258.449 146.725 258.23 146.831C258.011 146.937 257.817 147.089 257.662 147.276C257.516 147.458 257.412 147.67 257.359 147.897C257.314 148.125 257.297 148.359 257.307 148.591C257.307 149.071 257.307 149.529 257.307 149.965C257.272 150.35 257.287 150.737 257.352 151.118C257.426 151.381 257.561 151.622 257.746 151.824C257.93 152.025 258.16 152.181 258.415 152.278C258.785 152.41 259.021 152.396 259.014 152.433C258.805 152.499 258.58 152.499 258.371 152.433C258.073 152.351 257.799 152.196 257.576 151.982C257.353 151.768 257.187 151.501 257.093 151.206C256.998 150.805 256.964 150.392 256.99 149.98C256.99 149.547 256.99 149.089 256.99 148.606C256.975 148.344 256.993 148.081 257.041 147.823C257.14 147.387 257.384 146.998 257.733 146.719C258.082 146.439 258.515 146.287 258.962 146.286C259.265 146.282 259.566 146.346 259.841 146.474C260.116 146.602 260.358 146.79 260.55 147.025C260.725 147.238 260.856 147.483 260.934 147.747C261.013 148.011 261.038 148.288 261.008 148.562C261.008 149.042 261.008 149.5 261.008 149.936C261.028 150.347 260.998 150.758 260.92 151.162C260.841 151.46 260.688 151.733 260.474 151.955C260.261 152.177 259.995 152.341 259.701 152.433C259.477 152.516 259.23 152.516 259.006 152.433Z"
        fill="#263238"
      />
      <path
        d="M264.644 146.464H264.57C263.578 146.464 262.775 147.268 262.775 148.259V150.63C262.775 151.622 263.578 152.425 264.57 152.425H264.644C265.635 152.425 266.439 151.622 266.439 150.63V148.259C266.439 147.268 265.635 146.464 264.644 146.464Z"
        fill="white"
      />
      <path
        d="M264.636 152.433C264.636 152.396 264.865 152.433 265.227 152.248C265.478 152.142 265.7 151.978 265.876 151.77C266.052 151.562 266.176 151.316 266.239 151.051C266.277 150.666 266.277 150.277 266.239 149.891C266.239 149.456 266.239 148.998 266.239 148.517C266.261 148.286 266.237 148.053 266.168 147.831C266.1 147.609 265.988 147.403 265.84 147.225C265.678 147.043 265.478 146.899 265.255 146.802C265.031 146.705 264.79 146.658 264.547 146.663C264.303 146.668 264.064 146.725 263.845 146.831C263.626 146.937 263.432 147.089 263.277 147.276C263.131 147.458 263.027 147.67 262.974 147.897C262.929 148.125 262.912 148.359 262.922 148.591C262.922 149.071 262.922 149.529 262.922 149.965C262.89 150.35 262.905 150.737 262.966 151.118C263.044 151.379 263.18 151.62 263.364 151.821C263.548 152.022 263.776 152.178 264.03 152.278C264.4 152.41 264.636 152.396 264.636 152.433C264.424 152.498 264.198 152.498 263.986 152.433C263.688 152.351 263.414 152.196 263.191 151.982C262.968 151.768 262.802 151.501 262.708 151.206C262.617 150.804 262.582 150.392 262.605 149.98C262.605 149.547 262.605 149.089 262.605 148.606C262.59 148.344 262.608 148.081 262.656 147.823C262.755 147.387 262.999 146.998 263.348 146.719C263.697 146.439 264.13 146.287 264.577 146.286C264.88 146.282 265.181 146.346 265.456 146.474C265.731 146.602 265.973 146.79 266.165 147.025C266.517 147.456 266.685 148.008 266.631 148.562C266.631 149.054 266.631 149.512 266.631 149.936C266.648 150.346 266.621 150.757 266.549 151.162C266.468 151.46 266.313 151.732 266.099 151.954C265.884 152.176 265.618 152.341 265.323 152.433C265.102 152.516 264.857 152.516 264.636 152.433Z"
        fill="#263238"
      />
      <path
        d="M272.326 146.464H272.252C271.26 146.464 270.457 147.268 270.457 148.259V150.63C270.457 151.622 271.26 152.425 272.252 152.425H272.326C273.317 152.425 274.121 151.622 274.121 150.63V148.259C274.121 147.268 273.317 146.464 272.326 146.464Z"
        fill="white"
      />
      <path
        d="M272.324 152.433C272.324 152.396 272.561 152.433 272.923 152.248C273.173 152.142 273.396 151.978 273.572 151.77C273.747 151.562 273.872 151.316 273.935 151.051C273.973 150.666 273.973 150.277 273.935 149.891C273.935 149.453 273.935 148.995 273.935 148.517C273.958 148.287 273.935 148.054 273.868 147.832C273.801 147.61 273.691 147.404 273.543 147.225C273.381 147.043 273.181 146.899 272.958 146.802C272.735 146.705 272.493 146.658 272.25 146.663C272.006 146.668 271.767 146.725 271.548 146.831C271.329 146.937 271.135 147.089 270.98 147.276C270.834 147.458 270.731 147.67 270.677 147.897C270.632 148.125 270.615 148.359 270.625 148.591C270.625 149.071 270.625 149.529 270.625 149.965C270.59 150.35 270.605 150.737 270.67 151.118C270.744 151.381 270.879 151.622 271.064 151.824C271.249 152.025 271.478 152.181 271.733 152.278C272.103 152.41 272.339 152.396 272.332 152.433C272.123 152.499 271.898 152.499 271.689 152.433C271.391 152.351 271.117 152.196 270.894 151.982C270.671 151.768 270.505 151.501 270.411 151.206C270.317 150.805 270.282 150.392 270.308 149.98C270.308 149.547 270.308 149.089 270.308 148.606C270.293 148.344 270.311 148.081 270.359 147.823C270.458 147.387 270.702 146.998 271.051 146.719C271.4 146.439 271.833 146.287 272.28 146.286C272.583 146.282 272.884 146.346 273.159 146.474C273.434 146.602 273.677 146.79 273.868 147.025C274.043 147.238 274.174 147.483 274.252 147.747C274.331 148.011 274.356 148.288 274.326 148.562C274.326 149.042 274.326 149.5 274.326 149.936C274.346 150.347 274.316 150.758 274.238 151.162C274.159 151.46 274.006 151.733 273.792 151.955C273.579 152.177 273.313 152.341 273.019 152.433C272.795 152.516 272.548 152.516 272.324 152.433Z"
        fill="#263238"
      />
      <path
        d="M277.962 146.464H277.888C276.896 146.464 276.093 147.268 276.093 148.259V150.63C276.093 151.622 276.896 152.425 277.888 152.425H277.962C278.953 152.425 279.757 151.622 279.757 150.63V148.259C279.757 147.268 278.953 146.464 277.962 146.464Z"
        fill="white"
      />
      <path
        d="M277.969 152.433C277.969 152.396 278.198 152.433 278.56 152.248C278.811 152.142 279.033 151.978 279.209 151.77C279.385 151.562 279.509 151.316 279.572 151.051C279.611 150.666 279.611 150.277 279.572 149.891C279.572 149.456 279.572 148.998 279.572 148.517C279.596 148.286 279.573 148.053 279.504 147.831C279.435 147.608 279.323 147.402 279.173 147.225C279.011 147.043 278.811 146.899 278.588 146.802C278.365 146.705 278.123 146.658 277.88 146.663C277.637 146.668 277.397 146.725 277.178 146.831C276.959 146.937 276.765 147.089 276.61 147.276C276.467 147.46 276.364 147.671 276.307 147.897C276.263 148.125 276.245 148.359 276.255 148.591C276.255 149.071 276.255 149.529 276.255 149.965C276.223 150.35 276.238 150.737 276.3 151.118C276.377 151.379 276.513 151.62 276.697 151.821C276.882 152.022 277.109 152.178 277.364 152.278C277.733 152.41 277.969 152.396 277.969 152.433C277.758 152.498 277.531 152.498 277.319 152.433C277.021 152.351 276.748 152.196 276.524 151.982C276.301 151.768 276.135 151.501 276.041 151.206C275.95 150.804 275.915 150.392 275.938 149.98C275.938 149.547 275.938 149.089 275.938 148.606C275.923 148.344 275.941 148.081 275.989 147.823C276.088 147.387 276.332 146.998 276.681 146.719C277.03 146.439 277.463 146.287 277.91 146.286C278.214 146.282 278.514 146.346 278.789 146.474C279.064 146.602 279.307 146.79 279.498 147.025C279.85 147.456 280.018 148.008 279.964 148.562C279.964 149.054 279.964 149.512 279.964 149.936C279.982 150.346 279.955 150.757 279.883 151.162C279.801 151.46 279.646 151.732 279.432 151.954C279.218 152.176 278.951 152.341 278.656 152.433C278.435 152.516 278.191 152.516 277.969 152.433Z"
        fill="#263238"
      />
      <path
        d="M283.606 146.464H283.533C282.541 146.464 281.737 147.268 281.737 148.259V150.63C281.737 151.622 282.541 152.425 283.533 152.425H283.606C284.598 152.425 285.402 151.622 285.402 150.63V148.259C285.402 147.268 284.598 146.464 283.606 146.464Z"
        fill="white"
      />
      <path
        d="M283.607 152.433C283.607 152.396 283.836 152.433 284.198 152.248C284.448 152.142 284.671 151.978 284.846 151.77C285.022 151.562 285.147 151.316 285.21 151.051C285.248 150.666 285.248 150.277 285.21 149.891C285.21 149.455 285.21 148.997 285.21 148.517C285.233 148.286 285.21 148.053 285.141 147.83C285.073 147.608 284.96 147.402 284.811 147.225C284.648 147.043 284.449 146.899 284.225 146.802C284.002 146.705 283.761 146.658 283.517 146.663C283.274 146.668 283.034 146.725 282.815 146.831C282.596 146.937 282.402 147.089 282.247 147.276C282.107 147.462 282.004 147.672 281.944 147.897C281.903 148.126 281.888 148.359 281.9 148.591C281.9 149.084 281.9 149.542 281.9 149.965C281.866 150.35 281.884 150.737 281.952 151.118C282.026 151.38 282.16 151.621 282.344 151.822C282.527 152.023 282.754 152.179 283.008 152.277C283.378 152.41 283.614 152.396 283.614 152.433C283.402 152.499 283.176 152.499 282.964 152.433C282.666 152.35 282.393 152.194 282.17 151.98C281.947 151.766 281.781 151.5 281.686 151.206C281.595 150.804 281.56 150.392 281.583 149.98C281.583 149.544 281.583 149.086 281.583 148.606C281.565 148.344 281.58 148.081 281.627 147.823C281.726 147.386 281.971 146.996 282.321 146.716C282.672 146.437 283.107 146.285 283.555 146.286C283.857 146.282 284.156 146.346 284.43 146.474C284.704 146.602 284.945 146.791 285.136 147.025C285.488 147.456 285.655 148.008 285.601 148.562C285.601 149.054 285.601 149.512 285.601 149.936C285.619 150.346 285.592 150.757 285.52 151.162C285.439 151.46 285.284 151.732 285.069 151.954C284.855 152.176 284.588 152.341 284.294 152.433C284.072 152.516 283.828 152.516 283.607 152.433Z"
        fill="#263238"
      />
      <path
        d="M289.242 146.464H289.169C288.177 146.464 287.373 147.268 287.373 148.259V150.63C287.373 151.622 288.177 152.425 289.169 152.425H289.242C290.234 152.425 291.038 151.622 291.038 150.63V148.259C291.038 147.268 290.234 146.464 289.242 146.464Z"
        fill="white"
      />
      <path
        d="M289.243 152.433C289.243 152.396 289.479 152.433 289.841 152.248C290.09 152.141 290.311 151.977 290.485 151.769C290.659 151.561 290.783 151.316 290.846 151.052C290.884 150.666 290.884 150.278 290.846 149.892C290.846 149.456 290.846 148.998 290.846 148.518C290.869 148.287 290.846 148.054 290.779 147.832C290.712 147.61 290.601 147.404 290.454 147.225C290.291 147.044 290.091 146.9 289.867 146.803C289.643 146.706 289.401 146.658 289.157 146.663C288.913 146.668 288.673 146.725 288.453 146.831C288.233 146.937 288.039 147.089 287.883 147.277C287.743 147.461 287.642 147.672 287.588 147.897C287.54 148.125 287.523 148.359 287.536 148.592C287.536 149.084 287.536 149.542 287.536 149.966C287.506 150.351 287.524 150.738 287.588 151.118C287.662 151.38 287.796 151.621 287.98 151.822C288.163 152.024 288.39 152.18 288.644 152.278C289.014 152.411 289.25 152.396 289.25 152.433C289.038 152.499 288.812 152.499 288.6 152.433C288.302 152.35 288.029 152.195 287.806 151.981C287.583 151.767 287.417 151.501 287.322 151.207C287.231 150.805 287.196 150.392 287.218 149.981C287.218 149.545 287.218 149.087 287.218 148.606C287.201 148.345 287.216 148.082 287.263 147.823C287.354 147.463 287.542 147.134 287.807 146.873C288.073 146.613 288.404 146.43 288.766 146.344C289.128 146.259 289.506 146.275 289.86 146.389C290.214 146.504 290.529 146.714 290.772 146.996C291.124 147.427 291.291 147.979 291.237 148.533C291.237 149.025 291.237 149.483 291.237 149.907C291.255 150.317 291.228 150.728 291.156 151.133C291.075 151.429 290.921 151.701 290.708 151.923C290.495 152.145 290.23 152.31 289.937 152.404C289.717 152.496 289.47 152.507 289.243 152.433Z"
        fill="#263238"
      />
      <path
        d="M294.879 146.464H294.805C293.813 146.464 293.01 147.268 293.01 148.259V150.63C293.01 151.622 293.813 152.425 294.805 152.425H294.879C295.87 152.425 296.674 151.622 296.674 150.63V148.259C296.674 147.268 295.87 146.464 294.879 146.464Z"
        fill="white"
      />
      <path
        d="M294.879 152.433C294.879 152.396 295.115 152.433 295.477 152.249C295.726 152.141 295.947 151.977 296.121 151.769C296.295 151.561 296.419 151.316 296.482 151.052C296.524 150.666 296.524 150.278 296.482 149.892C296.482 149.456 296.482 148.998 296.482 148.518C296.505 148.287 296.482 148.054 296.415 147.833C296.348 147.611 296.238 147.404 296.09 147.225C295.93 147.039 295.73 146.892 295.505 146.795C295.28 146.697 295.035 146.653 294.79 146.664C294.547 146.663 294.307 146.718 294.088 146.825C293.869 146.931 293.677 147.086 293.527 147.277C293.381 147.459 293.278 147.671 293.224 147.897C293.176 148.126 293.159 148.359 293.172 148.592C293.172 149.084 293.172 149.542 293.172 149.966C293.142 150.351 293.16 150.738 293.224 151.118C293.298 151.38 293.432 151.621 293.616 151.823C293.799 152.024 294.026 152.18 294.28 152.278C294.65 152.411 294.886 152.396 294.886 152.433C294.674 152.499 294.448 152.499 294.236 152.433C293.939 152.351 293.667 152.196 293.445 151.982C293.223 151.768 293.058 151.501 292.965 151.207C292.872 150.805 292.834 150.393 292.855 149.981C292.855 149.545 292.855 149.087 292.855 148.607C292.838 148.345 292.852 148.082 292.899 147.824C292.97 147.556 293.093 147.305 293.261 147.085C293.449 146.85 293.687 146.66 293.957 146.527C294.227 146.394 294.523 146.322 294.824 146.317C295.125 146.311 295.424 146.372 295.699 146.494C295.974 146.617 296.218 146.798 296.415 147.026C296.762 147.459 296.926 148.01 296.873 148.562C296.873 149.043 296.873 149.501 296.873 149.936C296.889 150.347 296.859 150.758 296.785 151.163C296.703 151.459 296.549 151.731 296.336 151.953C296.124 152.175 295.859 152.34 295.566 152.433C295.344 152.515 295.101 152.515 294.879 152.433Z"
        fill="#263238"
      />
      <path
        d="M20.9045 119.241L38.2426 142.622L68.383 142.866C68.383 142.866 38.2426 123.231 33.005 120.94C27.7674 118.65 20.9045 119.241 20.9045 119.241Z"
        fill="#00838F"
      />
      <path
        d="M361.608 168.493C361.608 168.604 298.461 168.944 220.584 169.232C142.706 169.52 79.5371 169.704 79.5371 169.601C79.5371 169.498 142.669 169.15 220.576 168.862C298.483 168.574 361.608 168.389 361.608 168.493Z"
        fill="#263238"
      />
      <path
        d="M158.05 162.76C156.593 162.729 155.144 162.989 153.789 163.526C152.433 164.063 151.199 164.865 150.158 165.886C149.117 166.906 148.291 168.125 147.728 169.47C147.166 170.814 146.877 172.258 146.881 173.716C146.879 175.195 147.168 176.661 147.733 178.029C148.298 179.396 149.127 180.639 150.173 181.686C151.219 182.733 152.46 183.564 153.827 184.13C155.194 184.697 156.659 184.989 158.139 184.989H179.089V162.915L158.05 162.76Z"
        fill="#E0E0E0"
      />
      <path
        d="M158.049 162.76C158.175 162.75 158.301 162.75 158.426 162.76H159.534H163.774L179.096 162.812H179.251V162.967C179.251 169.298 179.251 176.841 179.251 185.026V185.218H162.629C161.012 185.218 159.431 185.218 157.872 185.218C156.325 185.184 154.801 184.832 153.395 184.184C150.778 182.979 148.708 180.836 147.596 178.178C147.104 176.981 146.822 175.709 146.761 174.417C146.686 173.231 146.811 172.039 147.131 170.894C147.4 169.847 147.834 168.849 148.416 167.939C148.68 167.517 148.971 167.112 149.288 166.727C149.592 166.377 149.915 166.044 150.256 165.73C151.819 164.317 153.745 163.368 155.818 162.989C156.299 162.93 156.705 162.856 157.037 162.842H157.776C157.862 162.833 157.949 162.833 158.035 162.842H157.776L157.037 162.901C156.705 162.901 156.299 163.011 155.833 163.078C155.292 163.192 154.759 163.343 154.237 163.529C152.811 164.036 151.504 164.831 150.396 165.863C150.067 166.177 149.754 166.507 149.458 166.853C149.167 167.218 148.898 167.601 148.653 167.998C148.093 168.893 147.677 169.87 147.419 170.894C147.123 172.016 147.011 173.178 147.086 174.336C147.155 175.594 147.437 176.83 147.921 177.993C149.017 180.564 151.032 182.635 153.573 183.799C154.936 184.42 156.412 184.754 157.909 184.782C159.453 184.782 161.049 184.782 162.659 184.782H179.088L178.941 184.989C178.941 176.804 178.941 169.261 178.941 162.93L179.096 163.085L163.782 162.908L159.542 162.834L158.433 162.797L158.049 162.76Z"
        fill="#263238"
      />
      <path
        d="M190.658 173.715C190.658 176.701 189.472 179.565 187.361 181.676C185.249 183.788 182.386 184.974 179.4 184.974C176.414 184.974 173.55 183.788 171.439 181.676C169.328 179.565 168.142 176.701 168.142 173.715C168.142 167.503 173.187 162.952 179.4 162.952C185.613 162.952 190.658 167.503 190.658 173.715Z"
        fill="#FAFAFA"
      />
      <path
        d="M190.658 173.716C190.581 172.805 190.438 171.902 190.229 171.012C189.582 168.596 188.081 166.496 186.004 165.102C184.591 164.134 182.972 163.507 181.276 163.27C179.335 162.969 177.349 163.147 175.492 163.787C173.921 164.307 172.496 165.192 171.334 166.37C170.172 167.547 169.305 168.984 168.806 170.561C168.474 171.688 168.322 172.86 168.356 174.033C168.419 176.363 169.218 178.613 170.638 180.46C171.956 182.187 173.756 183.484 175.809 184.191C177.546 184.793 179.406 184.954 181.22 184.659C183.035 184.363 184.747 183.621 186.203 182.499C187.327 181.642 188.277 180.579 189.003 179.367C189.557 178.448 189.973 177.452 190.237 176.412C190.442 175.52 190.583 174.614 190.658 173.701C190.666 173.762 190.666 173.824 190.658 173.886C190.658 174.026 190.658 174.203 190.658 174.418C190.661 174.707 190.639 174.996 190.591 175.282C190.551 175.673 190.482 176.061 190.385 176.442C190.143 177.502 189.739 178.518 189.188 179.456C188.47 180.71 187.514 181.812 186.373 182.699C185.19 183.625 183.836 184.307 182.388 184.707C180.94 185.106 179.427 185.215 177.936 185.027C176.446 184.839 175.008 184.357 173.704 183.61C172.401 182.863 171.258 181.865 170.343 180.675C169.612 179.748 169.038 178.707 168.644 177.594C167.816 175.29 167.746 172.782 168.444 170.436C168.8 169.309 169.339 168.248 170.04 167.296C171.384 165.498 173.245 164.153 175.374 163.44C177.287 162.786 179.332 162.613 181.328 162.938C183.065 163.199 184.719 163.858 186.159 164.866C187.347 165.689 188.353 166.747 189.114 167.976C189.692 168.885 190.116 169.883 190.37 170.931C190.473 171.313 190.544 171.704 190.584 172.098C190.632 172.383 190.654 172.673 190.651 172.962C190.651 173.176 190.651 173.346 190.651 173.494C190.666 173.567 190.668 173.642 190.658 173.716Z"
        fill="#263238"
      />
      <path
        d="M179.807 179.737C183.132 179.737 185.828 177.041 185.828 173.716C185.828 170.391 183.132 167.695 179.807 167.695C176.482 167.695 173.786 170.391 173.786 173.716C173.786 177.041 176.482 179.737 179.807 179.737Z"
        fill="#263238"
      />
      <path
        d="M168.075 172.017C168.075 172.128 163.376 172.217 157.577 172.217C151.778 172.217 147.073 172.128 147.073 172.017C147.073 171.906 151.771 171.825 157.577 171.825C163.384 171.825 168.075 171.914 168.075 172.017Z"
        fill="#263238"
      />
      <path
        d="M150.729 173.294C150.729 173.25 150.914 173.124 151.172 173.294C151.263 173.356 151.333 173.444 151.375 173.545C151.416 173.647 151.427 173.758 151.406 173.866C151.385 173.974 151.332 174.073 151.255 174.151C151.179 174.229 151.08 174.283 150.973 174.306C150.856 174.345 150.729 174.346 150.611 174.309C150.493 174.272 150.39 174.199 150.316 174.1C150.265 174.02 150.234 173.93 150.225 173.836C150.216 173.742 150.229 173.648 150.264 173.56C150.389 173.265 150.626 173.257 150.626 173.309C150.626 173.361 150.537 173.449 150.522 173.634C150.508 173.819 150.626 174.011 150.87 173.937C151.113 173.863 151.106 173.649 150.988 173.494C150.87 173.339 150.744 173.346 150.729 173.294Z"
        fill="#263238"
      />
      <path
        d="M156.609 173.294C156.609 173.25 156.786 173.124 157.052 173.294C157.14 173.358 157.207 173.446 157.247 173.547C157.286 173.648 157.296 173.758 157.275 173.864C157.254 173.97 157.204 174.068 157.129 174.147C157.054 174.225 156.958 174.281 156.853 174.306C156.75 174.351 156.635 174.364 156.524 174.343C156.414 174.322 156.312 174.268 156.232 174.189C156.152 174.109 156.099 174.007 156.077 173.896C156.056 173.786 156.069 173.671 156.114 173.568C156.24 173.272 156.484 173.265 156.484 173.317C156.484 173.368 156.388 173.457 156.38 173.642C156.373 173.826 156.484 174.018 156.72 173.944C156.956 173.871 156.956 173.656 156.846 173.501C156.735 173.346 156.617 173.346 156.609 173.294Z"
        fill="#263238"
      />
      <path
        d="M162.483 173.294C162.483 173.25 162.668 173.124 162.927 173.294C163.003 173.351 163.064 173.425 163.107 173.509C163.149 173.594 163.171 173.687 163.17 173.782C163.157 173.904 163.107 174.019 163.028 174.112C162.949 174.206 162.844 174.274 162.726 174.308C162.608 174.342 162.483 174.34 162.366 174.303C162.25 174.266 162.146 174.195 162.07 174.1C162.016 174.022 161.983 173.931 161.973 173.837C161.964 173.742 161.98 173.647 162.018 173.56C162.136 173.265 162.38 173.257 162.38 173.309C162.38 173.361 162.284 173.449 162.277 173.634C162.269 173.819 162.38 174.011 162.616 173.937C162.853 173.863 162.86 173.649 162.742 173.494C162.624 173.339 162.491 173.346 162.483 173.294Z"
        fill="#263238"
      />
      <path
        d="M185.412 174.085L180.418 173.753L185.42 173.716C188.183 173.716 190.421 173.856 190.414 174.056C190.406 174.255 188.168 174.27 185.412 174.085Z"
        fill="#263238"
      />
      <path
        d="M185.022 175.888L180.36 174.056L185.14 175.533C187.777 176.353 189.868 177.173 189.801 177.365C189.735 177.557 187.585 176.9 185.022 175.888Z"
        fill="#263238"
      />
      <path
        d="M184.112 177.483L180.227 174.329L184.334 177.188C186.602 178.769 188.338 180.18 188.22 180.342C188.101 180.505 186.24 179.22 184.112 177.483Z"
        fill="#263238"
      />
      <path
        d="M182.747 178.725C181.225 176.427 180.058 174.662 179.991 174.551C180.065 174.654 181.365 176.331 183.042 178.51C184.719 180.69 185.96 182.573 185.798 182.692C185.635 182.81 184.269 181.029 182.747 178.725Z"
        fill="#263238"
      />
      <path
        d="M181.077 179.5C180.338 176.855 179.747 174.817 179.718 174.684L181.424 179.389C182.37 181.982 182.983 184.139 182.784 184.206C182.584 184.272 181.831 182.16 181.077 179.5Z"
        fill="#263238"
      />
      <path
        d="M179.253 179.737C179.334 176.989 179.401 174.868 179.408 174.735C179.408 174.868 179.504 176.952 179.622 179.737C179.74 182.522 179.659 184.738 179.46 184.738C179.26 184.738 179.164 182.499 179.253 179.737Z"
        fill="#263238"
      />
      <path
        d="M177.465 179.412L179.127 174.691C179.127 174.817 178.536 176.863 177.819 179.523C177.103 182.182 176.342 184.302 176.157 184.243C175.972 184.184 176.519 182.034 177.465 179.412Z"
        fill="#263238"
      />
      <path
        d="M175.809 178.547L178.823 174.551L176.112 178.762C174.634 181.081 173.26 182.869 173.09 182.751C172.921 182.633 174.147 180.756 175.809 178.547Z"
        fill="#263238"
      />
      <path
        d="M174.51 177.24L178.588 174.337L174.732 177.528C172.604 179.294 170.78 180.594 170.661 180.431C170.543 180.269 172.294 178.843 174.51 177.24Z"
        fill="#263238"
      />
      <path
        d="M173.682 175.6L178.446 174.063L173.807 175.947C171.244 176.981 169.109 177.668 169.042 177.476C168.976 177.284 171.052 176.45 173.682 175.6Z"
        fill="#263238"
      />
      <path
        d="M173.378 173.782H178.387L173.393 174.174C170.645 174.381 168.399 174.395 168.392 174.174C168.384 173.952 170.615 173.797 173.378 173.782Z"
        fill="#263238"
      />
      <path
        d="M173.638 171.958L178.418 173.436L173.542 172.298C170.853 171.677 168.718 171.005 168.77 170.82C168.822 170.636 171.008 171.138 173.638 171.958Z"
        fill="#263238"
      />
      <path
        d="M174.442 170.303L178.542 173.169L174.243 170.613C171.872 169.202 170.032 167.917 170.143 167.747C170.254 167.577 172.182 168.737 174.442 170.303Z"
        fill="#263238"
      />
      <path
        d="M175.707 168.966L178.758 172.933L175.419 169.202C173.587 167.141 172.22 165.361 172.375 165.235C172.53 165.11 174.023 166.779 175.707 168.966Z"
        fill="#263238"
      />
      <path
        d="M177.317 168.072L179.023 172.778L176.969 168.205C175.839 165.686 175.078 163.581 175.263 163.507C175.448 163.433 176.371 165.472 177.317 168.072Z"
        fill="#263238"
      />
      <path
        d="M179.119 167.703C179.23 170.459 179.311 172.571 179.318 172.704C179.318 172.579 179.06 170.488 178.75 167.733C178.439 164.977 178.351 162.746 178.55 162.732C178.75 162.717 179.001 164.948 179.119 167.703Z"
        fill="#263238"
      />
      <path
        d="M180.951 167.894C180.212 170.554 179.673 172.6 179.636 172.726C179.636 172.6 180.064 170.509 180.589 167.813C181.113 165.117 181.704 162.937 181.896 162.989C182.088 163.041 181.667 165.235 180.951 167.894Z"
        fill="#263238"
      />
      <path
        d="M182.635 168.641L179.916 172.844C179.975 172.726 180.995 170.864 182.317 168.449C183.639 166.033 184.858 164.142 185.035 164.245C185.213 164.349 184.112 166.321 182.635 168.641Z"
        fill="#263238"
      />
      <path
        d="M184.018 169.853L180.162 173.044L183.774 169.58C185.769 167.666 187.468 166.241 187.63 166.388C187.793 166.536 186.146 168.095 184.018 169.853Z"
        fill="#263238"
      />
      <path
        d="M184.969 171.426L180.33 173.303L184.822 171.086C187.296 169.867 189.372 169.025 189.453 169.21C189.535 169.395 187.525 170.392 184.969 171.426Z"
        fill="#263238"
      />
      <path
        d="M185.399 173.214L180.412 173.598L185.362 172.859C188.088 172.445 190.326 172.276 190.348 172.475C190.37 172.675 188.154 173 185.399 173.214Z"
        fill="#263238"
      />
      <path
        d="M233.158 184.598C233.158 184.598 232.995 184.443 232.751 184.11C232.421 183.648 232.139 183.153 231.909 182.633C231.178 180.87 230.717 179.007 230.542 177.107C230.335 175.221 230.281 173.321 230.38 171.426C230.384 170.642 230.466 169.86 230.624 169.092C230.673 169.872 230.673 170.654 230.624 171.434C230.603 173.314 230.692 175.193 230.89 177.063C231.066 178.928 231.483 180.763 232.131 182.522C232.677 183.837 233.217 184.561 233.158 184.598Z"
        fill="#263238"
      />
      <path
        d="M232.331 172.363C232.331 172.326 232.567 172.171 232.907 172.408C233.004 172.48 233.081 172.575 233.133 172.685C233.185 172.794 233.208 172.915 233.202 173.036C233.183 173.188 233.116 173.33 233.011 173.443C232.906 173.555 232.769 173.633 232.619 173.664C232.468 173.702 232.309 173.692 232.165 173.635C232.02 173.578 231.897 173.477 231.814 173.346C231.757 173.239 231.727 173.12 231.727 172.999C231.727 172.878 231.757 172.758 231.814 172.652C232.013 172.297 232.323 172.319 232.309 172.371C232.294 172.423 232.131 172.496 232.065 172.762C232.04 172.83 232.033 172.903 232.046 172.974C232.059 173.044 232.091 173.11 232.138 173.165C232.185 173.219 232.246 173.259 232.315 173.282C232.383 173.304 232.457 173.307 232.527 173.291C232.597 173.275 232.662 173.241 232.714 173.191C232.766 173.141 232.803 173.078 232.823 173.009C232.842 172.94 232.842 172.866 232.823 172.797C232.804 172.727 232.767 172.664 232.715 172.615C232.545 172.43 232.345 172.43 232.331 172.363Z"
        fill="#263238"
      />
      <path
        d="M233.35 179.079C233.35 179.079 233.587 178.887 233.919 179.124C234.018 179.195 234.097 179.29 234.149 179.4C234.202 179.509 234.227 179.63 234.222 179.752C234.202 179.905 234.134 180.048 234.028 180.161C233.922 180.274 233.783 180.35 233.631 180.38C233.482 180.416 233.324 180.406 233.181 180.349C233.038 180.292 232.917 180.191 232.833 180.062C232.776 179.955 232.747 179.836 232.747 179.715C232.747 179.594 232.776 179.474 232.833 179.368C233.033 179.013 233.343 179.035 233.336 179.087C233.328 179.139 233.158 179.212 233.085 179.478C233.055 179.546 233.046 179.621 233.057 179.694C233.069 179.767 233.101 179.835 233.149 179.891C233.198 179.947 233.262 179.988 233.332 180.009C233.403 180.03 233.479 180.03 233.55 180.01C233.624 179.999 233.693 179.967 233.749 179.918C233.805 179.868 233.845 179.803 233.865 179.731C233.884 179.659 233.883 179.582 233.859 179.511C233.836 179.44 233.793 179.377 233.735 179.331C233.565 179.146 233.358 179.161 233.35 179.079Z"
        fill="#263238"
      />
      <path
        d="M118.152 184.598C118.152 184.598 117.989 184.45 117.753 184.132C117.605 183.942 117.474 183.74 117.361 183.527C117.194 183.251 117.046 182.965 116.918 182.67C116.19 180.972 115.737 179.169 115.574 177.329C115.384 175.503 115.342 173.665 115.448 171.832C115.452 171.07 115.536 170.31 115.699 169.564C115.75 170.322 115.75 171.082 115.699 171.84C115.672 173.66 115.748 175.48 115.928 177.292C116.099 179.096 116.509 180.87 117.147 182.566C117.686 183.859 118.211 184.561 118.152 184.598Z"
        fill="#263238"
      />
      <path
        d="M117.325 172.363C117.288 172.326 117.561 172.171 117.894 172.408C117.992 172.479 118.071 172.574 118.124 172.684C118.177 172.793 118.202 172.914 118.197 173.036C118.176 173.189 118.108 173.332 118.002 173.445C117.896 173.557 117.758 173.634 117.606 173.664C117.456 173.7 117.299 173.69 117.156 173.633C117.013 173.576 116.891 173.475 116.808 173.346C116.751 173.239 116.721 173.12 116.721 172.999C116.721 172.878 116.751 172.758 116.808 172.652C117.015 172.297 117.325 172.319 117.31 172.371C117.295 172.423 117.133 172.496 117.059 172.762C117.035 172.83 117.029 172.902 117.043 172.972C117.056 173.042 117.088 173.108 117.136 173.161C117.183 173.215 117.244 173.254 117.312 173.276C117.38 173.298 117.452 173.302 117.522 173.286C117.592 173.27 117.656 173.236 117.708 173.187C117.76 173.138 117.798 173.076 117.818 173.008C117.838 172.939 117.839 172.867 117.821 172.797C117.803 172.728 117.767 172.665 117.717 172.615C117.539 172.43 117.332 172.43 117.325 172.363Z"
        fill="#263238"
      />
      <path
        d="M118.366 179.079C118.366 179.079 118.603 178.887 118.942 179.124C119.039 179.196 119.117 179.291 119.169 179.401C119.22 179.51 119.244 179.631 119.238 179.752C119.229 179.913 119.169 180.067 119.064 180.19C118.96 180.313 118.819 180.399 118.661 180.434C118.504 180.469 118.339 180.452 118.193 180.385C118.046 180.317 117.925 180.204 117.849 180.062C117.792 179.955 117.762 179.836 117.762 179.715C117.762 179.594 117.792 179.474 117.849 179.368C118.049 179.013 118.359 179.035 118.344 179.087C118.329 179.139 118.167 179.212 118.1 179.478C118.069 179.546 118.057 179.62 118.067 179.694C118.077 179.768 118.108 179.837 118.157 179.893C118.205 179.949 118.269 179.99 118.34 180.011C118.411 180.032 118.487 180.032 118.558 180.01C118.631 179.998 118.699 179.965 118.754 179.915C118.809 179.866 118.848 179.802 118.868 179.731C118.888 179.66 118.888 179.585 118.867 179.514C118.846 179.443 118.806 179.379 118.75 179.331C118.551 179.146 118.366 179.161 118.366 179.079Z"
        fill="#263238"
      />
      <path
        d="M313.983 184.598C313.825 184.456 313.688 184.291 313.577 184.11C313.249 183.646 312.967 183.151 312.734 182.633C312.003 180.87 311.542 179.007 311.368 177.107C311.16 175.221 311.106 173.321 311.205 171.426C311.204 170.641 311.288 169.859 311.456 169.092C311.498 169.872 311.498 170.654 311.456 171.434C311.439 173.314 311.53 175.193 311.73 177.063C311.904 178.928 312.319 180.763 312.963 182.522C313.51 183.837 314.042 184.561 313.983 184.598Z"
        fill="#263238"
      />
      <path
        d="M313.156 172.363C313.156 172.326 313.4 172.171 313.732 172.408C313.829 172.48 313.907 172.575 313.958 172.685C314.01 172.794 314.034 172.915 314.028 173.036C314.008 173.188 313.941 173.33 313.836 173.443C313.732 173.555 313.595 173.633 313.444 173.664C313.294 173.702 313.135 173.692 312.99 173.635C312.846 173.578 312.723 173.477 312.639 173.346C312.582 173.239 312.552 173.12 312.552 172.999C312.552 172.878 312.582 172.758 312.639 172.652C312.838 172.297 313.149 172.319 313.141 172.371C313.134 172.423 312.964 172.496 312.89 172.762C312.861 172.83 312.851 172.905 312.863 172.978C312.874 173.051 312.906 173.119 312.955 173.175C313.004 173.231 313.067 173.271 313.138 173.293C313.209 173.314 313.284 173.314 313.356 173.294C313.429 173.283 313.499 173.251 313.555 173.202C313.611 173.152 313.651 173.087 313.67 173.015C313.69 172.943 313.688 172.866 313.665 172.795C313.642 172.724 313.598 172.661 313.54 172.615C313.392 172.43 313.171 172.43 313.156 172.363Z"
        fill="#263238"
      />
      <path
        d="M314.174 179.079C314.137 179.079 314.41 178.887 314.743 179.124C314.86 179.201 314.951 179.312 315.003 179.442C315.056 179.572 315.067 179.715 315.036 179.852C315.004 179.989 314.932 180.113 314.828 180.207C314.724 180.301 314.594 180.362 314.454 180.38C314.305 180.416 314.148 180.406 314.005 180.349C313.862 180.292 313.74 180.191 313.657 180.062C313.6 179.955 313.57 179.836 313.57 179.715C313.57 179.594 313.6 179.474 313.657 179.368C313.856 179.013 314.166 179.035 314.152 179.087C314.137 179.139 313.974 179.212 313.9 179.478C313.876 179.546 313.871 179.618 313.884 179.688C313.898 179.758 313.93 179.824 313.977 179.877C314.024 179.931 314.085 179.97 314.153 179.992C314.221 180.014 314.294 180.018 314.363 180.002C314.433 179.986 314.497 179.952 314.549 179.903C314.601 179.854 314.639 179.792 314.659 179.724C314.679 179.655 314.68 179.583 314.662 179.513C314.644 179.444 314.608 179.381 314.558 179.331C314.388 179.146 314.181 179.161 314.174 179.079Z"
        fill="#263238"
      />
      <path
        d="M73.4807 117.972L103.724 162.443L230.787 163.566C230.787 163.566 226.694 158.654 218.686 157.937C212.496 157.39 124.202 150.624 124.202 150.624C121.72 148.895 95.7314 123.918 88.1003 119.383C83.4684 116.627 73.4807 117.972 73.4807 117.972Z"
        fill="#00838F"
      />
      <g opacity="0.4">
        <path d="M82.3752 131.047H102.498L108.866 136.765L86.3496 136.898L82.3752 131.047Z" fill="white" />
      </g>
      <path
        d="M225.143 159.606H224.745H223.489L218.643 159.502L200.832 159.059L142.006 157.426L107.707 156.429H107.626L107.574 156.362C98.7682 144.949 91.3218 135.294 86.0398 128.475L79.9526 120.519L78.3422 118.399L77.9359 117.852C77.8875 117.793 77.8454 117.728 77.8103 117.66C77.863 117.716 77.9123 117.776 77.958 117.837L78.3939 118.362C78.7707 118.85 79.3247 119.544 80.0413 120.453L86.2245 128.342C91.5138 135.146 99.012 144.772 107.869 156.148L107.736 156.082L142.028 157.042L200.795 158.778L218.606 159.347L223.444 159.517L224.7 159.576L225.143 159.606Z"
        fill="#263238"
      />
      <path
        d="M230.786 163.566C230.786 163.566 230.624 163.566 230.314 163.566H223.665L204.281 163.456L140.351 162.946L103.709 162.606H103.621L103.569 162.525L81.8133 130.419L75.6375 121.252L74.0122 118.814L73.5985 118.179L73.4656 117.957C73.5212 118.023 73.5729 118.092 73.6207 118.164L74.0566 118.777L75.7261 121.185L81.998 130.323L103.872 162.355L103.732 162.274L140.373 162.584L204.303 163.205L223.68 163.441L228.947 163.522H230.314L230.786 163.566Z"
        fill="#263238"
      />
      <path
        d="M426.99 145.704L427.654 130.345C427.654 130.345 408.13 127.863 396.273 130.345C384.416 132.828 378.972 145.704 378.972 145.704L389.078 149.974L404.724 171.441L426.99 145.704Z"
        fill="#00838F"
      />
      <g opacity="0.3">
        <path
          d="M426.988 145.704L427.653 130.345C427.653 130.345 408.128 127.863 396.271 130.345C384.415 132.828 378.97 145.704 378.97 145.704L389.076 149.974L406.008 169.624L426.988 145.704Z"
          fill="black"
        />
      </g>
      <path
        d="M396.361 96.8578C398.577 96.3998 400.217 94.6416 402.167 93.5039C403.64 92.6785 405.289 92.22 406.976 92.1672C408.663 92.1145 410.338 92.4691 411.859 93.201C414.881 94.6785 417.097 96.5992 417.54 99.9383L414.113 104.371"
        fill="#263238"
      />
      <path
        d="M421.862 115.895L418.737 152.692C418.205 158.949 416.144 168.028 406.289 166.728C397.824 164.962 396.346 156.644 396.848 150.801C397.085 148.03 397.277 145.726 397.284 145.437C397.284 145.437 387.991 143.76 388.102 134.43C388.154 129.924 389.025 120.645 389.904 112.268C390.372 107.929 392.455 103.924 395.74 101.05C399.025 98.1757 403.271 96.6425 407.634 96.7548H408.535C418.205 97.5526 422.933 106.233 421.862 115.895Z"
        fill="#FFD4BD"
      />
      <path
        d="M397.27 145.312C401.574 145.494 405.835 144.39 409.511 142.143C409.511 142.143 406.475 148.791 397.189 147.72L397.27 145.312Z"
        fill="#FDB094"
      />
      <path
        d="M391.233 116.679C391.366 116.885 392.711 116.738 394.314 117.366C395.917 117.993 396.907 119.02 397.128 118.946C397.239 118.946 397.128 118.599 396.714 118.134C396.093 117.495 395.336 117.005 394.498 116.701C393.704 116.373 392.842 116.244 391.987 116.324C391.418 116.398 391.159 116.582 391.233 116.679Z"
        fill="#263238"
      />
      <path
        d="M405.55 118.931C405.779 119.035 406.821 118.192 408.505 117.845C410.189 117.498 411.563 117.845 411.711 117.646C411.859 117.446 411.526 117.35 410.928 117.195C410.067 117.007 409.174 117.007 408.313 117.195C407.474 117.357 406.684 117.714 406.008 118.237C405.557 118.606 405.439 118.894 405.55 118.931Z"
        fill="#263238"
      />
      <path
        d="M407.597 119.551C407.587 119.818 407.682 120.078 407.861 120.276C408.041 120.473 408.291 120.592 408.557 120.608C408.688 120.622 408.821 120.61 408.947 120.572C409.073 120.534 409.19 120.471 409.291 120.387C409.392 120.303 409.476 120.199 409.536 120.082C409.597 119.966 409.633 119.838 409.643 119.706C409.651 119.441 409.557 119.183 409.379 118.986C409.202 118.79 408.955 118.669 408.69 118.65C408.559 118.634 408.425 118.644 408.298 118.681C408.17 118.718 408.052 118.781 407.95 118.865C407.847 118.95 407.763 119.054 407.703 119.172C407.642 119.29 407.606 119.419 407.597 119.551Z"
        fill="#263238"
      />
      <path
        d="M393.643 119.396C393.631 119.664 393.725 119.926 393.905 120.126C394.084 120.325 394.336 120.445 394.603 120.46C394.734 120.475 394.867 120.462 394.993 120.424C395.119 120.386 395.236 120.324 395.337 120.24C395.438 120.156 395.522 120.052 395.582 119.935C395.643 119.818 395.679 119.69 395.689 119.559C395.699 119.29 395.604 119.028 395.423 118.829C395.242 118.631 394.99 118.511 394.722 118.495C394.591 118.482 394.46 118.495 394.334 118.533C394.209 118.572 394.093 118.635 393.993 118.719C393.892 118.803 393.809 118.906 393.749 119.022C393.689 119.139 393.653 119.266 393.643 119.396Z"
        fill="#263238"
      />
      <path
        d="M400.417 128.01C399.669 127.754 398.893 127.593 398.105 127.53C397.736 127.485 397.366 127.404 397.366 127.153C397.343 126.774 397.438 126.397 397.64 126.074L398.785 123.312C400.388 119.382 401.555 116.146 401.392 116.087C401.23 116.028 399.811 119.153 398.208 123.09C397.824 124.058 397.47 124.981 397.108 125.868C396.867 126.302 396.786 126.806 396.879 127.293C396.929 127.424 397.008 127.542 397.111 127.638C397.213 127.734 397.336 127.806 397.47 127.847C397.672 127.906 397.88 127.941 398.09 127.951C398.86 128.067 399.642 128.087 400.417 128.01Z"
        fill="#263238"
      />
      <path
        d="M406.097 112.194C406.259 112.534 407.574 112.239 409.236 112.276C410.899 112.313 412.191 112.608 412.391 112.276C412.472 112.113 412.214 111.803 411.652 111.537C410.91 111.17 410.094 110.978 409.266 110.976C408.433 110.96 407.608 111.132 406.85 111.478C406.289 111.729 406.023 112.032 406.097 112.194Z"
        fill="#263238"
      />
      <path
        d="M391.58 113.768C391.713 114.063 392.954 113.871 394.425 113.967C395.895 114.063 397.091 114.374 397.276 114.093C397.357 113.96 397.143 113.687 396.648 113.413C395.989 113.071 395.263 112.877 394.521 112.845C393.768 112.791 393.012 112.899 392.304 113.162C391.78 113.376 391.521 113.628 391.58 113.768Z"
        fill="#263238"
      />
      <path
        d="M420.931 122.78C421.094 122.713 427.388 121.044 427.026 127.412C426.664 133.78 420.377 132.11 420.377 131.925C420.377 131.741 420.931 122.78 420.931 122.78Z"
        fill="#FFD4BD"
      />
      <path
        d="M422.431 129.621C422.431 129.621 422.534 129.702 422.719 129.798C422.975 129.926 423.272 129.95 423.546 129.864C424.233 129.658 424.861 128.616 424.957 127.471C425.004 126.927 424.928 126.379 424.735 125.868C424.687 125.665 424.589 125.476 424.452 125.319C424.314 125.162 424.14 125.041 423.945 124.967C423.819 124.928 423.684 124.933 423.562 124.981C423.44 125.029 423.338 125.118 423.273 125.233C423.184 125.41 423.214 125.535 423.177 125.543C423.14 125.55 423.051 125.432 423.118 125.173C423.157 125.016 423.255 124.879 423.391 124.789C423.481 124.727 423.581 124.684 423.688 124.661C423.794 124.638 423.904 124.637 424.011 124.656C424.27 124.723 424.507 124.856 424.7 125.041C424.893 125.226 425.035 125.457 425.112 125.713C425.354 126.278 425.453 126.895 425.4 127.508C425.289 128.793 424.558 129.953 423.627 130.167C423.454 130.208 423.274 130.209 423.1 130.171C422.926 130.132 422.763 130.055 422.623 129.946C422.431 129.776 422.408 129.628 422.431 129.621Z"
        fill="#FDB094"
      />
      <path
        d="M388.006 110.289C390.059 110.868 392.221 110.952 394.313 110.533C396.405 110.114 398.368 109.205 400.04 107.88C402.352 106.019 404.169 103.293 407.036 102.488C408.012 102.251 409.026 102.211 410.018 102.371C411.01 102.531 411.96 102.887 412.813 103.418C414.511 104.492 415.972 105.902 417.105 107.563C415.184 112.261 416.27 117.58 417.371 122.529C417.445 123.091 417.705 123.611 418.109 124.007C418.848 124.605 419.993 124.184 420.665 123.512C421.661 122.346 422.297 120.917 422.497 119.397C423.245 115.996 423.308 112.48 422.682 109.055C422.051 105.615 420.423 102.437 417.998 99.9167C414.844 96.8683 410.632 95.1583 406.245 95.1445C401.873 95.1732 397.621 96.5794 394.093 99.1632C392.4 100.28 391.016 101.804 390.067 103.596"
        fill="#263238"
      />
      <path
        d="M427.557 112.238C426.995 115.929 425.864 119.51 424.203 122.854C423.538 122.315 422.541 122.684 421.854 123.186C420.923 123.873 420.022 124.849 418.655 124.162C418.311 123.979 418.012 123.723 417.778 123.411C417.545 123.099 417.383 122.74 417.303 122.359C416.175 117.28 416.149 112.017 417.23 106.927C416.319 105.737 415.188 104.733 413.898 103.972L414.001 104.083C411.504 102.93 408.66 101.763 406.089 102.738C404.095 103.477 402.787 105.361 401.206 106.794C398.119 109.482 394.091 110.834 390.007 110.554C389.623 113.147 389.601 116.375 389.209 118.968L387.266 114.07C385.959 116.863 382.457 116.53 382.457 116.53L381.578 113.221C383.861 113.095 386.107 110.465 386.107 110.465C386.107 110.465 381.977 112.541 379.813 110.739L379.155 106.846C383.048 107.584 386.727 103.809 388.544 101.549C389.772 100.051 391.134 98.6683 392.615 97.4194C395.695 94.8043 398.2 93.0609 400.682 92.3295C403.888 91.3913 409.096 91.3913 412.236 92.5511C415.375 93.7109 418.005 96.5994 418.146 99.9385C420.14 98.5644 422.91 98.8747 425.038 100.02C425.038 100.02 431.258 103.27 427.483 112.334"
        fill="#263238"
      />
      <path
        d="M417.074 107.562C417.037 107.562 416.734 107.089 416.04 106.314C415.076 105.219 413.919 104.31 412.627 103.632C411.704 103.234 410.737 102.949 409.746 102.783C408.639 102.533 407.485 102.6 406.414 102.975C404.153 103.861 402.646 106.343 400.29 108.028C398.113 109.71 395.502 110.735 392.762 110.983C392.153 111.035 391.541 111.055 390.93 111.042C390.679 111.024 390.428 111.059 390.191 111.145C389.977 111.249 389.733 111.418 389.504 111.559C388.633 112.113 388.241 113.118 387.724 113.901C387.329 114.55 386.806 115.111 386.186 115.549C385.566 115.987 384.862 116.294 384.119 116.449C383.721 116.534 383.315 116.576 382.907 116.575C382.766 116.588 382.623 116.576 382.486 116.538C382.486 116.486 383.07 116.538 384.075 116.257C384.772 116.075 385.427 115.757 386.001 115.32C386.576 114.884 387.058 114.339 387.421 113.716C387.909 112.977 388.263 111.891 389.275 111.204C389.513 111.041 389.76 110.891 390.014 110.754C390.314 110.632 390.637 110.579 390.96 110.598C391.554 110.604 392.148 110.577 392.74 110.517C395.381 110.263 397.899 109.275 400.009 107.666C401.094 106.818 402.114 105.889 403.06 104.888C403.959 103.906 405.05 103.119 406.266 102.576C407.418 102.184 408.658 102.125 409.842 102.406C410.862 102.596 411.855 102.913 412.797 103.351C414.115 104.068 415.274 105.046 416.202 106.225C416.473 106.538 416.72 106.872 416.941 107.222C417.003 107.328 417.048 107.443 417.074 107.562Z"
        fill="#455A64"
      />
      <path
        d="M401.487 132.539C401.436 132.377 402.714 132.126 403.704 131.062C404.115 130.587 404.463 130.061 404.738 129.496C404.967 129.052 405.137 128.757 405.203 128.801C405.27 128.846 405.203 129.141 405.07 129.629C404.873 130.288 404.532 130.896 404.073 131.409C403.601 131.92 402.998 132.293 402.33 132.488C401.827 132.628 401.502 132.606 401.487 132.539Z"
        fill="#263238"
      />
      <path
        d="M427.558 112.238C427.019 112.392 426.454 112.442 425.896 112.386C424.548 112.31 423.224 111.992 421.988 111.448C420.744 110.912 419.604 110.162 418.62 109.231C418.195 108.865 417.844 108.421 417.585 107.924C417.637 107.887 418.036 108.374 418.804 109.032C420.827 110.725 423.299 111.793 425.918 112.105C426.923 112.223 427.551 112.172 427.558 112.238Z"
        fill="white"
      />
      <path
        d="M426.834 114.742C426.296 114.906 425.73 114.956 425.172 114.89C423.823 114.818 422.498 114.5 421.264 113.952C420.017 113.422 418.875 112.671 417.895 111.736C417.471 111.371 417.121 110.93 416.861 110.435C416.913 110.391 417.311 110.886 418.08 111.536C419.086 112.378 420.208 113.072 421.411 113.597C422.615 114.12 423.89 114.461 425.194 114.609C426.198 114.727 426.826 114.676 426.834 114.742Z"
        fill="white"
      />
      <path
        d="M381.158 155.247C381.158 155.247 375.337 159.894 371.06 168.951C368.399 174.662 366.304 180.62 364.803 186.739C364.803 186.739 362.328 191.017 357.556 197.939C352.784 204.86 356.233 216.835 356.403 220.374C356.573 223.912 365.46 232.585 369.08 233.213C372.7 233.841 381.158 228.345 381.158 228.345V155.247Z"
        fill="#00838F"
      />
      <path
        d="M389.076 149.973L404.176 166.779L426.988 145.703C426.988 145.703 433.267 145.703 437.788 147.594C444.503 150.416 449.866 192.724 449.866 192.724L454.446 227.895L458.835 289.734L381.319 289.808L381.083 155.218L389.076 149.973Z"
        fill="#00838F"
      />
      <path
        d="M465.131 177.838H367.448C367.448 177.838 370.137 162.99 380.604 154.82L388.797 149.752L404.178 166.78L427.019 144.913C433.569 145.295 439.918 147.312 445.488 150.779C451.641 154.701 459.664 160.227 465.131 177.838Z"
        fill="#263238"
      />
      <path
        d="M362.533 190.36C363.146 189.421 363.604 188.195 364.75 188.047C365.657 187.99 366.565 188.154 367.394 188.528C372.434 190.409 377.077 193.217 381.083 196.809L380.957 206.242C376.643 202.468 365.592 195.161 360.753 193.012C360.753 193.019 361.913 191.298 362.533 190.36Z"
        fill="#E0E0E0"
      />
      <path
        d="M367.667 190.16C367.719 190.16 368.244 191.061 369.145 192.509C370.327 194.4 371.605 196.229 372.972 197.991C374.345 199.744 375.807 201.425 377.352 203.029C378.023 203.666 378.662 204.337 379.266 205.038C379.047 204.916 378.845 204.764 378.667 204.588C378.298 204.285 377.781 203.849 377.19 203.221C375.579 201.662 374.078 199.994 372.698 198.227C371.317 196.459 370.063 194.596 368.946 192.65C368.517 191.911 368.207 191.29 367.985 190.855C367.845 190.64 367.738 190.406 367.667 190.16Z"
        fill="#263238"
      />
      <path
        d="M364.004 195.184C364.247 195.243 364.481 195.335 364.699 195.457C365.127 195.649 365.748 195.959 366.501 196.351C368.467 197.391 370.371 198.542 372.204 199.801C374.035 201.055 375.793 202.412 377.472 203.864C378.114 204.425 378.624 204.891 378.949 205.23C379.274 205.57 379.473 205.755 379.451 205.777C379.429 205.799 378.609 205.134 377.287 204.078C375.964 203.022 374.103 201.581 371.968 200.118C369.833 198.656 367.831 197.444 366.361 196.595C365.548 196.172 364.761 195.701 364.004 195.184Z"
        fill="#263238"
      />
      <path
        d="M427.654 130.322C426.243 130.16 409.533 149.95 404.177 166.757C404.177 166.757 395.859 146.323 383.426 138.633C381.651 140.782 380.162 143.151 378.993 145.681L389.099 149.95L389.328 164.127L404.997 176.456L420.584 165.412L427.388 145.836L427.654 130.322Z"
        fill="#263238"
      />
      <path
        d="M437.805 147.151C436.098 163.729 435.52 180.404 436.076 197.06H447.711L449.713 203.901L447.608 204.935L381.173 206.73V198.604L428.637 196.742C428.637 196.742 425.327 161.586 432.294 145.57C434.178 145.917 436.023 146.447 437.805 147.151Z"
        fill="#E0E0E0"
      />
      <path
        d="M456.494 252.982L381.231 255.524L381.276 265.415L457.04 262.815L456.494 252.982Z"
        fill="#E0E0E0"
      />
      <path
        d="M403.216 289.734C403.191 289.329 403.251 288.924 403.394 288.544C403.841 287.475 404.446 286.479 405.189 285.589C405.693 284.933 406.113 284.216 406.437 283.454C406.766 282.527 406.96 281.556 407.013 280.573C407.095 279.51 407.095 278.357 407.109 277.16C407.124 275.964 407.109 274.671 407.109 273.326C407.043 262.57 406.696 247.714 406.326 231.307C405.957 214.9 405.588 200.051 405.329 189.281C405.204 183.917 405.107 179.574 405.041 176.545C405.041 175.067 404.997 173.893 404.982 173.08C404.982 172.703 404.982 172.4 404.982 172.179C404.974 172.078 404.974 171.977 404.982 171.876C404.994 171.977 404.994 172.078 404.982 172.179C404.982 172.4 404.982 172.696 405.026 173.08C405.026 173.893 405.107 175.053 405.159 176.537C405.255 179.566 405.396 183.925 405.573 189.273C405.891 200.029 406.312 214.885 406.681 231.3C407.05 247.714 407.353 262.563 407.346 273.326C407.346 274.671 407.346 275.949 407.346 277.168C407.346 278.387 407.309 279.517 407.22 280.588C407.162 281.598 406.952 282.594 406.6 283.543C406.261 284.315 405.824 285.04 405.3 285.7C404.552 286.576 403.932 287.553 403.46 288.603C403.331 288.968 403.249 289.348 403.216 289.734Z"
        fill="#263238"
      />
      <path
        d="M421.345 245.307C421.702 245.386 422.051 245.497 422.387 245.639C423.045 245.883 424.005 246.201 425.194 246.57C428.306 247.515 431.499 248.167 434.731 248.52C437.966 248.863 441.226 248.9 444.468 248.631C445.716 248.528 446.721 248.417 447.423 248.321C447.778 248.253 448.139 248.218 448.501 248.217C448.158 248.334 447.804 248.418 447.445 248.468C446.758 248.609 445.753 248.764 444.49 248.904C437.954 249.604 431.345 248.896 425.106 246.828C423.916 246.43 422.963 246.09 422.328 245.779C421.986 245.655 421.656 245.497 421.345 245.307Z"
        fill="#00838F"
      />
      <path
        d="M386.395 246.909C386.395 246.85 387.54 246.518 389.35 245.809C391.714 244.87 393.976 243.695 396.102 242.3C398.222 240.896 400.19 239.277 401.975 237.468C403.349 236.08 404.11 235.134 404.191 235.178C404.057 235.43 403.893 235.666 403.704 235.88C403.242 236.499 402.749 237.093 402.226 237.66C400.474 239.541 398.505 241.207 396.361 242.625C394.216 244.04 391.91 245.194 389.491 246.06C388.764 246.326 388.024 246.555 387.274 246.747C386.991 246.843 386.695 246.897 386.395 246.909Z"
        fill="#00838F"
      />
      <path
        d="M400.786 185.565C400.786 185.624 400.343 185.617 399.996 186.112C399.899 186.246 399.832 186.399 399.8 186.561C399.768 186.723 399.772 186.89 399.811 187.05C399.875 187.235 399.993 187.395 400.15 187.511C400.307 187.627 400.495 187.693 400.69 187.7C400.884 187.727 401.081 187.695 401.256 187.608C401.432 187.521 401.577 187.383 401.673 187.213C401.741 187.062 401.776 186.898 401.776 186.733C401.776 186.567 401.741 186.403 401.673 186.252C401.414 185.706 400.934 185.639 400.986 185.58C401.037 185.521 401.104 185.536 401.289 185.58C401.552 185.682 401.773 185.869 401.916 186.112C402.027 186.296 402.094 186.503 402.112 186.717C402.13 186.931 402.099 187.146 402.02 187.346C401.904 187.593 401.713 187.797 401.474 187.93C401.236 188.062 400.961 188.116 400.69 188.084C400.418 188.07 400.158 187.972 399.943 187.805C399.729 187.637 399.572 187.407 399.493 187.146C399.451 186.936 399.457 186.718 399.512 186.511C399.567 186.303 399.67 186.111 399.811 185.949C399.998 185.724 400.261 185.574 400.55 185.528C400.683 185.499 400.786 185.543 400.786 185.565Z"
        fill="#00838F"
      />
      <path
        d="M383.382 138.22C383.494 138.272 383.601 138.336 383.699 138.412L384.549 139.01C385.287 139.527 386.322 140.355 387.548 141.418C390.711 144.233 393.522 147.42 395.918 150.911C398.304 154.403 400.388 158.093 402.145 161.94C402.825 163.418 403.342 164.615 403.682 165.457L404.066 166.432C404.119 166.542 404.156 166.659 404.177 166.779C404.102 166.682 404.04 166.575 403.992 166.462L403.549 165.516C403.165 164.696 402.611 163.514 401.902 162.059C400.079 158.265 397.975 154.613 395.608 151.133C393.219 147.666 390.455 144.475 387.363 141.618C386.167 140.539 385.147 139.682 384.46 139.128L383.647 138.471C383.47 138.316 383.374 138.235 383.382 138.22Z"
        fill="#455A64"
      />
      <path
        d="M409.06 174.484C409.076 174.369 409.108 174.257 409.156 174.152C409.252 173.9 409.363 173.598 409.503 173.236C409.806 172.43 410.286 171.293 410.922 169.896C414.248 162.628 418.599 155.873 423.842 149.84C424.847 148.687 425.689 147.771 426.287 147.166L426.989 146.471C427.067 146.386 427.154 146.309 427.248 146.242C427.248 146.242 427.189 146.353 427.041 146.523L426.398 147.262C425.829 147.897 425.024 148.835 424.049 150.01C418.942 156.116 414.614 162.833 411.166 170.007C410.501 171.381 409.984 172.504 409.636 173.28L409.23 174.174C409.187 174.284 409.129 174.388 409.06 174.484Z"
        fill="#455A64"
      />
      <path
        d="M399.567 168.854C399.493 168.854 399.405 167.591 398.961 165.611C398.701 164.452 398.363 163.312 397.949 162.198C396.942 159.465 395.497 156.912 393.672 154.641C392.927 153.714 392.122 152.835 391.264 152.011C389.786 150.615 388.752 149.891 388.796 149.832C389.059 149.962 389.307 150.121 389.535 150.305C389.827 150.495 390.109 150.703 390.377 150.925C390.695 151.184 391.064 151.457 391.434 151.812C392.331 152.616 393.168 153.485 393.938 154.412C394.867 155.558 395.705 156.776 396.442 158.054C397.157 159.343 397.767 160.687 398.267 162.073C398.667 163.208 398.983 164.371 399.213 165.552C399.323 166.055 399.375 166.52 399.434 166.926C399.487 167.269 399.519 167.614 399.53 167.961C399.572 168.257 399.584 168.556 399.567 168.854Z"
        fill="#455A64"
      />
      <path
        d="M439.672 161.852C439.792 162.53 439.856 163.217 439.864 163.905C439.96 165.168 440.115 166.912 440.381 168.825C440.647 170.739 440.987 172.46 441.253 173.701C441.433 174.365 441.561 175.041 441.637 175.725C441.349 175.096 441.131 174.437 440.987 173.76C440.562 172.153 440.234 170.523 440.004 168.877C439.766 167.232 439.63 165.574 439.598 163.913C439.551 163.225 439.576 162.534 439.672 161.852Z"
        fill="#455A64"
      />
      <path
        d="M381.158 179.921C381.269 179.921 381.336 196.705 381.313 217.404C381.291 238.104 381.188 254.888 381.084 254.888C380.981 254.888 380.907 238.111 380.929 217.404C380.951 196.698 381.055 179.921 381.158 179.921Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M381.992 231.855C382.433 230.492 383.13 229.226 384.046 228.124C385.523 226.152 387.001 224.105 389.217 222.953C391.433 221.8 393.901 221.741 396.338 221.682C405.883 221.453 415.545 221.232 424.846 223.492C431.923 225.221 439.155 228.39 446.218 226.602C448.899 225.923 452.128 224.615 454.425 226.181L454.602 229.587C451.17 232.277 447.501 234.651 443.639 236.679C439.813 238.562 435.513 239.737 431.303 239.316C429.175 239.109 427.04 238.503 424.935 238.85C423.154 239.146 421.5 240.106 419.697 240.18C416.506 240.298 413.965 237.705 411.734 235.43C409.185 232.83 406.378 230.34 402.972 229.07C399.567 227.799 395.408 227.976 392.63 230.333C391.071 231.655 390.074 233.532 388.567 234.906C387.06 236.28 384.6 237.055 383.026 235.762C382.51 235.27 382.14 234.644 381.957 233.955C381.775 233.265 381.787 232.538 381.992 231.855Z"
          fill="black"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M447.843 204.861C445.488 201.769 443.897 198.163 443.2 194.339C442.503 190.514 442.72 186.579 443.832 182.854L449.742 203.901L447.843 204.861Z"
          fill="black"
        />
      </g>
      <path d="M388.797 198.301L386.684 151.015L381.852 154.036V199.306L388.102 198.323" fill="#E0E0E0" />
      <path
        d="M405.33 177.816C405.219 177.816 405.138 175.474 404.798 172.645C404.458 169.815 404.007 167.474 404.118 167.474C404.333 167.944 404.482 168.441 404.561 168.951C404.834 170.17 405.041 171.403 405.182 172.645C405.33 173.871 405.409 175.104 405.418 176.338C405.455 176.833 405.425 177.33 405.33 177.816Z"
        fill="#455A64"
      />
      <path
        d="M465.129 177.838C469.029 190.751 477.214 214.509 475.264 218.845C473.314 223.181 435.439 233.664 435.439 233.664L427.387 214.538L449.711 203.923L442.088 177.86"
        fill="#00838F"
      />
      <path
        d="M467.435 185.27C467.435 185.27 461.525 184.731 456.568 186.969C451.611 189.207 446.669 193.485 446.669 193.485L449.432 203.295C449.432 203.295 455.977 196.351 470.596 195.442L467.435 185.27Z"
        fill="#E0E0E0"
      />
      <path
        d="M439.718 208.628C439.718 208.628 452.276 212.373 449.432 229.497L441.528 231.935C441.528 231.935 443.382 216.51 432.212 211.989L439.718 208.628Z"
        fill="#E0E0E0"
      />
      <path
        d="M456.774 227.097C456.651 227.159 456.522 227.208 456.389 227.245L455.267 227.614L450.952 228.988L435.491 233.797L435.343 233.842L435.284 233.746L427.202 214.605L427.128 214.435L427.298 214.354L430.253 212.936L449.608 203.761L449.519 203.96L444.525 186.592L443.151 181.687C442.996 181.14 442.885 180.705 442.797 180.394C442.76 180.254 442.738 180.143 442.715 180.055C442.693 179.966 442.715 179.944 442.715 179.936C442.715 179.929 442.715 179.973 442.76 180.047L442.871 180.372C442.964 180.687 443.097 181.116 443.27 181.658L444.747 186.533L449.866 203.864L449.911 204.004L449.778 204.071L430.438 213.283L427.483 214.701L427.579 214.457C430.534 221.483 433.267 228.035 435.609 233.62L435.409 233.524L450.923 228.841L455.23 227.54L456.367 227.215L456.774 227.097Z"
        fill="#263238"
      />
      <path
        d="M449.713 203.9C449.857 203.774 450.022 203.674 450.201 203.605C450.676 203.383 451.17 203.205 451.678 203.073C452.427 202.875 453.202 202.798 453.976 202.844C454.92 202.91 455.853 203.091 456.753 203.383C458.394 203.915 459.881 204.835 461.09 206.065C461.453 206.433 461.782 206.834 462.072 207.262C462.279 207.565 462.368 207.749 462.345 207.764C462.323 207.779 461.836 207.136 460.868 206.287C459.644 205.154 458.19 204.297 456.605 203.775C455.735 203.493 454.835 203.309 453.924 203.228C453.182 203.164 452.435 203.212 451.708 203.369C450.474 203.612 449.743 203.967 449.713 203.9Z"
        fill="#263238"
      />
      <path d="M395.563 205.355L423.369 224.784H381.66L352.931 205.466L395.563 205.355Z" fill="#455A64" />
      <path
        d="M432.789 217.752C432.789 217.752 429.095 218.542 427.27 217.752C425.446 216.961 419.144 215.255 419.144 215.255L411.919 216.681L415.332 219.857L401.296 222.613C401.296 222.613 399.08 225.435 402.678 225.435C406.276 225.435 409.977 225.509 409.977 225.509C409.977 225.509 409.186 228.929 412.341 228.929C412.725 229.789 413.403 230.484 414.253 230.89C415.103 231.296 416.069 231.387 416.98 231.145C416.98 231.145 418.125 234.299 420.363 234.299C422.602 234.299 437.059 229.173 437.45 228.914C437.842 228.656 437.975 224.312 436.135 221.416C434.296 218.52 432.789 217.752 432.789 217.752Z"
        fill="#FFD4BD"
      />
      <path
        d="M416.757 231.204C417.863 230.769 419.051 230.58 420.237 230.65C419.13 231.083 417.943 231.272 416.757 231.204Z"
        fill="#FDB094"
      />
      <path
        d="M412.54 229.431C414.079 228.716 415.718 228.237 417.401 228.013C416.658 228.429 415.856 228.73 415.022 228.907C414.225 229.201 413.389 229.378 412.54 229.431Z"
        fill="#FDB094"
      />
      <path
        d="M408.387 225.435C409.514 225.245 410.651 225.129 411.793 225.087C412.92 224.913 414.058 224.814 415.199 224.792C414.115 225.189 412.976 225.416 411.823 225.464C410.683 225.638 409.524 225.628 408.387 225.435Z"
        fill="#FDB094"
      />
      <path
        d="M369.716 219.909L372.567 217.493H380.442C380.442 217.493 381.365 219.251 380.442 219.924C379.519 220.596 376.01 221.01 376.01 221.01L376.911 221.623L387.253 218.579C387.253 218.579 390.947 219.968 388.598 221.623C388.598 221.623 389.683 223.839 388.095 225.198L386.529 226.528C386.529 226.528 387.113 227.533 385.694 229.04C384.276 230.547 382.739 233.088 381.262 232.903C379.784 232.718 377.827 232.807 377.066 232.164C376.591 231.634 376.328 230.948 376.327 230.236C376.327 230.236 373.734 230.975 373.232 229.394C372.73 227.813 372.308 226.72 372.308 226.72C372.308 226.72 372.057 227.134 371.134 225.797C370.21 224.459 370.883 222.524 370.883 222.524C370.883 222.524 369.28 220.286 369.716 219.909Z"
        fill="#FFD4BD"
      />
      <path
        d="M377.021 230.629C377.021 230.525 379.178 229.698 381.845 228.575C384.511 227.452 386.632 226.477 386.683 226.573C386.279 226.894 385.835 227.159 385.361 227.363C384.519 227.807 383.329 228.368 381.992 228.93C380.655 229.491 379.414 229.942 378.513 230.237C378.034 230.43 377.533 230.562 377.021 230.629Z"
        fill="#FDB094"
      />
      <path
        d="M374.287 226.757C375.047 226.81 375.809 226.81 376.569 226.757C378.391 226.471 380.144 225.853 381.741 224.932C383.339 223.984 384.989 223.125 386.683 222.361C387.204 222.141 387.743 221.963 388.293 221.83C388.493 221.765 388.703 221.735 388.914 221.741C388.914 221.8 388.072 222.007 386.794 222.605C385.516 223.204 383.839 224.223 381.918 225.272C380.292 226.221 378.485 226.817 376.614 227.023C376.044 227.071 375.47 227.044 374.907 226.942C374.69 226.921 374.479 226.859 374.287 226.757Z"
        fill="#FDB094"
      />
      <path
        d="M374.399 222.665C374.362 222.561 374.901 222.281 375.61 222.029C376.319 221.778 376.918 221.653 376.955 221.756C376.991 221.859 376.445 222.14 375.743 222.391C375.041 222.643 374.428 222.731 374.399 222.665Z"
        fill="#FDB094"
      />
      <path
        d="M387.022 207.506L367.394 207.395L363.11 205.415H368.606C368.606 205.415 368.739 204.019 371.162 203.339C372.671 202.946 374.254 202.925 375.772 203.281C377.291 203.636 378.701 204.356 379.879 205.378L384.097 205.437L387.022 207.506Z"
        fill="#EBEBEB"
      />
      <path
        d="M427.61 214.324C427.909 214.407 428.197 214.526 428.467 214.679C429.229 215.049 429.969 215.464 430.683 215.92C433.089 217.379 435.051 219.469 436.356 221.963C437.139 223.614 437.577 225.408 437.644 227.234C437.712 229.06 437.407 230.881 436.748 232.586C436.646 232.879 436.509 233.159 436.341 233.421C436.684 232.333 436.961 231.225 437.169 230.104C437.602 227.392 437.203 224.613 436.024 222.133C434.743 219.701 432.858 217.64 430.55 216.149C428.769 214.96 427.58 214.383 427.61 214.324Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M449.758 203.901C451.811 203.329 453.967 203.226 456.066 203.601C458.164 203.976 460.152 204.818 461.88 206.065C462.85 206.699 463.619 207.596 464.097 208.651C464.228 208.913 464.29 209.205 464.277 209.499C464.264 209.792 464.176 210.077 464.023 210.328C463.854 210.506 463.649 210.647 463.423 210.742C463.197 210.837 462.953 210.885 462.708 210.882C461.282 210.89 459.891 210.439 458.741 209.596C457.559 208.813 456.525 207.831 455.402 206.974C453.683 205.672 451.747 204.684 449.684 204.056"
          fill="black"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M363.36 195.908C364.099 195.169 365.162 195.671 366.064 196.107C370.082 198.065 373.407 201.241 377.389 203.413L379.117 204.891C374.531 203.517 370.035 201.857 365.657 199.919C364.81 199.614 364.049 199.107 363.441 198.442C363.132 198.096 362.954 197.653 362.94 197.189C362.925 196.726 363.074 196.272 363.36 195.908Z"
          fill="black"
        />
      </g>
      <path
        d="M498.971 289.734C498.971 289.845 387.267 289.926 249.5 289.926C111.734 289.926 0 289.845 0 289.734C0 289.623 111.682 289.542 249.5 289.542C387.318 289.542 498.971 289.631 498.971 289.734Z"
        fill="#263238"
      />
      <path
        d="M340.814 209.257C340.814 209.367 273.589 209.449 190.703 209.449C107.817 209.449 40.5776 209.367 40.5776 209.257C40.5776 209.146 107.802 209.064 190.703 209.064C273.604 209.064 340.814 209.153 340.814 209.257Z"
        fill="#E0E0E0"
      />
      <path
        d="M76.3542 247.257C76.9378 245.233 78.1936 243.276 80.1291 242.441C82.0646 241.606 84.4803 242.064 86.5413 242.818C88.6024 243.571 90.6265 244.568 92.8132 244.701C94.9998 244.834 97.4746 243.8 98.1321 241.702C98.7452 239.737 97.5633 237.639 96.071 236.206C94.502 234.696 92.5781 233.605 90.4767 233.035C88.3754 232.464 86.1641 232.431 84.0468 232.94C81.9296 233.448 79.9743 234.482 78.3614 235.945C76.7485 237.407 75.5298 239.253 74.8176 241.31"
        fill="#00838F"
      />
      <path
        opacity="0.4"
        d="M96.071 236.206C94.502 234.696 92.5781 233.605 90.4767 233.035C88.3754 232.464 86.1641 232.431 84.0468 232.94C81.9296 233.448 79.9743 234.482 78.3614 235.945C76.7485 237.407 75.5298 239.253 74.8176 241.31L76.3542 247.257C76.9378 245.233 78.1936 243.276 80.1291 242.441C82.0646 241.606 84.4803 242.064 86.5413 242.818C88.6024 243.571 90.6265 244.568 92.8132 244.701C94.9998 244.834 97.4746 243.8 98.1321 241.702C98.7452 239.737 97.5633 237.639 96.071 236.206Z"
        fill="black"
      />
      <path
        d="M59.0097 256.055C55.8036 253.004 55.9587 247.693 57.5322 243.556C59.1057 239.419 62.6368 236.257 66.5374 234.167C70.3514 232.165 74.5691 231.053 78.8742 230.913C83.1793 230.774 87.4602 231.611 91.3958 233.361C89.47 233.446 87.5942 234.001 85.9319 234.977C84.2696 235.953 82.8713 237.321 81.8587 238.961C80.0931 241.916 79.7016 245.432 78.2537 248.52C76.6116 252 73.6998 254.72 70.1157 256.121C66.5317 257.523 62.5472 257.499 58.9801 256.055"
        fill="#00838F"
      />
      <path
        d="M57.4671 237.72C53.9138 233.524 53.441 226.787 53.9655 221.305C54.49 215.824 55.7606 210.32 54.8963 204.891C54.3792 201.67 53.1307 198.597 52.6801 195.361C52.2294 192.125 52.8574 188.476 55.3617 186.393C58.2427 183.992 62.749 184.561 65.7704 186.807C68.7919 189.052 70.4984 192.598 71.7616 196.122C74.5466 203.864 75.5735 212.455 73.2834 220.36C70.9933 228.264 65.6227 235.334 57.77 237.72"
        fill="#00838F"
      />
      <path
        d="M55.759 272.16C55.746 272.001 55.746 271.84 55.759 271.68C55.759 271.363 55.759 270.897 55.759 270.291C55.8034 269.087 55.9216 267.336 56.1875 265.209C56.5048 262.703 56.9611 260.217 57.5542 257.762C58.2928 254.749 59.4359 251.849 60.9523 249.141C62.4771 246.449 64.3444 243.966 66.5076 241.754C68.2687 239.935 70.2204 238.31 72.3288 236.908C73.2153 236.295 74.0723 235.829 74.811 235.431C75.5497 235.032 76.2368 234.692 76.776 234.441L78.054 233.901C78.2025 233.834 78.3556 233.777 78.5121 233.731C78.3739 233.821 78.2281 233.897 78.0762 233.96L76.8277 234.544C76.2959 234.825 75.6384 235.165 74.8923 235.571C74.1461 235.977 73.3188 236.494 72.4397 237.115C67.8225 240.253 63.9862 244.407 61.2257 249.26C57.9605 254.977 57.0592 260.991 56.4091 265.209C56.1136 267.336 55.9733 269.065 55.892 270.269C55.8551 270.845 55.8255 271.311 55.8107 271.651C55.8133 271.822 55.7959 271.993 55.759 272.16Z"
        fill="#263238"
      />
      <path
        d="M25.0871 221.188C18.5123 225.162 18.5271 228.774 19.6278 232.187C20.7285 235.6 24.7989 237.964 28.1159 236.686C30.007 235.947 31.3811 234.322 32.5483 232.667C33.7155 231.013 34.8162 229.247 36.4636 228.058C38.1109 226.868 40.4823 226.418 42.1297 227.592"
        fill="#00838F"
      />
      <g opacity="0.4">
        <path
          d="M25.0863 221.188C18.5116 225.162 18.5264 228.774 19.6271 232.187C20.7278 235.6 24.7982 237.964 28.1151 236.686C30.0063 235.947 31.3803 234.322 32.5475 232.667C33.7147 231.013 34.8154 229.247 36.4628 228.058C37.6079 227.211 39.0276 226.821 40.4446 226.964L25.0863 221.188Z"
          fill="black"
        />
        <path
          d="M40.4446 226.936L42.1289 227.563C41.6289 227.216 41.0497 227.001 40.4446 226.936Z"
          fill="black"
        />
      </g>
      <path
        d="M20.1672 225.516C21.8811 223.138 26.1362 222.451 28.8178 223.633C31.4994 224.815 33.4645 227.171 35.23 229.543C36.9956 231.914 38.7021 234.374 41.1399 236.006C43.5777 237.639 46.9759 238.223 49.4063 236.583C50.1805 236.006 50.8293 235.277 51.3131 234.441C51.797 233.606 52.1058 232.681 52.2209 231.722C52.4341 229.8 52.3015 227.856 51.8294 225.982C51.2532 223.226 50.3002 220.338 48.0914 218.594C45.6314 216.644 42.1815 216.548 39.0788 216.652C35.4738 216.77 31.8171 217.043 28.4337 218.292C25.0503 219.54 21.6004 222.236 20.1672 225.546"
        fill="#00838F"
      />
      <path
        d="M50.2925 266.892C50.2863 266.843 50.2863 266.794 50.2925 266.745C50.2925 266.631 50.2925 266.491 50.2925 266.324C50.2925 265.925 50.2925 265.378 50.2334 264.691C50.1891 263.25 50.1374 261.204 50.0635 258.685C49.9896 256.166 49.8788 253.13 49.6941 249.783C49.5094 246.437 49.2583 242.758 48.7486 238.931C48.5391 237.065 48.1759 235.219 47.6626 233.413C47.1403 231.746 46.4652 230.131 45.6459 228.589C44.9137 227.189 44.0636 225.855 43.1046 224.6C42.2615 223.51 41.3327 222.49 40.327 221.549C38.8555 220.196 37.1958 219.064 35.3996 218.188C35.082 218.04 34.8012 217.899 34.5427 217.796L33.9 217.545L33.5085 217.39L33.3755 217.323C33.3755 217.323 33.4272 217.323 33.5158 217.36L33.9222 217.493L34.5796 217.715C34.8382 217.811 35.1189 217.944 35.4513 218.084C37.282 218.926 38.9735 220.042 40.4673 221.394C41.4903 222.334 42.4364 223.354 43.2967 224.445C44.273 225.707 45.1381 227.052 45.8823 228.463C46.7165 230.019 47.4041 231.649 47.9359 233.332C48.4588 235.149 48.8295 237.008 49.044 238.887C49.5538 242.736 49.7828 246.422 49.9675 249.768C50.1522 253.115 50.2186 256.144 50.2703 258.678C50.3221 261.211 50.3442 263.273 50.3516 264.691V266.324C50.3516 266.493 50.3516 266.634 50.3516 266.745C50.3387 266.796 50.3188 266.846 50.2925 266.892Z"
        fill="#263238"
      />
      <path
        d="M52.6632 269.863C52.3443 268.943 52.0973 267.999 51.9245 267.041C51.471 264.419 51.3692 261.748 51.6216 259.1C52.0266 255.179 52.8691 251.316 54.1333 247.583C55.4778 243.35 57.3172 238.829 58.8834 233.961C59.6539 231.597 60.2829 229.189 60.7671 226.75C61.2035 224.476 61.5167 222.181 61.7053 219.873C62.0059 215.96 62.075 212.033 61.9122 208.112C61.8014 204.758 61.6241 202.047 61.5059 200.178C61.4542 199.255 61.4098 198.531 61.3803 198.021C61.3803 197.785 61.3803 197.6 61.3803 197.46C61.3705 197.396 61.3705 197.331 61.3803 197.268C61.3878 197.331 61.3878 197.396 61.3803 197.46C61.3803 197.6 61.3803 197.785 61.432 198.014C61.4763 198.531 61.5354 199.247 61.6167 200.171C61.7644 202.04 61.9713 204.751 62.1116 208.105C62.3017 212.044 62.2524 215.991 61.9639 219.925C61.7825 222.244 61.4767 224.552 61.0479 226.839C60.5621 229.288 59.9306 231.705 59.1567 234.079C57.5832 238.962 55.7437 243.483 54.3845 247.701C53.1134 251.412 52.2538 255.252 51.8211 259.151C51.5532 261.782 51.6228 264.436 52.0279 267.048C52.1904 267.972 52.3382 268.688 52.4711 269.161C52.5229 269.39 52.5672 269.567 52.6041 269.708C52.6411 269.848 52.6632 269.863 52.6632 269.863Z"
        fill="#263238"
      />
      <path d="M68.9759 272.839H38.082L37.3728 266.893H68.9759V272.839Z" fill="#455A64" />
      <path d="M41.3984 272.16L45.2546 290.001H62.0461L65.6585 272.16H41.3984Z" fill="#455A64" />
      <path
        d="M68.9763 272.839C68.9763 272.921 62.1799 272.965 53.8027 272.943C45.4254 272.921 38.6365 272.832 38.6365 272.751C38.6365 272.669 45.4254 272.625 53.8027 272.647C62.1799 272.669 68.9763 272.758 68.9763 272.839Z"
        fill="#455A64"
      />
      <path
        d="M63.7009 281.645C62.8643 281.012 61.9508 280.489 60.9824 280.086C59.9274 279.67 58.8067 279.445 57.6729 279.421C56.2825 279.419 54.9033 279.669 53.6024 280.16C52.1988 280.633 50.8839 281.187 49.5985 281.497C48.4615 281.738 47.2906 281.773 46.1412 281.6C45.3663 281.48 44.6172 281.23 43.925 280.862C43.7348 280.766 43.5542 280.652 43.3857 280.522C43.324 280.482 43.267 280.434 43.2158 280.381C43.4681 280.495 43.7146 280.621 43.9546 280.758C44.6584 281.071 45.4056 281.275 46.1708 281.364C47.2945 281.507 48.4346 281.452 49.5394 281.201C50.7731 280.891 52.088 280.33 53.5064 279.85C54.8403 279.348 56.2553 279.098 57.6802 279.111C58.8415 279.147 59.9862 279.397 61.0563 279.85C61.7791 280.147 62.4577 280.542 63.073 281.024C63.2421 281.151 63.4025 281.289 63.5532 281.438C63.6271 281.578 63.7009 281.637 63.7009 281.645Z"
        fill="#263238"
      />
      <path
        d="M63.4347 282.835C62.6255 282.223 61.7479 281.707 60.8196 281.298C59.8093 280.88 58.7289 280.657 57.6356 280.641C56.2897 280.652 54.9566 280.902 53.6982 281.379C52.3389 281.83 51.0609 282.362 49.842 282.687C48.7629 282.998 47.639 283.126 46.5177 283.064C45.7594 283.018 45.0182 282.819 44.3384 282.48C44.1556 282.382 43.9803 282.271 43.8139 282.148C43.7532 282.109 43.6984 282.062 43.6514 282.007C43.6514 282.007 43.9099 282.155 44.3901 282.377C45.0698 282.663 45.7954 282.826 46.5324 282.857C47.6294 282.888 48.7242 282.743 49.7755 282.428C50.9722 282.089 52.2355 281.557 53.6095 281.099C54.9032 280.623 56.2722 280.386 57.6504 280.397C58.7741 280.428 59.8809 280.679 60.9082 281.136C61.6012 281.439 62.2519 281.831 62.8437 282.303C63.0505 282.465 63.1983 282.606 63.3017 282.702C63.4051 282.798 63.4421 282.827 63.4347 282.835Z"
        fill="white"
      />
      <path
        d="M63.975 280.463C63.1112 279.807 62.1741 279.255 61.1826 278.816C60.1079 278.35 58.9606 278.076 57.7918 278.003C56.3581 277.971 54.9323 278.222 53.5958 278.742C52.2414 279.271 50.8577 279.723 49.4515 280.094C48.2683 280.297 47.0591 280.297 45.876 280.094C45.0777 279.963 44.3033 279.714 43.5785 279.355C43.3866 279.254 43.2015 279.14 43.0245 279.015C42.9063 278.919 42.8472 278.875 42.8472 278.86C42.8472 278.845 43.1205 279.022 43.6302 279.251C44.3573 279.561 45.1225 279.772 45.9055 279.879C47.0661 280.047 48.2464 280.025 49.3997 279.813C50.7895 279.43 52.1581 278.974 53.4997 278.446C54.871 277.913 56.3358 277.661 57.8065 277.708C59.0052 277.798 60.1784 278.101 61.2712 278.601C62.0032 278.931 62.6927 279.348 63.3249 279.842C63.4986 279.975 63.6639 280.118 63.8198 280.271C63.9307 280.397 63.9824 280.456 63.975 280.463Z"
        fill="white"
      />
      <path
        d="M69.2493 272.899C69.2493 272.98 62.2978 273.046 53.7358 273.046C45.1739 273.046 38.2224 272.98 38.2224 272.899C38.2224 272.817 45.1665 272.751 53.7358 272.751C62.3052 272.751 69.2493 272.781 69.2493 272.899Z"
        fill="#263238"
      />
    </svg>
  )
}
