import { ComponentMaintenanceReserveRateSchema } from '../../contract'
import {
  ComponentMaintenanceReserveContractSchema,
  type ComponentMaintenanceReserveContractDto,
} from '../../contract/ComponentMaintenanceReserveContractDto'
import { type AuxiliaryPowerUnitPRMaintenanceRate } from './auxiliaryPowerUnitPRMaintenanceRate'

export interface AuxiliaryPowerUnitMaintenanceReserveContract extends ComponentMaintenanceReserveContractDto {
  auxiliaryPowerUnitPRMaintenanceReserveRate: AuxiliaryPowerUnitPRMaintenanceRate
}

export const AuxiliaryPowerUnitMaintenanceReserveContractSchema = ComponentMaintenanceReserveContractSchema.extend({
  auxiliaryPowerUnitPRMaintenanceReserveRate: ComponentMaintenanceReserveRateSchema.optional().nullable(),
})
