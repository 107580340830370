import { type AssetSearchDTO } from '@flyward/assets'
import { ComponentContainer } from '@flyward/platform'
import { setSelectedAssetId, setSuccessfulReportId, useFlyForwardDefaultParametersPrefetch } from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import {
  selectUnsortedReportAssets,
  selectSelectedAssetId,
  selectFilteredAssetsData,
} from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import React, { useEffect } from 'react'
import { FlyForwardAssetDetails } from './FlyForwardAssetDetails'
import { FlyForwardAssetSelection } from './FlyForwardAssetSelection'
import { useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash'

const FlyForwardPageBody = () => {
  const dispatch = useAppDispatch()

  const filteredAssetsData = useAppSelector(selectFilteredAssetsData)
  const reportAssets = useAppSelector(selectUnsortedReportAssets)
  const selectedAssetId = useAppSelector(selectSelectedAssetId)

  const prefetchGetAllAssetDefaultFlyForwardParameters = useFlyForwardDefaultParametersPrefetch('getFlyForwardDefaultParameters')

  const [searchParams] = useSearchParams()

  const initialParams: string[] = searchParams.getAll('assetIds')

  const selectedAsset = filteredAssetsData?.items.find((asset: AssetSearchDTO) => asset.assetId === selectedAssetId?.id)

  useEffect(() => {
    dispatch(setSelectedAssetId({ assetId: { id: initialParams?.[0], index: 0 } }))
    dispatch(setSuccessfulReportId({ reportId: '' }))

    for (const assetId of initialParams) {
      prefetchGetAllAssetDefaultFlyForwardParameters({ assetId })
    }
    // intended for [] so that we 100% run it only once
  }, [])

  return (
    <React.Fragment>
      <ComponentContainer className="p-6">
        <FlyForwardAssetSelection />
      </ComponentContainer>
      {reportAssets.map((reportAsset) => (
        <React.Fragment key={`${reportAsset.assetId.id}_${reportAsset.assetId.index}`}>
          {!isNil(selectedAsset) && selectedAssetId?.id === reportAsset.assetId.id && selectedAssetId.index === reportAsset.assetId.index && (
            <FlyForwardAssetDetails currentAssetData={reportAsset} selectedAsset={selectedAsset} index={reportAsset.assetId.index} />
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}

export { FlyForwardPageBody }
