import { EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { type Control, type Path, type FieldValues } from 'react-hook-form'

interface IInflationEditableProps<TFormData extends FieldValues> {
  formControl: Control<TFormData, unknown>
  percentageFieldName: Path<TFormData>
}

export const InflationEditable = <TFormData extends FieldValues>({ formControl, percentageFieldName }: IInflationEditableProps<TFormData>) => {
  return (
    <div className="flex gap-6">
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Percentage}
        className="basis-1/6"
        label="Percentage"
        formControl={formControl}
        fieldName={percentageFieldName}
      />
    </div>
  )
}
