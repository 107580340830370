/* eslint-disable max-len */
import { flexRender, type Table } from '@tanstack/react-table'
import { isNil } from 'lodash'

interface CustomColumnMeta {
  className?: string
}

const GenericTable = <TData,>(tableInstance: Table<TData>) => {
  return (
    <table className="w-full table-auto border-collapse border border-black-20">
      <thead>
        {tableInstance.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="flex">
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                colSpan={header.colSpan}
                className={`truncate border border-black-20 bg-primary-dark-1 ${!isNil(header) ? (header.column.columnDef.meta as CustomColumnMeta)?.className : ''}`}
              >
                {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {tableInstance.getRowModel().rows?.map((row) => (
          <tr key={row.id} className="flex bg-header-table">
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={`truncate border border-black-20 ${!isNil(cell) ? (cell.column.columnDef.meta as CustomColumnMeta)?.className : ''}`}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export { GenericTable }
