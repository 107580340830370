import { ComponentMinimumReDeliveryConditionDtoSchema } from '@flyward/platform'
import { EscalationDtoSchema, type EscalationDto } from '../../contract/EscalationDto'
import { LandingGearMaintenanceReserveContractSchema, type LandingGearMaintenanceReserveContract } from './landingGearMaintenanceReserve'
import { type LandingGearMinimumReDeliveryCondition } from './landingGearMinimumReDeliveryCondition'
import { z } from 'zod'
import { ContractDeliverySnapshotDtoSchema, type ContractDeliverySnapshotDto } from '../../contract/ContractDeliverySnapshotDto'

export interface LandingGearContract {
  escalations: EscalationDto
  landingGearMaintenanceReserveContract: LandingGearMaintenanceReserveContract
  minimumReDeliveryConditions: LandingGearMinimumReDeliveryCondition[]
  contractDeliverySnapshot: ContractDeliverySnapshotDto
}

export const LandingGearContractSchema = z.object({
  escalations: EscalationDtoSchema.optional().nullable(),
  landingGearMaintenanceReserveContract: LandingGearMaintenanceReserveContractSchema.optional().nullable(),
  minimumReDeliveryConditions: z.array(ComponentMinimumReDeliveryConditionDtoSchema).optional().nullable(),
  contractDeliverySnapshot: ContractDeliverySnapshotDtoSchema,
})
