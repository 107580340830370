import { TabsBase, type ITabs } from '../TabsBase'

interface IOutletTabsProps {
  tabs: ITabs
  activeTab?: string // Optional prop to set active tab externally
  onTabChanged?: (newActiveTab: string) => void
}

export const OutletTabs = ({ tabs, activeTab, onTabChanged }: Readonly<IOutletTabsProps>) => {
  return (
    <TabsBase
      tabs={tabs}
      controlledActiveTab={activeTab}
      tabHeaderLabelClassName={'!text-base'}
      onTabChanged={onTabChanged}
      headerClassName="px-6"
      activeTabContentClassName="border-primary-light-2"
    />
  )
}
