import { http, HttpResponse } from 'msw'
import { type IGetReportDetailsQueryResponse } from './types'
import { APIRoutes } from '@flyward/platform/constants'

const reportsApiMocks = [
  http.get(APIRoutes.ForecastModule.ReportsController.SearchReports(), () => {
    return HttpResponse.json<IGetReportDetailsQueryResponse>({
      reportId: '123',
      reportName: 'report',
      assetWithReportItemList: [],
    })
  }),
]

export { reportsApiMocks }
