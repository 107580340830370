import { NumberCellWithLabel, NumberDisplayType } from '@flyward/platform'
import { type Inflation } from '../../models'

interface IInflationReadOnlyProps {
  inflation?: Inflation
}

export const InflationReadOnly = ({ inflation = undefined }: IInflationReadOnlyProps) => {
  return (
    <div className="flex gap-6">
      <NumberCellWithLabel displayType={NumberDisplayType.Percentage} className="basis-1/6" label="Percentage" info={inflation?.percentage} />
    </div>
  )
}
