import { createContext, useContext, useEffect, useMemo, useState, type ReactNode } from 'react'
import { type UserAuthenticatedContextType } from './UserAuthenticatedContextType'
import { useZitadelAuthenticated } from '../ZitadelAuthenticated/ZitadelAuthenticatedContext'
import { type LoggedInUserDto } from '@flyward/platform/models/DTOs/LoggedInUserDto'
import { useGetMeQuery } from '@flyward/appIdentity/store/api/users/endpoints'
import { isNil } from 'lodash'
import { removePersistedSupportFilteredTenantId } from '@flyward/platform/services/AUTH/supportFilteredTenantIdStore'
import { useAppDispatch } from '@flyward/platform/store/configureHooks'
import { setSupportFilteredTenantId } from '@flyward/platform/store/slices/features/auth/authSlice'

const UserAuthenticatedContext = createContext<UserAuthenticatedContextType | undefined>(undefined)

export const useUserAuthenticated = (): UserAuthenticatedContextType => {
  const context = useContext(UserAuthenticatedContext)
  if (context === undefined) {
    throw new Error('useUserAuthenticated must be used within an UserAuthorizationProvider')
  }
  return context
}

interface IUserAuthenticatedProviderProps {
  children: ReactNode
}

export const UserAuthorizationProvider = ({ children }: IUserAuthenticatedProviderProps) => {
  const dispatch = useAppDispatch()

  const [loggedUser, setLoggedUser] = useState<LoggedInUserDto | null>(null)
  const { isUserAuthenticated } = useZitadelAuthenticated()
  const { data: loggedUserDto, refetch } = useGetMeQuery(undefined, { skip: isUserAuthenticated !== true })

  useEffect(() => {
    if (isUserAuthenticated ?? false) {
      refetch()
    }
    if (!isNil(loggedUserDto)) {
      setLoggedUser(loggedUserDto)
      if (loggedUser?.isSupportUser === true && !isNil(loggedUserDto.tenantId)) {
        dispatch(
          setSupportFilteredTenantId({
            tenantId: loggedUserDto.tenantId,
          }),
        )
      } else {
        removePersistedSupportFilteredTenantId()
      }
    }
  }, [dispatch, isUserAuthenticated, loggedUser?.isSupportUser, loggedUserDto, refetch])

  const userContextValue = useMemo(() => ({ loggedUser, isUserLogged: isUserAuthenticated }), [isUserAuthenticated, loggedUser])

  return <UserAuthenticatedContext.Provider value={userContextValue}>{children}</UserAuthenticatedContext.Provider>
}
