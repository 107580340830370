import { type IFilterData } from '@flyward/assets/models/FilterData'

export const initialFilterData: IFilterData = {
  filterSelection: {
    selectedMsn: [],
    selectedLessee: [],
    selectedAircraftModel: [],
    selectedEngineType: [],
    selectedAircraftAge: [],
    selectedRedeliveryDate: [],
    selectedRegions: [],
    selectedPortfolio: [],
  },
  availableFilters: {
    msns: [],
    lessees: [],
    aircraftModels: [],
    engineTypes: [],
    aircraftAges: [],
    redeliveryDates: [],
    regions: [],
    portfolio: [],
  },
}
