import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { UnauthorizedRetryCounter } from '@flyward/platform/services'
import { useUserAuthenticated, useZitadelAuthenticated, ZitadelAuthenticatedProvider } from '@flyward/appIdentity/context'
import { isUserAuthorized } from './ProtectedRouteUtils'
import { isNil } from 'lodash'

export const ProtectedRouteOutlet: React.FC = () => {
  const { isUserAuthenticated, logout } = useZitadelAuthenticated()
  const { loggedUser } = useUserAuthenticated()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)
  const [failedAttempt, setFailedAttempt] = useState(0)
  const [showOutlet, setShowOutlet] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isNil(loggedUser)) {
      if (isUserAuthorized(loggedUser, location.pathname)) {
        setShowOutlet(true)
      } else {
        navigate('/unauthorized')
      }
    }
    if (isUserAuthenticated !== true && failedAttempt < UnauthorizedRetryCounter.maxRetries) {
      setFailedAttempt((prevAttempt) => prevAttempt + 1)
    }
    if (isUserAuthenticated === true) {
      setFailedAttempt(0)
    }
    setIsAuthenticated(isUserAuthenticated ?? null)
  }, [isUserAuthenticated, failedAttempt, location.pathname, loggedUser, navigate])

  if (isAuthenticated === false && failedAttempt >= UnauthorizedRetryCounter.maxRetries) {
    logout()
  }

  if (isAuthenticated === null) {
    if (failedAttempt < UnauthorizedRetryCounter.maxRetries) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <p>Please wait...</p>
        </div>
      )
    } else {
      return <Navigate to="/" replace />
    }
  }

  return <ZitadelAuthenticatedProvider>{showOutlet && <Outlet />}</ZitadelAuthenticatedProvider>
}
