import { type StandAloneEngineDTO } from '@flyward/assets/models'
import { ComponentContainer, EditableDateCellWithLabelWithLabel, EditableInputCellWithLabelWithLabel, ErrorBoundary } from '@flyward/platform'
import { type Control, type FormState, type UseFormWatch, type UseFormSetValue } from 'react-hook-form'

interface IEditStandaloneEngineGeneralEditProps {
  formControl: Control<StandAloneEngineDTO, unknown>
  formState: FormState<StandAloneEngineDTO>
  watchFields: UseFormWatch<StandAloneEngineDTO>
  setFormValue: UseFormSetValue<StandAloneEngineDTO>
}

export const StandaloneEngineEditableDetails = ({ formControl, setFormValue }: IEditStandaloneEngineGeneralEditProps) => {
  return (
    <ComponentContainer className="p-6">
      <ErrorBoundary>
        <div className="flex w-full flex-col">
          <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">General Information</p>
          <div className="flex flex-col gap-x-6 gap-y-2">
            <div className="flex gap-x-6">
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Operator" formControl={formControl} fieldName={`lessee`} />
              <EditableInputCellWithLabelWithLabel
                className="basis-1/6"
                label="Lessee Habitual Base"
                formControl={formControl}
                fieldName={`lesseeHabitualBase`}
              />
              <EditableDateCellWithLabelWithLabel
                className="basis-1/6"
                label="Lease Start"
                formControl={formControl}
                fieldName={`leaseStartDate`}
                setFormValue={setFormValue}
              />
              <EditableDateCellWithLabelWithLabel
                className="basis-1/6"
                label="Lease End"
                formControl={formControl}
                fieldName={`leaseEndDate`}
                setFormValue={setFormValue}
              />
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Region" formControl={formControl} fieldName={`region`} />
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Portfolio" formControl={formControl} fieldName={`portfolio`} />
            </div>
            <div className="flex w-full gap-x-6">
              <EditableDateCellWithLabelWithLabel
                className="basis-1/6"
                label="Tech Spec. Date"
                formControl={formControl}
                fieldName={`dateOfLastTechSpec`}
                setFormValue={setFormValue}
                allowClear={false}
              />
              <div className="basis-5/6" />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </ComponentContainer>
  )
}
