import { TabsBase, type ITabs } from '../TabsBase'

interface IComponentTabsProps {
  tabs: ITabs
  controlledActiveTab?: string // Optional prop to set active tab externally
  onTabChanged?: (newActiveTab: string) => void
  tabTriggerHeight?: string
  tabTriggerWidth?: string
  tabTriggerLabelHeight?: string
  columnClassName?: string
  headerCellClassName?: string
}

export const ComponentTabs = ({
  tabs,
  controlledActiveTab = '',
  onTabChanged = () => {},
  tabTriggerHeight = '',
  tabTriggerWidth = '',
  tabTriggerLabelHeight = '',
  headerCellClassName = '',
  columnClassName = '',
}: Readonly<IComponentTabsProps>) => {
  return (
    <TabsBase
      tabs={tabs}
      controlledActiveTab={controlledActiveTab}
      onTabChanged={onTabChanged}
      headerClassName={`${tabTriggerHeight} flex border-b-2 border-primary`}
      headerCellClassName={`${headerCellClassName} ${tabTriggerLabelHeight} ${tabTriggerWidth} !mb-0 shrink-0 justify-end p-0`}
      activeTabHeaderClassName="border-0"
      tabHeaderLabelClassName={`!text-sm ${tabTriggerLabelHeight} flex
        items-center justify-end rounded-t-lg bg-header-table px-4 text-right leading-4 text-black-100`}
      activeTabHeaderLabelClassName={`rounded-t-lg bg-primary text-black-0 ${tabTriggerLabelHeight} flex items-center px-4 leading-4`}
      columnClassName={columnClassName}
    />
  )
}
