/* eslint-disable max-len */
import { useAssetComponentsForms } from '@flyward/assets/context'
import { type AircraftEngineDto, type EngineUnitContractDto } from '@flyward/assets/models'
import { ContractualReadOnlyTab } from './ContractualReadOnlyTab'
import { ContractualEditableTab } from './ContractualEditableTab'
import { type Control } from 'react-hook-form'

interface IContractualTabProps {
  component: AircraftEngineDto
  engineContract: EngineUnitContractDto
  llpMinimumReDeliveryConditions: string
  eprMinimumReDeliveryConditions: string
  formControl: Control<AircraftEngineDto, unknown>
}

export const ContractualTab = ({
  component,
  engineContract,
  llpMinimumReDeliveryConditions,
  eprMinimumReDeliveryConditions,
  formControl,
}: IContractualTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()
  const escalations = engineContract.escalations
  const maintenanceReserve = engineContract.engineUnitMaintenanceReserveContract
  const contractDeliverySnapshot = engineContract.contractDeliverySnapshot

  return (
    <>
      {isInEditMode ? (
        <ContractualEditableTab component={component} formControl={formControl} />
      ) : (
        <ContractualReadOnlyTab
          buildStandard={engineContract.llPsContractualBuildStandard}
          currentLlpMrFund={maintenanceReserve.engineLLPsTotalMaintenanceReserveRate?.currentFund}
          isMrFlag={maintenanceReserve.isMaintenanceReserveActive}
          llpMrRate={maintenanceReserve.engineLLPsTotalMaintenanceReserveRate?.rateAmount}
          llpMrRateEscalation={escalations.llPsEscalation?.maintenanceReserveAgreedEscalationPercentage}
          llpMrRateType={engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate?.utilizationUnit}
          mrRatesYear={maintenanceReserve.enginePerformanceRestorationMaintenanceRate?.ratesYear}
          returnConditionsLlp={llpMinimumReDeliveryConditions}
          returnConditionsPr={eprMinimumReDeliveryConditions}
          isEolActive={engineContract.engineUnitMaintenanceReserveContract.isEolActive ?? false}
          contractDeliverySnapshot={contractDeliverySnapshot}
        />
      )}
    </>
  )
}
