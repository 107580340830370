import { type ApuDto } from '@flyward/assets/models'
import { formatDate, TextCellWithLabel } from '@flyward/platform'

export interface IAPUDetailsReadOnly {
  component: ApuDto
}

export const APUDetailsReadOnly = ({ component }: IAPUDetailsReadOnly) => {
  return (
    <div className="flex gap-x-6">
      <TextCellWithLabel className="mr-6 basis-2/6" label="Manufacturer" info={component.manufacturingDetail.manufacturer} />
      <TextCellWithLabel className="basis-1/6" label="Model" info={component.componentModel} />
      <TextCellWithLabel className="basis-1/6" label="S/N" info={component.componentSerialNumber} />
      <TextCellWithLabel className="basis-1/6" label="DOM" info={formatDate(component.manufacturingDetail.dom)} />
      <div className="basis-1/6" />
    </div>
  )
}
