import { ComponentSchema, type ComponentDto } from '../../ComponentDto'
import { EngineUnitContractDtoSchema, type EngineUnitContractDto } from './EngineUnitContractDto'
import { EngineLessorContributionsDtoSchema, type EngineLessorContributionsDto } from './EngineLessorContributionsDto'
import { EngineLifeLimitedPartDtoSchema, type EngineLifeLimitedPartDto } from './EngineLifeLimitedPartDto'
import { z } from 'zod'
import { validateNullableNaturalNumber } from '@flyward/platform'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Engine.AircraftEngineDto
 */
export interface AircraftEngineDto extends ComponentDto {
  engineThrustRating?: number
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
  engineContract: EngineUnitContractDto
  lessorContributions?: EngineLessorContributionsDto
}

export const AircraftEngineDtoSchema = ComponentSchema.extend({
  engineThrustRating: validateNullableNaturalNumber,
  engineLifeLimitedParts: z.array(EngineLifeLimitedPartDtoSchema).nullable(),
  engineContract: EngineUnitContractDtoSchema,
  lessorContributions: EngineLessorContributionsDtoSchema,
})
