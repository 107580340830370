/* eslint-disable max-len */
import * as React from 'react'
import { type IIconProps } from '../IIconProps'
import { IconVariant } from '../IconVariant'

export const LocalAirport = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg className={className} data-variant={IconVariant[variant]} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.4 21H10.2L14.7 13.35H19.65C20.397 13.35 21 12.747 21 12C21 11.253 20.397 10.65 19.65 10.65H14.7L10.2 3L8.4 3L10.65 10.65H5.7L4.35 8.4H3L3.9 12L3 15.6H4.35L5.7 13.35L10.65 13.35L8.4 21Z" />
    </svg>
  )
}
