import React from 'react'
import { Icon, IconVariant } from '../Icon'
import { Size } from '../common'
import { cn } from '../_shadcn/utils/utils'

export interface ICardProps {
  iconSize?: Size
  topIcon?: IconVariant
  label?: string
  onClick?: () => void
}

export const Card: React.FC<ICardProps> = ({
  iconSize = Size.None,
  topIcon = IconVariant.ArrowBack,
  label = 'LabelText for Card',
  onClick,
}: ICardProps) => {
  return (
    <button
      className={cn(
        'group mb-8 flex flex-col items-center justify-center gap-6 rounded-lg bg-white/30 p-6',
        'backdrop-blur-md hover:bg-white lg:h-40 lg:w-44 xl:h-48 xl:w-72 5xl:h-72 5xl:w-76',
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          'flex flex-col items-center justify-center rounded-full  outline-2',
          'group-hover:outline lg:h-15 lg:w-15 xl:h-20 xl:w-20 5xl:h-38 5xl:w-38',
        )}
      >
        {topIcon !== undefined && <Icon variant={topIcon} size={iconSize} className={cn('text-primary-dark-2')} />}
      </div>
      <div className="font-semibold text-primary-dark-2 lg:text-sm xl:text-h6 5xl:text-h5">{label}</div>
    </button>
  )
}
