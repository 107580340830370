import { type ZitadelTokenResponse } from '../../models'
import { isNil, isEmpty } from 'lodash'

export const LOCALSTORAGE_USERTOKEN_KEY = 'Flyward_UserAccessToken'
export const LOCALSTORAGE_ZITADEL_USERTOKEN_KEY = 'Flyward_ZitadelUserAccessToken'

/**
 * Checks if a string is null, undefined, or empty.
 * @param {unknown} value - The string to check.
 * @returns {boolean} - True if the string is null, undefined, or empty; otherwise, false.
 */
const isNullOrEmpty = (value: unknown) => isNil(value) || isEmpty(value)

const persistZitadelUserAccessToken = (tokenData: ZitadelTokenResponse) => {
  if (isNullOrEmpty(tokenData)) {
    return
  }
  localStorage.setItem(LOCALSTORAGE_ZITADEL_USERTOKEN_KEY, JSON.stringify(tokenData))
}

const getZitadelPersistedUserAccessToken = (): ZitadelTokenResponse | null => {
  const tokenString = localStorage.getItem(LOCALSTORAGE_ZITADEL_USERTOKEN_KEY)
  if (isNullOrEmpty(tokenString)) return null
  try {
    const tokenData = JSON.parse(tokenString!)
    return tokenData
  } catch (error) {
    return null
  }
}

const removePersistedZitadelUserAccessToken = () => {
  localStorage.removeItem(LOCALSTORAGE_ZITADEL_USERTOKEN_KEY)
}

export { getZitadelPersistedUserAccessToken, persistZitadelUserAccessToken, removePersistedZitadelUserAccessToken }
