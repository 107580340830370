import { validateNullableDate, validateNullableNaturalNumber } from '@flyward/platform/helpers'
import { type CheckType } from '../../enums/CheckType'
import { z } from 'zod'

/**
 * Cloned from from API: Platform.Core.Entity.DTOs.Utilization.ComponentUtilizationAtEventDto
 */
export interface ComponentUtilizationAtEventDto {
  componentUtilizationSnapshotId: string
  eventType: CheckType
  totalHoursSinceNewAtLastQualifyingEvent: number
  cyclesSinceNewAtLastQualifyingEvent: number
  dateAtLastQualifyingEvent?: string
}

export const ComponentUtilizationAtEventDtoSchema = z.object({
  componentUtilizationSnapshotId: z.any().optional().nullable(),
  eventType: z.any().optional().nullable(),
  totalHoursSinceNewAtLastQualifyingEvent: validateNullableNaturalNumber,
  cyclesSinceNewAtLastQualifyingEvent: validateNullableNaturalNumber,
  dateAtLastQualifyingEvent: validateNullableDate,
})
