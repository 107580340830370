import { NumberDisplay, NumberDisplayType } from '@flyward/platform'
import { getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table'
import { isArray, isNil } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { AircraftExpandedDataGridBody } from './AircraftExpandedDataGridBody'
import { AircraftExpandedDataGridHeader } from './AircraftExpandedDataGridHeader'
import { generateColumns, Label, rowProperties } from './constants'
import { type ExpandedRowFields, type IAircraftExpandedDataGridProps, type IExpandedColumnDefs, type IOverriddenProperty } from './types'
import { type ExpandedComponentDto } from '@flyward/platform/models/DTOs/ExpandedComponentDto'

const AircraftExpandedDataGrid = ({ airframes, engines, landingGears, auxiliaryPowerUnits }: IAircraftExpandedDataGridProps) => {
  const [columnDefs, setColumnDefs] = useState<IExpandedColumnDefs>({
    airframe: [],
    engine: [],
    landingGear: [],
    apu: [],
    thrustReverser: [],
  })

  const formatValue = useCallback((value: string | number | undefined, label: Label) => {
    if (value === undefined) {
      return 'N/A'
    }

    if (typeof value === 'number') {
      let displayType = NumberDisplayType.Decimal
      if (label === Label.PR_fund || label === Label.LLP_fund) {
        displayType = NumberDisplayType.CurrencyRounded
      }

      return <NumberDisplay value={value} displayType={displayType} />
    }

    return value
  }, [])

  const insertDataInColumn = useCallback(
    (
      row: ExpandedRowFields,
      property: keyof ExpandedComponentDto,
      label: Label,
      column: ExpandedComponentDto[] | undefined,
      rowLabel: keyof Omit<ExpandedRowFields, 'name'>,
      overriddenProperties: IOverriddenProperty[] = [],
    ) => {
      let rowComponent = {}

      if (!isArray(column)) {
        return rowComponent
      }

      column.forEach((component: ExpandedComponentDto) => {
        if (
          overriddenProperties.length === 0 ||
          isNil(overriddenProperties.find((overriddenProperty: IOverriddenProperty): boolean => overriddenProperty.name === rowLabel))
        ) {
          rowComponent = {
            [component.serialNumber]: formatValue(component[property] as string | number | undefined, label),
          }
        } else {
          overriddenProperties
            .filter(({ name }) => name === rowLabel)
            .forEach(({ value }) => {
              rowComponent = {
                [component.serialNumber]: formatValue(value, label),
              }
            })
        }

        row[rowLabel] = { ...row[rowLabel], ...rowComponent }
      })
    },
    [formatValue],
  )

  const formattedData: ExpandedRowFields[] = useMemo(() => {
    const formattedDataRows: ExpandedRowFields[] = []

    rowProperties.forEach(({ label, property, overriddenProperties }) => {
      const row: ExpandedRowFields = {
        name: label,
        airframe: {},
        engine: {},
        landingGear: {},
        apu: {},
        thrustReverser: {},
      }

      insertDataInColumn(row, property, label, airframes, 'airframe', overriddenProperties)
      insertDataInColumn(row, property, label, engines, 'engine', overriddenProperties)
      insertDataInColumn(row, property, label, landingGears, 'landingGear', overriddenProperties)
      insertDataInColumn(row, property, label, auxiliaryPowerUnits, 'apu', overriddenProperties)

      formattedDataRows.push(row)
    })

    if (formattedDataRows.length > 0) {
      const firstRow = formattedDataRows[0]
      const columnDefs = {
        airframe: Object.keys(firstRow.airframe).sort((a, b) => a.localeCompare(b)),
        engine: Object.keys(firstRow.engine).sort((a, b) => a.localeCompare(b)),
        landingGear: Object.keys(firstRow.landingGear).sort((a, b) => a.localeCompare(b)),
        apu: Object.keys(firstRow.apu).sort((a, b) => a.localeCompare(b)),
        thrustReverser: Object.keys(firstRow.thrustReverser).sort((a, b) => a.localeCompare(b)),
      }
      setColumnDefs(columnDefs)
    }

    return formattedDataRows
  }, [airframes, engines, landingGears, auxiliaryPowerUnits, insertDataInColumn])

  const table = useReactTable<ExpandedRowFields>({
    data: formattedData,
    columns: generateColumns(columnDefs),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="flex w-full overflow-x-auto">
      <div className="w-[0.4375rem] bg-[linear-gradient(180deg,_#005C64,_#02AEBD66)]"></div>
      <table className="w-full table-fixed">
        <AircraftExpandedDataGridHeader table={table} />
        <AircraftExpandedDataGridBody table={table} />
      </table>
    </div>
  )
}

export { AircraftExpandedDataGrid }
