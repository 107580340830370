import { Icon, type IconVariant } from '../Icon'
import { Size } from '../common'
import { Button as CnButton, ButtonVariant as CnButtonVariant, type ButtonProps as CnButtonProps } from '../_shadcn'
import { cn } from '../utils'

export interface IButtonProps extends CnButtonProps {
  buttonType?: 'button' | 'submit' | 'reset'
  children?: React.ReactNode
  'data-testid'?: string
  isPressed?: boolean
  label?: string
  labelClassName?: string
  leftIcon?: IconVariant
  leftIconClassName?: string
  rightIcon?: IconVariant
  rightIconClassName?: string
}

const Button = ({
  buttonType = 'button',
  children = <></>,
  className: buttonClassName,
  disabled = false,
  isPressed = false,
  label,
  labelClassName,
  leftIcon,
  leftIconClassName,
  onClick,
  rightIcon,
  rightIconClassName,
  size = Size.None,
  variant = CnButtonVariant.Primary,
  ...props
}: IButtonProps) => {
  const buttonClass = `flex items-center content-center gap-x-1 group ${label !== '' ? 'justify-between' : 'justify-around '} ${buttonClassName}`

  const leftIconClass = `flex items-center justify-center ${leftIconClassName}`
  const rightIconClass = `flex items-center justify-center ${rightIconClassName}`

  const iconSize = size === null || size === Size.None ? Size.Medium : size

  return (
    <CnButton
      variant={variant}
      pressed={isPressed && !disabled ? variant : null}
      size={size}
      className={buttonClass}
      type={buttonType}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <div className="flex items-center justify-center gap-x-1">
        {leftIcon !== undefined && <Icon variant={leftIcon} size={iconSize} className={leftIconClass} />}
        {Boolean(label) && <p className={cn('items-center justify-center p-0', labelClassName)}>{label}</p>}
        {children}
      </div>
      {rightIcon !== undefined && <Icon data-testid="button-right-icon" variant={rightIcon} size={iconSize} className={rightIconClass} />}
    </CnButton>
  )
}

export { Button, CnButtonVariant as ButtonVariant }
