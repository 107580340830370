import { useAssetComponentsForms } from '@flyward/assets/context'
import { type ApuDto } from '@flyward/assets/models'
import { type UseFormSetValue, type Control } from 'react-hook-form'
import { APUDetailsEditable } from './APUDetailsEditable'
import { APUDetailsReadOnly } from './APUDetailsReadOnly'

export interface IAPUDetailsProps {
  component: ApuDto
  setFormValue: UseFormSetValue<ApuDto>
  formControl: Control<ApuDto, unknown>
}

export const APUDetails = ({ component, setFormValue, formControl }: IAPUDetailsProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return (
    <>{isInEditMode ? <APUDetailsEditable setFormValue={setFormValue} formControl={formControl} /> : <APUDetailsReadOnly component={component} />}</>
  )
}
