import * as React from 'react'
import {
  Select as CnSelect,
  SelectTrigger as CnSelectTrigger,
  SelectContent as CnSelectContent,
  SelectItem as CnSelectItem,
  SelectValue as CnSelectValue,
  Label as CnLabel,
} from '../_shadcn'
import { cn } from '../utils'

interface ISelectOption {
  value: string
  label: string
}

interface ISelectProps {
  options: ISelectOption[]
  selectClassName?: string
  selectTriggerClassName?: string
  labelClassName?: string
  disabled?: boolean
  label?: string
  setControlledValue?: (value: string) => void
  controlledValue: string
}

const Select = ({
  options,
  selectClassName,
  labelClassName,
  selectTriggerClassName,
  disabled,
  label,
  controlledValue,
  setControlledValue = (_value) => {},
  ...props
}: Readonly<ISelectProps>) => {
  const id = React.useId()
  const labelColor = disabled ?? false ? 'text-text-3' : 'text-text-1'

  const [value, setValue] = React.useState(controlledValue)

  return (
    <div className={cn('flex flex-col justify-center gap-y-0.4', selectClassName)}>
      {label != null && (
        <CnLabel data-testid="select-label" htmlFor={id}>
          <p className={cn(`!text-xs font-semibold ${labelColor}`, labelClassName)} data-testid="select-label-text">
            {label}
          </p>
        </CnLabel>
      )}
      <CnSelect
        value={value}
        onValueChange={(value: string) => {
          setValue(value)
          setControlledValue(value)
        }}
        disabled={disabled}
        {...props}
      >
        <CnSelectTrigger
          className={cn('h-6 w-full justify-between rounded bg-black-0 px-2 py-1 text-xs', selectTriggerClassName, selectClassName)}
          value={value?.toString()}
        >
          <CnSelectValue />
        </CnSelectTrigger>
        <CnSelectContent className=" bg-black-0 text-xs " date-testid="select-content" {...props}>
          {options.map((option: ISelectOption) => (
            <CnSelectItem key={option.value} value={option.value}>
              {option.label}
            </CnSelectItem>
          ))}
        </CnSelectContent>
      </CnSelect>
    </div>
  )
}

export { Select, type ISelectProps }
