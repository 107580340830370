import { type AircraftEngineDto } from '@flyward/assets/models'
import {
  CheckTypeDisplay,
  EditableDateCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  InputType,
  NumberCellWithLabel,
  NumberDisplayType,
} from '@flyward/platform'
import { Fragment } from 'react'
import { type UseFormSetValue, type Control } from 'react-hook-form'

export interface IPRTechnicalEditableTabProps {
  component: AircraftEngineDto
  formControl: Control<AircraftEngineDto, unknown>
  setFormValue: UseFormSetValue<AircraftEngineDto>
}

export const PRTechnicalEditableTab = ({ component, formControl, setFormValue }: IPRTechnicalEditableTabProps) => {
  const utilizationAtEvents = component.assetComponentUtilizationSnapshot.componentUtilizationAtEvents
  const UtilizationAtEventComponent = utilizationAtEvents.map((event, index) => {
    return (
      <Fragment key={event.eventType}>
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`TSN @ last ${CheckTypeDisplay(event.eventType)}`}
          formControl={formControl}
          fieldName={`assetComponentUtilizationSnapshot.componentUtilizationAtEvents.${index}.totalHoursSinceNewAtLastQualifyingEvent`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`CSN @ last ${CheckTypeDisplay(event.eventType)}`}
          formControl={formControl}
          fieldName={`assetComponentUtilizationSnapshot.componentUtilizationAtEvents.${index}.cyclesSinceNewAtLastQualifyingEvent`}
        />
        <EditableDateCellWithLabelWithLabel
          className="basis-1/6"
          label={`Last ${CheckTypeDisplay(event.eventType)} date`}
          formControl={formControl}
          fieldName={`assetComponentUtilizationSnapshot.componentUtilizationAtEvents.${index}.dateAtLastQualifyingEvent`}
          setFormValue={setFormValue}
        />
      </Fragment>
    )
  })

  const UtilizationSinceLastShopVisitComponent = utilizationAtEvents.map((event) => {
    return (
      <Fragment key={event.eventType}>
        <NumberCellWithLabel
          className="basis-1/6"
          label="TSLPR"
          info={component.assetComponentUtilizationSnapshot.hoursSinceEvent}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="CSLPR"
          info={component.assetComponentUtilizationSnapshot.cyclesSinceEvent}
          displayType={NumberDisplayType.Integer}
        />
      </Fragment>
    )
  })

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-6">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label="TSN"
          formControl={formControl}
          fieldName={`assetComponentUtilizationSnapshot.totalHoursSinceNew`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label="CSN"
          formControl={formControl}
          fieldName={`assetComponentUtilizationSnapshot.cyclesSinceNew`}
        />
        {UtilizationAtEventComponent}
        <div className="basis-1/6" />
      </div>
      <div className="flex gap-x-6">
        {UtilizationSinceLastShopVisitComponent}
        <div className="mr-18 basis-4/6" />
      </div>
    </div>
  )
}
