import { NumberDisplay, NumberDisplayType, type TableColumn } from '@flyward/platform'
import { type DerateRatio } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { cloneDeep } from 'lodash'

interface IDerateRatiosReadOnlyTableProps {
  derateRatios: DerateRatio[] | undefined
  tableClassName?: string
}
const tableColumns: Array<TableColumn<DerateRatio>> = [
  {
    accessor: 'id',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'intervalStart',
    title: 'Interval start',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Percentage} />,
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
  {
    accessor: 'intervalEnd',
    title: 'Interval end',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Percentage} />,
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
  {
    accessor: 'percentage',
    title: 'Percentage',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Percentage} />,
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
]
export const DerateRatiosReadOnlyTable = ({
  derateRatios,
  tableClassName,
}: IDerateRatiosReadOnlyTableProps): React.ReactElement<IDerateRatiosReadOnlyTableProps> => {
  return (
    <MaintenanceProgramDetailsTable
      columns={tableColumns}
      data={cloneDeep(derateRatios)?.sort((a, b) => a.intervalStart - b.intervalStart)}
      tableClassName={tableClassName}
    />
  )
}
