import { useState } from 'react'
import { cn } from '../utils'
import { Button, ButtonVariant } from '../Button'
import { IconVariant } from '../Icon'
import { isNil } from 'lodash'

interface ICollapsibleContainerProps {
  title: string
  children?: React.ReactNode
  containerClassName?: string
  labelClassName?: string
  button?: React.ReactElement
}

/**
 * This wraps components
 */
export const CollapsibleContainer = ({
  children,
  containerClassName,
  labelClassName,
  title,
  button,
}: ICollapsibleContainerProps): React.ReactElement<ICollapsibleContainerProps> => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)

  return (
    <div className={cn('flex flex-col gap-y-4', containerClassName)}>
      <div className="flex items-center justify-between border-b border-primary-light-2 pb-2">
        <p className={cn('text-base font-semibold text-text-1', labelClassName)}>{title}</p>
        <div className="flex">
          {!isNil(button) && button}
          <Button
            leftIcon={isExpanded ? IconVariant.ExpandLess : IconVariant.ExpandMore}
            variant={ButtonVariant.Ghost}
            className="w-9 px-[0.5625rem] text-text-1"
            onClick={() => {
              setIsExpanded((prevValue) => !prevValue)
            }}
          />
        </div>
      </div>
      {isExpanded && children}
    </div>
  )
}
