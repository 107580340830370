import { useAssetComponentsForms } from '@flyward/assets/context'
import { type ApuDto } from '@flyward/assets/models'
import { APUContractualEditableTab } from './APUContractualEditableTab'
import { APUContractualReadOnlyTab } from './APUContractualReadOnlyTab'
import { type Control } from 'react-hook-form'

interface IAPUContractualTabProps {
  component: ApuDto
  formControl: Control<ApuDto, unknown>
}

export const APUContractualTab = ({ component, formControl }: IAPUContractualTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()
  return (
    <>
      {isInEditMode ? (
        <APUContractualEditableTab component={component} formControl={formControl} />
      ) : (
        <APUContractualReadOnlyTab component={component} />
      )}
    </>
  )
}
