import { APIRoutes } from '@flyward/platform'
import { flywardApi } from '@flyward/platform/store'
import { type IGetReportItemDetailsQueryRequest, type IGetReportItemQueryDetails } from './types'

const reportsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportItem: builder.query<IGetReportItemQueryDetails, IGetReportItemDetailsQueryRequest>({
      query: ({ reportItemId }) => ({
        url: APIRoutes.ForecastModule.ReportItemController.GetSingleReportItem(reportItemId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'reportItems', id: request.reportItemId }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetReportItemQuery, util: reportsApiSliceUtil } = reportsApi

type Endpoints = typeof reportsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TReportsApiActions = InitiateActions[keyof InitiateActions]
