/* eslint-disable max-len */
import { Card, type IconVariant, Logo, LogoVariant, Size } from '@flyward/platform'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

interface Route {
  path: string
  label: string
  icon: IconVariant
}

interface IHomePageProps {
  routes: Route[]
}

export const HomePage = ({ routes }: IHomePageProps): React.ReactElement<IHomePageProps> => {
  const navigate = useNavigate()
  const [logoWidth, setLogoWidth] = useState('100')
  const [logoHeight, setLogoHeight] = useState('14')

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 3000) {
        setLogoWidth('200')
        setLogoHeight('32')
      } else if (window.innerWidth >= 1440) {
        setLogoWidth('126')
        setLogoHeight('18')
      } else {
        setLogoWidth('100')
        setLogoHeight('14')
      }
    }

    handleWindowResize()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div className="flex h-screen w-screen flex-col overflow-x-hidden bg-primary-light-1 bg-landing-page bg-cover">
      <div className="flex h-14 w-screen flex-row items-center justify-between px-28 py-6 3xl:h-24 5xl:h-32">
        <Logo variant={LogoVariant.black} height={logoHeight} width={logoWidth} />
        <div className="flex">
          <Outlet />
        </div>
      </div>
      <div className="m-0 flex w-full items-center bg-landing-page-picture bg-cover p-0 pl-36 lg:h-[480px] xl:h-[600px] 3xl:h-[880px] 5xl:h-[1200px]">
        <div>
          <div className="animate-slideInUp bg-gradient-to-t from-text-1 to-text-5 bg-clip-text font-extrabold text-transparent lg:pb-2 lg:text-h3 xl:pb-5 xl:text-landing 3xl:text-landing2k 5xl:text-landing4k">
            Better Decisions,
          </div>
          <div className="animate-slideInUp bg-gradient-to-t from-text-1 to-text-5 bg-clip-text text-h3 font-extrabold text-transparent lg:text-h3 xl:text-landing 3xl:text-landing2k 5xl:text-landing4k">
            More Often
          </div>
        </div>
      </div>
      <ul className="flex flex-wrap justify-center gap-6 border-slate-900 md:gap-20 lg:pt-4 xl:pt-8">
        {routes.map((route: Route) => (
          <li key={route.path} className="flex h-auto w-full md:w-auto">
            <Card
              iconSize={Size.ResponsiveTablet}
              topIcon={route.icon}
              label={route.label}
              onClick={() => {
                navigate(route.path)
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
