import React, { type PropsWithChildren, useMemo, useState } from 'react'
import { type IButtonProps } from '../../components/Button'
import { type IInputProps } from '../../components/Input'

interface HeaderContentContextType {
  title: string
  buttons: IButtonProps[]
  customElements: React.ReactNode[]
  inputs: IInputProps[]
  hasBackButton: boolean
  setTitle: (title: string) => void
  setInputs: (inputs: IInputProps[]) => void
  setButtons: (buttons: IButtonProps[]) => void
  setCustomElements: (customElements: React.ReactNode[]) => void
  setHasBackButton: (hasBackButton: boolean) => void
}

const HeaderContentContext = React.createContext<HeaderContentContextType>({
  title: '',
  buttons: [],
  customElements: [],
  inputs: [],
  hasBackButton: false,
  setTitle: (_title) => {},
  setInputs: (_inputs) => {},
  setButtons: (_buttons) => {},
  setCustomElements: (_customElements) => {},
  setHasBackButton: (_hasBackButton) => {},
})

const HeaderContentContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [title, setTitle] = useState<string>('')
  const [hasBackButton, setHasBackButton] = useState<boolean>(false)
  const [buttons, setButtons] = useState<IButtonProps[]>([])
  const [customElements, setCustomElements] = useState<React.ReactNode[]>([])
  const [inputs, setInputs] = useState<IInputProps[]>([])

  const value = useMemo((): HeaderContentContextType => {
    return {
      title,
      buttons,
      customElements,
      inputs,
      hasBackButton,
      setTitle,
      setButtons,
      setCustomElements,
      setInputs,
      setHasBackButton,
    }
  }, [title, buttons, inputs, customElements, hasBackButton])

  return <HeaderContentContext.Provider value={value}>{children}</HeaderContentContext.Provider>
}

const useHeaderContent = () => React.useContext(HeaderContentContext)

export { HeaderContentContextProvider, useHeaderContent }
