import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from './utils'
import { Size } from '../common'

enum ButtonVariant {
  Navigation = 'nav',
  Hamburger = 'hamburger',
  Account = 'account',
  Collapsible = 'collapsible',
  Primary = 'primary',
  Secondary = 'secondary',
  Ghost = 'ghost',
  Square = 'square',
  SquareGhost = 'squareGhost',
  Circle = 'circle',
  Outline = 'outline',
  ModalCancel = 'modalCancel',
  ModalConfirm = 'modalConfirm',
  EventView = 'eventView',
}

const buttonVariants = cva(
  'flex items-center justify-center whitespace-nowrap gap-x-1 border rounded-[0.25rem] transition-colors focus-visible:outline-none disabled:pointer-events-none m-0 p-0 transition-size duration-200 ease-in-out cursor-pointer disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        [ButtonVariant.Navigation]:
          'bg-transparent border-none p-1 h-8 !text-base font-semibold text-text-1 hover:text-primary disabled:text-text-1 active:bg-primary active:border-primary active:text-black-0',
        [ButtonVariant.Hamburger]: 'bg-transparent border-none p-1 h-8 text-text-1',
        [ButtonVariant.Account]: 'bg-transparent border-none p-1 h-8 font-semibold text-text-1 hover:text-primary disabled:text-text-1',
        [ButtonVariant.Collapsible]: 'bg-black-0 border-primary rounded-full w-6.5 h-6.5 drop-shadow-md text-primary',
        [ButtonVariant.Primary]:
          'bg-primary border-primary text-text-button hover:bg-primary-light-1 hover:border-primary-light-1 disabled:bg-primary-light-2 disabled:border-primary-light-2 active:bg-primary active:border-primary',
        [ButtonVariant.Secondary]:
          'bg-black-0 border-primary text-primary hover:bg-primary-light-1 hover:border-primary-light-1 hover:text-text-button disabled:bg-primary-light-2 disabled:border-primary-light-2 disabled:text-text-button active:bg-primary active:border-primary active:text-text-button',
        [ButtonVariant.Ghost]: 'bg-transparent border-none text-primary hover:text-primary-light-1 disabled:text-primary-light-2',
        [ButtonVariant.Square]:
          'bg-primary border-primary text-text-button hover:bg-primary-light-1 hover:border-primary-light-1 disabled:bg-primary-light-2 disabled:border-primary-light-2',
        [ButtonVariant.SquareGhost]:
          'bg-transparent border-transparent text-primary hover:bg-primary-light-1 hover:border-primary-light-1 hover:text-primary-light-1 disabled:text-primary-light-2',
        [ButtonVariant.Circle]:
          'bg-primary border-primary text-text-button rounded-[2.38rem] hover:bg-primary-light-1 hover:border-primary-light-1 disabled:bg-primary-light-2 disabled:border-primary-light-2',
        [ButtonVariant.Outline]:
          'border border-input bg-black-0 hover:border-primary hover:text-primary disabled:border-black-20 disabled:text-text-3',
        [ButtonVariant.ModalCancel]: 'h-9 px-4 py-1 !text-xs font-semibold border-text-2 bg-black-0 text-text-2',
        [ButtonVariant.ModalConfirm]:
          'h-9 px-4 py-1 !text-xs font-semibold bg-primary border-primary text-text-button hover:bg-primary-light-1 hover:border-primary-light-1 disabled:bg-primary-light-2 disabled:border-primary-light-2 active:bg-primary active:border-primary',
        [ButtonVariant.EventView]:
          'inline-flex w-full self-stretch overflow-y-hidden rounded bg-primary-dark-2 px-2 py-1 text-xs font-normal text-white',
      },
      size: {
        [Size.None]: '',
        [Size.ExtraSmall]: 'h-9 px-[0.9375rem] py-[0.5625rem] !text-xs font-semibold',
        [Size.Small]: 'h-9 px-[0.9375rem] py-[0.5625rem] !text-xs font-semibold',
        [Size.Medium]: 'h-[2.625rem] px-[1.1875rem] py-[0.5625rem] !text-sm font-semibold',
        [Size.Large]: 'h-12 px-[1.4375rem] py-[0.5625rem] !text-base font-semibold',
        [Size.ExtraLarge]: 'h-14 px-6.5 py-[0.75rem] !text-h6 font-semibold',
        [Size.ResponsiveTablet]: '',
      },
      pressed: {
        [ButtonVariant.Navigation]: ' bg-primary border-primary text-black-0 hover:text-black-0',
        [ButtonVariant.Hamburger]: '',
        [ButtonVariant.Account]: '',
        [ButtonVariant.Collapsible]: '',
        [ButtonVariant.Primary]: 'bg-primary border-primary hover:bg-primary hover:border-primary',
        [ButtonVariant.Secondary]: 'bg-primary border-primary text-text-button hover:bg-primary hover:border-primary hover:text-text-button',
        [ButtonVariant.Ghost]: '',
        [ButtonVariant.Square]: '',
        [ButtonVariant.SquareGhost]: '',
        [ButtonVariant.Circle]: '',
        [ButtonVariant.Outline]: '',
        [ButtonVariant.ModalCancel]: '',
        [ButtonVariant.ModalConfirm]: '',
        [ButtonVariant.EventView]: '',
      },
    },
    defaultVariants: {
      variant: ButtonVariant.Primary,
      size: Size.None,
    },
  },
)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, pressed, size, ...props }, ref) => {
  return <button className={cn(buttonVariants({ size, variant, pressed, className }))} ref={ref} {...props} />
})
Button.displayName = 'Button'

export { Button, ButtonVariant, buttonVariants }
