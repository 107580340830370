/* eslint-disable @typescript-eslint/unbound-method */
import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import { type IFlyForwardDefaultParametersQueryRequest, type IFlyForwardDefaultParametersQueryResponse } from './types'

const flyForwardDefaultParametersApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getFlyForwardDefaultParameters: builder.query<IFlyForwardDefaultParametersQueryResponse, IFlyForwardDefaultParametersQueryRequest>({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.AssetsController.GetFlyForwardDefaultParameters(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'flyForwardDefaultParameters', id: `${request.assetId}` }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetFlyForwardDefaultParametersQuery,
  useLazyGetFlyForwardDefaultParametersQuery,
  usePrefetch: useFlyForwardDefaultParametersPrefetch,
  util: flyForwardDefaultParametersApiSliceUtil,
} = flyForwardDefaultParametersApi

type Endpoints = typeof flyForwardDefaultParametersApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TFlyForwardDefaultParametersApiActions = InitiateActions[keyof InitiateActions]
