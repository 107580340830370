import React from 'react'
import ReactDOM from 'react-dom/client'
import { reportWebVitals, reportHandler } from './main-app/services'
import { App } from './main-app/App'

import './main.css'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <IntlProvider locale="en-US">
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </IntlProvider>
  </React.StrictMode>,
)

void reportWebVitals(reportHandler)
