import { EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { type Path, type Control, type FieldValues } from 'react-hook-form'

interface IBasicAssumptionsEditableProps<TFormData extends FieldValues> {
  formControl: Control<TFormData, unknown>
  downtimeFieldPath: Path<TFormData>
  delayFieldPath: Path<TFormData>
}

export const BasicAssumptionsEditable = <TFormData extends FieldValues>({
  formControl,
  downtimeFieldPath,
  delayFieldPath,
}: IBasicAssumptionsEditableProps<TFormData>): React.ReactElement<IBasicAssumptionsEditableProps<TFormData>> => {
  return (
    <div className="flex gap-6">
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.NaturalNumber}
        className="basis-1/6"
        label="Check downtime (months)"
        formControl={formControl}
        fieldName={downtimeFieldPath}
      />
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.NaturalNumber}
        className="basis-1/6"
        label="Delay in Payment on Claims(months)"
        formControl={formControl}
        fieldName={delayFieldPath}
      />
    </div>
  )
}
