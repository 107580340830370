import { type NavigateFunction, useNavigate } from 'react-router-dom'
import { useReactTable, getCoreRowModel, type ColumnDef, flexRender, type Row } from '@tanstack/react-table'
import { type ProgramDto } from '../../models'
import {
  ActionDialog,
  Button,
  ButtonVariant,
  ButtonWithDialog,
  CellWithButton,
  IconVariant,
  Size,
  TextCellWithLabel,
  ToastVariant,
  UploadInput,
  cn,
  useToast,
} from '@flyward/platform/components'
import { format } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import { isNil } from 'lodash'
import { useImportAirframeKnowledgeBaseMutation } from '@flyward/knowledgeBase/store/api/airframeMaintenancePrograms'
import { useImportAuxiliaryPowerUnitKnowledgeBaseMutation } from '@flyward/knowledgeBase/store/api/auxiliaryPowerUnitMaintenanceProgram'
import { useImportEngineKnowledgeBaseMutation } from '@flyward/knowledgeBase/store/api/engineMaintenancePrograms'
import { useImportLandingGearKnowledgeBaseMutation } from '@flyward/knowledgeBase/store/api/landingGearMaintenancePrograms'
import { AuthorizedElement } from '@flyward/appIdentity/context/AuthorizedRole/AuthorizedElement'
import { ComponentType } from '@flyward/platform/models/enums'
import { formatAxiosErrorMessage, longDateFormat } from '@flyward/platform/helpers'
import { ChangeState } from './ChangeState/ChangeState'

type ComponentTypes = ComponentType.Airframe | ComponentType.EngineUnit | ComponentType.LandingGear | ComponentType.AuxiliaryPowerUnit

const labels: Record<ComponentTypes, { singular: string; plural: string }> = {
  [ComponentType.EngineUnit]: { singular: 'Engine', plural: 'Engines' },
  [ComponentType.Airframe]: { singular: 'Airframe', plural: 'Airframes' },
  [ComponentType.AuxiliaryPowerUnit]: { singular: 'APU', plural: "APU's" },
  [ComponentType.LandingGear]: { singular: 'Landing Gear', plural: 'Landing Gears' },
}

const actionCell = (row: Row<ProgramDto>, onDelete: (maintenanceId: string) => Promise<string | undefined>) => {
  return (
    <AuthorizedElement>
      <ButtonWithDialog
        data-permission-element-id="delete-any-maintenance-program"
        isExpanded={row.getIsExpanded()}
        id={row.original.id}
        name={row.original.maintenanceProgramName}
        onConfirm={onDelete}
        type="Maintenance program"
        dataPermissionId="delete-any-maintenance-program"
        actionLabel="Delete"
        icon={IconVariant.Delete}
      />
    </AuthorizedElement>
  )
}

const getFormattedValue = (getValue: () => unknown) => {
  const value = getValue()
  return value as string
}

const getFormattedDate = (getValue: () => unknown) => {
  const value = getFormattedValue(getValue)
  const date = new Date(value)
  return format(date, longDateFormat)
}

const generateColumns = (
  componentType: ComponentTypes,
  navigate: NavigateFunction,
  detailsPath: string,
  onDelete: (maintenanceId: string) => Promise<string | undefined>,
  onChangeState: (assetId: string) => Promise<string | undefined>,
): Array<ColumnDef<ProgramDto>> => [
  {
    accessorKey: 'maintenanceProgramName',
    header: () => null,
    cell: ({ getValue, row }) => (
      <CellWithButton
        label={`${labels[componentType].singular} Name`}
        info={getFormattedValue(getValue)}
        onClick={() => {
          navigate(`${detailsPath}/${row.original.id}`)
        }}
      />
    ),
    meta: {
      className: 'basis-12/24',
    },
  },
  {
    accessorKey: 'dateOfCreation',
    header: () => null,
    cell: ({ getValue }) => <TextCellWithLabel label="Date" info={getFormattedDate(getValue)} />,
    meta: {
      className: 'basis-9/24',
    },
  },
  {
    accessorKey: 'isActive',
    header: () => null,
    cell: ({ row, getValue }) => <ChangeState maintenanceId={row.original.id} checked={getValue() as boolean} onChangeState={onChangeState} />,
    meta: {
      className: 'basis-2/24',
    },
  },
  {
    accessorKey: 'delete',
    header: () => null,
    cell: ({ row }) => actionCell(row, onDelete),
    meta: {
      className: 'basis-1/24',
    },
  },
]

interface CustomColumnMeta {
  className?: string
}

interface IMaintenanceProgramsGridProps {
  componentType: ComponentTypes
  programs: ProgramDto[]
  detailsRoute: string
  onDelete: (assetId: string) => Promise<string | undefined>
  onChangeState: (assetId: string) => Promise<string | undefined>
}

const MaintenanceProgramsGrid = ({ programs, componentType, detailsRoute, onDelete, onChangeState }: IMaintenanceProgramsGridProps) => {
  const navigate = useNavigate()
  const { toast } = useToast()
  const [file, setFile] = useState<File | null>(null)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [airframeImport] = useImportAirframeKnowledgeBaseMutation()
  const [apuImport] = useImportAuxiliaryPowerUnitKnowledgeBaseMutation()
  const [engineImport] = useImportEngineKnowledgeBaseMutation()
  const [landingGearImport] = useImportLandingGearKnowledgeBaseMutation()

  const columns = useMemo(
    () => generateColumns(componentType, navigate, detailsRoute, onDelete, onChangeState),
    [componentType, navigate, detailsRoute, onDelete, onChangeState],
  )

  const table = useReactTable<ProgramDto>({
    data: programs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  const handleSave = useCallback(async () => {
    switch (componentType) {
      case ComponentType.Airframe: {
        const { error: airframeError } = await airframeImport({ componentType, file: file! })

        if (isNil(airframeError)) {
          toast({
            variant: ToastVariant.Success,
            description: 'Your knowledge program has been successfully imported!',
            icon: IconVariant.Success,
          })
        } else {
          toast({
            variant: ToastVariant.Destructive,
            description: formatAxiosErrorMessage(airframeError?.message),
            icon: IconVariant.Error,
          })
        }
        break
      }
      case ComponentType.EngineUnit: {
        const { error: engineError } = await engineImport({ componentType, file: file! })

        if (isNil(engineError)) {
          toast({
            variant: ToastVariant.Success,
            description: 'Your knowledge program has been successfully imported!',
            icon: IconVariant.Success,
          })
        } else {
          toast({
            variant: ToastVariant.Destructive,
            description: formatAxiosErrorMessage(engineError?.message),
            icon: IconVariant.Error,
          })
        }
        break
      }
      case ComponentType.LandingGear: {
        const { error: landingGearError } = await landingGearImport({ componentType, file: file! })

        if (isNil(landingGearError)) {
          toast({
            variant: ToastVariant.Success,
            description: 'Your knowledge program has been successfully imported!',
            icon: IconVariant.Success,
          })
        } else {
          toast({
            variant: ToastVariant.Destructive,
            description: formatAxiosErrorMessage(landingGearError?.message),
            icon: IconVariant.Error,
          })
        }
        break
      }
      case ComponentType.AuxiliaryPowerUnit: {
        const { error: apuError } = await apuImport({ componentType, file: file! })

        if (isNil(apuError)) {
          toast({
            variant: ToastVariant.Success,
            description: 'Your knowledge program has been successfully imported!',
            icon: IconVariant.Success,
          })
        } else {
          toast({
            variant: ToastVariant.Destructive,
            description: formatAxiosErrorMessage(apuError?.message),
            icon: IconVariant.Error,
          })
        }
        break
      }
      default:
        break
    }
  }, [airframeImport, apuImport, componentType, engineImport, file, landingGearImport])

  const handleSuccess = () => {
    setFile(null)
  }

  const handleCancel = () => {
    setFile(null)
  }

  return (
    <div className="flex h-full flex-grow flex-col px-4">
      <div className="mb-auto flex flex-grow flex-col">
        <div className="flex justify-between py-4 pr-6">
          <div className="basis-20/24">
            <AuthorizedElement>
              <Button
                data-permission-element-id="knowledge-program-import"
                variant={ButtonVariant.Secondary}
                leftIcon={IconVariant.Add}
                label={`Add a new ${labels[componentType].singular}`}
                size={Size.Small}
                onClick={() => {
                  setIsUploadModalOpen(true)
                }}
              />
            </AuthorizedElement>
            <AuthorizedElement>
              <ActionDialog
                data-permission-element-id="knowledge-program-import"
                isValid={!isNil(file)}
                confirmBtnLabel="Upload"
                dialogHeader="Upload file"
                key="upload-file"
                onConfirm={handleSave}
                onCancel={handleCancel}
                onSuccess={handleSuccess}
                isOpen={isUploadModalOpen}
                setIsOpen={setIsUploadModalOpen}
                dialogContent={<UploadInput setFile={setFile} />}
              />
            </AuthorizedElement>
          </div>
        </div>
        <div className="block h-[calc(100vh-20rem)] w-full overflow-y-auto">
          <table className="w-full">
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id} className={cn('flex border-t px-6 py-2', 'items-center border-primary-light-2')}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className={cn(`flex flex-grow flex-row ${!isNil(cell) ? (cell.column.columnDef.meta as CustomColumnMeta)?.className : ''}`)}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <p className="ml-2 mt-2">{`Total ${labels[componentType].plural}: ${programs.length}`}</p>
    </div>
  )
}

export { MaintenanceProgramsGrid }
