import { type IMaintenanceScheduleDisplay } from '@flyward/forecasts/models'
import { type AssetType } from '@flyward/platform/models'
import { ActionDialog, Button, ButtonVariant, IconVariant, type IYearMonth } from '@flyward/platform/components'
import { type Row } from '@tanstack/react-table'
import { useState } from 'react'
import { SetManualEvent } from './SetManualEvent'

const ManualEventWizard = (
  assetType: AssetType,
  reportItemId: string,
  index: number,
  assetSchedules: IMaintenanceScheduleDisplay[],
  isEditable: boolean,
  startYearMonth: IYearMonth,
  endYearMonth: IYearMonth,
  row: Row<IMaintenanceScheduleDisplay>,
) => {
  const editedMasterComponentId = row.original.forecastedCheck.masterComponentId ?? row.original.forecastedCheck.componentId
  const currentCheckType = row.original.forecastedCheck.checkType
  const originalYearMonth = row.original.yearMonth as IYearMonth

  const [isEditClicked, setIsEditClicked] = useState<boolean>(false)
  const [userConfirmedRisk, setUserConfirmedRisk] = useState<boolean>(false)

  return (
    <>
      <>
        {isEditable && (
          <Button
            variant={ButtonVariant.Ghost}
            leftIcon={IconVariant.Edit}
            onClick={() => {
              setIsEditClicked(true)
            }}
          />
        )}
      </>

      {!userConfirmedRisk && (
        <ActionDialog
          isValid={true}
          confirmBtnLabel="Continue"
          key={`confirm-risk-${editedMasterComponentId}-${currentCheckType}-${originalYearMonth.year}-${originalYearMonth.month}`}
          onConfirm={async () => {
            setUserConfirmedRisk(true)
          }}
          isOpen={isEditClicked}
          setIsOpen={setIsEditClicked}
          onCancel={() => {
            setUserConfirmedRisk(false)
          }}
          dialogContent={
            <p className="font-semibold">
              If you make changes, they will affect the entire process.
              <br />
              Are you sure you want to continue?
            </p>
          }
        />
      )}

      {userConfirmedRisk && (
        <SetManualEvent
          assetType={assetType}
          reportItemId={reportItemId}
          index={index}
          assetSchedules={assetSchedules}
          startYearMonth={startYearMonth}
          endYearMonth={endYearMonth}
          row={row}
          isEditConfirm={userConfirmedRisk}
          setIsEditConfirm={setUserConfirmedRisk}
        />
      )}
    </>
  )
}

export { ManualEventWizard }
