export enum FlyToType {
  Redelivery = 1,
  Custom = 2,
}

export const getFlyToTypeDisplayName = (flyToType: FlyToType): string => {
  switch (flyToType) {
    case FlyToType.Redelivery:
      return 'Redelivery Date'
    case FlyToType.Custom:
      return 'Custom Date'
  }
}
