interface IMaintenanceProgramDetailsAreaProps {
  children?: React.ReactNode
  title: string
}

export const MaintenanceProgramDetailsArea = ({
  children,
  title,
}: IMaintenanceProgramDetailsAreaProps): React.ReactElement<IMaintenanceProgramDetailsAreaProps> => {
  return (
    <div className="mb-4 bg-black-0">
      <h1 className="bg-primary-dark-2 px-4 py-2 text-text-4">{title}</h1>
      <div className="flex h-full flex-col gap-y-6 p-6">{children}</div>
    </div>
  )
}
