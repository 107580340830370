import { useMemo } from 'react'
import { generateColumnsForSubComponents, getCommonPinningStyles } from '../customStatisticsColumns'
import { flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table'
import { cn, type MonthlyStatistics, type ComponentMonthlyStatistics, Tooltip } from '@flyward/platform'

interface ICustomStatisticsExpandedTable {
  data: ComponentMonthlyStatistics[]
  propertyName: keyof MonthlyStatistics
}

export const CustomStatisticsExpandedTable = ({ data, propertyName: columnName }: ICustomStatisticsExpandedTable) => {
  const columns = useMemo(() => {
    if (data.length === 0) {
      return []
    }
    return generateColumnsForSubComponents(columnName, data[0])
  }, [columnName, data])

  const table = useReactTable<ComponentMonthlyStatistics>({
    data,
    columns,
    initialState: {
      columnPinning: { left: ['forecastedCheck-componentModel'] },
    },
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="m-0 block w-full bg-background-row-table p-0">
      <table className="m-0 w-full p-0">
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={`${row.original.forecastedCheck.componentId}`} className="gap-x-10 text-xs">
                {row.getVisibleCells().map((cell, index) => {
                  const { column } = cell

                  return (
                    <td
                      key={cell.id}
                      className={cn(
                        'items-center border-b border-black-10',
                        'bg-background-row-table pl-4 pr-2',
                        'overflow-hidden text-ellipsis whitespace-nowrap',
                        `${
                          index === 0
                            ? 'sticky left-0 z-10 w-40 max-w-40 text-right font-semibold text-text-1'
                            : 'w-32 max-w-32 justify-start text-right text-text-2'
                        }`,
                      )}
                      style={{ ...getCommonPinningStyles(column) }}
                    >
                      {index === 0 ? (
                        <Tooltip
                          content={
                            <p className="h-4 overflow-hidden text-ellipsis whitespace-nowrap text-nowrap text-right">
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </p>
                          }
                          tooltipContent={flexRender(cell.column.columnDef.cell, cell.getContext())}
                        />
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
