/* eslint-disable max-len */
import * as React from 'react'
import { type IIconProps } from '../IIconProps'
import { IconVariant } from '../IconVariant'

export const ExcelExtract = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg className={className} data-variant={IconVariant[variant]} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5274 2.00828L2.32507 4.13373C2.13597 4.16967 2 4.34158 2 4.53381V19.462C2 19.6542 2.13597 19.8261 2.32507 19.862L13.5274 21.9875C13.5524 21.9922 13.5774 22 13.6024 22C13.6947 22 13.7806 21.9719 13.8525 21.9125C13.9447 21.8359 14.0025 21.7187 14.0025 21.5999V2.39586C14.0025 2.27709 13.9447 2.15987 13.8525 2.08329C13.7603 2.00672 13.6446 1.9864 13.5274 2.00828ZM14.8027 4.39628V7.19687H15.6029V7.99704H14.8027V9.99747H15.6029V10.7976H14.8027V12.7981H15.6029V13.5982H14.8027V15.9987H15.6029V16.7989H14.8027V19.5995H20.804C21.2447 19.5995 21.6041 19.24 21.6041 18.7993V5.19645C21.6041 4.75573 21.2447 4.39628 20.804 4.39628H14.8027ZM16.403 7.19687H19.6037V7.99704H16.403V7.19687ZM4.67556 8.2721H6.726L7.80122 10.5101C7.88562 10.6867 7.96063 10.9039 8.02627 11.1477H8.03877C8.08097 11.0024 8.16224 10.7742 8.27633 10.4851L9.46408 8.2721H11.3395L9.1015 11.9729L11.402 15.7487H9.41407L8.11379 13.3107C8.06534 13.22 8.01533 13.0528 7.96376 12.8106H7.95126C7.92625 12.9246 7.86686 13.1028 7.77622 13.3357L6.47594 15.7487H4.47552L6.86353 12.0104L4.67556 8.2721ZM16.403 9.99747H19.6037V10.7976H16.403V9.99747ZM16.403 12.7981H19.6037V13.5982H16.403V12.7981ZM16.403 15.9987H19.6037V16.7989H16.403V15.9987Z"
        fill="#00838F"
      />
    </svg>
  )
}
