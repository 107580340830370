/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useAssetComponentsForms } from '@flyward/assets/context/AssetComponentsFormsContext'
import { AircraftDetailsDtoSchema, type AircraftDetailsDto } from '../../../../models/DTOs/aircraftDetailsDto'
import { AircraftReadonlyDetails } from './AircraftReadonlyDetails'
import { AircraftEditableDetails } from './AircraftEditableDetails'
import { CnForm, IconVariant, ToastVariant, useToast } from '@flyward/platform/components'
import { useUpdateAircraftDetailsMutation } from '@flyward/assets/store'
import { useEffect, useRef } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { isNil } from 'lodash'
import { formatAxiosErrorMessage } from '@flyward/platform'

interface IAircraftDetailsProps {
  assetId: string
  aircraft: AircraftDetailsDto
}

export const AircraftDetails = ({ assetId, aircraft }: IAircraftDetailsProps) => {
  const { isInEditMode, registerFormRef, setHasErrors } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)

  const { toast } = useToast()

  const [updateAircraft] = useUpdateAircraftDetailsMutation()

  const form = useForm<AircraftDetailsDto>({
    defaultValues: aircraft,
    values: aircraft,
    resolver: zodResolver(AircraftDetailsDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleAircraftUpdate,
    formState,
    getValues: getAircraftFormValues,
    setValue: setAircraftFormValue,
    control: aircraftFormControl,
    trigger: triggerAircraftValidation,
  } = form

  const aircraftFormValues: AircraftDetailsDto = getAircraftFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('Aircraft Details formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerAircraftValidation()
  }, [isValid, triggerAircraftValidation])

  useEffect(() => {
    registerFormRef({ id: [assetId, 'aircraftDetails'], submitRef, hasChanges: isDirty, isValid })
  }, [registerFormRef, aircraftFormValues, isDirty, isValid, assetId])

  const onUpdateAircraft: SubmitHandler<AircraftDetailsDto> = async (data) => {
    const result = await updateAircraft({ assetId, aircraft: data })
    if (!isNil(result.error)) {
      setHasErrors(true)
      toast({
        variant: ToastVariant.Destructive,
        description: formatAxiosErrorMessage(result.error?.message),
        icon: IconVariant.Error,
      })
    } else {
      setHasErrors(false)
      toast({
        variant: ToastVariant.Success,
        description: 'Aircraft updated successfully',
        icon: IconVariant.Success,
      })
    }
  }

  return (
    <CnForm {...form}>
      <form onSubmit={handleAircraftUpdate(onUpdateAircraft)}>
        {isInEditMode ? (
          <AircraftEditableDetails aircraft={aircraft} formControl={aircraftFormControl} setFormValue={setAircraftFormValue} />
        ) : (
          <AircraftReadonlyDetails aircraft={aircraft} />
        )}
        <input type="submit" className="hidden" ref={submitRef} />
      </form>
    </CnForm>
  )
}
