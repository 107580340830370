/* eslint-disable max-len */
import * as React from 'react'
import { type IIconProps } from '../IIconProps'
import { IconVariant } from '../IconVariant'

export const ToggleOn = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg
      className={className}
      data-variant={IconVariant[variant]}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5.80728 9.84431L8.17794 12.215L14.1984 6.2002L15 7.00179L8.17794 13.8238L5 10.6459L5.80728 9.84431Z"
      />
    </svg>
  )
}
