import { validateNullableDate, validateNullableNaturalNumber, validateRequiredDate, validateRequiredString } from '@flyward/platform/helpers'
import { type EnvironmentalImpactType } from '@flyward/platform/models/enums'
import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Asset.AircraftDetailsDto
 */
export interface AircraftDetailsDto {
  manufacturer?: string
  aircraftType: string
  serialNumber: string
  registrationNo: string

  engineModel: string
  engineThrustRating?: number
  dom: string
  leaseStartDate?: string
  leaseEndDate?: string
  lessee: string
  lesseeHabitualBase: string

  operatingEnvironment: EnvironmentalImpactType
  portfolio: string
  region: string
  dateOfLastTechSpec: string
  averageFhFcRatio: number
  installedEngineSerialNumbers: string[]
}

export const AircraftDetailsDtoSchema = z.object({
  manufacturer: z.string().optional().nullable(),
  aircraftType: z.string().optional().nullable(),
  serialNumber: validateRequiredString('Serial Number'),
  registrationNo: z.string().optional().nullable(),
  engineModel: z.string().optional().nullable(),
  engineThrustRating: validateNullableNaturalNumber,
  dom: validateRequiredDate('DOM'),
  leaseStartDate: validateNullableDate,
  leaseEndDate: validateNullableDate,
  lessee: z.string().optional().nullable(),
  lesseeHabitualBase: z.string().optional().nullable(),

  operatingEnvironment: z.any().optional().nullable(),
  portfolio: z.string().optional().nullable(),
  region: z.string().optional().nullable(),
  dateOfLastTechSpec: validateRequiredDate('Date of Last Tech Spec'),
  averageFhFcRatio: z.number().optional().nullable(),
  installedEngineSerialNumbers: z.array(z.string()).optional().nullable(),
})
