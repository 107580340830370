import React, { type InputHTMLAttributes, useEffect, useRef } from 'react'

interface IndeterminateCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean
}

const IntermediateCheckbox: React.FC<IndeterminateCheckboxProps> = ({ indeterminate, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref.current !== null && rest.checked != null) {
      ref.current.indeterminate = Boolean(!rest.checked && indeterminate)
    }
  }, [indeterminate, rest.checked])

  return <input type="checkbox" ref={ref} {...rest} />
}

export { IntermediateCheckbox }
