import {
  NumberDisplay,
  type UtilizationUnits,
  UtilizationUnitsDisplay,
  type TableColumn,
  NumberDisplayType,
  TableRow,
  TableCell,
} from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type llpCheckDto, type EngineProgramDto } from '../../../../models'
import { cloneDeep } from 'lodash'

interface EngineLlpCheckReadOnlyDisplay {
  programId: string
  componentModel?: string
  componentModule?: string
  unit?: UtilizationUnits
  limit?: number
  cost?: number
  costPerCycle?: number
}

interface IEngineLifeLimitedPartsTableProps {
  llpChecks: llpCheckDto[] | undefined
  engineProgram: EngineProgramDto | undefined
  tableClassName?: string
}

const llpTableColumns: Array<TableColumn<EngineLlpCheckReadOnlyDisplay>> = [
  {
    accessor: 'programId',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'componentModel',
    title: 'Description',
    cellClassName: 'basis-2/9',
    headerClassName: 'basis-2/9',
  },
  {
    accessor: 'componentModule',
    title: 'Module',
    cellClassName: 'basis-2/9',
    headerClassName: 'basis-2/9',
  },
  {
    accessor: 'unit',
    title: 'Unit',
    valueGetter: (value) => UtilizationUnitsDisplay(value as UtilizationUnits),
    cellClassName: 'basis-2/9',
    headerClassName: 'basis-2/9',
  },
  {
    accessor: 'limit',
    title: 'Limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
    cellClassName: 'basis-1/9',
    headerClassName: 'basis-1/9',
  },
  {
    accessor: 'cost',
    title: 'CLP',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={value} className="truncate" />,
    cellClassName: 'basis-1/9',
    headerClassName: 'basis-1/9',
  },
  {
    accessor: 'costPerCycle',
    title: 'Cost/Cycle',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.Currency} currencyDecimals={2} value={value} className="truncate" />,
    cellClassName: 'basis-1/9',
    headerClassName: 'basis-1/9',
  },
]

export const EngineLifeLimitedPartsReadOnlyTable = ({
  llpChecks,
  engineProgram,
  tableClassName = '',
}: IEngineLifeLimitedPartsTableProps): React.ReactElement<IEngineLifeLimitedPartsTableProps> => {
  const llpTableData: EngineLlpCheckReadOnlyDisplay[] | undefined =
    llpChecks !== undefined
      ? cloneDeep(llpChecks).map((t) => ({
          programId: t.id,
          componentModel: t.componentModel,
          componentModule: t.componentModule,
          unit: t?.utilizationUnit,
          limit: t?.matureLimit,
          cost: t?.baseCost,
          costPerCycle: t?.costPerCycle,
        }))
      : undefined

  const totalLLPsCost = engineProgram?.totalLLPsCost
  const totalLLPsCostPerCycle = engineProgram?.totalLLPsCostPerCycle

  const summaryRow = (
    <TableRow className="flex font-semibold">
      <TableCell className="text-1 basis-2/9 border border-black-20 bg-primary-dark-3 p-px">Total</TableCell>
      <TableCell className="basis-5/9 border border-black-20 bg-primary-dark-3 p-px text-text-1"></TableCell>
      <TableCell className="basis-1/9 border border-black-20 bg-primary-dark-3 p-px text-text-1">
        <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={totalLLPsCost} />
      </TableCell>
      <TableCell className="basis-1/9 border border-black-20 bg-primary-dark-3 p-px text-text-1">
        <NumberDisplay displayType={NumberDisplayType.Currency} value={totalLLPsCostPerCycle} />
      </TableCell>
    </TableRow>
  )

  return <MaintenanceProgramDetailsTable columns={llpTableColumns} data={llpTableData} summaryRow={summaryRow} tableClassName={tableClassName} />
}
