import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface SignalRConnectionsState {
  receiveDemoForecastMessageSignalRConnectionId: string | null
}

const signalRSlice = createSlice({
  name: 'signalR',
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  initialState: { receiveDemoForecastMessageSignalRConnectionId: null } as SignalRConnectionsState,
  reducers: {
    clearSignalRConnections: () => ({ receiveDemoForecastMessageSignalRConnectionId: null }),
    setReceiveDemoForecastMessageSignalRConnectionId: (state, { payload: { connectionId } }: PayloadAction<{ connectionId: string | null }>) => {
      state.receiveDemoForecastMessageSignalRConnectionId = connectionId
    },
  },
})

export const { clearSignalRConnections, setReceiveDemoForecastMessageSignalRConnectionId } = signalRSlice.actions

export default signalRSlice.reducer
