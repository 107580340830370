import { validateNullableNaturalNumber } from '@flyward/platform/helpers'
import { type UtilizationUnits, type MinimumReDeliveryConditionType } from '../enums'
import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Airframe.AirframeContractDto.ComponentMinimumReDeliveryConditionDto
 */
export interface ComponentMinimumReDeliveryConditionDto {
  minimumReDeliveryConditionType: MinimumReDeliveryConditionType
  minimumReDeliveryConditionUnit: UtilizationUnits
  value: number
}

export const ComponentMinimumReDeliveryConditionDtoSchema = z.object({
  minimumReDeliveryConditionType: z.any().optional().nullable(),
  maintenanceReserveCollectionType: z.any().optional().nullable(),
  minimumReDeliveryConditionUnit: validateNullableNaturalNumber,
  value: validateNullableNaturalNumber,
})
