/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { type TRootState } from '@flyward/platform/store/configureReducers'
import { isNil } from 'lodash'

const selectFleetSearchInput = (state: TRootState) => state.fleet.fleetFilters

const selectSelectedAssetIds = (state: TRootState) => state.fleet.selectedAssetIds

const selectHasActiveFilters = (state: TRootState) =>
  !isNil(state.fleet.fleetFilters.filterData) && Object.values(state.fleet.fleetFilters.filterData).some((selection) => selection.length > 0)

export { selectFleetSearchInput, selectHasActiveFilters, selectSelectedAssetIds }
