import { ZitadelAuthenticatedProvider } from '@flyward/appIdentity'
import { UserAuthorizationProvider } from '@flyward/appIdentity/context/UserAuthorization/UserAuthorizationContext'
import { UserInformation } from './UserInformation'

export const UserInformationWrapper = () => {
  return (
    <ZitadelAuthenticatedProvider>
      <UserAuthorizationProvider>
        <UserInformation />
      </UserAuthorizationProvider>
    </ZitadelAuthenticatedProvider>
  )
}
