import { cn } from '../utils'

interface IComponentContainerProps {
  children?: React.ReactNode
  className?: string
}

export const ComponentContainer = ({ children, className }: IComponentContainerProps): React.ReactElement<IComponentContainerProps> => {
  return (
    <div data-testid="component-container" className={cn('flex flex-col', 'items-center justify-center', 'gap-10 rounded-lg bg-black-0', className)}>
      {children}
    </div>
  )
}
