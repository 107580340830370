import { PageContainer } from '@flyward/main-app/layout'
import { GenerateError } from './GenerateError'
import { ErrorBoundary } from '@flyward/platform'

export const TestPage = () => {
  return (
    <PageContainer>
      <div className="bg-gray-400 p-10">
        Un-Contained Error:
        <GenerateError />
        <p className="mt-2">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id ipsum cumque reiciendis quam quae necessitatibus sapiente, saepe tenetur
          distinctio dolores, similique reprehenderit ex adipisci expedita voluptate, corporis veniam ut cum!
        </p>
        <div className="bg-gray-400 p-10">
          Contained error:
          <div className="bg-gray-200 p-10">
            <ErrorBoundary>
              <GenerateError />
              <div className="bg-gray-100 p-10">
                Sub level:
                <ErrorBoundary>
                  <GenerateError />
                </ErrorBoundary>
              </div>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}
