import { type Cell, flexRender, type Row, type Table } from '@tanstack/react-table'
import { type ExpandedRowFields } from '../types'

interface IAircraftExpandedDataGridBodyProps {
  table: Table<ExpandedRowFields>
}

const AircraftExpandedDataGridBody = ({ table }: IAircraftExpandedDataGridBodyProps) => {
  return (
    <tbody>
      {table.getRowModel().rows.map((row: Row<ExpandedRowFields>) => {
        return (
          <tr key={row.id} className="gap-x-10 px-4 py-2">
            {row.getVisibleCells().map((cell: Cell<ExpandedRowFields, unknown>) => {
              return (
                <td key={cell.id} className="border border-black-10">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

export { AircraftExpandedDataGridBody }
