import { differenceInMonths, differenceInYears } from 'date-fns'
import { isNil, isEmpty } from 'lodash'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const round = (value: number, precision: number = Number.NaN): number => {
  const multiplier = Math.pow(10, !Number.isNaN(precision) ? precision : 0)
  return Math.round(value * multiplier) / multiplier
}

export const getAssetAge = (value: string) => {
  if (isEmpty(value)) {
    return 'N/A'
  }
  const domDate = new Date(value)
  return getAssetAgeFromDate(domDate)
}

export const getAssetAgeFromDate = (domDate: Date) => {
  const currentDate = new Date()
  const ageInYears = differenceInYears(currentDate, domDate)
  const ageInMonths = differenceInMonths(currentDate, domDate)
  const age = ageInYears + (ageInMonths % 12) / 12
  return age.toFixed(1)
}

export const formatDate = (value: string | null | undefined) => {
  if (isNil(value) || isEmpty(value)) {
    return '-'
  }
  const date = new Date(value)
  return formatDateFromDate(date)
}

export const formatDateFromDate = (value: Date | undefined) => {
  if (value === undefined) return '-'
  const year = value.getFullYear()
  const month = months[value.getMonth()]
  const day = value.getDate()
  return `${day}-${month}-${year}`
}

export const formatBoolean = (value: boolean | undefined | null) => {
  if (isNil(value)) return '-'
  return value ? 'Yes' : 'No'
}

export const formatNullable = (value: string | number | undefined | null) => {
  if (typeof value === 'string' && isEmpty(value)) return '-'
  if (isNil(value)) return '-'
  return value
}

export const formatNumber = (value: number | undefined | null) => {
  if (isNil(value)) return '-'
  return value.toLocaleString('en')
}
