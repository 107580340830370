/* eslint-disable max-len */
import * as React from 'react'
import { IconVariant } from '../IconVariant'
import { type IIconProps } from '../IIconProps'

export const AccountCircle = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg className={className} data-variant={IconVariant[variant]} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 3C7.032 3 3 7.032 3 12C3 16.968 7.032 21 12 21C16.968 21 21 16.968 21 12C21 7.032 16.968 3 12 3ZM12 5.7C13.494 5.7 14.7 6.906 14.7 8.4C14.7 9.894 13.494 11.1 12 11.1C10.506 11.1 9.3 9.894 9.3 8.4C9.3 6.906 10.506 5.7 12 5.7ZM12 18.48C9.75 18.48 7.761 17.328 6.6 15.582C6.627 13.791 10.2 12.81 12 12.81C13.791 12.81 17.373 13.791 17.4 15.582C16.239 17.328 14.25 18.48 12 18.48Z" />
    </svg>
  )
}
