/* eslint-disable max-len */
import * as React from 'react'
import { type IIconProps } from '../IIconProps'
import { IconVariant } from '../IconVariant'

export const PlaneZoomOut = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg
      className={className}
      data-variant={IconVariant[variant]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.41667 8L13.0833 10.25L17.9596 8.4305C18.4291 8.25531 18.9188 8.14012 19.4172 8.08766C19.9697 8.02951 20.5276 8.04898 21.0746 8.14552L21.6667 8.25L21.7546 8.26759C22.2181 8.3603 22.6009 8.6858 22.7669 9.12841C22.8967 9.47463 22.7278 9.86135 22.3856 10.0014L15.6667 12.75L17.1667 15.8333L16.5 16.5833L12.75 13.8333L5.5 15.25C5.68617 15.6689 5.20125 16.0662 4.82718 15.8012L4.16667 15.3333H3.10797C2.72304 15.3333 2.47973 14.9198 2.66667 14.5833L1 13.75L1.91667 13.5833L3.33333 13.9167L3.16667 11.3333L3.91667 10.9167L5.75 13.0833L8.91667 11.8333L1.91667 8.16667L4.41667 8Z" />
    </svg>
  )
}
