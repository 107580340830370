import { type CheckTyeRateDTO } from '@flyward/platform/models/DTOs/CheckTyeRateDTO'
import { ComponentTypeDisplay, type CheckType } from '@flyward/platform/models'
import { cloneDeep } from 'lodash'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { EditMRRates } from './MRRates/EditMRrates'

interface IRatesParametersProps {
  flyForwardParameters: FlyForwardParametersDTO
  handleRateChange: (
    originalFlyForwardParameters: FlyForwardParametersDTO,
    masterComponentSerialNumber: string,
    rateType: 'mrRates' | 'eolRates',
    checkType: CheckType,
    field: keyof CheckTyeRateDTO,
    value: number,
  ) => void
  handleRateActive: (
    originalFlyForwardParameters: FlyForwardParametersDTO,
    masterComponentSerialNumber: string,
    activeType: 'eolIsActive' | 'mrIsActive',
    value: boolean,
  ) => void
}

const EditableRatesParameters = ({ flyForwardParameters, handleRateChange, handleRateActive }: IRatesParametersProps) => {
  const sortedMasterComponentsRates = cloneDeep(flyForwardParameters.masterComponentsRates).sort((a, b) => {
    if (a.componentType < b.componentType) return -1
    if (a.componentType > b.componentType) return 1

    if (a.masterComponentSerialNumber < b.masterComponentSerialNumber) return -1
    if (a.masterComponentSerialNumber > b.masterComponentSerialNumber) return 1
    return 0
  })

  return (
    <div className="flex flex-col pt-4">
      {sortedMasterComponentsRates.map((masterComponentRate) => (
        <div key={masterComponentRate.masterComponentId + masterComponentRate.masterComponentSerialNumber} className="flex items-center gap-x-6 py-4">
          <div>
            <p className="pb-2 text-base font-semibold text-gray-700">
              {ComponentTypeDisplay(masterComponentRate.componentType)} {masterComponentRate.masterComponentSerialNumber}
            </p>
            <div className="flex gap-x-6">
              <EditMRRates
                masterComponentRate={masterComponentRate}
                flyForwardParameters={flyForwardParameters}
                handleRateChange={handleRateChange}
                handleRateActive={handleRateActive}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export { EditableRatesParameters }
