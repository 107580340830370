import { type AuxiliaryPowerUnitMinimumReDeliveryCondition, type ApuDto } from '@flyward/assets/models'
import {
  BooleanCellWithLabel,
  CheckTypeDisplay,
  formatNumber,
  MaintenanceReserveCollectionTypeDisplay,
  MinimumReDeliveryConditionTypeDisplay,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'

interface IAPUContractualReadOnlyTabProps {
  component: ApuDto
}

export const APUContractualReadOnlyTab = ({ component }: IAPUContractualReadOnlyTabProps) => {
  const maintenanceReserve = component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract

  const rate = maintenanceReserve.auxiliaryPowerUnitPRMaintenanceReserveRate
  const minimumRedeliveryConditions = component.auxiliaryPowerUnitContract.minimumReDeliveryConditions
  const escalations = component.auxiliaryPowerUnitContract.escalations
  const contractDeliverySnapshot = component.auxiliaryPowerUnitContract.contractDeliverySnapshot

  const minimumRedeliveryConditionsDisplay: string = minimumRedeliveryConditions
    ?.map((minimumRedeliveryCondition: AuxiliaryPowerUnitMinimumReDeliveryCondition) => {
      return `${MinimumReDeliveryConditionTypeDisplay(
        minimumRedeliveryCondition.minimumReDeliveryConditionType,
      )}\n${formatNumber(minimumRedeliveryCondition.value)} ${UtilizationUnitsDisplay(minimumRedeliveryCondition.minimumReDeliveryConditionUnit)}`
    })
    .join('\n')
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-x-6 gap-y-2">
        <div className="flex w-full gap-x-6 gap-y-2" key={rate?.checkType}>
          <BooleanCellWithLabel
            className="basis-1/6"
            label="MR active"
            info={component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive}
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate?.checkType)} MR rate`}
            info={rate?.rateAmount}
            preventInfo={
              !component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive &&
              !component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isEolActive
            }
            currencyDecimals={2}
            displayType={NumberDisplayType.Currency}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate?.checkType)} rate type`}
            info={UtilizationUnitsDisplay(rate?.utilizationUnit)}
            preventInfo={
              !component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive &&
              !component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isEolActive
            }
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} rate year`}
            info={rate.ratesYear}
            displayType={NumberDisplayType.Year}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate?.checkType)} MR collection type`}
            info={MaintenanceReserveCollectionTypeDisplay(rate?.maintenanceReserveCollectionType)}
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label={`Current ${CheckTypeDisplay(rate?.checkType)} MR fund`}
            info={rate?.currentFund}
            preventInfo={
              !component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive &&
              !component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isEolActive
            }
            displayType={NumberDisplayType.CurrencyRounded}
          />
        </div>
      </div>
      <div className="flex w-full gap-x-6 gap-y-2">
        <BooleanCellWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          info={component.auxiliaryPowerUnitContract?.auxiliaryPowerUnitMaintenanceReserveContract?.isEolActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="PR MR Escalation"
          info={escalations?.maintenanceReserveAgreedEscalationPercentage}
          preventInfo={
            !component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive &&
            !component.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isEolActive
          }
          displayType={NumberDisplayType.Percentage}
        />
        <NumberCellWithLabel
          key={contractDeliverySnapshot.checkType}
          className="basis-1/6"
          label={`Hours since ${CheckTypeDisplay(contractDeliverySnapshot.checkType)} at contract delivery`}
          info={contractDeliverySnapshot.hoursSinceEventAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <div className="mr-12 basis-3/6" />
      </div>
      <div className="flex gap-x-6">
        <TextCellWithLabel className="basis-1/6 whitespace-pre" label="Redelivery Conditions" info={minimumRedeliveryConditionsDisplay} />
        <div className="mr-10 basis-3/6" />
      </div>
    </div>
  )
}
