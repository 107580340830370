import { useUserAuthenticated } from '../UserAuthorization'
import React, { useEffect, useState } from 'react'
import { isElementAvailable } from './AuthorizedElementUtils'

interface IAuthorizedElementProps {
  children: React.ReactNode
}

export const AuthorizedElement = ({ children }: IAuthorizedElementProps) => {
  const { loggedUser } = useUserAuthenticated()
  const [dataId, setDataId] = useState<string>('')

  useEffect(() => {
    React.Children.toArray(children).forEach((child) => {
      if (React.isValidElement(child)) {
        const dataId = String(child.props['data-permission-element-id'])
        setDataId(dataId)
      }
    })
  }, [children, setDataId])

  return <>{isElementAvailable(loggedUser, dataId) && children}</>
}
