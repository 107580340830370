import {
  cn,
  type ComponentMinimumReDeliveryConditionDto,
  EditableInputCellWithLabelWithLabel,
  EditableSelectCellWithLabel,
  InputType,
  isArrayEmptyOrNull,
  MinimumReDeliveryConditionTypeDisplay,
  TextCellWithLabel,
  UtilizationUnitsOptions,
} from '@flyward/platform'
import { Fragment } from 'react'
import { cloneDeep } from 'lodash'
import { type Control, type Path, type FieldValues } from 'react-hook-form'

interface IRedeliveryConditionsEditableProps<TFormData extends FieldValues> {
  label: string
  conditions: ComponentMinimumReDeliveryConditionDto[]
  formControl: Control<TFormData, unknown>
  fieldPath: Path<TFormData>
  className?: string
}

export const RedeliveryConditionsEditable = <TFormData extends FieldValues>({
  label,
  conditions,
  formControl,
  fieldPath,
  className = '',
}: IRedeliveryConditionsEditableProps<TFormData>): React.ReactElement<IRedeliveryConditionsEditableProps<TFormData>> => {
  if (isArrayEmptyOrNull(conditions)) {
    return (
      <Fragment>
        <TextCellWithLabel className="basis-2/6" label={`Redelivery Conditions`} />
      </Fragment>
    )
  }

  cloneDeep(conditions).sort((a, b) => Number(a.minimumReDeliveryConditionUnit) - Number(b.minimumReDeliveryConditionUnit))

  const conditionsElements = conditions.map((condition: ComponentMinimumReDeliveryConditionDto, index) => {
    const valuePath: Path<TFormData> = `${fieldPath}.${index}.value` as Path<TFormData>
    const utilisationUnitPath: Path<TFormData> = `${fieldPath}.${index}.minimumReDeliveryConditionUnit` as Path<TFormData>
    return (
      <div className="flex items-center justify-start" key={`${index} - ${condition?.minimumReDeliveryConditionUnit}`}>
        <TextCellWithLabel
          className="basis-2/6 whitespace-pre"
          info={MinimumReDeliveryConditionTypeDisplay(condition.minimumReDeliveryConditionType)}
        />
        <EditableInputCellWithLabelWithLabel
          className="min-w-24 basis-2/6 whitespace-pre"
          inputType={InputType.NaturalNumber}
          formControl={formControl}
          fieldName={valuePath}
        />
        <EditableSelectCellWithLabel
          className="min-w-24 basis-2/6 whitespace-pre"
          options={UtilizationUnitsOptions}
          formControl={formControl}
          fieldName={utilisationUnitPath}
        />
      </div>
    )
  })

  return (
    <div className={cn(`basis-2/6 ${className}`)}>
      <em className={cn('block text-xs font-semibold capitalize not-italic text-text-3')}>{label}</em>
      {conditionsElements}
    </div>
  )
}
