import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAircraftDetailsQueryResponse } from './types'

const aircraftsApiMocks = [
  http.get(APIRoutes.AssetsModule.AircraftsController.GetDetails('*'), () => {
    return HttpResponse.json<IGetAircraftDetailsQueryResponse>(
      {
        registrationNo: 'EC-NBB',
        installedEngineSerialNumbers: ['V1234', 'V5678'],
        engineModel: 'V2527-A5',
        engineThrustRating: 27000,
        manufacturer: 'Airbus',
        dom: '2011-03-22',
        aircraftType: 'A320-200',
        serialNumber: '1111',
        portfolio: 'Tiger',
        region: 'EMEA',
        dateOfLastTechSpec: '2023-03-01',
        leaseStartDate: '2021-06-11',
        leaseEndDate: '2028-06-10',
        lessee: 'Air FlyTest',
        lesseeHabitualBase: 'Spain',
        operatingEnvironment: 1,
        averageFhFcRatio: 1.8,
      },
      { status: 200 },
    )
  }),
]

export { aircraftsApiMocks }
