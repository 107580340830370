import { type AirframeDto } from '@flyward/assets/models'
import { useAssetComponentsForms } from '@flyward/assets/context/AssetComponentsFormsContext'
import { AirframeTechnicalReadOnlyTab } from './AirframeTechnicalReadOnlyTab'
import { AirframeTechnicalEditableTab } from './AirframeTechnicalEditableTab'
import { type Control, type FormState, type UseFormSetValue, type UseFormWatch } from 'react-hook-form'

interface IAirframeTechnicalTabProps {
  airframe: AirframeDto
  formControl: Control<AirframeDto, unknown>
  formState: FormState<AirframeDto>
  watchFields: UseFormWatch<AirframeDto>
  setFormValue: UseFormSetValue<AirframeDto>
}

export const AirframeTechnicalTab = ({ airframe, formControl, formState, watchFields, setFormValue }: IAirframeTechnicalTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  if (isInEditMode) {
    return (
      <AirframeTechnicalEditableTab
        airframe={airframe}
        formControl={formControl}
        formState={formState}
        watchFields={watchFields}
        setFormValue={setFormValue}
      />
    )
  } else {
    return <AirframeTechnicalReadOnlyTab airframe={airframe} />
  }
}
