import React from 'react'
import { cn } from '../../utils'

export const SimpleCell = ({
  children,
  className,
}: {
  children: string | number | undefined | React.ReactNode
  className?: string
}) => <p className={cn('p-2 text-xs text-text-2', className)}>{children}</p>
