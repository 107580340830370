import { cn } from '@flyward/platform/components/utils'
import { formatDate } from '@flyward/platform/utils'

interface IDateCellWithLabelProps {
  label: string
  className?: string
  info?: string
  infoClassName?: string
  labelClassName?: string
  preventInfo?: boolean
}

const DateCellWithLabel = ({
  label,
  className = '',
  info = undefined,
  infoClassName = '',
  labelClassName = '',
  preventInfo = false,
}: IDateCellWithLabelProps) => {
  return (
    <div className={(cn('flex flex-col justify-center gap-y-1'), className)}>
      <em className={cn('block text-xs font-semibold capitalize not-italic text-text-3', labelClassName)}>{label}</em>
      <span className={cn('block text-sm text-text-1', infoClassName)}>{!preventInfo ? formatDate(info) : 'N/A'}</span>
    </div>
  )
}

export { DateCellWithLabel }
export type { IDateCellWithLabelProps }
