/* eslint-disable @typescript-eslint/no-misused-promises */
import { CnForm, ComponentContainer, ComponentTabs, ErrorBoundary } from '@flyward/platform'
import { ApuDtoSchema, type ApuDto } from '../../../../../models'
import { APUContractualTab } from './APUContractualTab'
import { APUTechnicalTab } from './APUTechnicalTab'
import { useEffect, useRef, useState } from 'react'
import { ApuLessorContributionsTab } from './APULessorContributionsTab'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useAssetComponentsForms } from '@flyward/assets/context'
import { zodResolver } from '@hookform/resolvers/zod'
import { useUpdateApuMutation } from '@flyward/assets/store/api'
import { APUDetails } from './APUDetails'

interface IAuxiliaryPowerUnitDetailsProps {
  auxiliaryPowerUnit: ApuDto
}

export const AuxiliaryPowerUnitDetails = ({
  auxiliaryPowerUnit,
}: IAuxiliaryPowerUnitDetailsProps): React.ReactElement<IAuxiliaryPowerUnitDetailsProps> => {
  const { registerFormRef } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)

  const [currentTab, setCurrentTab] = useState<string>('Technical')

  const [updateApu] = useUpdateApuMutation()

  const onTabChanged = (activeTab: string) => {
    setCurrentTab(activeTab)
  }

  const form = useForm<ApuDto>({
    defaultValues: auxiliaryPowerUnit,
    values: auxiliaryPowerUnit,
    resolver: zodResolver(ApuDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    register: registerApuFormField,
    handleSubmit: handleApuUpdate,
    formState,
    getValues: getApuFormValues,
    setValue: setApuFormValue,
    control: auxiliaryPowerUnitFormControl,
    trigger: triggerApuValidation,
  } = form

  const auxiliaryPowerUnitFormValues: ApuDto = getApuFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('APU formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerApuValidation()
  }, [isValid, triggerApuValidation])

  useEffect(() => {
    registerFormRef({ id: [auxiliaryPowerUnitFormValues.assetId, auxiliaryPowerUnitFormValues.componentId], submitRef, hasChanges: isDirty, isValid })
  }, [registerFormRef, auxiliaryPowerUnitFormValues, isDirty, isValid])

  const onUpdateApu: SubmitHandler<ApuDto> = (data) => {
    updateApu({ assetId: auxiliaryPowerUnit.assetId, apu: data })
  }

  const tabs = {
    Technical: {
      content: (
        <ErrorBoundary>
          <APUTechnicalTab component={auxiliaryPowerUnitFormValues} setFormValue={setApuFormValue} formControl={auxiliaryPowerUnitFormControl} />
        </ErrorBoundary>
      ),
    },
    Contractual: {
      content: (
        <ErrorBoundary>
          <APUContractualTab component={auxiliaryPowerUnitFormValues} formControl={auxiliaryPowerUnitFormControl} />
        </ErrorBoundary>
      ),
    },
    'Lessor Contribution': {
      content: (
        <ErrorBoundary>
          <ApuLessorContributionsTab
            key={auxiliaryPowerUnitFormValues.componentId}
            component={auxiliaryPowerUnitFormValues}
            registerFormField={registerApuFormField}
            setFormValue={setApuFormValue}
            formControl={auxiliaryPowerUnitFormControl}
            formState={formState}
          />
        </ErrorBoundary>
      ),
    },
  }

  return (
    <CnForm {...form}>
      <form onSubmit={handleApuUpdate(onUpdateApu)}>
        <ComponentContainer className="p-6">
          <div className="flex w-full flex-col">
            <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">APU</p>
            <div className="flex flex-col gap-y-2">
              <APUDetails component={auxiliaryPowerUnit} setFormValue={setApuFormValue} formControl={auxiliaryPowerUnitFormControl} />
              <ComponentTabs
                tabTriggerHeight="h-9"
                tabTriggerLabelHeight="h-8"
                tabs={tabs}
                controlledActiveTab={currentTab}
                onTabChanged={onTabChanged}
              />
            </div>
          </div>
        </ComponentContainer>
        <input type="submit" className="hidden" ref={submitRef} />
      </form>
    </CnForm>
  )
}
