import { Select } from '@flyward/platform/components/Select'
import { CnFormControl, CnFormField, CnFormItem, CnFormMessage } from '@flyward/platform/components/Form'
import { cn } from '@flyward/platform/components/utils'
import { type ISelectOption } from '@flyward/platform/models'
import { type FieldValues, type Control, type Path } from 'react-hook-form'

interface IEditableSelectCellWithLabel<TFormData extends FieldValues> {
  label?: string
  options: ISelectOption[]
  disabled?: boolean
  className?: string
  labelClassName?: string
  formControl: Control<TFormData, unknown>
  fieldName: Path<TFormData>
}

const EditableSelectCellWithLabel = <TFormData extends FieldValues>({
  label = '',
  options,
  disabled = false,
  className = '',
  formControl,
  fieldName,
  labelClassName = '',
}: IEditableSelectCellWithLabel<TFormData>) => {
  return (
    <div className={(cn('flex flex-col justify-center gap-y-1'), className)}>
      <em className={cn('block text-xs font-semibold capitalize not-italic text-text-3', labelClassName)}>{label}</em>
      <CnFormField
        control={formControl}
        name={fieldName}
        render={({ field }) => {
          return (
            <CnFormItem>
              <CnFormControl>
                <Select
                  disabled={disabled}
                  options={options}
                  controlledValue={field.value?.toString()}
                  setControlledValue={(value: string) => {
                    field.onChange(value)
                  }}
                  selectClassName="w-17"
                />
              </CnFormControl>
              <CnFormMessage />
            </CnFormItem>
          )
        }}
      />
    </div>
  )
}

export { EditableSelectCellWithLabel }
export type { IEditableSelectCellWithLabel }
