import * as React from 'react'
import { type IIconProps } from '../IIconProps'
import { IconVariant } from '../IconVariant'

export const Add = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg className={className} data-variant={IconVariant[variant]} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  )
}
