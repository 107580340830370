import {
  type ApuProgramDto,
  AuxiliaryPowerUnitMaintenanceProgramEditable,
  AuxiliaryPowerUnitMaintenanceProgramReadOnly,
} from '@flyward/knowledgeBase'
import { type Control } from 'react-hook-form'

interface IAuxiliaryPowerUnitMaintenanceProgramPageBodyProps {
  isInEditMode: boolean
  auxiliaryPowerUnitMaintenanceProgram: ApuProgramDto | undefined
  formControl: Control<ApuProgramDto, unknown>
}

export const AuxiliaryPowerUnitMaintenanceProgramPageBody = ({
  isInEditMode,
  auxiliaryPowerUnitMaintenanceProgram,
  formControl,
}: IAuxiliaryPowerUnitMaintenanceProgramPageBodyProps) => {
  return isInEditMode ? (
    <AuxiliaryPowerUnitMaintenanceProgramEditable
      auxiliaryPowerUnitMaintenanceProgram={auxiliaryPowerUnitMaintenanceProgram}
      formControl={formControl}
    />
  ) : (
    <AuxiliaryPowerUnitMaintenanceProgramReadOnly auxiliaryPowerUnitMaintenanceProgram={auxiliaryPowerUnitMaintenanceProgram} />
  )
}
