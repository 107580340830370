import {
  Pagination as CnPagination,
  PaginationContent as CnPaginationContent,
  PaginationEllipsis as CnPaginationEllipsis,
  PaginationItem as CnPaginationItem,
  PaginationLink as CnPaginationLink,
  PaginationNext as CnPaginationNext,
  PaginationPrevious as CnPaginationPrevious,
} from '../_shadcn'

interface IPaginationNavigationProps {
  currentPage: number
  totalPages: number
  pageSize: number
  isLoading: boolean
  totalCount: number
  setCurrentPage: (page: number) => void
}

const pageBack = (currentPage: number, setCurrentPage: (page: number) => void) => {
  setCurrentPage(Math.max(currentPage - 1, 0))
}

const pageForward = (currentPage: number, totalPages: number, setCurrentPage: (page: number) => void) => {
  setCurrentPage(Math.min(currentPage + 1, totalPages - 1))
}

const hasSuccessor = (currentPage: number, totalPages: number) => {
  return currentPage < totalPages - 1
}

const hasMultipleSuccessors = (currentPage: number, totalPages: number) => {
  return totalPages - currentPage > 3
}

const hasPredecessor = (currentPage: number, totalPages: number) => {
  return currentPage > 1 && totalPages > 1
}

const hasMultiplePredecessors = (currentPage: number, totalPages: number) => {
  return currentPage > 2 && totalPages > 3
}

const generatePageNumbers = (currentPage: number, totalPages: number) => {
  const pages = []
  const firstPage = 0
  const lastPage = totalPages - 1

  pages.push(firstPage)

  if (hasMultiplePredecessors(currentPage, totalPages)) {
    pages.push('prev')
  }

  if (hasPredecessor(currentPage, totalPages)) {
    pages.push(currentPage - 1)
  }

  if (currentPage > 0) {
    pages.push(currentPage)
  }

  if (hasSuccessor(currentPage, totalPages)) {
    pages.push(currentPage + 1)
  }

  if (hasMultipleSuccessors(currentPage, totalPages)) {
    pages.push('next')
  }

  if (totalPages > 1 && currentPage < totalPages - 2) {
    pages.push(lastPage)
  }

  return pages
}

const PaginationNavigation = ({ currentPage, totalPages, pageSize, isLoading, totalCount, setCurrentPage }: IPaginationNavigationProps) => {
  const pageNumbers = generatePageNumbers(currentPage, totalPages)

  return (
    <CnPagination className="flex items-center">
      <CnPaginationContent>
        <CnPaginationItem>
          <CnPaginationPrevious
            isActive={currentPage !== 0 && !isLoading}
            onClick={() => {
              pageBack(currentPage, setCurrentPage)
            }}
          />
        </CnPaginationItem>
        {pageNumbers.map((pageNumber) =>
          typeof pageNumber === 'number' ? (
            <CnPaginationItem key={pageNumber}>
              <CnPaginationLink
                isCurrentPage={pageNumber === currentPage}
                isActive={!isLoading}
                onClick={() => {
                  setCurrentPage(pageNumber)
                }}
              >
                {pageNumber + 1}
              </CnPaginationLink>
            </CnPaginationItem>
          ) : (
            <CnPaginationItem key={pageNumber}>
              <CnPaginationEllipsis />
            </CnPaginationItem>
          ),
        )}
        <CnPaginationItem>
          <CnPaginationNext
            isActive={pageSize > 0 && (currentPage + 1) * pageSize < totalCount && !isLoading}
            onClick={() => {
              pageForward(currentPage, totalPages, setCurrentPage)
            }}
          />
        </CnPaginationItem>
      </CnPaginationContent>
    </CnPagination>
  )
}

export { PaginationNavigation }
