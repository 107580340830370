import { isEmpty } from 'lodash'

const _baseHubUrl: string = isEmpty(import.meta.env.VITE_API_URL) ? '/api' : import.meta.env.VITE_API_URL
const _forecastHubUrl = _baseHubUrl + '/hubs/forecasts'

const HubRoutes = {
  ForecastModule: {
    ForecastHub: _forecastHubUrl,
  },
}

export { HubRoutes }
