import { type IdWithIndex } from '@flyward/platform'
import { type TRootState } from '@flyward/platform/store/configureReducers'

const selectUnsortedReportAssets = (state: TRootState) => state.flyForward.reportAssets ?? []
const selectForecastingAssetsSearchInput = (state: TRootState) => state.flyForward.forecastingAssetsSearchInput
const selectFilteredAssetsData = (state: TRootState) => state.flyForward.filteredAssetsData
const selectSelectedAssetId = (state: TRootState) => state.flyForward.selectedAssetId
const selectSuccessfulReportId = (state: TRootState) => state.flyForward.successfulReportId

const selectReportAssetByAssetId = (state: TRootState, assetId: string) => {
  const reportAssetIdIndex = state.flyForward.reportAssets?.findIndex((reportAssetId) => reportAssetId.assetId.id === assetId) ?? -1

  if (reportAssetIdIndex === -1) {
    return
  }

  return state.flyForward.reportAssets[reportAssetIdIndex]
}

const selectReportAssetFlyForwardParametersByAssetId = (state: TRootState, assetId: IdWithIndex) => {
  const reportAssetIdIndex =
    state.flyForward.reportAssets?.findIndex(
      (reportAssetId) => reportAssetId.assetId.id === assetId.id && reportAssetId.assetId.index === assetId.index,
    ) ?? -1

  if (reportAssetIdIndex === -1) {
    return
  }

  return state.flyForward.reportAssets[reportAssetIdIndex].flyForwardParameters
}

const selectReportAssetFlyForwardInitialParametersByAssetId = (state: TRootState, assetId: IdWithIndex) => {
  const reportAssetIdIndex =
    state.flyForward.reportAssets?.findIndex(
      (reportAssetId) => reportAssetId.assetId.id === assetId.id && reportAssetId.assetId.index === assetId.index,
    ) ?? -1

  if (reportAssetIdIndex === -1) {
    return
  }

  return state.flyForward.reportAssets[reportAssetIdIndex].initialFlyForwardParameters
}

export {
  selectReportAssetByAssetId,
  selectReportAssetFlyForwardParametersByAssetId,
  selectReportAssetFlyForwardInitialParametersByAssetId,
  selectUnsortedReportAssets,
  selectForecastingAssetsSearchInput,
  selectFilteredAssetsData,
  selectSelectedAssetId,
  selectSuccessfulReportId,
}
