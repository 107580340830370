import { EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { type Control, type Path, type FieldValues } from 'react-hook-form'

interface IStubLifeEditableProps<TFormData extends FieldValues> {
  formControl: Control<TFormData, unknown>
  percentageFieldName: Path<TFormData>
}

export const StubLifeEditable = <TFormData extends FieldValues>({
  formControl,
  percentageFieldName,
}: IStubLifeEditableProps<TFormData>): React.ReactElement<IStubLifeEditableProps<TFormData>> => {
  return (
    <div className="flex gap-6">
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.NaturalNumber}
        className="basis-1/6"
        formControl={formControl}
        fieldName={percentageFieldName}
      />
    </div>
  )
}
