export interface IError400Response {
  errors: Record<string, string[]>
}

const Error400Display = ({ errors }: IError400Response) => {
  return (
    <div>
      <p>Validation errors:</p>
      <ul className="list-disc">
        {Object.entries(errors).map(([key, value]) => {
          return (
            <li key={key}>
              <strong>{key}</strong>
              <ul className="list-disc">
                {value.map((error) => {
                  return (
                    <li className="pl-2" key={error}>
                      {error}
                    </li>
                  )
                })}
              </ul>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export { Error400Display }
