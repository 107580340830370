import { ComponentMinimumReDeliveryConditionDtoSchema, type ComponentMinimumReDeliveryConditionDto } from '@flyward/platform/models'
import { EscalationDtoSchema, type EscalationDto } from '../../contract/EscalationDto'
import { AirframeMaintenanceReserveContractSchema, type AirframeMaintenanceReserveContract } from './AirframeMaintenanceReserveContract'
import { z } from 'zod'
import { ContractDeliverySnapshotDtoSchema, type ContractDeliverySnapshotDto } from '../../contract/ContractDeliverySnapshotDto'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Airframe.AirframeContractDto
 */
export interface AirframeContractDto {
  escalations: EscalationDto
  contractDeliverySnapshots: ContractDeliverySnapshotDto[]
  airframeMaintenanceReserveContract: AirframeMaintenanceReserveContract
  minimumReDeliveryConditions: ComponentMinimumReDeliveryConditionDto[]
}

export const AirframeContractSchema = z.object({
  escalations: EscalationDtoSchema,
  contractDeliverySnapshots: z.array(ContractDeliverySnapshotDtoSchema),
  airframeMaintenanceReserveContract: AirframeMaintenanceReserveContractSchema,
  minimumReDeliveryConditions: z.array(ComponentMinimumReDeliveryConditionDtoSchema),
})
