import { cn } from '../../utils'
import { type TableColumn, type TableRowData } from '../../common'
import {
  Table as CnTable,
  TableCell as CnTableCell,
  TableHead as CnTableHead,
  TableHeader as CnTableHeader,
  TableRow as CnTableRow,
} from '../../_shadcn'
import { isNil } from 'lodash'

interface IDynamicTableProps<T> {
  columns: Array<TableColumn<T>>
  data: TableRowData<T | undefined> | undefined
  tableClassName?: string
  summaryRow?: React.ReactElement
}

const defaultGetRenderer: <T>(value: T[keyof T] | object | null | string | number) => React.ReactNode = (value) => <>{value}</>

const DynamicTable = <T extends object>({ columns, data, tableClassName = '', summaryRow }: Readonly<IDynamicTableProps<T>>) => {
  const idField = columns.find((c) => c.isIdField)!

  if (isNil(data) || data.length === 0) {
    return <p>No data</p>
  }

  return (
    <CnTable className={tableClassName}>
      <CnTableHeader>
        <CnTableRow className="flex">
          {columns.map((column: TableColumn<T>) => (
            <CnTableHead
              key={column.accessor.toString()}
              className={cn('truncate whitespace-nowrap p-1 font-bold', column.headerClassName, {
                hidden: column.isHidden,
              })}
            >
              {column.title}
            </CnTableHead>
          ))}
        </CnTableRow>
      </CnTableHeader>
      <tbody>
        {data.map((row, rowIndex) => {
          if (isNil(row)) {
            return <></>
          }

          return (
            <CnTableRow key={`${row[idField.accessor] as string}_${rowIndex}`} className="flex">
              {columns.map((column) => {
                const valueRenderer = column.valueGetter ?? defaultGetRenderer
                const celRender = valueRenderer(row[column.accessor])
                return isNil(column.customCell) ? (
                  <CnTableCell
                    key={`${row[idField.accessor] as string}_${column.accessor.toString()}`}
                    data-accessor={column.accessor}
                    className={cn('truncate whitespace-nowrap px-1', column.cellClassName, {
                      hidden: column.isHidden,
                    })}
                  >
                    {celRender as string}
                  </CnTableCell>
                ) : (
                  <CnTableCell
                    key={`${row[idField.accessor] as string}_${column.accessor.toString()}`}
                    data-accessor={column.accessor}
                    className={cn('truncate whitespace-nowrap px-1', column.cellClassName, {
                      hidden: column.isHidden,
                    })}
                  >
                    {column.customCell({ row, rowIndex, column })}
                  </CnTableCell>
                )
              })}
            </CnTableRow>
          )
        })}
        {summaryRow}
      </tbody>
    </CnTable>
  )
}

export { DynamicTable }
