import { type LandingGearDto } from '@flyward/assets/models'
import { NumberCellWithLabel, CheckTypeDisplay, NumberDisplayType, TextCellWithLabel, formatDate } from '@flyward/platform'
import { Fragment } from 'react'

interface ILGTechnicalReadOnlyTabProps {
  component: LandingGearDto
}

export const LGTechnicalReadOnlyTab = ({ component }: ILGTechnicalReadOnlyTabProps) => {
  const utilizationAtEvents = component.assetComponentUtilizationSnapshot.componentUtilizationAtEvents

  const UtilizationAtEventComponent = utilizationAtEvents.map((event) => {
    return (
      <Fragment key={event.eventType}>
        <NumberCellWithLabel
          className="basis-1/6"
          label={`TSN @ last ${CheckTypeDisplay(event.eventType)}`}
          info={event.totalHoursSinceNewAtLastQualifyingEvent}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`CSN @ last ${CheckTypeDisplay(event.eventType)}`}
          info={event.cyclesSinceNewAtLastQualifyingEvent}
          displayType={NumberDisplayType.Integer}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`Last ${CheckTypeDisplay(event.eventType)} date`}
          info={formatDate(event.dateAtLastQualifyingEvent?.toString())}
        />
      </Fragment>
    )
  })
  return (
    <div className="flex w-full gap-x-6 gap-y-2">
      <NumberCellWithLabel
        className="basis-1/6"
        label="TSN"
        info={component.assetComponentUtilizationSnapshot.totalHoursSinceNew}
        displayType={NumberDisplayType.Integer}
      />
      <NumberCellWithLabel
        className="basis-1/6"
        label="CSN"
        info={component.assetComponentUtilizationSnapshot.cyclesSinceNew}
        displayType={NumberDisplayType.Integer}
      />
      {UtilizationAtEventComponent}
      <div className="basis-1/6" />
    </div>
  )
}
