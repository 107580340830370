/* eslint-disable max-len */
import * as React from 'react'
import { type IIconProps } from '../IIconProps'
import { IconVariant } from '../IconVariant'

export const FlightTakeOff = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg
      className={className}
      data-variant={IconVariant[variant]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.49984 18.9999H21.4998V20.9999H2.49984V18.9999ZM22.0698 9.63995C21.8598 8.83995 21.0298 8.35995 20.2298 8.57995L14.9198 9.99995L8.01984 3.56995L6.08984 4.07995L10.2298 11.2499L5.25984 12.5799L3.28984 11.0399L1.83984 11.4299L4.42984 15.9199C4.42984 15.9199 11.5498 14.0199 20.9998 11.4899C21.8098 11.2599 22.2798 10.4399 22.0698 9.63995Z" />
    </svg>
  )
}
