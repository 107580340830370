import {
  CheckType,
  type ComponentMonthlyStatistics,
  type IYearMonth,
  IconVariant,
  FullScreenPopover,
  Button,
  ButtonVariant,
  cn,
  allNonEngineCheckTypes,
} from '@flyward/platform'
import { useCallback, useState } from 'react'
import { EventDetailPopoverContent } from '../../schedule/EventView'
import { type IMaintenanceScheduleDisplay } from '@flyward/forecasts/models/entities/MaintenanceSchedule'
import { format, startOfMonth } from 'date-fns'
import { cloneDeep } from 'lodash'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'

interface ICheckTypeDisplayProps {
  checkTypes: CheckType[]
  eventYearMonth: IYearMonth
  componentsMonthlyStatistics: ComponentMonthlyStatistics[]
  allEvents: IMaintenanceScheduleDisplay[]
  componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[]
}

export const CheckTypeDisplay = ({
  checkTypes,
  eventYearMonth,
  componentsMonthlyStatistics,
  allEvents,
  componentTotalStatisticsAtEvent,
}: ICheckTypeDisplayProps) => {
  const engineCheckTypes = [CheckType.EngineLlpReplacement, CheckType.Epr]
  const sortedAllEvents = cloneDeep(allEvents).sort((a, b) => (a.yearMonth as IYearMonth).year - (b.yearMonth as IYearMonth).year)

  const [isEventViewPopoverOpen, setIsEventViewPopoverOpen] = useState(false)
  const [selectedEventsGrid, setSelectedEventsGrid] = useState<IMaintenanceScheduleDisplay[]>([])
  const getAllMonths = () => {
    const months = []
    for (let i = 0; i < 12; i++) {
      months.push(format(startOfMonth(new Date(2020, i, 1)), 'MMM'))
    }
    return months
  }
  const months: string[] = getAllMonths()

  const eventsGrid = useCallback(
    (events: IMaintenanceScheduleDisplay[], year: number, month: string) => {
      return events.filter((el) => {
        const { year: eventYear, month: eventMonth } = el.yearMonth as IYearMonth
        const monthIndex = months.findIndex((m) => m === month) + 1
        return eventYear === year && eventMonth === monthIndex
      })
    },
    [months],
  )

  const openPopover = () => {
    setSelectedEventsGrid(eventsGrid(sortedAllEvents, eventYearMonth.year, months[eventYearMonth.month - 1]))
    setIsEventViewPopoverOpen(true)
  }

  const closePopover = () => {
    setIsEventViewPopoverOpen(false)
  }

  const hasEngineEvents = engineCheckTypes.some((checkType) => checkTypes?.includes(checkType))
  const hasAircraftEvents = allNonEngineCheckTypes.some((checkType) => checkTypes?.includes(checkType))

  return (
    <>
      <div className="flex gap-1">
        {hasAircraftEvents && (
          <Button variant={ButtonVariant.Ghost} leftIcon={IconVariant.PlaneZoomOut} onClick={openPopover} className="-ml-2 h-full w-[1.75rem]" />
        )}
        {hasEngineEvents && (
          <Button
            variant={ButtonVariant.Ghost}
            className={cn('h-full w-[1.75rem]', !hasAircraftEvents ? '-ml-2' : '')}
            leftIcon={IconVariant.Engine}
            onClick={openPopover}
          />
        )}
      </div>

      <FullScreenPopover
        content={
          <EventDetailPopoverContent
            componentsMonthlyStatistics={componentsMonthlyStatistics}
            yearMonth={eventYearMonth}
            eventsGrid={selectedEventsGrid}
            componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
          />
        }
        isOpen={isEventViewPopoverOpen}
        onClose={closePopover}
        popoverTitle="Event Details"
      />
    </>
  )
}
