import { ErrorBoundary, IconVariant, ToastVariant, useHeaderContent, useNavigationState, useToast } from '@flyward/platform'
import { type IGetAssetWithReportItemListWithIndex, useGetReportDetailsQuery } from '@flyward/platform/store/slices'
import { isEmpty, isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../layout'
import { ReportItemAssets } from './ReportItemAssets'
import { ReportBody } from './ReportBody'
import { ReportItemExcelExtract } from '@flyward/forecasts/components'

const ReportPage = () => {
  const { reportId } = useParams()
  const { setTitle, setHasBackButton, setCustomElements } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()
  const { toast } = useToast()

  const onGetReportError = useCallback(() => {
    toast({
      variant: ToastVariant.Destructive,
      description: 'Error loading the report',
      icon: IconVariant.Error,
    })
  }, [])

  const {
    data: report,
    isError: isReportError,
    isLoading: isReportLoading,
    error: reportError,
  } = useGetReportDetailsQuery(
    { reportId: reportId! },
    {
      skip: isNil(reportId) || isEmpty(reportId),
    },
  )

  const [selectedAsset, setSelectedAsset] = useState<string | null>(report?.assetWithReportItemList?.[0]?.reportItemId ?? null)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const assetWithReportItemListWithIndexZero: IGetAssetWithReportItemListWithIndex[] =
    report?.assetWithReportItemList?.map((reportItem) => {
      return {
        ...reportItem,
        index: 0,
      }
    }) ?? []

  const maxIndexOnReportItemArrayForAssetId = (array: IGetAssetWithReportItemListWithIndex[]) => {
    array.forEach((a, index) => {
      if (index === 0) {
        a.index = 0
      } else if (a.assetId === array[index - 1].assetId) {
        a.index = array[index - 1].index + 1
      } else {
        a.index = 0
      }
    })

    return array
  }

  const assetWithReportItemListWithIndex: IGetAssetWithReportItemListWithIndex[] =
    maxIndexOnReportItemArrayForAssetId(assetWithReportItemListWithIndexZero)

  const handleAssetSelection = (reportId: string, index: number) => {
    setSelectedAsset(reportId)
    setSelectedIndex(index)
  }

  useEffect(() => {
    if (isReportError) {
      onGetReportError()
    }
  }, [isReportError, onGetReportError])

  useEffect(() => {
    const reportItemIds = () => {
      if (!isNil(report) && !isEmpty(report)) {
        return report?.assetWithReportItemList
          .map((asset) => ({
            reportItemId: asset.reportItemId,
            assetSerialNumber: asset.assetSerialNumber,
          }))
          .filter((item): item is { reportItemId: string; assetSerialNumber: string } => item.reportItemId !== undefined)
      }
      return []
    }
    setHasBackButton(true)
    toggleVisibility()

    setCustomElements(
      !isEmpty(reportId) && !isNil(reportId)
        ? [<ReportItemExcelExtract key={`extract-${reportId}`} reportId={reportId} reportItems={reportItemIds()} />]
        : [],
    )

    return () => {
      toggleVisibility()
      setCustomElements([])
    }
  }, [report, reportId, setCustomElements, setHasBackButton, toggleVisibility])

  useEffect(() => {
    setTitle(`Report ${report?.reportName ?? ''}`)
    setSelectedAsset(report?.assetWithReportItemList?.[0]?.reportItemId ?? null)

    return () => {
      setTitle('')
    }
  }, [setTitle, report])

  if (isNil(report)) {
    return (
      <ErrorBoundary controlledError={reportError as Error} isLoadingControlled={isReportLoading}>
        <span data-testid="loading-report"></span>
      </ErrorBoundary>
    )
  }

  return (
    <PageContainer>
      <ErrorBoundary isLoadingControlled={isReportLoading}>
        <ReportItemAssets assetsData={assetWithReportItemListWithIndex} selectedAsset={selectedAsset} onAssetSelect={handleAssetSelection} />
        {!isNil(selectedAsset) && (
          <ReportBody
            reportItem={{
              id: selectedAsset,
              index: selectedIndex,
            }}
          />
        )}
      </ErrorBoundary>
    </PageContainer>
  )
}

export { ReportPage }
