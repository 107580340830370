import { useAssetComponentsForms } from '@flyward/assets/context'
import { LGDetailsReadOnly } from './LGDetailsReadOnly'
import { type LandingGearDto } from '@flyward/assets/models'
import { LGDetailsEditable } from './LGDetailsEditable'
import { type UseFormSetValue, type Control } from 'react-hook-form'

export interface ILGDetailsProps {
  component: LandingGearDto
  setFormValue: UseFormSetValue<LandingGearDto>
  formControl: Control<LandingGearDto, unknown>
}

export const LGDetails = ({ component, setFormValue, formControl }: ILGDetailsProps) => {
  const { isInEditMode } = useAssetComponentsForms()
  return (
    <>{isInEditMode ? <LGDetailsEditable setFormValue={setFormValue} formControl={formControl} /> : <LGDetailsReadOnly component={component} />}</>
  )
}
