import { useUserAuthenticated } from '@flyward/appIdentity/context/UserAuthorization/UserAuthorizationContext'
import { useZitadelAuthenticated } from '@flyward/appIdentity/context/ZitadelAuthenticated/ZitadelAuthenticatedContext'
import { Button, ButtonVariant, IconVariant, Size } from '@flyward/platform'
import { isNil } from 'lodash'
import { useCallback } from 'react'

export const UserInformation = () => {
  const zitadel = useZitadelAuthenticated()
  const { loggedUser, isUserLogged } = useUserAuthenticated()

  const getIconSize = useCallback(() => {
    if (window.innerWidth >= 3000) {
      return Size.ExtraLarge
    } else if (window.innerWidth >= 1440) {
      return Size.Large
    } else {
      return Size.Medium
    }
  }, [])

  const logout = () => {
    zitadel.logout()
  }

  return (
    <div className="flex">
      <Button
        variant={ButtonVariant.Account}
        size={getIconSize()}
        leftIcon={IconVariant.AccountCircle}
        label={(isUserLogged ?? false) && !isNil(loggedUser) ? loggedUser.fullName : ''}
        className="w-full text-black-100"
        onClick={() => {}}
      />
      {(isUserLogged ?? false) && (
        <Button
          variant={ButtonVariant.Account}
          size={getIconSize()}
          leftIcon={IconVariant.LogOut}
          label={'Log out'}
          className="w-full text-black-100"
          onClick={logout}
        />
      )}
    </div>
  )
}
