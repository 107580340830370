import { MoreHorizontal } from 'lucide-react'
import * as React from 'react'
import { Size } from '../common'
import { Icon, IconVariant } from '../Icon'
import { Button, ButtonProps, ButtonVariant } from './button'
import { cn } from './utils'

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav role="navigation" aria-label="pagination" className={cn('mx-auto flex w-full justify-center', className)} {...props} />
)
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(({ className, ...props }, ref) => (
  <ul ref={ref} className={cn('flex flex-row items-center gap-1', className)} {...props} />
))
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
))
PaginationItem.displayName = 'PaginationItem'

type PaginationLinkProps = {
  isActive?: boolean
  isCurrentPage?: boolean
  className?: string
  onClick?: () => void
} & ButtonProps

const PaginationLink = ({ className, isActive, isCurrentPage, size = Size.Small, ...props }: PaginationLinkProps) => (
  <Button
    aria-current={isActive ? 'page' : undefined}
    variant={isCurrentPage ? ButtonVariant.Primary : ButtonVariant.Outline}
    disabled={!isActive}
    {...props}
    className={cn('h-6 w-6 text-center text-xs', isActive && !isCurrentPage && 'text-primary', className)}
    onClick={() => (isActive ? props.onClick?.() : undefined)}
  />
)
PaginationLink.displayName = 'PaginationLink'

const PaginationPrevious = ({ className, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    name="pagination-prev"
    data-testid="pagination-prev"
    aria-label="Go to previous page"
    size={Size.Small}
    disabled={!props.isActive}
    className={cn('gap-1', className)}
    {...props}
  >
    <Icon variant={IconVariant.KeyboardArrowLeft} className={props.isActive ? 'text-primary' : 'text-black-20'} />
  </PaginationLink>
)
PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationNext = ({ className, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    name="pagination-next"
    data-testid="pagination-next"
    aria-label="Go to next page"
    size={Size.Small}
    disabled={!props.isActive}
    className={cn('gap-1', className)}
    {...props}
  >
    <Icon variant={IconVariant.KeyboardArrowRight} className={props.isActive ? 'text-primary' : 'text-black-20'} />
  </PaginationLink>
)
PaginationNext.displayName = 'PaginationNext'

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
  <span data-testid="pagination-ellipsis" aria-hidden className={cn('flex h-6 w-6 items-center justify-center', className)} {...props}>
    <MoreHorizontal className="h-4 w-4 text-primary" />
    <span className="sr-only">More pages</span>
  </span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'

export { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious }
