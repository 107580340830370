import { ReportItemStatus } from '@flyward/forecasts/models/enums'
import { ComponentWithLoadingSpinner, Icon, IconVariant, Size } from '@flyward/platform'

interface AssetSelectionStatusIconProps {
  status: ReportItemStatus
}

const AssetSelectionStatusIcon = ({ status }: AssetSelectionStatusIconProps) => {
  let icon: IconVariant = IconVariant.EmptySvg

  switch (status) {
    case ReportItemStatus.QueuedForProcessing:
      icon = IconVariant.Schedule
      break
    case ReportItemStatus.Active:
      icon = IconVariant.Tick
      break
    case ReportItemStatus.Failed:
      icon = IconVariant.Error
      break
  }

  return (
    <ComponentWithLoadingSpinner isLoading={status === ReportItemStatus.Processing} defaultSize={16}>
      <Icon className="mr-1 h-4" size={Size.Small} variant={icon} />
    </ComponentWithLoadingSpinner>
  )
}

export { AssetSelectionStatusIcon }
