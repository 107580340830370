import { type FleetSearchInput } from '@flyward/assets/models'
import { PageSizes } from '@flyward/platform/models/enums'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { initialFilterData } from './types'

interface FleetState {
  fleetFilters: FleetSearchInput
  selectedAssetIds: string[]
}

const initialState: FleetState = {
  fleetFilters: {
    searchTerm: '',
    expand: true,
    assetType: undefined,
    filterData: initialFilterData.filterSelection,
    Page: 0,
    PageSize: PageSizes.Twenty,
  },
  selectedAssetIds: [],
}

const fleetSlice = createSlice({
  name: 'fleet',
  initialState,
  reducers: {
    resetFleetSlice: (state) => {
      state.fleetFilters = initialState.fleetFilters
      state.selectedAssetIds = initialState.selectedAssetIds
    },
    resetAllFleetSearchInput: (state) => {
      state.fleetFilters = initialState.fleetFilters
    },
    resetFleetFilters: (state) => {
      state.fleetFilters.filterData = initialState.fleetFilters.filterData
    },
    setFleetSearchInput: (state, { payload: { newFleetSearchInput } }: PayloadAction<{ newFleetSearchInput: FleetSearchInput }>) => {
      state.fleetFilters = newFleetSearchInput
    },
    setFleetSearchTerm: (state, { payload: { searchTerm } }: PayloadAction<{ searchTerm: string }>) => {
      state.fleetFilters.searchTerm = searchTerm
    },
    setFleetPageNumber: (state, { payload: { page } }: PayloadAction<{ page: number }>) => {
      state.fleetFilters.Page = page
    },
    setFleetPageSize: (state, { payload: { pageSize } }: PayloadAction<{ pageSize: PageSizes }>) => {
      state.fleetFilters.PageSize = pageSize
    },
    setSelectedAssetIds: (state, { payload: { selectedAssetIds } }: PayloadAction<{ selectedAssetIds: string[] }>) => {
      state.selectedAssetIds = selectedAssetIds
    },
  },
})

export const {
  resetFleetSlice,
  resetAllFleetSearchInput,
  resetFleetFilters,
  setFleetSearchInput,
  setFleetSearchTerm,
  setFleetPageNumber,
  setFleetPageSize,
  setSelectedAssetIds,
} = fleetSlice.actions
export const { reducer: fleetReducer } = fleetSlice
