import { type Control } from 'react-hook-form'
import { type AirframeProgramDto } from '@flyward/knowledgeBase/models'
import { AirframeMaintenanceProgramEditable, AirframeMaintenanceProgramReadOnly } from '@flyward/knowledgeBase/components'

interface IAirframeMaintenanceProgramPageBodyProps {
  isInEditMode: boolean
  airframeMaintenanceProgram: AirframeProgramDto | undefined
  formControl: Control<AirframeProgramDto, unknown>
}

export const AirframeMaintenanceProgramPageBody = ({
  isInEditMode,
  airframeMaintenanceProgram,
  formControl,
}: IAirframeMaintenanceProgramPageBodyProps) => {
  return isInEditMode ? (
    <AirframeMaintenanceProgramEditable airframeMaintenanceProgram={airframeMaintenanceProgram} formControl={formControl} />
  ) : (
    <AirframeMaintenanceProgramReadOnly airframeMaintenanceProgram={airframeMaintenanceProgram} />
  )
}
