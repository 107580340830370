import { type ExpandedComponentDto } from '@flyward/platform/models/DTOs/ExpandedComponentDto'

enum Label {
  Type = 'Type',
  TSN = 'TSN',
  CSN = 'CSN',
  TSLPR = 'TSLPR',
  CSLPR = 'CSLPR',
  LLP_FC_rem = 'LLP FC rem',
  LLP_descriptor = 'LLP descriptor',
  PR_fund = 'PR fund',
  LLP_fund = 'LLP fund',
}

interface IRowProperty {
  label: Label
  property: keyof ExpandedComponentDto
  overriddenProperties?: IOverriddenProperty[]
}

interface IOverriddenProperty {
  name: 'airframe' | 'engine' | 'landingGear' | 'apu' | 'thrustReverser'
  value: string
}

interface ExpandedRowFields {
  name: string
  airframe: Record<string, string | undefined>
  engine: Record<string, string | undefined>
  landingGear: Record<string, string | undefined>
  apu: Record<string, string | undefined>
  thrustReverser: Record<string, string | undefined>
}

interface IExpandedColumnDefs {
  airframe: string[]
  engine: string[]
  landingGear: string[]
  apu: string[]
  thrustReverser: string[]
}

interface IAircraftExpandedDataGridProps {
  airframes: ExpandedComponentDto[]
  engines: ExpandedComponentDto[]
  landingGears: ExpandedComponentDto[]
  auxiliaryPowerUnits: ExpandedComponentDto[]
}

export type { ExpandedRowFields, IAircraftExpandedDataGridProps, IExpandedColumnDefs, IOverriddenProperty, IRowProperty }
export { Label }
