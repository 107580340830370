import type { StandaloneEngineMaintenancePrograms } from '@flyward/assets/models'
import { ComponentContainer } from '@flyward/platform'
import { EditEngineMaintenanceProgram } from './EditEngineMaintenanceProgram'
import { AuthorizedElement, useUserAuthenticated } from '@flyward/appIdentity'
import { isNil } from 'lodash'
import { ReadOnlyEngineMaintenanceProgram } from './ReadOnlyEngineMaintenanceProgram'

export const EngineMaintenanceProgram = ({
  assetId,
  assignedEngineProgramId,
  availablePrograms,
  changePrograms,
}: StandaloneEngineMaintenancePrograms) => {
  const { loggedUser } = useUserAuthenticated()
  return (
    <ComponentContainer className="p-6">
      {!isNil(loggedUser) && (
        <div className="flex w-full gap-x-6">
          <AuthorizedElement>
            <ReadOnlyEngineMaintenanceProgram
              data-permission-element-id="read-only-asset-view"
              assetId={assetId}
              assignedEngineProgramId={assignedEngineProgramId}
              availablePrograms={availablePrograms}
              changePrograms={changePrograms}
            />
          </AuthorizedElement>
          <AuthorizedElement>
            <EditEngineMaintenanceProgram
              data-permission-element-id="edit-asset"
              assetId={assetId}
              assignedEngineProgramId={assignedEngineProgramId}
              availablePrograms={availablePrograms}
              changePrograms={changePrograms}
            />
          </AuthorizedElement>
        </div>
      )}
      <div className="mr-12 basis-2/6"></div>
    </ComponentContainer>
  )
}
