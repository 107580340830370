import { useEffect, useRef, useState, type ReactNode } from 'react'
import style from './ComponentWithLoadingSpinner.module.css'

export interface IComponentWithLoadingSpinnerProps {
  isLoading: boolean
  defaultSize?: number
  children: ReactNode
}

export const ComponentWithLoadingSpinner = ({ children, isLoading = false, defaultSize = 0 }: IComponentWithLoadingSpinnerProps) => {
  const containerRef = useRef<HTMLSpanElement | null>(null)
  const containerHeight = Number(containerRef.current?.clientHeight)
  const containerWidth = Number(containerRef.current?.clientWidth)
  const [height, setHeight] = useState<number>(containerHeight)
  const [width, setWidth] = useState<number>(containerWidth)

  useEffect(() => {
    if (!isNaN(containerHeight)) {
      setHeight(containerHeight)
    }
  }, [containerHeight, defaultSize])

  useEffect(() => {
    if (isNaN(containerWidth)) {
      setWidth(defaultSize)
    } else {
      setWidth(containerWidth)
    }
  }, [containerWidth, defaultSize])

  const spinnerSize = isLoading ? Math.min(height, width) : 0

  return (
    <span
      className="m-0 flex p-0"
      style={{
        height: !isNaN(height) ? height : 'auto',
        width: !isNaN(height) ? width + spinnerSize : 'auto',
      }}
    >
      <span
        className="flex items-center justify-center"
        style={{ height: !isNaN(spinnerSize) ? spinnerSize : 'auto', width: !isNaN(spinnerSize) ? spinnerSize : 'auto' }}
      >
        <div
          className={style.spinner}
          style={{ height: !isNaN(spinnerSize) ? spinnerSize : 'auto', width: !isNaN(spinnerSize) ? spinnerSize : 'auto' }}
        ></div>
      </span>
      <span className="m-0 flex p-0" ref={containerRef}>
        {children}
      </span>
    </span>
  )
}
