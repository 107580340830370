import { useGetAllAirframeChecksWithDetailsByAssetIdQuery } from '@flyward/assets/store/api/components/airframes/endpoints'
import { AircraftExpandedDataGrid } from '../../AssetExpandedDataGrid'
import { type AssetSearchDTO } from '@flyward/assets/models'
import { useGetAllLandingGearChecksWithDetailsByAssetIdQuery } from '@flyward/assets/store/api/components/landingGears/endpoints'
import { useGetAllEngineChecksWithDetailsByAssetIdQuery } from '@flyward/assets/store/api/components/engines/endpoints'
import { useGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQuery } from '@flyward/assets/store/api/components/auxiliaryPowerUnits/endpoints'

interface IAircraftExpandedDataGridWrapperProps {
  data: AssetSearchDTO
}

const AircraftExpandedDataGridWrapper = ({ data }: IAircraftExpandedDataGridWrapperProps) => {
  const { data: airframes = [] } = useGetAllAirframeChecksWithDetailsByAssetIdQuery({
    assetId: data.assetId,
  })
  const { data: landingGears = [] } = useGetAllLandingGearChecksWithDetailsByAssetIdQuery({
    assetId: data.assetId,
  })
  const { data: engines = [] } = useGetAllEngineChecksWithDetailsByAssetIdQuery({
    assetId: data.assetId,
  })
  const { data: auxiliaryPowerUnits = [] } = useGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQuery({
    assetId: data.assetId,
  })

  return <AircraftExpandedDataGrid airframes={airframes} landingGears={landingGears} engines={engines} auxiliaryPowerUnits={auxiliaryPowerUnits} />
}

export { AircraftExpandedDataGridWrapper }
