/* eslint-disable max-len */
import { type IYearMonth, cn, FullScreenPopover, type ComponentMonthlyStatistics } from '@flyward/platform'
import { format, startOfMonth } from 'date-fns'
import { type ReactElement, useCallback, useState } from 'react'
import { ComponentCheckTypeList } from './ComponentCheckTypeList'
import { type IMaintenanceScheduleDisplay } from '../../../models'
import { EventDetailPopoverContent } from './EventDetailPopoverContent'
import { cloneDeep } from 'lodash'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'

interface IEventViewPopoverProps {
  uniqueEvents: IMaintenanceScheduleDisplay[]
  allEvents: IMaintenanceScheduleDisplay[]
  componentsMonthlyStatistics: ComponentMonthlyStatistics[]
  componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[]
}

export const EventViewPopoverContent = ({
  allEvents,
  uniqueEvents,
  componentsMonthlyStatistics,
  componentTotalStatisticsAtEvent,
}: Readonly<IEventViewPopoverProps>) => {
  const sortedUniqueEvents = cloneDeep(uniqueEvents).sort((a, b) => (a.yearMonth as IYearMonth).year - (b.yearMonth as IYearMonth).year)
  const sortedAllEvents = cloneDeep(allEvents).sort((a, b) => (a.yearMonth as IYearMonth).year - (b.yearMonth as IYearMonth).year)
  const startYear: number = (sortedUniqueEvents[0]?.yearMonth as IYearMonth)?.year
  const finishYear: number = (sortedUniqueEvents[sortedUniqueEvents.length - 1]?.yearMonth as IYearMonth)?.year
  const years: number[] = []
  const [isEventViewPopoverOpen, setIsEventViewPopoverOpen] = useState<boolean>(false)
  const [eventYearMonth, setEventYearMonth] = useState<IYearMonth>()
  const [selectedEventsGrid, setSelectedEventsGrid] = useState<IMaintenanceScheduleDisplay[]>([])
  const getAllMonths = () => {
    const months = []
    for (let i = 0; i < 12; i++) {
      months.push(format(startOfMonth(new Date(2020, i, 1)), 'MMM'))
    }
    return months
  }

  const months: string[] = getAllMonths()

  const eventsGrid = useCallback(
    (events: IMaintenanceScheduleDisplay[], year: number, month: string) => {
      return events.filter((el) => {
        const { year: eventYear, month: eventMonth } = el.yearMonth as IYearMonth
        const monthIndex = months.findIndex((m) => m === month) + 1
        return eventYear === year && eventMonth === monthIndex
      })
    },
    [months],
  )

  const openEventDetailViewPopover = useCallback(
    (yearMonth: IYearMonth) => {
      setIsEventViewPopoverOpen(true)
      setEventYearMonth(yearMonth)
      setSelectedEventsGrid(eventsGrid(sortedAllEvents, yearMonth.year, months[yearMonth.month - 1]))
    },
    [eventsGrid, months, sortedAllEvents],
  )

  const closePopover = () => {
    setIsEventViewPopoverOpen(false)
  }

  const getBorderRadiusForBoxes = (quarterNumber: number) => {
    if (quarterNumber !== 4) {
      return 'border-r'
    } else {
      return ''
    }
  }

  const getBorderEntireBox = (quarterNumber: number) => {
    if (quarterNumber === 1) {
      return 'rounded-tl-lg'
    } else if (quarterNumber === 4) {
      return 'rounded-tr-lg'
    } else {
      return ''
    }
  }

  for (let index = startYear; index <= finishYear; index++) {
    years.push(index)
  }

  const getEventInYearMonth = (year: number, month: string): ReactElement => {
    const events = eventsGrid(sortedUniqueEvents, year, month)
    if (events.length === 0)
      return (
        <div className="h-full w-full p-2">
          <div className="text-left text-xs font-normal text-zinc-700">No Event</div>
        </div>
      )

    return <ComponentCheckTypeList eventsGrid={events} openEventDetailViewPopover={openEventDetailViewPopover} />
  }

  return (
    <>
      <div className="mt-6 h-[calc(100vh-8rem)] w-full overflow-y-scroll">
        <div className="inline-flex h-16 w-full items-start justify-between border-b border-cyan-700 bg-white py-4 pl-2 text-xl font-semibold text-zinc-950">
          From {startYear} - To {finishYear}
        </div>
        {years.map((year) => (
          <div key={year}>
            <div className="flex h-auto min-h-38 w-full bg-white">
              {[1, 2, 3, 4].map((quarterNumber) => (
                <div className={cn('h-auto w-1/4 border-stone-300 bg-white', getBorderRadiusForBoxes(quarterNumber))} key={quarterNumber}>
                  <div
                    className={cn(
                      'mb-4 inline-flex h-14 w-full items-center justify-center gap-4 bg-header-table p-4 text-center text-xl font-semibold text-zinc-950',
                      getBorderEntireBox(quarterNumber),
                    )}
                  >
                    Q{quarterNumber}
                  </div>
                  <div className="inline-flex h-auto min-h-20 w-full items-start justify-start">
                    {[1, 2, 3].map((monthsElement) => (
                      <div key={monthsElement} className="inline-flex h-auto w-1/3 flex-col items-start justify-start gap-2 px-2 pb-2">
                        <div className="inline-flex h-5 w-full items-center justify-start gap-1">
                          <div className="text-base font-bold leading-tight text-gray-800">
                            {months[3 * (quarterNumber - 1) + (monthsElement - 1)]}
                          </div>
                          <div className="text-base font-normal leading-tight text-gray-800">{year}</div>
                        </div>
                        <div className="h-px w-full bg-neutral-200"></div>
                        <div className="h-auto w-full">{getEventInYearMonth(year, months[3 * (quarterNumber - 1) + (monthsElement - 1)])}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <FullScreenPopover
        content={
          <EventDetailPopoverContent
            componentsMonthlyStatistics={componentsMonthlyStatistics}
            eventsGrid={selectedEventsGrid}
            yearMonth={eventYearMonth}
            componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
          />
        }
        isOpen={isEventViewPopoverOpen}
        onClose={closePopover}
        popoverTitle="Event Details"
      />
    </>
  )
}
