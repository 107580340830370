import { type EngineLifeLimitedPartDto, type AircraftEngineDto } from '@flyward/assets/models'
import { type UseFormSetValue, type UseFormRegister, type Control, type FormState } from 'react-hook-form'
import { EPRLessorContributionsEditableTab } from './EPRLessorContributionsEditableTab'
import { LLPsLessorContributionsEditableTab } from './LLPsLessorContributionsEditableTab'

interface IEngineLessorContributionsEditableTabProps {
  component: AircraftEngineDto
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
  register: UseFormRegister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

export const EngineLessorContributionsEditableTab = ({
  component,
  engineLifeLimitedParts,
  register,
  setFormValue,
  formControl,
  formState,
}: IEngineLessorContributionsEditableTabProps) => {
  return (
    <div>
      <div className="mb-4">
        <EPRLessorContributionsEditableTab
          component={component}
          register={register}
          setFormValue={setFormValue}
          formControl={formControl}
          formState={formState}
        />
      </div>
      <div>
        <LLPsLessorContributionsEditableTab
          component={component}
          engineLifeLimitedParts={engineLifeLimitedParts}
          register={register}
          setFormValue={setFormValue}
          formControl={formControl}
          formState={formState}
        />
      </div>
    </div>
  )
}
