import { NumberCellWithLabel, NumberDisplayType } from '@flyward/platform'
import { type BasicAssumption } from '../../models'

interface IBasicAssumptionsReadOnlyProps {
  basicAssumption: BasicAssumption | undefined
}

export const BasicAssumptionsReadOnly = ({ basicAssumption }: IBasicAssumptionsReadOnlyProps): React.ReactElement<IBasicAssumptionsReadOnlyProps> => {
  return (
    <div className="flex gap-6">
      <NumberCellWithLabel
        displayType={NumberDisplayType.Integer}
        className="basis-1/6"
        label="Check downtime (months)"
        info={basicAssumption?.checkDowntimeMonths}
      />

      <NumberCellWithLabel
        displayType={NumberDisplayType.Integer}
        className="basis-1/6"
        label="Delay in Payment on Claims(months)"
        info={basicAssumption?.monthsDelayInPaymentOnClaims}
      />
    </div>
  )
}
