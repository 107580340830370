import { FormattedNumber } from 'react-intl'

export enum NumberDisplayType {
  Integer,
  Decimal,
  Percentage,
  Currency,
  CurrencyRounded,
  Year,
}

interface INumberDisplayProps {
  className?: string
  decimals?: number
  currencyDecimals?: number
  displayType?: NumberDisplayType
  value: unknown
}

const NumberDisplay = ({ className, decimals = 2, currencyDecimals = 2, displayType = NumberDisplayType.Integer, value }: INumberDisplayProps) => {
  if (value === null || value === undefined || isNaN(value as number)) {
    return <span className={className}>-</span>
  }

  const getFormattedNumber = (): JSX.Element => {
    switch (displayType) {
      case NumberDisplayType.Integer:
        return <FormattedNumber value={Math.round(Number(value))} minimumFractionDigits={0} maximumFractionDigits={0} />
      case NumberDisplayType.Decimal:
        return <FormattedNumber value={Number(value)} minimumFractionDigits={0} maximumFractionDigits={decimals} />
      case NumberDisplayType.Percentage:
        return (
          <>
            <FormattedNumber value={Number(value)} minimumFractionDigits={0} maximumFractionDigits={decimals} />%
          </>
        )
      case NumberDisplayType.Currency:
        return (
          <FormattedNumber
            value={Number(value)}
            minimumFractionDigits={currencyDecimals}
            maximumFractionDigits={currencyDecimals}
            style="currency"
            currency="USD"
          />
        )
      case NumberDisplayType.CurrencyRounded:
        return (
          <FormattedNumber value={Math.ceil(Number(value))} minimumFractionDigits={0} maximumFractionDigits={0} style="currency" currency="USD" />
        )
      case NumberDisplayType.Year:
        return <>{Number(value)}</>
    }
  }

  return (
    <span className={className} data-rawvalue={value}>
      {getFormattedNumber()}
    </span>
  )
}

export { NumberDisplay }
