import { isNil } from 'lodash'
import { type AircraftEngineDto, type StandAloneEngineDTO } from '../../../../models'
import { StandAloneEngineDetails } from '../StandAloneEngineDetails'
import { EngineDetails } from './EngineDetails'

interface IEngineTechSpecProps {
  assetId: string
  engine: AircraftEngineDto | undefined
  standAloneEngine: StandAloneEngineDTO | undefined
}

export const EngineTechSpec = ({ engine, standAloneEngine, assetId }: IEngineTechSpecProps) => {
  return (
    <div className="flex flex-col gap-y-4">
      {!isNil(standAloneEngine) && <StandAloneEngineDetails standAloneEngine={standAloneEngine} assetId={assetId} />}
      {!isNil(engine) && <EngineDetails key={engine.componentId} engine={engine} />}
    </div>
  )
}
