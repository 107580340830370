import React, { type PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FullPageLoadingSpinner } from '../../components/FullPageSpinner/FullPageSpinner'
import { isNil } from 'lodash'

interface SpinnerStateContextType {
  isShowing: boolean
  showSpinner: (controlledDelaySeconds?: number) => void
  hideSpinner: (controlledDelaySeconds?: number) => void
}

const SpinnerStateContext = React.createContext<SpinnerStateContextType>({
  isShowing: false,
  showSpinner: () => {},
  hideSpinner: () => {},
})

const SpinnerStateContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isShowing, setIsShowing] = useState<boolean>(false)
  const [secondsSinceLoading, setSecondsSinceLoading] = useState<number>(0)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const changeSpinnerState = useCallback((state: boolean, controlledDelaySeconds?: number) => {
    if (!isNil(timeoutRef.current)) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(
      () => {
        setIsShowing(state)
      },
      !isNil(controlledDelaySeconds) ? controlledDelaySeconds * 1000 : 1000,
    )
  }, [])

  useEffect(() => {
    if (isShowing) {
      const interval = setInterval(() => {
        setSecondsSinceLoading((prevTime) => prevTime + 1)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    } else {
      setSecondsSinceLoading(0)
    }
  }, [isShowing])

  const value = useMemo(
    (): SpinnerStateContextType => ({
      isShowing,
      showSpinner: (controlledDelaySeconds) => {
        changeSpinnerState(true, controlledDelaySeconds)
      },
      hideSpinner: (controlledDelaySeconds) => {
        changeSpinnerState(false, controlledDelaySeconds)
      },
    }),
    [isShowing, changeSpinnerState],
  )

  return (
    <SpinnerStateContext.Provider value={value}>
      <FullPageLoadingSpinner
        isShowing={isShowing}
        secondsSinceLoading={secondsSinceLoading}
        hideSpinner={() => {
          changeSpinnerState(false, 0)
        }}
      />
      {children}
    </SpinnerStateContext.Provider>
  )
}

const useFullPageLoadingSpinner = () => React.useContext(SpinnerStateContext)
export { SpinnerStateContextProvider, useFullPageLoadingSpinner as useSpinnerState }
