import { useCallback, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { Input, type IInputProps, InputType } from '../Input'

export interface ISearchInputProps extends Omit<IInputProps, 'value' | 'type'> {
  resetValue: () => void
}

const minSearchLength = 2

export const SearchInput = ({
  inputClassName,
  disabled,
  label,
  placeholder,
  controlledValue,
  setValueAfterValidation: externalSetValue = (_value) => {},
  setRawValueOnChange = (_value) => {},
}: Readonly<ISearchInputProps>) => {
  const [value, setValue] = useState<string>(controlledValue ?? '')

  const handleExternalSetValue = useCallback(
    (updatedValue: string) => {
      if (updatedValue.length >= minSearchLength) {
        externalSetValue(updatedValue)
      }
    },
    [externalSetValue],
  )

  const debouncedSearch = useMemo(() => debounce(handleExternalSetValue, 300), [handleExternalSetValue])

  const handleSetValue = useCallback(
    (newValue: string) => {
      const updatedValue = newValue.trim()
      setValue(updatedValue)
      debouncedSearch(updatedValue)
    },
    [debouncedSearch],
  )

  return (
    <Input
      inputClassName={inputClassName}
      disabled={disabled}
      label={label}
      controlledValue={value}
      setValueAfterValidation={handleSetValue}
      setRawValueOnChange={setRawValueOnChange}
      placeholder={placeholder}
      type={InputType.Search}
    />
  )
}
