import { type AircraftEngineDto, type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto } from '@flyward/assets/models'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { contractualReserveEditableColumns } from './constants'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type Control, type FormState, type UseFormRegister, type UseFormSetValue } from 'react-hook-form'
import { BooleanCellWithLabel, EditableInputCellWithLabelWithLabel, InputType, TextCellWithLabel, UtilizationUnitsDisplay } from '@flyward/platform'

interface IContractualReserveEditableTabProps {
  component: AircraftEngineDto
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

const ContractualReserveEditableTab = ({ component, performanceRestorationReserves, formControl }: IContractualReserveEditableTabProps) => {
  const maintenanceReserve = component.engineContract.engineUnitMaintenanceReserveContract
  const contractualReserveTable = useReactTable<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto>({
    data: performanceRestorationReserves,
    columns: contractualReserveEditableColumns(formControl),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <>
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-6">
          <BooleanCellWithLabel
            className="basis-1/6"
            label="MR Flag First Run"
            info={component.engineContract.engineUnitMaintenanceReserveContract.isFirstRun}
            preventInfo={!component.engineContract.engineUnitMaintenanceReserveContract.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.Percentage}
            className="basis-1/6"
            label="PR MR Agreed Escalation"
            formControl={formControl}
            fieldName={`engineContract.escalations.eprEscalation.maintenanceReserveAgreedEscalationPercentage`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.Decimal}
            className="basis-1/6"
            label="PR MR rate"
            formControl={formControl}
            fieldName={`engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate.rateAmount`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label="PR MR rate type"
            info={UtilizationUnitsDisplay(
              component.engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate?.utilizationUnit,
            )}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.NaturalNumber}
            className="basis-1/6"
            label="Current PR MR fund"
            formControl={formControl}
            fieldName={`engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate.currentFund`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <div className="basis-1/6" />
        </div>
      </div>
      <div className="w-52">
        <GenericTable {...contractualReserveTable} />
      </div>
    </>
  )
}

export { ContractualReserveEditableTab }
export type { IContractualReserveEditableTabProps }
