import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IGetAllLandingGearsByAssetIdQueryResponse,
  type IGetAllLandingGearsByAssetIdQueryRequest,
  type IPutLandingGearsMaintenanceProgramMutationRequest,
  type IGetAllLandingGearChecksWithDetailsByAssetIdQueryResponse,
  type IGetAllLandingGearChecksWithDetailsByAssetIdQueryRequest,
  type IPutLandingGearMutationRequest,
} from './types'
import { ComponentType, ComponentTypeURI } from '@flyward/platform'
import { type AxiosError } from 'axios'

const landingGearsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLandingGearsByAssetId: builder.query<IGetAllLandingGearsByAssetIdQueryResponse, IGetAllLandingGearsByAssetIdQueryRequest>({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.LandingGearsController.GetAll(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'landingGears', id: request.assetId }],
    }),
    getAllLandingGearChecksWithDetailsByAssetId: builder.query<
      IGetAllLandingGearChecksWithDetailsByAssetIdQueryResponse,
      IGetAllLandingGearChecksWithDetailsByAssetIdQueryRequest
    >({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.LandingGearsController.GetExpandedLandingGears(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [
        'apiData',
        { type: 'componentChecks', id: `${request.assetId}-${ComponentTypeURI(ComponentType.LandingGear)}` },
      ],
    }),
    updateLandingGearsMaintenanceProgram: builder.mutation<void, IPutLandingGearsMaintenanceProgramMutationRequest>({
      queryFn: async (
        { assetId, kbProgramId, landingGears },
        _queryApi,
        _extraOptions,
        baseQuery,
      ): Promise<{ data: void } | { error: AxiosError<unknown, unknown> }> => {
        if (landingGears == null || landingGears.length === 0) {
          return { data: undefined }
        }

        // Iterate over the landingGears and update each one
        for (const landingGear of landingGears) {
          const landingGearUpdateResponse = await baseQuery({
            url: APIRoutes.AssetsModule.Components.LandingGearsController.Update(assetId),
            method: 'PUT',
            data: { ...landingGear, assignedKBProgramId: kbProgramId },
          })

          // If an error occurs while updating landingGears, return early
          if (landingGearUpdateResponse.error != null) {
            return { error: landingGearUpdateResponse.error }
          }
        }

        return { data: undefined }
      },
      invalidatesTags: (_, __, request) => [{ type: 'landingGears', id: request.assetId }],
    }),
    updateLandingGear: builder.mutation<string, IPutLandingGearMutationRequest>({
      query: ({ assetId, landingGear }) => ({
        url: APIRoutes.AssetsModule.Components.LandingGearsController.Update(assetId),
        method: 'PUT',
        data: landingGear,
      }),
      invalidatesTags: (_, __, request) => [{ type: 'landingGears', id: request.assetId }, { type: 'assets' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllLandingGearsByAssetIdQuery,
  useGetAllLandingGearChecksWithDetailsByAssetIdQuery,
  useUpdateLandingGearsMaintenanceProgramMutation,
  useUpdateLandingGearMutation,
  util: landingGearsApiSliceUtil,
} = landingGearsApi

type Endpoints = typeof landingGearsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TLandingGearsApiActions = InitiateActions[keyof InitiateActions]
