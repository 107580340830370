import { useEffect, useState } from 'react'
import {
  Button,
  ButtonVariant,
  Size,
  getFlyToTypeDisplayName,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  cn,
} from '@flyward/platform'
import { ReadonlyRatesParameters } from '@flyward/main-app/pages/FlyForward/FlyForwardPageBody/FlyForwardAssetDetails/FlyForwardParameters/Rates/ReadonlyRatesParameters'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'

export interface IReportFlyForwardParametersProps {
  parameters: FlyForwardParametersDTO
}

export const ReportFlyForwardParameters = ({ parameters }: IReportFlyForwardParametersProps) => {
  const [showAdditionalParams, setShowAdditionalParams] = useState<boolean>(false)
  const [ratio, setRatio] = useState<string>('')

  useEffect(() => {
    const computerRatio = parameters.averageMonthlyFlightHours / parameters.averageMonthlyFlightCycles
    setRatio(computerRatio % 1 === 0 ? computerRatio.toString() : computerRatio.toFixed(2))
  }, [parameters.averageMonthlyFlightCycles, parameters.averageMonthlyFlightHours])

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="mt-auto flex items-center gap-x-6">
          <div className="flex gap-x-2">
            <TextCellWithLabel label="Fly to" info={getFlyToTypeDisplayName(parameters.flyToType)} className="w-42" />
            <div className="mb-4 mt-auto h-1 w-2 bg-primary" />
            <TextCellWithLabel label="End" info={parameters.endDate} className="w-42" />
          </div>
          <NumberCellWithLabel
            label="Build Goal"
            info={parameters.engineLifeLimitedPartsBuildGoal}
            className="w-42"
            displayType={NumberDisplayType.Integer}
          />
        </div>
        <div>
          <p className="mt-4 !text-sm font-semibold">Average monthly utilisation</p>
          <div className="mt-2 flex items-center gap-x-6">
            <NumberCellWithLabel
              label="Flight Hours"
              info={parameters.averageMonthlyFlightHours}
              className="w-42"
              displayType={NumberDisplayType.Integer}
            />
            <NumberCellWithLabel
              label="Flight Cycle"
              info={parameters.averageMonthlyFlightCycles}
              className="w-42"
              displayType={NumberDisplayType.Integer}
            />
            <TextCellWithLabel label="Hours to Cycle Ratio" info={ratio} className="w-42" />
          </div>
        </div>
      </div>

      <Button
        variant={ButtonVariant.Ghost}
        size={Size.Medium}
        label={showAdditionalParams ? 'Close Parameters' : 'Additional Parameters'}
        className="ml-[56.625rem] mt-2"
        onClick={() => {
          setShowAdditionalParams((prevValue) => !prevValue)
        }}
      />
      <div className={cn('w-full', showAdditionalParams ? 'block' : 'hidden')}>
        <div className="mt-2 flex items-center gap-x-6">
          <NumberCellWithLabel
            label="Average APU Hours"
            info={parameters.averageMonthlyAPUHours}
            className="w-42"
            displayType={NumberDisplayType.Integer}
          />
        </div>
        <ReadonlyRatesParameters flyForwardParameters={parameters} />
      </div>
    </div>
  )
}

export default ReportFlyForwardParameters
