import { EditableInputCellWithLabelWithLabel, InputType, type TableColumn } from '@flyward/platform'
import { type DerateRatio } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { cloneDeep } from 'lodash'
import { type Path, type Control, type FieldValues } from 'react-hook-form'

interface IDerateRatiosEditableTableProps<TFormData extends FieldValues> {
  derateRatios: DerateRatio[] | undefined
  formControl: Control<TFormData, unknown>
  tableClassName?: string
}
const tableColumns = <TFormData extends FieldValues>(formControl: Control<TFormData, unknown>): Array<TableColumn<DerateRatio>> => [
  {
    accessor: 'id',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'intervalStart',
    title: 'Interval start',
    customCell: ({ rowIndex: index }) => {
      const filedName = `derateRatios.${index}.intervalStart` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
  {
    accessor: 'intervalEnd',
    title: 'Interval end',
    customCell: ({ rowIndex: index }) => {
      const filedName = `derateRatios.${index}.intervalEnd` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
  {
    accessor: 'percentage',
    title: 'Percentage',
    customCell: ({ rowIndex: index }) => {
      const filedName = `derateRatios.${index}.percentage` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-2/6',
    headerClassName: 'basis-2/6',
  },
]
export const DerateRatiosEditableTable = <TFormData extends FieldValues>({
  derateRatios,
  formControl,
  tableClassName = '',
}: IDerateRatiosEditableTableProps<TFormData>): React.ReactElement<IDerateRatiosEditableTableProps<TFormData>> => {
  return (
    <MaintenanceProgramDetailsTable
      columns={tableColumns(formControl)}
      data={cloneDeep(derateRatios)?.sort((a, b) => a.intervalStart - b.intervalStart)}
      tableClassName={tableClassName}
    />
  )
}
