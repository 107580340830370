import { isNil, isEmpty } from 'lodash'

export const LOCALSTORAGE_SUPPORT_FILTERED_TENANT_ID = 'Flyward_SupportFilteredTenantId'

const isNullOrEmpty = (value: unknown) => isNil(value) || isEmpty(value)

const persistSupportFilteredTenantId = (tenantId: string) => {
  if (isNullOrEmpty(tenantId)) {
    removePersistedSupportFilteredTenantId()
  }
  localStorage.setItem(LOCALSTORAGE_SUPPORT_FILTERED_TENANT_ID, JSON.stringify(tenantId))
}

const getPersistedSupportFilteredTenantId = (): string | null => {
  const tenantId = localStorage.getItem(LOCALSTORAGE_SUPPORT_FILTERED_TENANT_ID)
  if (isNullOrEmpty(tenantId)) return null
  try {
    const tokenData = JSON.parse(tenantId!)
    return tokenData
  } catch (error) {
    return null
  }
}

const removePersistedSupportFilteredTenantId = () => {
  localStorage.removeItem(LOCALSTORAGE_SUPPORT_FILTERED_TENANT_ID)
}

export { getPersistedSupportFilteredTenantId, persistSupportFilteredTenantId, removePersistedSupportFilteredTenantId }
