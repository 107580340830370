import { CheckTypeDisplay, formatDate, NumberCellWithLabel, NumberDisplayType, TextCellWithLabel } from '@flyward/platform'
import { Fragment } from 'react'
import { cloneDeep } from 'lodash'
import { type AirframeDto } from '@flyward/assets/models'

interface IAirframeTechnicalReadOnlyTabProps {
  airframe: AirframeDto
}

export const AirframeTechnicalReadOnlyTab = ({ airframe }: IAirframeTechnicalReadOnlyTabProps) => {
  const utilizationAtEvents = airframe.assetComponentUtilizationSnapshot.componentUtilizationAtEvents

  const UtilizationAtEventComponent = cloneDeep(utilizationAtEvents)
    .sort((a, b) => Number(a.eventType) - Number(b.eventType))
    .map((event) => {
      return (
        <Fragment key={event.eventType}>
          <TextCellWithLabel
            className="basis-1/6"
            label={`Date @ last ${CheckTypeDisplay(event.eventType)}`}
            info={formatDate(event.dateAtLastQualifyingEvent?.toString())}
          />
        </Fragment>
      )
    })
  return (
    <div className="flex w-full gap-x-6 gap-y-2">
      <NumberCellWithLabel
        className="basis-1/6"
        label="TSN"
        info={airframe.assetComponentUtilizationSnapshot.totalHoursSinceNew}
        displayType={NumberDisplayType.Integer}
      />
      <NumberCellWithLabel
        className="basis-1/6"
        label="CSN"
        info={airframe.assetComponentUtilizationSnapshot.cyclesSinceNew}
        displayType={NumberDisplayType.Integer}
      />
      {UtilizationAtEventComponent}
      <div className="mr-6 basis-2/6" />
    </div>
  )
}
