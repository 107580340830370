export enum Role {
  None = 0,
  NormalUser = 1,
  SuperUser = 2,
  Admin = 3,
}

export const RoleDisplay = (value: Role) => {
  switch (value) {
    case Role.None:
      return 'None'
    case Role.NormalUser:
      return 'Normal User'
    case Role.SuperUser:
      return 'Super User'
    case Role.Admin:
      return 'Admin'
  }
}
