import { AssetComponentsFormsProvider } from '@flyward/assets/context'
import { AircraftPageBody } from './AircraftPageBody'
import { AircraftPageHeader } from './AircraftPageHeader'

export const AircraftPage = () => {
  return (
    <AssetComponentsFormsProvider>
      <AircraftPageHeader />
      <AircraftPageBody />
    </AssetComponentsFormsProvider>
  )
}
