export interface IError500Response {
  errorMessage: string
}

const Error500Display = ({ errorMessage }: IError500Response) => {
  return (
    <div>
      <p>Internal server error:</p>
      <p>{errorMessage.slice(0, Math.min(errorMessage.length, 1000))}</p>
    </div>
  )
}

export { Error500Display }
