import { AssetComponentsFormsProvider } from '@flyward/assets/context'
import { StandaloneEnginePageBody } from './StandaloneEnginePageBody'
import { StandaloneEnginePageHeader } from './StandaloneEnginePageHeader'

export const StandaloneEnginePage = () => {
  return (
    <AssetComponentsFormsProvider>
      <StandaloneEnginePageHeader />
      <StandaloneEnginePageBody />
    </AssetComponentsFormsProvider>
  )
}
