/* eslint-disable max-len */
export enum LogoVariant {
  black = 'black',
  white = 'white',
  green = 'green',
}

const colorMap = {
  [LogoVariant.black]: '#070D0E',
  [LogoVariant.white]: '#FFFFFF',
  [LogoVariant.green]: '#4DA8B1',
}

interface ILogoProps {
  isCollapsed?: boolean
  height?: string
  variant?: LogoVariant
  width?: string
}

export const Logo = ({ isCollapsed = false, height, variant = LogoVariant.black, width }: ILogoProps): React.ReactElement<ILogoProps> => {
  const primaryColor = colorMap[variant]
  const secondaryColor = colorMap[LogoVariant.green]

  if (isCollapsed) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="m-auto" height={height ?? '18'} width={width ?? '18'} viewBox="0 0 18 18" fill="none">
        <path
          d="M14.6573 0H0.0078125V3.34225H12.4041L0.149163 15.5972L2.51868 17.9584L14.6573 5.81986V17.9917H17.9995V3.34225V0H14.6573Z"
          fill={secondaryColor}
        />
      </svg>
    )
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height ?? '18'} width={width ?? '126'} viewBox="0 0 126 18" fill="none">
      <path d="M4.21921 17.9834H0.876953V0H15.1439V2.80185H4.21921V17.9834Z" fill={primaryColor} />
      <path d="M12.2681 10.043H2.1582V12.8698H12.2681V10.043Z" fill={primaryColor} />
      <path d="M17.3145 17.9834V0H20.6567V15.1566H30.06V17.9834H17.3145Z" fill={primaryColor} />
      <path d="M43.2867 0H40.0026L34.6816 9.70254L29.4105 0H25.8438L32.9107 12.7455V17.9834H36.2447V12.7455L43.2867 0Z" fill={primaryColor} />
      <path
        d="M46.0393 17.9834L40.0781 0H43.5451L48.8661 16.2624H47.1451L52.6657 0H55.7502L61.1211 16.2624H59.45L64.8957 0H68.08L62.1188 17.9834H58.5686L53.6633 3.30901H54.5862L49.5978 17.9834H46.0393Z"
        fill={primaryColor}
      />
      <path
        d="M90.4609 17.9834V0H97.8605C99.4568 0 100.82 0.257737 101.959 0.77321C103.098 1.28868 103.98 2.02032 104.595 2.98476C105.21 3.94088 105.518 5.07991 105.518 6.40185C105.518 7.72379 105.21 8.8545 104.595 9.81062C103.98 10.7667 103.098 11.4901 101.959 11.9972C100.82 12.5044 99.4568 12.7538 97.8605 12.7538H92.3067L93.7949 11.2406V18H90.4609V17.9834ZM93.8032 11.5898L92.315 9.99354H97.7108C99.1824 9.99354 100.297 9.6776 101.036 9.04573C101.785 8.41386 102.151 7.53257 102.151 6.40185C102.151 5.2545 101.776 4.37321 101.036 3.75797C100.297 3.14273 99.1824 2.8351 97.7108 2.8351H92.315L93.8032 1.18891V11.5898ZM102.225 17.9834L97.6776 11.4568H101.253L105.825 17.9834H102.225Z"
        fill={primaryColor}
      />
      <path
        d="M107.447 17.9834V0H115.312C117.266 0 118.979 0.374134 120.45 1.13072C121.922 1.8873 123.069 2.92656 123.893 4.26513C124.716 5.6037 125.123 7.17506 125.123 8.99585C125.123 10.7917 124.716 12.3631 123.893 13.7099C123.069 15.0568 121.922 16.1044 120.45 16.861C118.979 17.6176 117.266 17.9917 115.312 17.9917H107.447V17.9834ZM110.79 15.1566H115.154C116.51 15.1566 117.674 14.8988 118.663 14.3834C119.644 13.8679 120.409 13.1529 120.949 12.2217C121.49 11.2988 121.756 10.218 121.756 8.98753C121.756 7.74042 121.49 6.65127 120.949 5.73672C120.409 4.82217 119.644 4.10716 118.663 3.59169C117.682 3.07622 116.51 2.81848 115.154 2.81848H110.79V15.1566Z"
        fill={primaryColor}
      />
      <path
        d="M84.5342 0H69.8848V3.34225H82.2811L70.0261 15.5972L72.3956 17.9584L84.5342 5.81986V17.9917H87.8765V3.34225V0H84.5342Z"
        fill={secondaryColor}
      />
    </svg>
  )
}
