import React, { useCallback } from 'react'
import { scaleLinear, scaleOrdinal } from '@visx/scale'
import { LinePath, Bar } from '@visx/shape'
import { curveMonotoneX } from '@visx/curve'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { GridRows, GridColumns } from '@visx/grid'
import { Group } from '@visx/group'
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend'
import { withParentSize } from '@visx/responsive'
import { useTooltip, defaultStyles, useTooltipInPortal, TooltipWithBounds } from '@visx/tooltip'
import {
  NumberDisplay,
  NumberDisplayType,
  YearMonth,
  type IYearMonth,
  type MonthlyStatistics,
} from '@flyward/platform'

const getMonthYear = (rawValue: unknown) => {
  if (rawValue === undefined) return ''
  const value: IYearMonth = rawValue as IYearMonth
  const yearMonth: YearMonth = new YearMonth(value.year, value.month)
  return yearMonth.toShortString()
}

const keys = ['dollarMaintenanceBurn', 'maintenanceReserveBalance', 'endOfLease', 'fullLifeShortfall'] as const
type Key = (typeof keys)[number]

const colors: Record<Key, string> = {
  dollarMaintenanceBurn: '#4472c4',
  maintenanceReserveBalance: '#ffc923',
  endOfLease: '#5b9bd5',
  fullLifeShortfall: '#70ad47',
}

const keyLabels: Record<Key, string> = {
  dollarMaintenanceBurn: 'Dollar Maint. Burn',
  maintenanceReserveBalance: 'MR Balance',
  endOfLease: 'EOL',
  fullLifeShortfall: 'Full Life Shortfall',
}

const mutableKeys: Key[] = Array.from(keys)

const ordinalColorScale = scaleOrdinal<Key, string>({
  domain: mutableKeys,
  range: Object.values(colors),
})

interface ChartProps {
  parentWidth: number
  parentHeight: number
  assetMonthlyStatistics: MonthlyStatistics[]
}

const tooltipStyles = {
  ...defaultStyles,
  background: 'rgba(0,0,0,0.9)',
  borderRadius: '4px',
  color: 'white',
}

export const ForecastingSummaryChart: React.FC<ChartProps> = ({
  parentWidth,
  parentHeight,
  assetMonthlyStatistics,
}) => {
  const { containerRef, containerBounds } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  })

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<MonthlyStatistics>({
    tooltipOpen: true,
    tooltipLeft: parentWidth / 3,
    tooltipTop: parentHeight / 3,
  })

  const xMax = parentWidth - 100
  const yMax = parentHeight - 100 // Adjusted to account for the legend

  const xScale = scaleLinear<number>({
    domain: [
      Math.min(...assetMonthlyStatistics.map((d) => YearMonth.fromObject(d.yearMonth).toDate().getTime())),
      Math.max(...assetMonthlyStatistics.map((d) => YearMonth.fromObject(d.yearMonth).toDate().getTime())),
    ],
    range: [0, xMax],
  })

  const yScale = scaleLinear<number>({
    domain: [
      0,
      Math.max(
        ...assetMonthlyStatistics.map((d) =>
          Math.max(d.dollarMaintenanceBurn, d.maintenanceReserveBalance, d.endOfLease, d.fullLifeShortfall),
        ),
      ),
    ],
    range: [yMax, 0],
    round: true,
  })

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGRectElement>, data: MonthlyStatistics) => {
      // coordinates should be relative to the container in which Tooltip is rendered
      const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left
      const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top
      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY,
        tooltipData: data,
      })
    },
    [showTooltip, containerBounds],
  )

  const TooltipComponent = TooltipWithBounds

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp)
    return date.toLocaleDateString('en-US', { year: '2-digit', month: 'short' })
  }

  const generateTooltipKey = (data: MonthlyStatistics) => {
    return `${data.yearMonth.year}-${data.yearMonth.month}-${data.eventMonth}`
  }

  return (
    <div className="relative mr-6 pr-6" ref={containerRef}>
      <svg width={parentWidth - 10} height={parentHeight - 40}>
        <Group left={60} top={20}>
          <GridRows scale={yScale} width={xMax} height={yMax} stroke="#e0e0e0" />
          <GridColumns scale={xScale} width={xMax} height={yMax} stroke="#e0e0e0" />
          <AxisLeft
            left={0}
            scale={yScale}
            tickFormat={(d: unknown) => {
              const value = d as number
              return value >= 1000 ? `$${(value / 1000000).toString()}m` : value.toString()
            }}
          />
          <AxisBottom top={yMax} scale={xScale} tickFormat={(d) => formatDate(d as number)} />
          {keys.map((key) => (
            <LinePath<MonthlyStatistics>
              key={key}
              data={assetMonthlyStatistics}
              x={(d) => xScale(YearMonth.fromObject(d.yearMonth).toDate().getTime())}
              y={(d) => yScale(d[key])}
              stroke={colors[key]}
              strokeWidth={2}
              curve={curveMonotoneX}
            />
          ))}
          {assetMonthlyStatistics.map((d) => (
            <Bar
              role="chart-bar"
              key={`bar-${d.eventMonth}`}
              x={xScale(YearMonth.fromObject(d.yearMonth).toDate().getTime())}
              y={0}
              width={10}
              height={yMax}
              fill="transparent"
              onPointerMove={(ev) => {
                handlePointerMove(ev, d)
              }}
              onMouseLeave={hideTooltip}
            />
          ))}
        </Group>
      </svg>
      <div className="mt-5 flex justify-center">
        <LegendOrdinal scale={ordinalColorScale} direction="row" labelMargin="0 15px 0 0">
          {(labels) =>
            labels.map((label) => (
              <LegendItem key={`legend-${label.text}`} className="pr-3">
                <svg width={15} height={15}>
                  <rect fill={label.value} width={15} height={15} />
                </svg>
                <LegendLabel className="pl-1 text-xs">{keyLabels[label.datum]}</LegendLabel>
              </LegendItem>
            ))
          }
        </LegendOrdinal>
      </div>
      {tooltipOpen && tooltipData != null ? (
        <>
          <div className="position-indicator" />
          <div className="crosshair horizontal" style={{ transform: `translateY(${tooltipTop}px)` }} />
          <div className="crosshair vertical" style={{ transform: `translateX(${tooltipLeft}px)` }} />
          <TooltipComponent
            key={generateTooltipKey(tooltipData)} // Use stable key generation method
            left={tooltipLeft}
            top={tooltipTop}
            style={tooltipStyles}
          >
            <p>
              <span className="pr-1">Date:</span>
              {getMonthYear(tooltipData.yearMonth)}
            </p>
            <p>
              <span className="pr-1">Dollar Maint. Burn:</span>
              <NumberDisplay
                displayType={NumberDisplayType.CurrencyRounded}
                value={tooltipData.dollarMaintenanceBurn}
              />
            </p>
            <p>
              <span className="pr-1">MR Balance:</span>
              <NumberDisplay
                displayType={NumberDisplayType.CurrencyRounded}
                value={tooltipData.maintenanceReserveBalance}
              />
            </p>
            <p>
              <span className="pr-1">EOL:</span>
              <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={tooltipData.endOfLease} />
            </p>
            <p>
              <span className="pr-1">Full Life Shortfall:</span>
              <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={tooltipData.fullLifeShortfall} />
            </p>
          </TooltipComponent>
        </>
      ) : null}
    </div>
  )
}

export const ResponsiveForecastingSummaryChart = withParentSize(ForecastingSummaryChart)
