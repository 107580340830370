import { useAssetComponentsForms } from '@flyward/assets/context'
import { type ApuDto } from '@flyward/assets/models'
import { type UseFormSetValue, type UseFormRegister, type Control, type FormState } from 'react-hook-form'
import { ApuLessorContributionsReadonlyTab } from './ApuLessorContributionsReadonlyTab'
import { ApuLessorContributionsEditableTab } from './ApuLessorContributionsEditableTab'

interface IApuLessorContributionsTabProps {
  component: ApuDto
  registerFormField: UseFormRegister<ApuDto>
  setFormValue: UseFormSetValue<ApuDto>
  formControl: Control<ApuDto, unknown>
  formState: FormState<ApuDto>
}

export const ApuLessorContributionsTab = ({
  component,
  registerFormField,
  setFormValue,
  formControl,
  formState,
}: IApuLessorContributionsTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return isInEditMode ? (
    <ApuLessorContributionsEditableTab
      component={component}
      register={registerFormField}
      setFormValue={setFormValue}
      formControl={formControl}
      formState={formState}
    />
  ) : (
    <ApuLessorContributionsReadonlyTab component={component} />
  )
}
