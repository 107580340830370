import { AssetType, Label, RadioGroup, RadioGroupItem } from '@flyward/platform'

interface IAssetTypeFilterProps {
  assetType: AssetType | undefined
  setAssetType: (assetType: AssetType | undefined) => void
  allowAllOption?: boolean
  id: string
}

export const AssetTypeFilter = ({ assetType = undefined, setAssetType, allowAllOption = true, id }: IAssetTypeFilterProps) => {
  return (
    <div className="flex">
      <RadioGroup
        value={assetType?.toString() ?? 'all'}
        className="flex"
        onValueChange={(value) => {
          if (value === 'all') {
            setAssetType(undefined)
          } else {
            setAssetType(value as unknown as AssetType)
          }
        }}
      >
        {allowAllOption && (
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="all" id="all" />
            <Label htmlFor="all">All</Label>
          </div>
        )}
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={AssetType.Aircraft.toString()} id={`${id}-aircraft`} data-testid={`${id}-aircraft-radio`} />
          <Label htmlFor={`${id}-aircraft`}>Aircraft</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={AssetType.StandaloneEngine.toString()} id={`${id}-engine`} data-testid={`${id}-engine-radio`} />
          <Label htmlFor={`${id}-engine`}>Standalone Engine</Label>
        </div>
      </RadioGroup>
    </div>
  )
}
