import { EscalationDtoSchema, type EscalationDto } from '../../contract/EscalationDto'
import { z } from 'zod'

/**
 * Cloned from API:Assets.Core.Domain.DTOs.Components.Engine.EngineUnitEscalationDto
 */
export interface EngineUnitEscalationDto {
  eprEscalation: EscalationDto
  llPsEscalation: EscalationDto
}

export const EngineUnitEscalationSchema = z.object({
  eprEscalation: EscalationDtoSchema,
  llPsEscalation: EscalationDtoSchema,
})
