import { ComponentSchema, type ComponentDto } from '../../ComponentDto'
import { AuxiliaryPowerUnitContractSchema, type AuxiliaryPowerUnitContract } from './auxiliaryPowerUnitContract'
import { LessorContributionDtoSchema, type LessorContributionDto } from '../../DTOs/lessorContributionDto'
import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Apu.ApuDto
 */
export interface ApuDto extends ComponentDto {
  auxiliaryPowerUnitContract: AuxiliaryPowerUnitContract
  lessorContribution: LessorContributionDto
}

export const ApuDtoSchema = ComponentSchema.extend({
  auxiliaryPowerUnitPRUtilizationSnapshot: z.any().nullable(),
  auxiliaryPowerUnitContract: AuxiliaryPowerUnitContractSchema,
  lessorContribution: LessorContributionDtoSchema.optional().nullable(),
})
