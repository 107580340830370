import { type AircraftEngineDto, type EngineLifeLimitedPartDto } from '@flyward/assets/models'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { llpTechnicalEditableColumns } from './constants'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type Control, type FormState, type UseFormRegister, type UseFormSetValue } from 'react-hook-form'

interface ILLPTechnicalEditableTabProps {
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

const LLPTechnicalEditableTab = ({ engineLifeLimitedParts, formControl }: ILLPTechnicalEditableTabProps) => {
  const llpTechnicalTable = useReactTable<EngineLifeLimitedPartDto>({
    data: engineLifeLimitedParts,
    columns: llpTechnicalEditableColumns(formControl),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable {...llpTechnicalTable} />
}

export { LLPTechnicalEditableTab }
export type { ILLPTechnicalEditableTabProps }
