import { GroupHeader, SimpleCell, SimpleHeader } from '@flyward/platform'
import { type ColumnDef } from '@tanstack/react-table'
import { Label, type ExpandedRowFields, type IExpandedColumnDefs, type IRowProperty } from './types'

const rowProperties: IRowProperty[] = [
  { label: Label.Type, property: 'model' },
  { label: Label.TSN, property: 'totalHoursSinceNew' },
  { label: Label.CSN, property: 'cyclesSinceNew' },
  {
    label: Label.TSLPR,
    property: 'hoursSinceLastPr',
    overriddenProperties: [
      {
        name: 'airframe',
        value: 'N/A',
      },
      {
        name: 'landingGear',
        value: 'N/A',
      },
      // APU has TSLPR
    ],
  },
  {
    label: Label.CSLPR,
    property: 'cyclesSinceLastPr',
    overriddenProperties: [
      {
        name: 'airframe',
        value: 'N/A',
      },
      {
        name: 'landingGear',
        value: 'N/A',
      },
      {
        name: 'apu',
        value: 'N/A',
      },
    ],
  },
  { label: Label.LLP_FC_rem, property: 'llpCyclesRemaining' },
  { label: Label.LLP_descriptor, property: 'llpDescriptor' },
  {
    label: Label.PR_fund,
    property: 'prFund',
    overriddenProperties: [
      {
        name: 'airframe',
        value: 'N/A',
      },
    ],
  },
  { label: Label.LLP_fund, property: 'llpsFund' },
]

const getStringValue = (getValue: () => unknown): string => {
  const value = getValue()
  return value as string
}

const generateColumns = (columnDefs: IExpandedColumnDefs): Array<ColumnDef<ExpandedRowFields>> => [
  {
    accessorKey: 'name',
    header: () => <GroupHeader className="m-0 justify-start bg-transparent p-2 text-xs font-normal text-text-2" title="Serial No" />,
    cell: ({ getValue }) => <SimpleCell>{getStringValue(getValue)}</SimpleCell>,
  },
  {
    id: 'airframe',
    header: () => <GroupHeader title="Airframe" />,
    columns: columnDefs.airframe.map((key) => ({
      id: `airframeEsn${key}`,
      accessorFn: (row) => row.airframe[key],
      header: () => <SimpleHeader title={key} />,
      cell: ({ getValue }) => <SimpleCell>{getStringValue(getValue)}</SimpleCell>,
    })),
  },
  {
    id: 'engine',
    header: () => <GroupHeader title="Engine" />,
    columns: columnDefs.engine.map((key) => ({
      id: `engineEsn${key}`,
      accessorFn: (row) => row.engine[key],
      header: () => <SimpleHeader title={key} />,
      cell: ({ getValue }) => <SimpleCell>{getStringValue(getValue)}</SimpleCell>,
    })),
  },
  {
    id: 'landingGear',
    header: () => <GroupHeader title="Landing Gear" />,
    columns: columnDefs.landingGear.map((key) => ({
      id: `lgSn${key}`,
      accessorFn: (row) => row.landingGear[key],
      header: () => <SimpleHeader title={key} />,
      cell: ({ getValue }) => <SimpleCell>{getStringValue(getValue)}</SimpleCell>,
    })),
  },
  {
    id: 'apu',
    header: () => <GroupHeader title="APU" />,
    columns: columnDefs.apu.map((key) => ({
      id: `apuSn${key}`,
      accessorFn: (row) => row.apu[key],
      header: () => <SimpleHeader title={key} />,
      cell: ({ getValue }) => <SimpleCell>{getStringValue(getValue)}</SimpleCell>,
    })),
  },
]

export { generateColumns, Label, rowProperties }
