/* eslint-disable @typescript-eslint/no-misused-promises */
import { AircraftEngineDtoSchema, type AircraftEngineDto } from '@flyward/assets/models'
import { CheckType, CnForm, ComponentContainer } from '@flyward/platform'
import { isNil, maxBy, orderBy } from 'lodash'
import { EngineDetailsTabs } from './EngineDetailsTabs'
import { type ComponentUtilizationAtEventDto } from '@flyward/platform/models/entities/Utilization'
import { EngineDetailsGeneral } from './EngineDetailsTabs/EngineDetailsGeneral'
import { useEffect, useRef } from 'react'
import { useAssetComponentsForms } from '@flyward/assets/context'
import { useUpdateEngineMutation } from '@flyward/assets/store'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

interface IEngineDetailsProps {
  engine: AircraftEngineDto
}

const EngineDetails = ({ engine }: IEngineDetailsProps) => {
  const { registerFormRef } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)
  const [updateEngine] = useUpdateEngineMutation()

  const form = useForm<AircraftEngineDto>({
    defaultValues: engine,
    values: engine,
    resolver: zodResolver(AircraftEngineDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    register: registerComponentFormField,
    handleSubmit: handleComponentUpdate,
    formState,
    getValues: getComponentFormValues,
    setValue: setComponentFormValue,
    control: componentFormControl,
    trigger: triggerComponentValidation,
  } = form

  const componentFormValues: AircraftEngineDto = getComponentFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('Engine formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerComponentValidation()
  }, [isValid, triggerComponentValidation])

  useEffect(() => {
    registerFormRef({ id: [componentFormValues.assetId, componentFormValues.componentId], submitRef, hasChanges: isDirty, isValid })
  }, [registerFormRef, componentFormValues, isDirty, isValid])

  const onUpdateComponent: SubmitHandler<AircraftEngineDto> = (data) => {
    updateEngine({ assetId: engine.assetId, engine: data })
  }

  let lastPerformanceRestoration: ComponentUtilizationAtEventDto | null = null
  let performanceRestorationReserves = []

  const eprs = engine.assetComponentUtilizationSnapshot.componentUtilizationAtEvents.filter((e) => e.eventType === CheckType.Epr)
  const lastEpr = maxBy(eprs, (e) => e.totalHoursSinceNewAtLastQualifyingEvent)
  if (!isNil(lastEpr)) {
    lastPerformanceRestoration = lastEpr
  }

  const orderedReserves = orderBy(
    engine.engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves,
    (r) => r.flightHoursToFlightCyclesRatio,
  )
  performanceRestorationReserves = orderedReserves

  return (
    <CnForm {...form}>
      <form onSubmit={handleComponentUpdate(onUpdateComponent)}>
        <ComponentContainer className="p-6">
          <div className="flex w-full flex-col">
            <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">Engine</p>
            <EngineDetailsGeneral engine={engine} setFormValue={setComponentFormValue} formControl={componentFormControl} />
            <EngineDetailsTabs
              engine={engine}
              lastPerformanceRestoration={lastPerformanceRestoration}
              performanceRestorationReserves={performanceRestorationReserves}
              registerFormField={registerComponentFormField}
              setFormValue={setComponentFormValue}
              formControl={componentFormControl}
              formState={formState}
              componentFormValues={componentFormValues}
            />
          </div>
        </ComponentContainer>
        <input type="submit" className="hidden" ref={submitRef} />
      </form>
    </CnForm>
  )
}

export { EngineDetails }
export type { IEngineDetailsProps }
