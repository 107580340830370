import { type ReactElement } from 'react'
import { Button, ButtonVariant } from '../Button'
import { IconVariant } from '../Icon'
import { Size } from '../common'
import { ErrorBoundary } from '@flyward/platform/hoc'

interface IFullScreenPopoverProps {
  content: ReactElement
  isOpen: boolean
  onClose: () => void
  popoverTitle: string
}

const FullScreenPopover = ({ content, isOpen = false, onClose, popoverTitle }: Readonly<IFullScreenPopoverProps>) => {
  if (!isOpen) {
    return null
  }

  return (
    <div className="fixed inset-0 z-[100] flex h-screen w-screen flex-shrink-0 items-center justify-center">
      <div className="shadow-md h-screen w-screen rounded bg-background-light p-6 text-center">
        <div className="flex-col">
          <div className="inline-flex h-10 w-full items-center justify-start gap-4">
            <Button
              leftIcon={IconVariant.ArrowBack}
              variant={ButtonVariant.Ghost}
              size={Size.Large}
              onClick={onClose}
              data-testid="header-back-button"
              className=" ml-0 pl-0 text-black-100"
            />
            <div className="text-h6 font-semibold leading-10 text-zinc-950">{popoverTitle}</div>
          </div>
          <div className="flex-1">
            <ErrorBoundary>{content}</ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  )
}

export { FullScreenPopover }
export type { IFullScreenPopoverProps }
