import React, { type PropsWithChildren, useCallback, useMemo, useState } from 'react'

interface NavigationStateContextType {
  isCollapsed: boolean
  toggleExpansion: () => void
  isHidden: boolean
  toggleVisibility: () => void
  isNavigationHidden: boolean
  toggleNavigationVisibility: () => void
}

const NavigationStateContext = React.createContext<NavigationStateContextType>({
  isCollapsed: false,
  toggleExpansion: () => {},
  isHidden: false,
  toggleVisibility: () => {},
  isNavigationHidden: false,
  toggleNavigationVisibility: () => {},
})

const NavigationStateContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [isNavigationHidden, setIsNavigationHidden] = useState(false)

  const toggleExpansion = useCallback(() => {
    setIsCollapsed((prevValue) => !prevValue)
  }, [])

  const toggleVisibility = useCallback(() => {
    setIsHidden((prevValue) => !prevValue)
  }, [])

  const toggleNavigationVisibility = useCallback(() => {
    setIsNavigationHidden((prevValue) => !prevValue)
  }, [])

  const value = useMemo(
    (): NavigationStateContextType => ({
      isCollapsed,
      toggleExpansion,
      isHidden,
      toggleVisibility,
      isNavigationHidden,
      toggleNavigationVisibility,
    }),
    [isCollapsed, isHidden, isNavigationHidden, toggleExpansion, toggleNavigationVisibility, toggleVisibility],
  )
  return <NavigationStateContext.Provider value={value}>{children}</NavigationStateContext.Provider>
}

const useNavigationState = () => React.useContext(NavigationStateContext)

export { NavigationStateContextProvider, useNavigationState }
