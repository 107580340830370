import { type ComponentType } from 'react'
import { type FallbackProps } from 'react-error-boundary'
import { Button, ButtonVariant } from '../components/Button'
import { IconVariant } from '../components/Icon/IconVariant'

const DefaultErrorFallback: ComponentType<FallbackProps> = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div role="alert">
      <p className="text-red-500">Something went wrong...</p>
      <pre>{error.message}</pre>
      <Button variant={ButtonVariant.Ghost} onClick={resetErrorBoundary} leftIcon={IconVariant.Refresh}>
        Try again
      </Button>
    </div>
  )
}

export { DefaultErrorFallback }
