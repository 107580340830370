import { LessorContributionDtoSchema, type LessorContributionDto } from '../../DTOs/lessorContributionDto'
import { z } from 'zod'

/**
 * Cloned from Assets.Core.Domain.DTOs.Components.Engine.LlpLessorContributionDto
 */
export interface LlpLessorContributionDto {
  llpId?: string
  lessorContribution?: LessorContributionDto
}

export const LlpLessorContributionDtoSchema = z.object({
  llpId: z.any(),
  lessorContribution: LessorContributionDtoSchema,
})
