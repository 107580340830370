import { cn } from '../../utils'

export interface GroupHeaderProps {
  title: string
  className?: string
}

export const GroupHeader = ({ title, className = '' }: GroupHeaderProps) => (
  <span
    className={cn(
      'flex items-center justify-center text-sm',
      'mx-6 mt-2 rounded-xl bg-header-table px-2 py-1 text-primary-dark-2',
      className,
    )}
  >
    {title}
  </span>
)
