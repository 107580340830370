import { type AircraftEngineDto } from '@flyward/assets/models'
import { type EngineLessorContributionsDto } from '@flyward/assets/models/aircraftComponents/aircraftEngine/EngineLessorContributionsDto'
import {
  CheckType,
  CheckTypeDisplay,
  CnFormControl,
  CnFormField,
  CnFormItem,
  EditableInputCellWithLabelWithLabel,
  InputType,
  SimpleHeader,
} from '@flyward/platform'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { isNil } from 'lodash'
import { type UseFormSetValue, type UseFormRegister, type Control, type FormState } from 'react-hook-form'

interface EPRLessorContributionsEditableTabProps {
  component: AircraftEngineDto
  register: UseFormRegister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

const generateGridColumns = (
  lessorContributions: EngineLessorContributionsDto[],
  formControl: Control<AircraftEngineDto, unknown>,
): Array<ColumnDef<EngineLessorContributionsDto>> => {
  const contractualReserveColumns: Array<ColumnDef<EngineLessorContributionsDto>> = [
    {
      id: 'checkType',
      accessorKey: 'checkType',
      header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: () => <div className="text-center text-xs">{CheckTypeDisplay(CheckType.Epr)}</div>,
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      header: () => <SimpleHeader title="Applicable" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: () => (
        <div className="text-center text-xs">
          <CnFormField
            control={formControl}
            name={`lessorContributions.eprLessorContribution.isActive`}
            render={({ field }) => {
              return (
                <CnFormItem>
                  <CnFormControl>
                    <select
                      defaultValue={field.value?.toString() ?? 'false'}
                      onChange={(e) => {
                        const value = e.target.value === 'true'
                        field.onChange(value)
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </CnFormControl>
                </CnFormItem>
              )
            }}
          />
        </div>
      ),
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'isUnlimited',
      accessorKey: 'isUnlimited',
      header: () => <SimpleHeader title="Unlimited" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (lessorContributions[row.index].eprLessorContribution?.isActive === true) {
          return (
            <div className="text-center text-xs">
              <CnFormField
                control={formControl}
                name={`lessorContributions.eprLessorContribution.isUnlimited`}
                render={({ field }) => {
                  return (
                    <CnFormItem>
                      <CnFormControl>
                        <select
                          defaultValue={field.value?.toString() ?? 'false'}
                          onChange={(e) => {
                            const value = e.target.value === 'true'
                            field.onChange(value)
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </CnFormControl>
                    </CnFormItem>
                  )
                }}
              />
            </div>
          )
        } else {
          return <div className="flex h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => <SimpleHeader title="Total Amount" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original.eprLessorContribution?.isUnlimited !== true && row.original.eprLessorContribution?.isActive === true) {
          return (
            <div className="text-center text-xs">
              <EditableInputCellWithLabelWithLabel
                inputType={InputType.NaturalNumber}
                className="basis-1/6"
                formControl={formControl}
                fieldName={`lessorContributions.eprLessorContribution.amount`}
              />
            </div>
          )
        } else {
          return <div className="flex h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-6/24',
      },
    },
  ]

  return contractualReserveColumns
}

export const EPRLessorContributionsEditableTab = ({ component, formControl }: EPRLessorContributionsEditableTabProps) => {
  const lessorContributions = !isNil(component.lessorContributions) ? [component.lessorContributions] : new Array<EngineLessorContributionsDto>()

  const contractualReserveColumns = generateGridColumns(lessorContributions, formControl)

  const contractualReserveTable = useReactTable<EngineLessorContributionsDto>({
    data: lessorContributions,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="w-100">
      <GenericTable {...contractualReserveTable} />
    </div>
  )
}
