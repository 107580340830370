import { combineReducers } from '@reduxjs/toolkit'
import { authReducer, flywardApi, flyForwardReducer, fleetReducer } from './slices'

const rootReducer = combineReducers({
  [flywardApi.reducerPath]: flywardApi.reducer,
  auth: authReducer,
  flyForward: flyForwardReducer,
  fleet: fleetReducer,
})

export { rootReducer }
export type TRootState = ReturnType<typeof rootReducer>
