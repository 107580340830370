import { Button, ButtonVariant, Combobox, DatePicker, type IdWithNameDto, SearchInput } from '@flyward/platform'
import { formatDate } from 'date-fns'
import { isNil } from 'lodash'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { type ReportSearchInput } from '../../../models/DTOs/Reports/ReportSearchInput'

interface IReportsSearchFilterProps {
  users: IdWithNameDto[]
  filter: ReportSearchInput
  onFilterUpdate: (filter: Partial<ReportSearchInput>) => void
}

export const ReportsSearchFilter = ({ users, filter, onFilterUpdate }: IReportsSearchFilterProps) => {
  const [componentKey, setComponentKey] = useState<string>(uuidv4())

  const usersOptions: Array<{ value: string; label: string }> = users.map((user) => ({
    value: user.id,
    label: user.name,
  }))

  const handleDeleteAllFilters = () => {
    onFilterUpdate({
      ...filter,
      Name: '',
      StartDate: '',
      EndDate: '',
      UserIds: [],
    })
    setComponentKey(uuidv4()) // Force re-render by updating the key
  }

  return (
    <div key={componentKey} className="flex items-end gap-4 bg-header-table p-4 pb-8">
      <SearchInput
        inputClassName="w-40"
        setValueAfterValidation={(value: string) => {
          onFilterUpdate({ ...filter, Name: value })
        }}
        resetValue={() => {
          onFilterUpdate({ ...filter, Name: '' })
        }}
        placeholder="Search"
      />
      <div className="flex items-end gap-2">
        <DatePicker
          label="Filter by Date"
          limit={
            filter.EndDate !== ''
              ? {
                  value: new Date(filter.EndDate),
                  type: 'max',
                }
              : undefined
          }
          setValue={(value?: Date) => {
            if (isNil(value)) {
              onFilterUpdate({ ...filter, StartDate: '' })
              return
            }

            const formattedDate = formatDate(value, 'yyyy-MM-dd')
            onFilterUpdate({ ...filter, StartDate: formattedDate })
          }}
        />
        <span className="h-8">-</span>
        <DatePicker
          label=" "
          limit={
            filter.StartDate !== ''
              ? {
                  value: new Date(filter.StartDate),
                  type: 'min',
                }
              : undefined
          }
          setValue={(value?: Date) => {
            if (isNil(value)) {
              onFilterUpdate({ ...filter, EndDate: '' })
              return
            }

            const formattedDate = formatDate(value, 'yyyy-MM-dd')
            onFilterUpdate({ ...filter, EndDate: formattedDate })
          }}
        />
      </div>

      <Combobox
        items={usersOptions}
        itemLabel="user"
        label="Filter by User"
        isMultiselect
        className="w-52"
        selectedValues={filter.UserIds}
        setSelectedValues={(value: string[]) => {
          onFilterUpdate({ ...filter, UserIds: value })
        }}
      />

      <div className="flex h-9">
        <Button variant={ButtonVariant.Ghost} label="Delete all Filters" className="text-xs font-semibold" onClick={handleDeleteAllFilters} />
      </div>
    </div>
  )
}
