import { useAssetComponentsForms } from '@flyward/assets/context'
import { type ApuDto } from '@flyward/assets/models'
import { type UseFormSetValue, type Control } from 'react-hook-form'
import { APUTechnicalEditableTab } from './APUTechnicalEditableTab'
import { APUTechnicalReadOnlyTab } from './APUTechnicalReadOnlyTab'

interface IAPUTechnicalTabProps {
  component: ApuDto
  setFormValue: UseFormSetValue<ApuDto>
  formControl: Control<ApuDto, unknown>
}

export const APUTechnicalTab = ({ component, setFormValue, formControl }: IAPUTechnicalTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return (
    <>
      {isInEditMode ? (
        <APUTechnicalEditableTab component={component} setFormValue={setFormValue} formControl={formControl} />
      ) : (
        <APUTechnicalReadOnlyTab component={component} />
      )}
    </>
  )
}
