import { isEmpty, isNil } from 'lodash'
import { type ReactNode, Suspense, useEffect, useState } from 'react'
import { Tabs as CnTabs, TabsContent as CnTabsContent, TabsList as CnTabsList, TabsTrigger as CnTabsTrigger } from '../_shadcn'
import { cn } from '../utils'
import styles from './TabsBase.module.css'

export type ITabs = Record<string, { content: ReactNode; tabContent?: ReactNode; onActiveTabClick?: () => void }>

interface ITabsBaseProps {
  tabs: ITabs
  controlledActiveTab?: string // Optional prop to set active tab externally
  activeTabContentClassName?: string
  activeTabHeaderClassName?: string
  activeTabHeaderLabelClassName?: string
  columnClassName?: string
  headerCellClassName?: string
  headerClassName?: string
  onTabChanged?: (newActiveTab: string) => void
  tabHeaderLabelClassName?: string
}

const TabsBase = ({
  tabs,
  controlledActiveTab = '',
  activeTabContentClassName = '',
  activeTabHeaderClassName = '',
  activeTabHeaderLabelClassName = '',
  columnClassName = '',
  headerCellClassName = '',
  headerClassName = '',
  onTabChanged = () => {},
  tabHeaderLabelClassName = '',
}: Readonly<ITabsBaseProps>) => {
  const keys = Object.keys(tabs)
  const defaultTab = keys[0]
  const [currentTabName, setCurrentTabName] = useState('')
  const [previousTabName, setPreviousTabName] = useState('')

  const handleTabChange = (newTab: string) => {
    setPreviousTabName(currentTabName)
    setCurrentTabName(newTab)
    onTabChanged(newTab)
  }

  useEffect(() => {
    setCurrentTabName(defaultTab)
    return () => {
      setCurrentTabName('')
    }
  }, [defaultTab])

  useEffect(() => {
    if (!isEmpty(controlledActiveTab)) {
      setCurrentTabName(controlledActiveTab)
    }
  }, [controlledActiveTab, onTabChanged])

  return (
    <CnTabs
      onValueChange={(value) => {
        handleTabChange(value)
      }}
      defaultValue={controlledActiveTab}
      value={currentTabName}
      className={cn('flex w-full flex-grow flex-col', columnClassName)}
    >
      <CnTabsList className={cn('flex w-full', headerClassName)}>
        {keys.map((key, index) => {
          const tab = tabs[key]

          const isActive = key === currentTabName
          const activeClassNames = isActive ? activeTabHeaderLabelClassName : ''
          return (
            <CnTabsTrigger
              key={key}
              value={key}
              onClick={() => {
                handleTabChange(key)
                const isOnSameTab = previousTabName === key
                if (isOnSameTab && isActive && tab.onActiveTabClick !== undefined && tab.onActiveTabClick !== null) {
                  tab.onActiveTabClick()
                }
              }}
              className={cn('mt-auto', columnClassName, headerCellClassName)}
              activeClassName={activeTabHeaderClassName}
              {...(isActive ? { 'data-state': 'active' } : { 'data-state': 'inactive' })}
            >
              <div
                className={cn(
                  'w-full font-semibold text-text-1',
                  tabHeaderLabelClassName,
                  activeClassNames,
                  index === 0 ? 'mx-[1px]' : 'mr-[1px]',
                  isActive ?? styles.TabsBoxShadow,
                )}
              >
                {isNil(tabs?.[key]?.tabContent) ? key : tabs?.[key]?.tabContent}
              </div>
            </CnTabsTrigger>
          )
        })}
      </CnTabsList>
      <div className={cn('flex flex-grow flex-col rounded-none border-t border-black-10', activeTabContentClassName, columnClassName)}>
        {Object.entries(tabs).map(([key, value]) => (
          <CnTabsContent key={key} value={key}>
            <Suspense fallback={<div>Loading...</div>}>{value.content}</Suspense>
          </CnTabsContent>
        ))}
      </div>
    </CnTabs>
  )
}

export { TabsBase, type ITabsBaseProps }
