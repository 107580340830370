export class PaginatedResult<T> {
  items: T[]
  totalCount: number
  currentPage: number
  pageSize: number

  constructor(items: T[], totalCount: number, currentPage: number, pageSize: number) {
    this.items = items
    this.totalCount = totalCount
    this.currentPage = currentPage
    this.pageSize = pageSize
  }
}
